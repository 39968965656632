import React, {Component} from "react";
// import {Col, Row, Card, Table, Button} from "antd"; import Auxiliary from
// "util/Auxiliary";
import {Link} from 'react-router-dom'
import {
    Button,
    Card,
    Col,
    Form,
    Input,
    Row,
    Select,
    message,
    Upload,
    Icon,
    notification,
    Modal
} from "antd";
import {connect} from "react-redux";
import {add_staff, update_staff,get_role} from "appRedux/actions/StaffActions";
import { getUserDetails,hideMessage,changestatus,update_user,update_staff_user } from "appRedux/actions/Auth";
import {baseURL} from 'util/config'
const FormItem = Form.Item;
var admin_roleid ='' ;
const { Option } = Select;
var roledata = [];

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }
   
  function beforeUpload(file) {
    // const isJPG = file.type === 'image/jpeg';
    // if (!isJPG) {
    //   message.error('You can only upload JPG file!');
    // }
    
    // return isJPG && isLt2M;

    if(file.type === '' || null){
        message.error('You can only upload image file!');
    // return true;
    }
    else{
        if ('image/jpeg' == file.type || 'image/png' == file.type || 'image/jpg' == file.type) {
            
        }
        else{
            message.error('You can only upload image file!');
        }
    }
  }

class Updatestaff extends Component {

    constructor(props) {
        super(props);
        this.state = {
            confirmDirty: false,
            fname: '',
            lname: '',
            email: '',
            isUpdate: true,
            isAdd: false,
            userid: '',
            username: '',
            cardTitle: 'Update Profile',
            editdata: '',
            disabled: '',
            loading: false,
            previewVisible: false,
            previewImage: "",
            fileList: []
        };
    }
    handlePreview = file => {

        if(file.type === '' || null){
            // return true;
          }
          else{
            if (file.type == 'image/jpeg' || 'image/png' || 'image/jpg') {
              // return true;
              this.setState({
                previewImage: file.thumbUrl,
                previewVisible : true
              });
            }
           
          }
        // this.setState({
        //   previewImage: file.thumbUrl,
        //   previewVisible : true
        // });

      };
    handleChange1 = (info) => {
        console.log("imageUrl",info)
        if (info.file.status === 'uploading') {
          this.setState({loading: true});
          return;
        }
        if (info.file.status === 'done') {
            // this.setState({imageUrl : info,loading: false})
        //   Get this url from response in real world.
          getBase64(info.file.originFileObj, imageUrl => this.setState({
            imageUrl,
            loading: false,
          }));

        //   console.log("imageUrl",this.state.imageUrl)
        }
      };
      handleChange = ({ fileList }) => {
        //---------------^^^^^----------------
        // this is equivalent to your "const img = event.target.files[0]"
        // here, antd is giving you an array of files, just like event.target.files
        // but the structure is a bit different that the original file
        // the original file is located at the `originFileObj` key of each of this files
        // so `event.target.files[0]` is actually fileList[0].originFileObj
        // const isJPG = fileList.type === 'image/jpeg';
        // const isLt2M = fileList.size / 1024 / 1024 < 2;
        // if (!isJPG) {
        // message.error('You can only upload JPG file!');
        // }
        // else if (!isLt2M) {
        // message.error('Image must smaller than 2MB!');
        // }
        // console.log('fileList1', fileList);
        if(fileList.type === '' || null){
            // return true;
        }
        else{
            // console.log('fileList2', fileList);
            if (fileList.type == 'image/jpeg' || 'image/png' || 'image/jpg') {
              // return true;
                if(fileList.length > 0){
                    fileList[0].status = 'success'
                }
              
              this.setState({ fileList });
              // return isJPG && isLt2M;
              console.log('fileList', fileList);
            }
           
        }
        
            // this.setState({ fileList });
            // // return isJPG && isLt2M;
            // console.log('fileList', fileList);
        // you store them in state, so that you can make a http req with them later
       
      };
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    if(this.state.fileList.length > 0){
                        values.avatar = this.state.fileList[0].thumbUrl;
                    }
                    else{
                        values.avatar = null;//baseURL+'/uploads/user/default2.png';
                    }
                    
                    
                    console.log('Received values of form: ', values);
                    if (this.state.isUpdate) {
                        var localstore = localStorage.getItem("user_id");
                        localstore = JSON.parse(localstore);
                        if(this.state.fileList.length > 0){
                        localstore.avatar = values.avatar
                        }
                        localstore.fname = values.fname.trim()
                        localstore.lname = values.lname.trim()
                        localstore.full_name = localstore.fname +' '+ localstore.lname;
                        localStorage.setItem("user_id",JSON.stringify(localstore));
                        console.log(JSON.stringify(localstore));
                        this.props.update_user(localstore);
                        values.userid = this.state.userid;
                        values.username = this.state.username;
                        values.update_profile = 1;
                        this.props.update_staff_user(values);

                        setTimeout(() => {
                            this.props.hideMessage();
                            this.props.history.push("/dashboard");
                        }, 3000);

                    } 

                }
            });
    }
    getRole() {
        this.props.get_role(4);
    }

   

    // componentWillUnmount(){
    //     this.getallData();
    // }
    componentDidUpdate(){
        // alert(this.props.status);
        if(this.props.status == 'Updatedataloaded' || this.props.status == ''){
            this.props.changestatus('updatedata');
            this.getallData();
        }
    }
    getallData(){

        var datarecord = this.props.AuthDetails;
        let datarecords = this.props.location.state.authUser;
        
        // eslint-disable-next-line
        if (datarecords == "undefined" || datarecords == "" || datarecords == null) {
            // Object is empty (Would return true in this example)
        } else {
            // Object is NOT empty
            console.log("datarecord", datarecord);
            if(this.props.status == ''){
                this.props.getUserDetails(datarecords.id);
            }
            this.setState({admin_roleid : datarecords.id}); 
            
            if(typeof this.props.AuthDetails != 'undefined' || datarecord == "undefined" || datarecord == "" || datarecord == null){
                
                console.log("AuthDetailss",datarecord);
                const form = this.props.form;
                // eslint-disable-next-line
                form.setFieldsValue({['fname']: datarecord.fname, ['lname']: datarecord.lname, ['email']: datarecord.email,['role']: datarecord.role});

                this.setState({isUpdate: true, cardTitle: "Update Profile", userid: datarecord.id, username: datarecord.username, disabled: "disabled",previewImage : datarecord.avatar }); 

            }
            
        }

        
    }

componentWillMount(){
        localStorage.setItem("progress", 40);
}
    componentDidMount() {
        localStorage.setItem("progress", 100);

        setTimeout(() => {  
                    localStorage.setItem("progress", 0);
                  }, 2000);  
        this.getRole();
        this.getallData();
        this.props.hideMessage();
        this.props.changestatus('');
    }
    handleCancel = () => this.setState({ previewVisible: false })

    render() {
        const {getFieldDecorator} = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: {
                    span: 24
                },
                sm: {
                    span: 8
                }
            },
            wrapperCol: {
                xs: {
                    span: 24
                },
                sm: {
                    span: 12
                }
            }
        };
        const formItemLayout1 = {
            wrapperCol: {
                xs: {
                    span: 24
                },
                sm: {
                    span: 24
                }
            }
        };


        function isEmpty(obj) {
            for (var key in obj) {
                if (obj.hasOwnProperty(key)) 
                    return false;
                }
            return true;
        }
        var RoleData = this.props.getRoleData;
        
        if (isEmpty(RoleData)) {
            // Object is empty (Would return true in this example)
        } 
        else 
        {
            // Object is NOT empty
            roledata = RoleData.group
            console.log("Role", roledata);
        }
        
        const uploadButton = (
            <div>
              <Icon type={this.state.loading ? 'loading' : 'plus'}/>
              <div className="ant-upload-text">Upload</div>
            </div>
          );
          const imageUrl = this.state.imageUrl;
          const {previewImage, fileList, previewVisible } = this.state;
        return (
            <Card className="gx-card add_profile_cust" title={this.state.cardTitle}>
                {/* <Link to="/staff" className="add_profile_back">
                    <Button type="ghost" icon="left">
                        <i className="icon icon-left"></i>
                        Back
                    </Button>
                </Link> */}
                <Form onSubmit={this.handleSubmit}>
                    
                    <FormItem {...formItemLayout} label="Profile Picture">
                        <Upload
                            name="avatar"
                            // listType="picture-card"
                            listType="picture-card"
                            className="avatar-uploader"
                            // showUploadList={false}
                            fileList={fileList}
                            action=""
                            onPreview={this.handlePreview}
                            onChange={this.handleChange}
                            // beforeUpload={() => false}
                            beforeUpload={beforeUpload}
                            >
                            {fileList.length == 1 ? null : uploadButton}
                            </Upload>
                            <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                            <img alt="example" style={{ width: '100%' }} src={previewImage} />
                            </Modal>
                            
                    </FormItem>
                    <FormItem {...formItemLayout} label="First Name">
                        {getFieldDecorator('fname', {
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    // pattern: new RegExp("^(?=.*\S).+$"),
                                    message: 'Please enter your First Name!'
                                }
                                
                                // {
                                //     pattern: new RegExp("^(?=.*\S).+$"),
                                //     message: 'Sorry, Only space in no allowed!'
                                // }
                                
                            ]
                        })(<Input id="fname"/>)}
                    </FormItem>

                    <FormItem {...formItemLayout} label="Last Name">
                        {getFieldDecorator('lname', {
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: 'Please enter your Last Name!'
                                }
                            ]
                        })(<Input id="lname"/>)}
                    </FormItem>
                    <FormItem {...formItemLayout} label="E-mail">
                        {getFieldDecorator('email', {
                            rules: [
                                {
                                    type: 'email',
                                    message: 'The enter is not valid E-mail!'
                                }, {
                                    required: true,
                                    message: 'Please enter your E-mail!'
                                },

                            ]
                        })(<Input id="email1" disabled={this.state.disabled}/>)}
                    </FormItem>
                    <FormItem
                        {...formItemLayout}
                        label="Role"
                        >
                            {getFieldDecorator('role',{
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please select Role'
                                    }
                                ]
                            
                        })(<Select
                            disabled={this.state.disabled}
                          >
                              <Option key="0" value="0" disabled selected>Please Select Role</Option>
                            {
                                this.state.admin_roleid == 1 
                                ?
                                <Option key={1}>Admin</Option>
                                :
                                roledata.map(province => (
                                <Option key={province.id}>{province.name}</Option>
                                ))
                            }
                          </Select>
                            )}
                        
                    </FormItem>
                    {/* <FormItem
                    {...formItemLayout}
                    label="Password"
                    >
                        {getFieldDecorator('password', {
                            rules: [
                                
                                { min: 6, message: 'Please enter minimum 6 characters.' },
                                { max: 12, message: 'Please enter maximum 13 characters.' },
                                {
                                    required: false,
                                    message: 'Please enter your Password!'
                                }, {
                                    validator: validateToNextPassword
                                }
                                
                            ]
                        })(<Input type="password" placeholder="New Password"/>
                        )}
                    </FormItem>

                    <FormItem
                    {...formItemLayout}
                    label="Confirm Password"
                    >
                        {getFieldDecorator('confirm', {
                            rules: [
                                {
                                    required: false,
                                    message: 'Please enter Confirm Password'
                                }, {
                                    validator: compareToFirstPassword
                                }
                            ]
                        })(<Input placeholder="Confirm Password" type="password"/>)}
                    </FormItem> */}
                    <FormItem {...formItemLayout1}>
                        <Row>
                            <Col span={24} sm={8}></Col>
                            <Col span={24} sm={12}>
                                {this.state.isUpdate
                                    ? <Button type="primary" htmlType="submit">UPDATE</Button>
                                    : <Button type="primary" htmlType="submit">Add</Button>}
                            </Col>
                        </Row>
                    </FormItem>
                </Form>
                {/* {this.props.showSuccessMessage
                    ? message.success(this.props.successMessage.toString())
                    : null}

                {this.props.showMessage
                    ? message.error(this.props.alertMessage.toString())
                    : null} */}
                {this.props.showSuccessMessage
                    ?
                    notification['success']({
                        message: 'success',
                        description: this.props.successMessage.toString()
                      })
                    : null}

                {this.props.showMessage 
                    ?
                    notification['error']({
                        message: 'error',
                        description: this.props.alertMessage.toString()
                      }) 
                    : null}
            </Card>
        );
    }

}

const RegistrationForm = Form.create()(Updatestaff);
const mapStateToProps = state => {
    return {
        AuthDetails : state.auth.UserDetails, 
        status : state.auth.status, 
        showSuccessMessage: state.auth.showSuccessMessage,
        showMessage: state.auth.showMessage, 
        successMessage: state.auth.successMessage, 
        alertMessage: state.auth.alertMessage,
        getRoleData: state.rolePermissionReducers.get_role_res
    }
};
// Object of action creators
const mapDispatchToProps = {
    add_staff: add_staff,
    update_staff_user: update_staff_user,
    hideMessage: hideMessage,
    get_role: get_role,
    getUserDetails : getUserDetails,
    changestatus : changestatus,
    update_user : update_user
}
// export default RegistrationForm;
export default connect(mapStateToProps, mapDispatchToProps)(RegistrationForm);
