import React, {Component} from "react";
import {Avatar, Button, Card, Col, Form, Input,InputNumber, Row, Switch, Select, Tabs, Modal, message, notification, Table} from "antd";
import {connect} from "react-redux";
import {get_assessment,get_assessmentbyprofiler,history_AssignedAssessment} from "appRedux/actions/AssessmentActions";
import {add_assign_assessment, hideMessage} from "appRedux/actions/ProfilerActions";
import CircularProgress from "components/CircularProgress/index";
import {baseURL} from 'util/config'
var name = '';
var city = '';
var date = '';
var company = '';
var TtlAssinedAssessment = 0;
var submited = 0;
var remain = 0;  
var inprogress = 0;
var TotalAssignedAssessment = 0;
const FormItem = Form.Item;
const Option = Select.Option;

class ProfileHeader extends Component {

      constructor(props) {
          super(props);
          this.myRef = React.createRef()  
          this.state = {
            name : '',
            company : '',
            city : '',
            date : '',
            submited : 0,  
            TotalAssignedAssessment :0,
            remain :'',
            ModalText: 'Content of the modal',
            visible: false,
            confirmLoading: false,   
            profiler_id : '',
            Historyvisible: false,
            current : 1
          };
      }
      showModal = () => {
        this.setState({
          visible: true,
        });
      };
      handleOk = (e) => {

        e.preventDefault();

        this.props.form.validateFieldsAndScroll(['assessment_id','max_assign_assessment'],(err, values) => {
          if (!err) {
            values.profiler_id = this.state.profiler_id;
            this.props.add_assign_assessment(values); 
              setTimeout(() => {
                this.props.hideMessage();
                // this.props.history.push("/assessment");
              }, 3000);
            this.setState({
              ModalText: 'The modal will be closed after two seconds',
              confirmLoading: true,
            });
            setTimeout(() => {
              this.setState({
                visible: false,
                confirmLoading: false,
              });
            }, 1000);
            const form = this.props.form;
          form.setFieldsValue({['assessment_id']: '',['max_assign_assessment'] : ''
          });
          }
        })
        
        setTimeout(() => {
          if(this.props.newdata.assessmentByProfiler.length > 0)
          {
            this.props.get_assessmentbyprofiler({profiler_id : this.props.newdata.assessmentByProfiler[0].profiler_id,type:''})
          

            //console.log("this.props.newdata.assessmentByProfiler",this.props.newdata.assessmentByProfiler[0].profiler_id)
            setTimeout(() => {
              this.props.hideMessage();
              // this.props.history.push("/assessment");
            }, 2000);
          }
          else{
              this.props.get_assessmentbyprofiler({profiler_id : this.state.profiler_id,type:''})
          }
        }, 4000);
        
        setTimeout(() => {
          this.props.hideMessage();
          // this.props.history.push("/assessment");
        }, 2000);
        
      };
      handleCancel = () => {
        //console.log('Clicked cancel button');
        const form = this.props.form;
        form.setFieldsValue({['assessment_id']: '',['max_assign_assessment'] : ''
        });
        this.setState({
          visible: false,
        });

        // this.props.get_assessmentbyprofiler({profiler_id : this.props.newdata.assessmentByProfiler[0].profiler_id,type:''})
        
      }
      
      HistoryshowModal = () => {

        this.setState({
          Historyvisible: true,
        });
        var value = {
          profiler_id : this.state.profiler_id,
          filter : '',
          current : ''
        }
        this.props.history_AssignedAssessment(value)
      };

      handleTableChange = (pagination, filters, sorter) => {
        // this.props.setstatustoinitial();
        //console.log("pagination",pagination)
        var currents = 0;
        this.setState({
            current : pagination.current
        })
        if(pagination.current >= 2){
                currents = (pagination.current - 1) * 2
        }
        else{
             currents = 0;
        }
        var value = {
          profiler_id : this.state.profiler_id,
          filter : '',
          current : ''
        }
        this.props.history_AssignedAssessment(value)

        // alert('handlechange')

    };
      
      HistoryhandleCancel = () => {
        //console.log('Clicked cancel button');
        const form = this.props.form;
        form.setFieldsValue({['assessment_id']: '',['max_assign_assessment'] : ''
        });
        this.setState({
          Historyvisible: false,
        });
      }
      componentWillMount(){

        if(typeof this.props.authUser != 'undefined'){

            var authpermission = this.props.authUser.permission;
            this.setState({authID : this.props.authUser.id});
            //console.log("authpermission",authpermission);
            if(authpermission.length > 0){
                if(!authpermission.includes('97')){
                    this
                    .props
                    .history
                    .push("/dashboard");
                }
            }
        }

        submited = 0
        TotalAssignedAssessment = 0  
        remain = 0 
        inprogress = 0 
        
    }
      componentDidMount() {

        let datarecord = this.props.data;
        // eslint-disable-next-line
        if (datarecord == "undefined" || datarecord == "" || datarecord == null) {
            // Object is empty (Would return true in this example)
        } else {
            // Object is NOT empty
            //console.log("dataAss", datarecord);
            // //console.log("dataAss", datarecord.assessment.TotalAssignedAssessment);

              this.setState({profiler_id : datarecord.id })
                name = datarecord.full_name
                company = datarecord.company_name
                city = datarecord.city
                date = datarecord.date  


                         
        }
        this.get_assessmentById()
        setTimeout(() => {
          this.props.hideMessage();
        }, 2000);
    }
    get_assessmentById(){
          var val ={
              authID : this.state.authID,
              filter : '',
              current : ''
          }
          this.props.get_assessment(val);
    }
    UNSAFE_componentWillUpdate(){
      setTimeout(() => {
        this.props.hideMessage();
        // this.props.history.push("/assessment");
      }, 2000);
    }
  render() {

      const columns = [
        {
            title: 'Assessment Name',
            dataIndex: 'name',
            // width: 300,

        }, {
            title: 'Max Assessment',
            dataIndex: 'max_assign_assessment',
            // width: 300,
            
        }, {
          title: 'Assigned Date',
          dataIndex: 'created_date',
          // width: 300,
          
        }]
        const CompanyData = [
          {
            id : 1,
            name : "KMS",
            max_assign_assessment : 10,
            created_date : '06-05-2020'
          }
        ]
        var RoleData = this.props.newdata;
        if (RoleData == "undefined" || RoleData == "" || RoleData == null) {
          // Object is empty (Would return true in this example)
        } 
        else  
        {
            // Object is NOT empty
            //console.log("RoleDataph", RoleData);
            submited = RoleData.submited
            TotalAssignedAssessment = RoleData.TotalAssignedAssessment  
            remain = RoleData.remain 
            inprogress = RoleData.inprogress 

              
        }

        function isEmpty(obj) {
            for(var key in obj) {
                if(obj.hasOwnProperty(key))
                    return false;
            }
            return true;
        }
        var AssessmenData = this.props.getAssessmentData;
        var sdata = '';
        var children = [];
        if(isEmpty(AssessmenData)){
            // Object is empty (Would return true in this example)
        }
        else 
        {
            // Object is NOT empty
            sdata = AssessmenData.assessment

            for (let i = 0 ; sdata.length > i ; i++) {
              if(sdata[i].publish == 1){
                children.push(<Option key={sdata[i].id}>{sdata[i].name}</Option>);
              }

            }

            //console.log("AssessmenDataHeader",sdata)
        }

       var historyData =  this.props.get_history_assigned_assessment
       var historyDataAssigned = ''
       var paginationToatal = ''
        if(isEmpty(historyData)){
          // Object is empty (Would return true in this example)
      }
      else 
      {
          // Object is NOT empty
          historyDataAssigned = historyData.historyAssessment
          paginationToatal = historyData.historyAssessmentTotal
          //console.log("historyDataAssigned",historyDataAssigned)
      }
        
        const {visible, confirmLoading, ModalText,Historyvisible} = this.state;
        const {getFieldDecorator} = this.props.form;
    
        // const {autoCompleteResult} = this.state;
    
    
        const formItemLayout1 = {
            labelCol: {
              xs: {span: 24},
              sm: {span: 6},
            },
            wrapperCol: {
              xs: {span: 24},
              sm: {span: 16},
            },
          };
    return (
    <div className="gx-profile-banner">
      <div className="gx-profile-container">
        <Button className="assignAssessmentbtn" onClick={this.showModal} type="primary" >
          ASSIGN NEW ASSESSMENT
        </Button>

        <Button className="historyassignAssessmentbtn" onClick={this.HistoryshowModal} type="primary" >
          ASSESSMENT HISTORY
        </Button>
        <div className="gx-profile-banner-top cust_assign_banner">
          <div className="gx-profile-banner-top-left">
            <div className="gx-profile-banner-avatar"> 
              <Avatar className="gx-size-90" alt="..." src={this.props.data.avatar ? this.props.data.avatar : baseURL+'/uploads/user/default2.png' } />
              {/* <Avatar src={authUser.avatar ? authUser.avatar : baseURL+'/uploads/user/default2.png' }
                className="gx-size-40 gx-pointer gx-mr-3" alt=""/> */}
            </div>
            <div className="gx-profile-banner-avatar-info">
  <h2 className="gx-mb-2 gx-mb-sm-3 gx-fs-xxl gx-font-weight-light">{ this.props.data.company_name !="" ? this.props.data.company_name : this.props.data.company_name }</h2>
  <p className="gx-mb-0 gx-fs-lg">{this.props.data.full_name}</p>
            </div>
          </div>
          <div className="gx-profile-banner-top-right">
            <ul className="gx-follower-list">
              <li>
    <span className="gx-follower-title gx-fs-lg gx-font-weight-medium">{TotalAssignedAssessment}</span>
                <span className="gx-fs-sm">Total Assessments</span></li>
              <li>
    <span className="gx-follower-title gx-fs-lg gx-font-weight-medium">{inprogress}</span>
                <span className="gx-fs-sm">In Progress</span></li>
              <li>
    <span className="gx-follower-title gx-fs-lg gx-font-weight-medium">{remain}</span>
                <span className="gx-fs-sm">Remaining </span>
              </li>
              <li>
    <span className="gx-follower-title gx-fs-lg gx-font-weight-medium">{submited}</span>
                <span className="gx-fs-sm">Completed</span>
              </li>
            </ul>
          </div>
        </div>
        <div className="gx-profile-banner-bottom">
          <span className="gx-link gx-profile-setting">
  <span className="gx-d-inline-flex gx-vertical-align-middle gx-ml-1 gx-ml-sm-0">Profiler Created Date : {this.props.data.date}</span>
          </span>
        </div>
      </div>
        <Modal title="Assign Assessment"
               visible={visible}
               onOk={this.handleOk}
               confirmLoading={confirmLoading}
               onCancel={this.handleCancel}
               width="900px"
               footer={[
                <Button key="back" onClick={this.handleCancel}>CANCEL</Button>,
                <Button 
                  // disabled={!this.state.tabname || !this.state.tvalue ||
                  //   this.state.questionarysection ? !this.state.bestinclass || !this.state.positiverecommendations : null
                  // }  
                type="primary" htmlType="submit" loading={this.state.loading} onClick={this.handleOk }> 
                  SUBMIT
                </Button>
              ]}
        >
          <Form onSubmit={this.handleOk}  >
            {/* <Input.Group > */}

            <FormItem
              {...formItemLayout1}
              label="Select Assessment"
            >
              {getFieldDecorator('assessment_id', {
                rules: [{
                  required: true, 
                  message: 'Please select Assessment',
                }],
                
              })(
                <Select
                
                  defaultValue={this.state.tvalue}
                  placeholder="Please select Assessment"
                  style={{width: '100%'}}
                >
                    
                    {
                      children
                    }
                  </Select>
              )}
            </FormItem>

            <FormItem
              {...formItemLayout1}
              label="No. Of Assessment"
            >
              {getFieldDecorator('max_assign_assessment', {
                rules: [{
                  required: true, 
                  message: 'Please enter maximum no. of assessment',
                  whitespace: true
                }],
                // onChange : (e) => this.tabname(e.target.value)
              })(
                <Input  type="number" min="1" max="100" placeholder="Please enter maximum no. of assessment" />
                // <InputNumber min={0} max={100} className="InputNumber"  placeholder="Please enter maximum no. of assessment" />
                
              )}
              {/* <span className="maxInputNumber">Maximum No. Of Assessment</span> */}
            </FormItem>

          </Form>
        </Modal>
        <Modal title="Assessment History"
               visible={Historyvisible}
              //  onOk={this.HistoryhandleOk}
              //  confirmLoading={confirmLoading}
               onCancel={this.HistoryhandleCancel}
               width="900px"
               footer={[
              
              ]}
        >

          {/* {
              this.props.loader ?
              <div className="gx-loader-view">
                  <CircularProgress/>
              </div> : null
          }
          {this.props.loader 
          ? 
          null 
          :  */}
              <Table style={{width: "100%"}}
                  className="gx-table-responsive"
                  columns={columns}
                  loading ={this.props.loader}
                  dataSource={historyDataAssigned}
                  rowkey={record => record.id}
                  // pagination={{
                  //     defaultPageSize: 5, 
                  //     hideOnSinglePage: true
                  // }}
                  pagination={{
                    // current : this.state.current,
                    // total : paginationToatal,
                    defaultPageSize: 5, 
                    hideOnSinglePage: true
                }}
                // onChange={this.handleTableChange}

              />
           {/* }  */}
                
         
      </Modal>
        {/* { 
        this.props.showSuccessMessage
            ? message.success(this.props.successMessage.toString())
            : null
        }

        {
        this.props.showMessage
            ? message.error(this.props.alertMessage.toString())
            : null
        } */}
        {this.props.showSuccessMessage
                    ?
                    notification['success']({
                        message: 'success',
                        description: this.props.successMessage.toString()
                      })
                    : null}

                {this.props.showMessage 
                    ?
                    notification['error']({
                        message: 'error',
                        description: this.props.alertMessage.toString()
                      }) 
                    : null}
    </div>
  )
    }
};
const ProfileHeaderForm = Form.create()(ProfileHeader);

// Object of action creators
const mapDispatchToProps = {
  get_assessment : get_assessment,
  hideMessage: hideMessage,
  add_assign_assessment : add_assign_assessment,
  get_assessmentbyprofiler : get_assessmentbyprofiler,
  history_AssignedAssessment : history_AssignedAssessment,
}

const mapStateToProps =  state => {
return { 
      getAssessmentData: state.assessmentReducers.get_assessment_res ,
      authUser : state.auth.authUser,
      showSuccessMessage: state.profilerReducers.showSuccessMessage, 
      showMessage: state.profilerReducers.showMessage, 
      successMessage: state.profilerReducers.successMessage, 
      alertMessage: state.profilerReducers.alertMessage,
      get_history_assigned_assessment : state.assessmentReducers.get_history_assigned_assessment,
      loader : state.assessmentReducers.loader
    }; 
};
// export default AssetRegistrationForm;
export default connect(mapStateToProps,mapDispatchToProps)(ProfileHeaderForm);