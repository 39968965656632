import {all} from "redux-saga/effects";
import authSagas from "./Auth";
import notesSagas from "./Notes";
import profilerSagas from "./ProfilerSagas";
import staffSagas from "./StaffSagas";
import rolePermissionSagas from "./RolePermissionSagas";
import assessmentSagas from "./AssessmentSagas";
import reportSagas from "./ReportSagas";

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    notesSagas(),
    profilerSagas(),
    staffSagas(),
    rolePermissionSagas(),
    assessmentSagas(),
    reportSagas(),
  ]);
}
