import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import Settings from "./Settings";
import Auth from "./Auth";
import Notes from "./Notes";
import Contact from "./Contact";
import Common from "./Common";
import ProfilerReducers from "./ProfilerReducers";
import StaffReducers from "./StaffReducers";
import RolePermissionReducers from "./RolePermissionReducers";
import AssessmentReducers from "./AssessmentReducers";
import ReportReducers from "./ReportReducers";



const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  auth: Auth,
  notes: Notes,
  contact: Contact,
  common: Common,
  profilerReducers : ProfilerReducers,
  staffReducers : StaffReducers,
  rolePermissionReducers : RolePermissionReducers,
  assessmentReducers : AssessmentReducers,
  reportReducers : ReportReducers
});

export default createRootReducer
