import React, {Component} from "react";
// import {Col, Row, Card, Table, Button} from "antd"; import Auxiliary from
// "util/Auxiliary";
import {Link} from 'react-router-dom'
import {
    Button,
    Card,
    Col,
    Form,
    Input,
    Row,
    Select,
    Modal,
    message,
    Avatar,
    notification,
    Divider,
    Upload,
    Tooltip
} from "antd"; 
import {connect} from "react-redux";
import { hideMessage,addReport,clearLink} from "appRedux/actions/ReportActions";
import {update_assessment_for_company} from "appRedux/actions/AssessmentActions";
import { contentdata , contentdata_html} from './profilerData'
import { get, post } from './requests';
// import renderHTML from 'react-render-html';
// import parse from 'html-react-parser';
import mammoth from "mammoth"; 
import CKEditor from 'ckeditor4-react';
import { setTimeout } from "timers";
import axios from 'axios';
import {baseURL, apikey} from 'util/config'
// CKEditor.plugins.addExternal( 'exportpdf', './node_modules/ckeditor4-plugin-exportpdf/' );
// import CKEditor from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import InlineEditor from '@ckeditor/ckeditor5-build-inline';
// import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

// import {convertToRaw, EditorState} from "draft-js";
// import {Editor} from "react-draft-wysiwyg";
// import draftToHtml from "draftjs-to-html";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
// import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
// import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
// import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
// import Heading from '@ckeditor/ckeditor5-heading/src/heading';
// import Font from '@ckeditor/ckeditor5-font/src/font';


const FormItem = Form.Item;
// const parses = require('html-react-parser');
const Option = Select.Option;
const {TextArea} = Input;
var datajson = []
var link_show = "";
const headers = {
    'Content-Type': 'application/json',
    'X-API-KEY': apikey
} 
// var contentdatas = contentdata;
var contentdatas = '';
var previewVisible =false;
// ClassicEditor
//     .create( document.querySelector( '#editor' ), {
//         fontSize: {
//             options: [
//                 9,
//                 11,
//                 13,
//                 'default',
//                 17,
//                 19,
//                 21
//             ]
//         },
//         plugins: [ Font],
//         toolbar: [ 'fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor']
//     } )
//     .then( editor => {
//         console.log( 'Editor was initialized', editor );
//     } )
//     .catch( error => {
//         console.error( error.stack );
//     } );
// const editorConfiguration = {
//     plugins: [ Essentials, Bold, Italic, Paragraph ],
//     toolbar: [ 'bold', 'italic' ]
// };
// ClassicEditor
//     .create( document.querySelector( '#editor' ), {
//         plugins: [ Essentials, Paragraph, Bold, Italic ],     // <--- MODIFIED
//         toolbar: [ 'bold', 'italic' ]                       // <--- MODIFIED
//     } )
//     .then( editor => {
//         console.log( 'Editor was initialized', editor );
//     } )
//     .catch( error => {
//         console.error( error.stack );
//     } );

// var editorState = EditorState.createEmpty();
const textTitle = <span>Change Logo!</span>;
var UploadState = 0;
function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }
   
  function beforeUpload(file) {
    console.log("fileimage",file.type)
    console.log("fileimage",file)
    
    if(file.type === '' || null){
      message.error('You can only upload image file!');
    }
    else{
      if ('image/jpeg' == file.type || 'image/png' == file.type || 'image/jpg' == file.type) {
      }
      else{
        message.error('You can only upload image file!');
      }      
    }
  
    return true;
  }
  var imagedata = ''

class ReportForm extends Component {

    constructor(props) {
        super(props);
        this.myRef = React.createRef()  
        this.state = {
            pvalset : false,
            confirmDirty: false,
            company_name: '',
            email: 'demo@example.com',
            poc_name: '',
            no_of_assessment: '',
            phone_number: '',
            address1: '',
            address2: '',
            city: '',
            pincode: '',
            note: '',
            isUpdate: false,
            isAdd: true,
            userid: '',
            username: '',
            cardTitle: 'Create Profiler',
            editdata: '',
            disabled: '',
            staffData: '',
            reportcontant : '',
            status : 0,
            data : '',
            AssessmentName : '',
            CompanyName : '',
            CompanyId : '',
            POCName : '',
            assessment_id : '',
            company_id : '',
            profiler_id : '',
            header : '',
            footer : '',
            previewVisible : false,
            link_show : "",
            authpermission :'',
            AuthId : '',
            docdata : '',
            confirmLoading_p: false,
            Avatars : '',
            editorConfig: {
                // plugins: [ Essentials, Heading, Paragraph, Bold, Italic, Font],
                toolbar: {
                    items: [
                        'heading',
                        '|',
                        'fontsize',
                        'fontfamily',
                        '|',
                        'bold',
                        'italic',
                        'underline',
                        'strikethrough',
                        'highlight',
                        '|',
                        'alignment',
                        '|',
                        'numberedList',
                        'bulletedList',
                        '|',
                        'link',
                        'blockquote',
                        'imageUpload',
                        'insertTable',
                        'mediaEmbed',
                        '|',
                        'undo',
                        'redo'
                    ]
                },
                heading: {
                    options: [
                        {model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph'},
                        {model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1'},
                        {model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2'},
                        {model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3'},
                        {model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4'},
                        {model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5'},
                        {model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6'},
                    ]
                },
                fontSize: {
                    options: [
                        9,
                        11,
                        13,
                        'default',
                        17,
                        19,
                        21
                    ]
                }
            }
            // editorState :  EditorState.createEmpty(),
           
        };
        this.setDataVal = this.setDataVal.bind( this );
    }


    previewhandleCancel = () => {
        // this.setState({previewVisible: false});
        previewVisible = false;
    }

    previewSubmit =(e) =>{
        e.preventDefault();
        console.log("assessment.data")
        this.setState({status : 2})
        this.setState({confirmLoading_p : true})
        // this
        //     .props
        //     .form
        //     .validateFieldsAndScroll((err, values) => {

        //         if (!err) {
                 
                    // values.AssessmentName = this.state.AssessmentName;
                    // values.CompanyName = this.state.CompanyName;
                    // values.POCName = this.state.POCName;
                    // values.profiler_id = this.state.profiler_id;
                    // values.assessment_id = this.state.assessment_id;
                    // values.company_id = this.state.company_id;
                    // values.status = this.state.status;
                    // values.data = this.state.data;
                    // values.header = this.state.header;
                    // values.footer = this.state.footer;

                    // console.log("values", values)
                    // this
                    //     .props
                    //     .addReport(values);

                    axios.post(baseURL + 'api/assessment/publishAssessmentReport', {
                        "AssessmentName": this.state.AssessmentName,
                        "CompanyName": this.state.CompanyName,
                        "POCName": this.state.POCName,
                        "profiler_id": this.state.profiler_id,
                        "assessment_id": this.state.assessment_id,
                        "company_id": this.state.company_id,
                        "status": 2,
                        "data": this.state.data,
                        "header": this.state.header,
                        "footer": this.state.footer,
                    }, {headers: headers})
                        .then((assessment) => {
                            console.log("assessment.data",assessment.data)

                            if (assessment.data.status) {
                                console.log("assessment.data1",assessment.data)
                                if(assessment.data.mode == 2){
                                    console.log("assessment.data2",assessment.data.mode)
                                    setTimeout(() => {
                                        console.log("assessment.data3",assessment.data.pdflink)
                                        window.open(assessment.data.pdflink, '_blank');
                                    }, 1000);
                                    this.setState({
                                        pvalset : false
                                    })
                                }
                            } else {
                                console.log("assessment.data5",assessment.data)
                                this.setState({confirmLoading_p : false})
                                // if(AddReport.mode != 2){
                                // yield put(showErrorMessage(AddReport.message));
                                // }
                            }
                        })
                        .catch(error => error);

                    
                        
                        // if(this.state.status != 2 ){
                        
                        //     // window.open('http://www.example.com?ReportID=1', '_blank');

                        //     // setTimeout(() => {
                        //     this
                        //     .props
                        //     .hideMessage();

                        //     this
                        //     .props
                        //     .history
                        //     .push("/report");
                        //     // }, 1000);  
                        

                        // }
                        setTimeout(() => {
                            this.setState({confirmLoading_p : false})
                        }, 3000);
                        
                    
            //     }
            // });
        
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this
            .props
            .form
            .validateFieldsAndScroll((err, values) => {

                if (!err) {
                 
                    values.AssessmentName = this.state.AssessmentName;
                    values.CompanyName = this.state.CompanyName;
                    values.POCName = this.state.POCName;
                    values.profiler_id = this.state.profiler_id;
                    values.assessment_id = this.state.assessment_id;
                    values.company_id = this.state.company_id;
                    values.status = this.state.status;
                    values.data = this.state.data;
                    values.header = this.state.header;
                    values.footer = this.state.footer;

                    // console.log("values", values)
                    this
                        .props
                        .addReport(values);

                    
                        
                        if(this.state.status != 2 ){
                        
                            // window.open('http://www.example.com?ReportID=1', '_blank');

                            // setTimeout(() => {
                            this
                            .props
                            .hideMessage();

                            this
                            .props
                            .history
                            .push("/report");
                            // }, 1000);  
                        

                        }
                        setTimeout(() => {
                            this.setState({confirmLoading_p : false})
                        }, 6000);
                        
                    
                }
            });

    }

    validateToNextPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirm'], {force: true});
        }
        callback();
    }
    
    componentDidUpdate() {

        if(this.props.link != "" && link_show == ""){
            // this.setState({previewVisible: true });
            // alert(this.props.link);
            // this.setState({previewVisible: true});            
            link_show = this.props.link;
            // previewVisible = true;
            localStorage.setItem("previewdocURL",this.props.link);
            localStorage.setItem("previewpdfURL",this.props.link);
            
            // alert('hii did')
            this.previewOpenLinK();

            
            
        }
        this.scrollToMyRef();
      }

      previewOpenLinK = () =>{
        // alert('hii fun')
        
        setTimeout(() => {
            // window.open('/previewDocx');
            // window.location.assign('https://docs.google.com/viewerng/viewer?url='+this.props.link, '_blank');
            // if(val == 2){
                this.setState({confirmLoading_p : false})
                // }
                window.open(this.props.link, '_blank');
            // window.open('https://docs.google.com/viewerng/viewer?url='+this.props.link, '_blank');
            // link_show = "";
        }, 1000);
        this.setState({
            pvalset : true
        })
      }

    componentWillMount(){
          localStorage.setItem("progress", 40);
        if(typeof this.props.authUser != 'undefined'){

            var authpermission = this.props.authUser.permission;
            var AuthId = this.props.authUser.id;
            // console.log("authpermission",authpermission);
            if(authpermission.length > 0){
                if(!authpermission.includes('96')){
                    this
                    .props
                    .history
                    .push("/dashboard");
                }
                this.setState({authpermission : authpermission, AuthId : AuthId})
            }
            
        }

    }


    componentDidMount() {

        localStorage.setItem("progress", 100);

        setTimeout(() => {  
                    localStorage.setItem("progress", 0);
                  }, 2000);  
        let datarecord = this.props.location.state;
        // eslint-disable-next-line
        // console.log("datarecord_out", datarecord);
        if (datarecord == "undefined" || datarecord == "" || datarecord == null)
        {
            // Object is empty (Would return true in this example)
        } 
        else 
        {
            // Object is NOT empty
            datarecord = datarecord.record
            // console.log("datarecord", datarecord);
             datajson = JSON.parse(datarecord.assessment_answer_json_data)
            console.log("datajson", datajson);


            this.setState({
                AssessmentName : datarecord.AssessmentName,
                CompanyName : datarecord.CompanyName,
                CompanyId : datarecord.CompanyId,
                Avatars : datarecord.Avatar,
                POCName : datarecord.POCName,
                assessment_id : datarecord.assessment_id,
                company_id : datarecord.assessment_to_company_id,
                profiler_id : datarecord.profiler_id,
                docdata : datarecord.content,
            })
        
        }
        
        

    }

    // https://cdn.ckeditor.com/4.14.1/full/ckeditor.js
      reportcontant = (evt) => {
          
        const newContent = evt.editor.getData();
        this.setState({reportcontant : newContent,data : newContent});
        console.log("newContent",newContent,evt)
      }
      reportcontantHead = (evt) => {
        const newContent = evt.editor.getData();
        this.setState({header : newContent});
      }
      reportcontantFooter = (evt) => {
        const newContent = evt.editor.getData();
        this.setState({footer : newContent});
      }
      modeChange = (val) => {
        
        this.setState({status : val})
        if(val == 2){

            this.setState({confirmLoading_p : true})

            if(this.state.pvalset){
                setTimeout(() => {
                    this.previewOpenLinK();
                }, 5000);
            } 
            
        }

      }
    //   setHeaderVal = (data) => {
    //     this.setState({header : data})
    //   }
    setHeaderVal = (evt) => {
        console.log("getData",evt.editor.getData())
        this.setState( {
			header: evt.editor.getData()
		} );
      }

      setDataVal = (evt) =>{

        console.log("getData",evt.editor.getData())
        this.setState( {
			data: evt.editor.getData()
		} );
        //   this.setState({data : data})
      }
      setFooterVal = (evt) => {
        console.log("getData",evt.editor.getData())
        this.setState( {
			footer: evt.editor.getData()
		} );
      }

    //   setFooterVal = (data) => {
    //     this.setState({footer : data})
    //   }

    handleChange = ({ fileList },id) => {
        //---------------^^^^^----------------
        // this is equivalent to your "const img = event.target.files[0]"
        // here, antd is giving you an array of files, just like event.target.files
        // but the structure is a bit different that the original file
        // the original file is located at the `originFileObj` key of each of this files
        // so `event.target.files[0]` is actually fileList[0].originFileObj
        console.log('fileList', fileList);
        console.log('handleChange', id);
        
        // UploadState = UploadState + 1 
        
        // you store them in state, so that you can make a http req with them later
        this.setState({ fileList });
        //  this.setState({'imagedata' : ''})
        imagedata = ''
        if(fileList.length > 0){

          var len = fileList.length - 1;
        
          if(typeof fileList[len].thumbUrl != 'undefined' || fileList[len].thumbUrl != '' || fileList[len].thumbUrl != null){

            // alert('out')

            if(fileList[len].type === '' || null){
              
            }
            else{
              if ('image/jpeg' == fileList[len].type || 'image/png' == fileList[len].type || 'image/jpg' == fileList[len].type) {
                this.setState({
                  // 'imagedata' : fileList[0].thumbUrl,
                  'comp_id' : id,
                  'Avatars' : fileList[len].thumbUrl
                })
                imagedata = fileList[len].thumbUrl
                setTimeout(() => {
                this.imageuploadforcomp();
                },1000);
                
              }
              else{
                // message.error('You can only upload image file!');
              }
              
            }
      
            
          
        }
        

        // message.success('upload image');
            
      };
    }
    
      imageuploadforcomp(){
        // var imagedata = fileList[0].thumbUrl\
        var newContentData = ''
        console.log("imagedata",imagedata)
        if(imagedata != ''){
          var values = {
            comp_id : this.state.comp_id,
            avatar : imagedata
          }
          console.log("imagedata",values)

          if(values.avatar != '' || values.avatar != null ){
            // alert('hii')
            // if(UploadState == 1){


                this.props.update_assessment_for_company(values); 
                setTimeout(() => {
                    this.setState({"Avatars" : values.avatar})
                  // message.success('Logo is uploaded.');
                },1000);

            newContentData = this.props.location.state.record.content
            
            var firstexplodearray = this.props.location.state.record.content.split('<td style="width:30%">')
            console.log("firstexplodearray",firstexplodearray)
            if(firstexplodearray.length >= 2){
                var secondexplodearray = firstexplodearray[1].split("</td>")

                secondexplodearray[0] = '<img src="'+values.avatar+'" style="height:75px; width:75px" />';

                firstexplodearray[1] = secondexplodearray.join("</td>")

             this.props.location.state.record.content = firstexplodearray.join('<td style="width:30%">')

             newContentData = firstexplodearray.join('<td style="width:30%">')
            }
            console.log("newContentData",newContentData)

            
            // }
            

            
            // this.props.history.push("/fill-assessment");
            
            // this.props.get_assessmentbyprofiler({profiler_id : this.state.authID,type : 'publish'});
            this.props.hideMessage();
            // setTimeout(() => {
            //   this.props.hideMessage();
            //   this.props.history.push("/fill-assessment");
            //   this.props.hideMessage();
            // },100);
            imagedata = ''
          }
          imagedata = ''
        }
        imagedata = ''
        UploadState = 0
          return
      }


    render() {
        const {getFieldDecorator} = this.props.form;

        function isEmpty(obj) {
            for(var key in obj) {
                if(obj.hasOwnProperty(key))
                    return false;
            }
            return true;
        }
        var datacontent_obj = this.props.location.state;
        if(isEmpty(datacontent_obj)) {
            // Object is empty (Would return true in this example)
        } else {
            // Object is NOT empty
            contentdatas =  datacontent_obj.record.content 

        console.log("contentdatas1",contentdatas)


        }
        const formItemLayout = {
            labelCol: {
                xs: {
                    span: 24
                },
                sm: {
                    span:10
                }
            },
            wrapperCol: {
                xs: {
                    span: 24
                },
                sm: {
                    span: 14
                }
            }
        };
        const formItemLayout1 = {
            wrapperCol: {
                xs: {
                    span: 24
                },
                sm: {
                    span: 24
                }
            }
        };


       return(
        <Card className="gx-card cust_asses" title='Report'> 

        <Form onSubmit={this.handleSubmit}>
        {/* <Form > */}

            <div className="assesment_btns" >
              <Row >
              
              <Col span={12} sm={12}>
                <Link to="/report" >
                      <Button type="ghost" icon="left">
                          <i className="icon icon-left"></i>
                          BACK
                      </Button>
                  </Link>
                </Col>
                {/* <Col span={12} sm={12}>
                    <Button disabled={this.state.panes.length == 0} type="ghost" onClick={this.showPreview}>
                      PREVIEW
                    </Button> 
                </Col> */}
                <Col span={12} sm={12}>
                      <Button type="primary" loading={this.state.confirmLoading_p} onClick={ (e) => this.previewSubmit(e)} >Preview</Button>
                </Col>
                <Col span={12} sm={12}>
                      <Button type="primary" onClick={ () => this.modeChange(0)} htmlType="submit">Draft</Button>
                </Col>
                {
                    this.state.authpermission.includes('112')
                    ?
                        this.state.AuthId == 1
                        ?
                            <Col span={12} sm={12}>
                            <Button type="primary" onClick={ () => this.modeChange(1)}  htmlType="submit">Publish</Button>
                            </Col>
                        :
                        <Col span={12} sm={12}>
                            <Button type="primary" onClick={ () => this.modeChange(1)}  htmlType="submit">Publish</Button>
                        </Col>
                    :
                        this.state.AuthId == 1
                        ?
                            <Col span={12} sm={12}>
                            <Button type="primary" onClick={ () => this.modeChange(1)}  htmlType="submit">Publish</Button>
                            </Col>
                        :
                        null
                }
                
              </Row>
            </div>
          
          
          <Row>
          <Col span={8} sm={8}>
                <FormItem
                    {...formItemLayout}
                    label="Company Logo"
                >
                    
                    <Upload
                        className="antduploadlist"
                        listType="picture"
                        showUploadList="false"
                        onChange={(e)  => this.handleChange(e,this.state.CompanyId)}
                        beforeUpload={beforeUpload}
                    >
                        <Tooltip placement="top" title={textTitle}>
                        <Avatar src={this.state.Avatars ? this.state.Avatars : 'https://via.placeholder.com/500X500/afafaf/000000?text=Click+Here!' }
                        className="gx-size-50 gx-pointer gx-mr-3" alt=""/>
                        </Tooltip>
                    </Upload>

                </FormItem>                  
            </Col>
            <Col span={8} sm={8}>
                <FormItem
                    {...formItemLayout}
                    label="Company Name"
                >
                    
                    {this.state.CompanyName}

                </FormItem>                  
            </Col>
            <Col span={8} sm={8} className="assess_rights">
                <FormItem
                    {...formItemLayout}
                    label="Point Of Contact"
                >
                   {this.state.POCName}
                </FormItem>
                
            </Col>
          </Row>

          <Row  style={{display:'none' }} >
            <Col span={24} sm={24}>

            <h3>Set Header</h3>
            <CKEditor

                    data="<p>Hello from CKEditor 5!</p>"
                    oninstanceReady={this.setHeaderVal}
                    onBlur={this.setHeaderVal}
                    onFocus={this.setHeaderVal}
                    onChange={this.setHeaderVal}

                />
            </Col>
          </Row>
          {/* <br></br> */}
          <Row>
            <Col span={24} sm={24} style= {{padding : '0px 115px 0px 115px'}} >
   
            <CKEditor

                    data={this.props.location.state.record.content}
                    oninstanceReady={this.setDataVal}
                    onBlur={this.setDataVal}
                    onFocus={this.setDataVal}
                    onChange={this.setDataVal}
                    config={ {
                        height : '1350px',
                        // width : '800px'
                    }}
                />
            </Col>
          </Row>
            <br></br>
          <Row style={{display:'none' }}>
            <Col span={24} sm={24}>
         
            <h3>Set Footer</h3>
            <CKEditor

            
                    data="<p>Hello from CKEditor 5!</p>"
                    oninstanceReady={this.setFooterVal}
                    onBlur={this.setFooterVal}
                    onFocus={this.setFooterVal}
                    onChange={this.setFooterVal}
         
                />
            </Col>
          </Row>
         
        </Form>

        <Modal
          visible={previewVisible}
          title="Preview"
          onCancel={this.previewhandleCancel}
          width	= "800px"
         >
            <iframe class="doc" src={"https://docs.google.com/gview?url="+this.props.link+"&embedded=true"}></iframe>
         </Modal>

        
      </Card>
       );
    }
    scrollToMyRef = () => window.scrollTo(0, 0)

}
const mapStateToProps = state => {
    return {
        authUser: state.auth.authUser, 
        showSuccessMessage: state.reportReducers.showSuccessMessage,
        showMessage: state.reportReducers.showMessage, 
        successMessage: state.reportReducers.successMessage, 
        alertMessage: state.reportReducers.alertMessage,
        link: state.reportReducers.link,
    }
};
// const {successMessage, alertMessage, showMessage, showSuccessMessage} =
// useSelector(({auth}) => auth); Object of action creators
const mapDispatchToProps = {
    hideMessage: hideMessage,
    addReport : addReport,
    clearLink : clearLink,
    update_assessment_for_company : update_assessment_for_company
} 
const EditReportForm = Form.create()(ReportForm);
// export default RegistrationForm;
export default connect(mapStateToProps, mapDispatchToProps)(EditReportForm);
