import React, {Component} from "react";
// import {Col, Row, Card, Table, Button} from "antd"; 
import {Link} from 'react-router-dom'
import {
    Button,
    Card,
    Col,
    Form,
    Input,
    Row,
    Select,
    message,
    Table,
    Tabs,
    Avatar,
    Badge,
    Upload,
    Tooltip,
    notification,
    
    
} from "antd";
import Widget from "components/Widget";
import {connect} from "react-redux";
import {add_profiler, update_profiler, hideMessage, get_staff} from "appRedux/actions/ProfilerActions";
import ProfilerAssessment from "../dashboard/Mycomponents/ProfilerAssessment";
import Auxiliary from "../../util/Auxiliary";
import ProfileHeader from "../dashboard/Mycomponents/ProfilerHeader";
import ProfileAssessmentTabItem from "../../routes/dashboard/Mycomponents/ProfileAssessmentTabItem";
import {get_assessmentbyprofiler,getAssessmentForCompany, setstatustoinitial,
    showAssessmentLoader,update_assessment_for_company} from "appRedux/actions/AssessmentActions";
import CircularProgress from "components/CircularProgress/index";
const {Option} = Select;
const TabPane = Tabs.TabPane;
var Profilerdata = ''; 
var assessment = '';
var assessmentByProfiler = '';
const Search = Input.Search;
const textTitle = <span>Change Logo!</span>;
function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }
   
  function beforeUpload(file) {
    //console.log("fileimage",file.type)
    //console.log("fileimage",file)
    
    if(file.type === '' || null){
      message.error('You can only upload image file!');
    }
    else{
      if ('image/jpeg' == file.type || 'image/png' == file.type || 'image/jpg' == file.type) {
      }
      else{
        message.error('You can only upload image file!');
      }      
    }
  
    return true;
  }
  var imagedata = ''
class AssignAssessment extends Component {

    constructor(props) {
        super(props);
        this.myRef = React.createRef()  
        this.state = {
            confirmDirty: false,
            company_name: '',
            email: 'demo@example.com',
            poc_name: '',
            no_of_assessment: '',
            phone_number: '',
            address1: '',
            address2: '',
            city: '',
            pincode: '',
            note: '',
            isUpdate: false,
            isAdd: true, 
            userid: '',
            username: '',
            cardTitle: 'Create Profiler',
            editdata: '',
            disabled: '',
            staffData: '',
            Profilerdata : '',
            authID : '',
            'profilerAssessmentAllData' : '',
            profiler_id : '',
            initState : false,
            Profilerdatarecord : '',
            current : 1,
            pagination: {
                current: 0,
                pageSize: 10,
                // defaultPageSize: 10, 
            },
            filter : '',
            fileList: [],
            previewImage : '',
            imagedata : '',
            comp_id : ''
        };
    }




    componentDidUpdate(prevProps) {
        // if (this.props.location !== prevProps.location) {
        // alert("hi");  
        // window.scrollTo(0, 0)/
        // this.anyRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        // }
        this.scrollToMyRef();


            setTimeout(() => {
            this.props.hideMessage();
            // this.props.history.push("/assessment");
          }, 2000);

        
      }

      componentWillMount(){
          localStorage.setItem("progress", 40);
        if(typeof this.props.authUser != 'undefined'){
            var authpermission = this.props.authUser.permission;
            this.setState({authID : this.props.authUser.id});
            //console.log("authpermission",authpermission);
            if(authpermission.length > 0){
                if(!authpermission.includes('93')){
                    this.props.history.push("/dashboard");
                }
            }
            // this.props.get_assessmentbyprofiler({profiler_id : this.state.authID});
           
        }
        let datarecord = this.props.location.update;

        // let retrievedState = JSON.parse(localStorage.getItem('profiler_record'));

        // //console.log("localStorageProfilerdata",retrievedState)
        if (datarecord == "undefined" || datarecord == "" || datarecord == null) {

                let retrievedStates = JSON.parse(localStorage.getItem('profiler_record'));

                //console.log("localStorageProfilerdataif",retrievedStates)
                this.setState({Profilerdatarecord : retrievedStates})
                this.setState({profiler_id: retrievedStates.id})
                this.props.get_assessmentbyprofiler({profiler_id : retrievedStates.id,type:''})
                retrievedStates.assessment = this.props.get_assessment_profiler
                this.setState({Profilerdata: retrievedStates})
    
                var values = {
                    "assessment_id" : 0,
                    "profiler_id" : retrievedStates.id,
                    
                  }
                this.props.getAssessmentForCompany(values);
                this.setState({initState : true})

        } else {
            // Object is NOT empty 
            //console.log("datarecord", datarecord)
            Profilerdata = datarecord;
            this.setState({Profilerdatarecord : datarecord})
            this.setState({profiler_id: datarecord.id})
            this.props.get_assessmentbyprofiler({profiler_id : datarecord.id,type:''})
            datarecord.assessment = this.props.get_assessment_profiler
            this.setState({Profilerdata: datarecord})

            var values = {
                "assessment_id" : 0,
                "profiler_id" : datarecord.id,
                
              }
            this.props.getAssessmentForCompany(values);
            this.setState({initState : true})
        }
        this.props.setstatustoinitial();
        
    }
    componentDidMount(){
        localStorage.setItem("progress", 100);

        setTimeout(() => {  
                    localStorage.setItem("progress", 0);
                  }, 2000);  


    }
    onChangeAssessment = (value) => {
        this.props.setstatustoinitial();
        var values = '';
        if(value == 'all'){
            values = {
                "assessment_id" : 0,
                "profiler_id" : this.state.profiler_id,
                
              }
        }
        else{
             values = {
                "assessment_id" : value,
                "profiler_id" : this.state.profiler_id,
                
              }
        }
        
            this.props.getAssessmentForCompany(values);
        // alert(value)
        this.setState({initState : true})
    }

    handleTableChange = (pagination, filters, sorter) => {
        //console.log("pagination",pagination.current)
        this.props.setstatustoinitial();
        var currents = 0;
        
        if(pagination.current >= 2){
                currents = (pagination.current - 1) * 10
        }
        else{
             currents = 0;
        }

        this.setState({
            current : pagination.current,
            field : sorter.field,
            order : sorter.order,
        })

        // if(value == 'all'){
        var  values = {
                "assessment_id" : 0,
                "profiler_id" : this.state.profiler_id,
                filter : this.state.filter,
                current : currents,
                field : sorter.field,
                order : sorter.order,
                
              }
              this.props.getAssessmentForCompany(values);

    };

    searchInputData = (vals) => {
        //console.log("searchInputData",vals)
        this.props.setstatustoinitial();
        this.setState({filter : vals})

        var values = '';
        // if(value == 'all'){
            values = {
                "assessment_id" : 0,
                "profiler_id" : this.state.profiler_id,
                filter : vals,
                current : 0,
                field : '',
                order : '',
                
              }
        
            this.props.getAssessmentForCompany(values);

    }

    handleChange = ({ fileList },id) => {
        //---------------^^^^^----------------
        // this is equivalent to your "const img = event.target.files[0]"
        // here, antd is giving you an array of files, just like event.target.files
        // but the structure is a bit different that the original file
        // the original file is located at the `originFileObj` key of each of this files
        // so `event.target.files[0]` is actually fileList[0].originFileObj
        console.log('fileList', fileList);
        console.log('handleChange', id);
        
        
        // you store them in state, so that you can make a http req with them later
        this.setState({ fileList });
        //  this.setState({'imagedata' : ''})
        imagedata = ''
        if(fileList.length > 0){

          var len = fileList.length - 1;
            console.log('fileList->len', len);
          if(typeof fileList[len].thumbUrl != 'undefined' || fileList[len].thumbUrl != '' || fileList[len].thumbUrl != null){

            console.log('fileList->typeof', fileList[len].thumbUrl);
            // alert('out')

            if(fileList[len].type == '' || null){
              console.log('fileList->type->null', fileList[len].type);
            }
            else{
              if ('image/jpeg' == fileList[len].type || 'image/png' == fileList[len].type || 'image/jpg' == fileList[len].type) {
                console.log('image/jpeg',fileList[len].type);
                this.setState({
                  // 'imagedata' : fileList[len].thumbUrl,
                  'comp_id' : id
                })
                imagedata = fileList[len].thumbUrl
                setTimeout(() => {
                this.imageuploadforcomp();
                },1000);
                
              }
              else{
                console.log('You can only upload image file');
                // message.error('You can only upload image file!');
              }
              
            }
      
            
          
        }
        

        // message.success('upload image');
            
      };
    }

      imageuploadforcomp(){
        // var imagedata = fileList[0].thumbUrl\
        //console.log("imagedata",imagedata)
        if(imagedata != ''){
          var values = {
            comp_id : this.state.comp_id,
            avatar : imagedata
          }
          //console.log("imagedata",values)

          if(values.avatar != '' || values.avatar != null ){
            // alert('hii')
            this.props.update_assessment_for_company(values); 

            setTimeout(() => {
                this.uploadImageList();
                },3000);
            
            // this.props.history.push("/fill-assessment");
            
            // this.props.get_assessmentbyprofiler({profiler_id : this.state.authID,type : 'publish'});
            this.props.hideMessage();
            // setTimeout(() => {
            //   this.props.hideMessage();
            //   this.props.history.push("/fill-assessment");
            //   this.props.hideMessage();
            // },100);
            imagedata = ''
          }
          imagedata = ''
        }
        imagedata = ''

        // this.setState({"fileList" : []})
          return
      }

      uploadImageList = () => {
        let retrievedStates = JSON.parse(localStorage.getItem('profiler_record'));

        ////console.log("localStorageProfilerdataif",retrievedStates)
        this.setState({Profilerdatarecord : retrievedStates})
        this.setState({profiler_id: retrievedStates.id})
        this.props.get_assessmentbyprofiler({profiler_id : retrievedStates.id,type:''})
        retrievedStates.assessment = this.props.get_assessment_profiler
        this.setState({Profilerdata: retrievedStates})

        var values = {
            "assessment_id" : 0,
            "profiler_id" : retrievedStates.id,
            
          }
        this.props.getAssessmentForCompany(values);
        this.setState({initState : true})
      }

    render() {

        function isEmpty(obj) {
            for (var key in obj) {
                if (obj.hasOwnProperty(key)) 
                    return false;
                }
            return true;
        }


        var RoleData = this.props.get_assessment_profiler;
        if (RoleData == "undefined" || RoleData == "" || RoleData == null)
        {
          // Object is empty (Would return true in this example)
        } 
        else 
        {
            // Object is NOT empty
            assessmentByProfiler = RoleData.assessmentByProfiler
            //console.log("assessmentByProfiler1",assessmentByProfiler)
            //console.log("assessmentByProfiler1",RoleData)


        }

        var AssessmentForCompany = ''
        if(this.state.initState == true){
         AssessmentForCompany = this.props.get_company_for_assessment;
        }
        
        var CompanyData = '';
        var paginationToatal = 0;
        if(isEmpty(AssessmentForCompany)){
            // Object is empty (Would return true in this example)
        }
        else 
        {
            // Object is NOT empty
            CompanyData = AssessmentForCompany.assessment
            paginationToatal = AssessmentForCompany.paginationTotal
            //console.log("AssessmentForCompany",AssessmentForCompany)
        }

        const columns = [
            {
                title: 'Logo',
                // dataIndex: 'company_name',
                width: 200,
                render: (text, record) => (
                    <span className="antduploadlist" >
                        <Upload
                            listType="picture"
                            showUploadList="false"
                            onChange={(e)  => this.handleChange(e,record.id)}
                            beforeUpload={beforeUpload}
                        >
                            <Tooltip placement="top" title={textTitle}>
                            <Avatar src={record.avatar ? record.avatar : 'https://via.placeholder.com/500X500/afafaf/000000?text=Click+Here!' }
                            className="gx-size-50 gx-pointer gx-mr-3" alt=""/>
                            </Tooltip>
                        </Upload>
                    </span>
                )

            }, 
            {
                title: 'Company Name',
                dataIndex: 'company_name',
                width: 300,
                sorter: true,

            }, {
                title: 'Point of Contact',
                dataIndex: 'person_name',
                width: 300,
                sorter: true,
                
            },
            {
                title: 'Date',
                dataIndex: 'created_datetime',
                width: 300,
                sorter: true,
                render: (text, record) => (
                    <span>
                        {
                        record.answer.length > 0
                        ?
                            record.answer[0].status == 1
                            ?
                            'Submitted '+record.answer[0].created_datetime
                            :
                            'Last Saved '+record.answer[0].created_datetime    
                        :
                        'Day Created '+record.created_datetime
                        }      
                    </span>
                )
                
            },
            
             {
                title: 'Status',
                key: 'action',
                width: 200,
                render: (text, record) => (
                    <span>
                        {
                        record.answer.length > 0
                        ?
                            record.answer[0].status == 1
                            ?
                            <>
                            <Link to={{ pathname:"/profiler-assessment-view", state : {record : record,Profilerdatarecord : this.state.Profilerdatarecord}, }} > 
                            <Tooltip placement="top" title='View Submitted Assessment'>
                                                <i className="icon icon-view"></i>
                                              </Tooltip>
                                {/* <Badge
                                    className="site-badge-count-109 cust_badges_assignT cust_btn_submited"
                                    count="Preview"
                                    style={{
                                    backgroundColor: "#0077b6",
                                    width : "100px"
                                    }}
                                /> */}
                            </Link>
                            {'\u00A0'}|{'\u00A0'} 
                            <Badge
                                className="site-badge-count-109 cust_badges_assignT cust_btn_submited"
                                count="Submitted"
                                style={{
                                backgroundColor: "#6BA539",
                                // width: "40px"
                                }}
                            />
                            
                            </>
                            :
                            <Badge
                                className="site-badge-count-109 cust_badges_assignT cust_btn_inprogress"
                                // abc={ this.setState({banned : record.banned}) }
                                // count={this.state.status_value}
                                count="In Progress"
                                style={{
                                backgroundColor: "#DC4405",
                                width: "100px"
                                }}
                            />  
                        :
                        <Badge
                            className="site-badge-count-109 cust_badges_assignT cust_btn_notstarted"
                            // abc={ this.setState({banned : record.banned}) }
                            // count={this.state.status_value}
                            count="Not Started Yet"
                            style={{
                            backgroundColor: "#10069F",
                            width: "100px"
                            }}
                        />
                        }       
                    </span>
                )    
            }
        ];

        const searchBtn = (
            <Search
            className="searchBtn"
            placeholder="Search"
            onSearch={(value) => this.searchInputData(value)}
            style={{width: 300, marginLeft:10,marginRight:10}}
            allowClear 
          />
           
       );
       
        return (
            <>
            <Auxiliary>
                
                            <ProfileHeader data={this.state.Profilerdata} newdata={this.props.get_assessment_profiler}/>

                <div className="gx-profile-content">
                    <Widget title="Assessment" 
                    extra={[
                        <>
                        {searchBtn}
                        <Select
                            defaultValue='all'
                            placeholder="Select Assessment"
                            style={{
                            width: 300,
                            verticalAlign: "middle"
                            }}
                            
                            onChange={(e) => this.onChangeAssessment(e)}
                        >
                            <Option key="all" value="all">All</Option>
                        {
                            assessmentByProfiler
                            ?
                            
                            
                            assessmentByProfiler.map(province => (
                                province.assessment.length > 0
                                ?
                                <Option key={province.assessment[0].id} >{province.assessment[0].name}</Option>
                                :
                                null
                            ))
                        :
                        <Option value="disabled" disabled>Please Select Assessment</Option>
                        }

                        </Select>
                        </>
                        ]}  
                        styleName="gx-card-tabs gx-card-tabs-right gx-card-profile"
                        >
                            <Table style={{width: "100%"}}
                                className="gx-table-responsive"
                                columns={columns}
                                dataSource={CompanyData}
                                loading ={this.props.loader}
                                rowkey="record.id"
                                pagination={{
                                    current : this.state.current,
                                    total : paginationToatal,
                                    defaultPageSize: 10, 
                                    hideOnSinglePage: true
                                }}
                                onChange={this.handleTableChange}

                            />
                
                </Widget> 
                </div>

            </Auxiliary>
            {this.props.showSuccessMessage
                    ?
                    notification['success']({
                        message: 'success',
                        description: this.props.successMessage.toString()
                      })
                    : null}

                {this.props.showMessage 
                    ?
                    notification['error']({
                        message: 'error',
                        description: this.props.alertMessage.toString()
                      }) 
                    : null}
            </>
       );
    }
    scrollToMyRef = () => window.scrollTo(0, 0)

}
const mapStateToProps = state => {
    return {authUser: state.auth.authUser, 

        getStaffData: state.staffReducers.get_staff_res,
        get_assessment_profiler: state.assessmentReducers.get_assessment_profiler ,
        loader : state.assessmentReducers.loader,
        get_company_for_assessment : state.assessmentReducers.get_company_for_assessment,
        status : state.assessmentReducers.status, }
};
// const {successMessage, alertMessage, showMessage, showSuccessMessage} =
// useSelector(({auth}) => auth); Object of action creators
const mapDispatchToProps = {
    add_profiler: add_profiler,
    update_profiler: update_profiler,
    hideMessage: hideMessage,
    get_staff : get_staff,
    get_assessmentbyprofiler : get_assessmentbyprofiler,
    getAssessmentForCompany : getAssessmentForCompany,
    setstatustoinitial,
    showAssessmentLoader,
    update_assessment_for_company

}
// const RegistrationForm = Form.create()(Addprofiler);
// export default RegistrationForm;
export default connect(mapStateToProps, mapDispatchToProps)(AssignAssessment);
