import React, {Component} from "react";
// import {Col, Row, Card, Table, Button} from "antd";
import {
    Row,
    Card,
    Table,
    Button,
    message,
    Popconfirm,
    Badge,
    notification,
    Input,
    Tooltip
} from "antd";
import {Link} from 'react-router-dom'
import Auxiliary from "util/Auxiliary";
import {connect} from "react-redux";
import {get_profiler,deleteUserById,userStatusChange,showProfilerLoader,hideMessage,setstatustoinitial} from "appRedux/actions/ProfilerActions";
import {clearAssessmentForCompany,clear_assessmentbyprofiler} from "appRedux/actions/AssessmentActions"
import { SendUserActivationLink } from "appRedux/actions/ProfilerActions";
import CircularProgress from "components/CircularProgress/index";
import { render } from "@testing-library/react";
import ReactExport from "react-export-excel";
import { SearchOutlined } from '@ant-design/icons';

const Search = Input.Search;
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
// const Dtext = 'Are you sure delete this task?';
const hurl = "#";
class Profiler extends Component {
    constructor(props) {
        super(props);
        this.state = {
            status_value: 'Active',
            status_color: '#6BA539',
            Active_color : '#6BA539',
            Deactive_color: '#ff4d4f',
            data: [],
            banned : '',
            current : 1,
            filter : '',
            field : 'undefined',
            order : 'undefined',
        };
        this.confirm = this
            .confirm
            .bind(this);
        this.deleteProfiler = this
            .deleteProfiler
            .bind(this);
        this.chageStatusProfiler = this
            .chageStatusProfiler
            .bind(this);
    }

    confirm(key) {
        console.log("mydata",key);
        message.info('Are you sure want to delete?');
        this.setState({sget_profilerByIdatus_value: 'Deactive', status_color: '#ff4d4f'});

    }

    deleteProfiler(key) {
        
        this.props.showProfilerLoader();
        this.props.deleteUserById(key);
        // message.success('Your record is deleted');
        // this.get_profilerById()
    }

    chageStatusProfiler(id,status) {
        // message.success('Status has been changed');
        var Statusdata = {
            "id" : id,
            "status" : status
        }
        this.props.showProfilerLoader();
        this.props.userStatusChange(Statusdata);
        // this.get_profilerById();
    }

    SendLink(email) {
        
        var email = {
            "email" : email
        }
        this.props.SendUserActivationLink(email);
        // message.success('Link has been sent.');
        // this.get_profilerById();
    }

    get_profilerById(){
        // alert(this.props.status);
        if(this.props.status == 'Initial'){
            // this.props.showProfilerLoader();
            var currents = 0;

            if(this.state.current >= 2){
                    currents = (this.state.current - 1) * 10
            }
            else{
                currents = 0;
            }
            var val = {
                group_id : 6,
                filter : this.state.filter,
                current : currents,
                field : this.state.field,
                order : this.state.order,
            }
            this.props.get_profiler(val);
            // console.log("vishalmelwani")
        }
        
    }
    componentDidUpdate(){
        if(this.props.showSuccessMessage || this.props.showMessage)
        {
            // this.get_profilerById()
                setTimeout(() => {
                    this.props.hideMessage();
                }, 100);
        }
        this.get_profilerById();
    }

    componentDidMount(){
        localStorage.setItem("progress", 100);

        setTimeout(() => {  
                    localStorage.setItem("progress", 0);
                  }, 2000); 
                  this.setState(
                    {
                        field : 'undefined',
                        order : 'undefined',
                        
                    }
                ) 
        this.props.setstatustoinitial()
        this.get_profilerById();
    }

    componentWillMount(){

    localStorage.setItem("progress", 40);
        if(typeof this.props.authUser != 'undefined'){

            var authpermission = this.props.authUser.permission;
            console.log("authpermission",authpermission);
            if(authpermission.length > 0){
                if(!authpermission.includes('93')){
                    this
                    .props
                    .history
                    .push("/dashboard");
                }
            }
        }
        
        this.get_profilerById();

        // if(typeof this.props.authUser != 'undefined'){
        //     var authpermission = this.props.authUser;
        //     console.log("authpermission",authpermission);
        //     if(!authpermission.includes('93')){
                // this
                // .props
                // .history
                // .push("/dashboard");
        //     }
        // }
        
    }

    searchInputData = (vals) => {
       
        console.log("searchInputData",vals)

        this.setState({filter : vals,current : 1})
        this.setState(
            {
                field : 'undefined',
                order : 'undefined',
                
            }
        )
        this.props.setstatustoinitial();
        // var val = {
        //     group_id : 5,
        //     filter : vals,
        //     current : 0
        // }
        // this.props.get_staff(val);
        // alert("search in put")

    }

    handleTableChange = (pagination, filters, sorter) => {
        
        console.log("pagination",pagination.current)
        var currents = 0;
        
        if(pagination.current >= 2){
                currents = (pagination.current - 1) * 10
        }
        else{
             currents = 0;
        }

        this.setState({
            current : pagination.current,
            field : sorter.field,
            order : sorter.order,
        })
        this.props.setstatustoinitial();
        // var values = {
        //     group_id : 5,
        //     current : currents,
        //     filter : this.state.filter
        // }
        // this.props.get_staff(values);
        // alert('handlechange')

    };


   

    redirectlink_assigned_assessment = (record) =>
    {


        this.props.clearAssessmentForCompany();
        this.props.clear_assessmentbyprofiler();
        let savedState =  JSON.stringify(record)
        localStorage.setItem('profiler_record', savedState);
        // localStorage.setItem('profiler_record', record);
        this.props.history.push({
            pathname:"/assigned-assessment",
            update : record
            })
    }
    // componentDidUpdate() {
    //     window.scrollTo(0,0);
    //   }
    render() {

        
        function isEmpty(obj) {
            for(var key in obj) {
                if(obj.hasOwnProperty(key))
                    return false;
            }
            return true;
        }
        var profilerData = this.props.getProfilerData;
        var pdata = ''; 
        var pagiTotal = 0;
        var excelData  = ''
        if(isEmpty(profilerData)) {
            // Object is empty (Would return true in this example) 
        } else {
            // Object is NOT empty
            pdata = profilerData.user
            excelData = profilerData.exceldata
            pagiTotal = profilerData.total
            console.log("pdata",pdata)
        }


        const columns = [
            {
                title: 'Name',
                dataIndex: 'company_name',
                width: 120,
                sorter: true,
                // align : "center",
                // key: "company_name",
                // fixed: 'left',
                // defaultSortOrder: "descend",
                // sorter: (a, b) => (a.company_name ? a.company_name.localeCompare(b.company_name) : 1),

            }, {
                title: 'Email',
                dataIndex: 'email',
                width: 180,
                sorter: true,
                // align : "center"
                // fixed: 'left'

            }, {
                title: 'KMS POC',
                dataIndex: 'poc_name',
                width: 120,
                sorter: true,
                
            },
            // {
            //     title: 'No. Of Assessment',
            //     dataIndex: 'no_of_assessment',
            //     width: 250,
            // },
            {
                title: 'Phone Number',
                dataIndex: 'phone_number',
                width: 110,
                sorter: true,

            },{
                title: 'Created Date',
                dataIndex: 'date',
                sorter: true,
                // key: "date",
                // sorter: (a, b) => a.date.localeCompare(b.date),
                width: 120
       
            },
            {
                title: 'Status',
                align : "center",
                // dataIndex: 'date',
                width: 100,
                render : (text,record) =>(
                    <span>
                        {
                            record.banned
                            ?
                            <Popconfirm
                            placement="top"
                            title="Are you sure change this status?"
                            onConfirm={(e) => this.chageStatusProfiler(record.id,'inactive')}
                            okText="YES"
                            cancelText="NO">
                            <a href={hurl}>
                            {/* {'\u00A0'}|{'\u00A0'} */}
                                <Badge
                                    className="site-badge-count-109"
                                    // abc={ this.setState({banned : record.banned}) }
                                    // count={this.state.status_value}
                                    count="Active"
                                    style={{
                                    backgroundColor: this.state.Active_color,
                                    width: "70px"
                                    }}
                                /></a>
                            </Popconfirm>
                            :
                            <Popconfirm
                            placement="top"
                            title="Are you sure change this status?"
                            onConfirm={(e) => this.chageStatusProfiler(record.id,'active')}
                            okText="YES"
                            cancelText="NO">
                            <a href={hurl}>
                            {/* {'\u00A0'}|{'\u00A0'} */}
                                <Badge
                                    className="site-badge-count-109"
                                    // abc={ this.setState({banned : record.banned}) }
                                    count="Deactive"
                                    style={{
                                    backgroundColor: this.state.Deactive_color,
                                    width: "70px"
                                    }}
                                /></a>
                            </Popconfirm>
                        }
                    </span>
                )
       
            },
            
            {
                title: 'Action',
                key: 'action',
                width: 200,
                // align : "center",
                render: (text, record) => (

                    <span>
                        
                        <Link onUpdate={() => window.scrollTo(0, 0)} to={{
                            pathname:"/add-profiler",
                            state : {record : record}
                        }} >
                            <i className="icon icon-edit"></i>
                        </Link>
                        
                        <Popconfirm
                            placement="top"
                            title="Are you sure want to delete?"
                            onConfirm={(e) => this.deleteProfiler(record.id)}
                            okText="YES"
                            cancelText="NO">
                            <a href={hurl}>
                            {'\u00A0'}|{'\u00A0'} 
                                <i
                                    className="icon icon-trash"
                                    style={{
                                    color: "red"
                                }}></i>
                            </a>
                        </Popconfirm>
                        
                        <span className="hoverCursor" onClick={()=> this.redirectlink_assigned_assessment(record)} >
                        {'\u00A0'}|{'\u00A0'} 
                            <Tooltip placement="top" title='Profiler View'>
                                <i className="icon icon-view"></i>
                            </Tooltip>
                        </span>
                        {/* <Link to={{
                                pathname:"/assigned-assessment",
                                update : record
                            }} >
                                {'\u00A0'}|{'\u00A0'} 
                                <i className="icon icon-view"></i>
                                
                        </Link> */}
                        {
                            record.banned
                            ?
                            ''
                            :
                            <Popconfirm
                            placement="top"
                            title="Send Link, Are you sure?"
                            onConfirm={(e) => this.SendLink(record.email)}
                            okText="YES"
                            cancelText="No">
                            <a href={hurl}>
                            {'\u00A0'}|{'\u00A0'}
                                <Badge
                                    className="site-badge-count-109"
                                    // abc={ this.setState({banned : record.banned}) }
                                    // count={this.state.status_value}
                                    count="Activation Link"
                                    style={{
                                    backgroundColor: this.state.Active_color
                                    }} 
                                /></a>
                            </Popconfirm>
                        }
                        

                    </span>
                )
            }
        ];


        const excelbtn = (
            <ExcelFile filename="Profiler" element={<Button type="primary" className="exportbtn" icon="download" >EXPORT</Button>}>
                <ExcelSheet data={excelData} name="Profiler">
                    <ExcelColumn label="Name" value="company_name"/>
                    <ExcelColumn label="Email" value="email"/>
                    <ExcelColumn label="KMS POC" value="poc_name"/>
                    <ExcelColumn label="Phone Number" value="phone_number"/>
                    <ExcelColumn label="Created Date" value="date"/>
                    {/* <ExcelColumn label="Status" value="sex"/> */}
                    <ExcelColumn label="Status"
                                 value={(record) => record.banned ? "Active" : "Deactive"}/>
                </ExcelSheet>
            </ExcelFile>
        );
        const searchBtn = (
            <Search
            className="searchBtn"
            placeholder="Search"
            onSearch={(value) => this.searchInputData(value)}
            style={{width: 300, marginLeft:10,marginRight:10}}
            allowClear 
          />
           
       );

        return (
         
                    <Card 
                        className="gx-card  cust_asses"
                        style={{width: "100%"}}
                        title="Profiler Management" 
                        extra={ 
                            <div className="cardextraelement">
                            {searchBtn}
                            <Link to="/add-profiler"  className="ADDbtn" >
                                <Button type="primary" icon="add">
                                    <i className="icon icon-add"></i>
                                    ADD
                                </Button>
                                </Link>
                            {excelbtn}
                        </div>
                            }>
                        
                        {/* {this.props.loader ?
                        <div className="gx-loader-view">
                            <CircularProgress/>
                        </div> : null}      */}
                        {/* {this.props.showSuccessMessage ?
              message.success(this.props.successMessage.toString()) : null}
                        {this.props.showMessage ?
              message.error(this.props.alertMessage.toString()) : null} */}
                        {/* {this.props.loader ? null :  */}
                        <Table style={{width: "100%"}}
                            className="gx-table-responsive"
                            columns={columns}
                            dataSource={pdata}
                            loading ={this.props.loader}
                            rowkey={record => record.id}
                            pagination={{
                                current : this.state.current,
                                total : pagiTotal,
                                defaultPageSize: 10, 
                                hideOnSinglePage: true
                            }}
                            onChange={this.handleTableChange}
                            // scroll={{ x: "max-content" }}
                        /> 
                        {/* // } */}

                {this.props.showSuccessMessage
                    ?
                    notification['success']({
                        message: 'success',
                        description: this.props.successMessage.toString(),
                      })
                    : null}

                {this.props.showMessage 
                    ?
                    notification['error']({
                        message: 'error',
                        description: this.props.alertMessage.toString(),
                      }) 
                    : null}
                    </Card>
            
        );
    }
    
};

// Object of action creators
const mapDispatchToProps = {
    get_profiler,
    deleteUserById,
    userStatusChange,
    SendUserActivationLink,
    showProfilerLoader,
    hideMessage,
    setstatustoinitial,
    clearAssessmentForCompany,
    clear_assessmentbyprofiler
}

const mapStateToProps =  state => {
return { 
        getProfilerData: state.profilerReducers.get_profiler_res,
        loader : state.profilerReducers.loader,
        showSuccessMessage : state.profilerReducers.showSuccessMessage,
        successMessage : state.profilerReducers.successMessage,
        authUser : state.auth.authUser,
        showMessage : state.profilerReducers.showMessage,
        alertMessage : state.profilerReducers.alertMessage,
        status : state.profilerReducers.status,
    }; 
};

// export default Profiler;
export default connect(mapStateToProps,mapDispatchToProps)(Profiler);
