import {
  HIDE_MESSAGE,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  ROLE_SHOW_MESSAGE,
  ROLE_PERMISSION_SHOW_SUCCESS_MESSAGE,

  ADD_PERMISSION,
  GET_PERMISSION_BY_ROLEID,
  GET_PERMISSION_SUCCESS_DATA,

  GET_ROLE,
  ADD_ROLE,
  DELETE_ROLE,
  GET_ROLE_SUCCESS_DATA,
  GET_STATUS_INITIAL


} from "constants/ActionTypes";


/* Role & Permission Module start */
export const get_role = (user) => {
    return {
      type: GET_ROLE,
      payload: user
    };
  };
export const setstatustoinitial = () => {
  return {
    type: GET_STATUS_INITIAL,
  };
}
  export const add_role = (user) => {
    return {
      type: ADD_ROLE,
      payload: user
    };
  };

  export const getRoleSuccess = (data) => {
    return {
      type: GET_ROLE_SUCCESS_DATA,
      payload: data
    };
  };

  export const addPermission = (data) => {
    return {
      type: ADD_PERMISSION,
      payload: data
    };
  };
  
  export const getpermissionSuccess = (data) => {
    return {
      type: GET_PERMISSION_SUCCESS_DATA,
      payload: data
    };
  };
  
  export const getPermissionByRoleID = (data) => {
    return {
      type: GET_PERMISSION_BY_ROLEID,
      payload: data
    };
  };

  export const deleteRoleById = (data) => {
    return {
      type: DELETE_ROLE,
      payload: data
    };
  };



/* Role & Permission Module end*/

export const showErrorMessage = (message) => {
  return {
    type: ROLE_SHOW_MESSAGE,
    payload: message
  };
};

export const showSuccessMessage = (message) => {
  return {
    type: ROLE_PERMISSION_SHOW_SUCCESS_MESSAGE,
    payload: message
  };
};


export const showRoleLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};
export const hideAuthLoader = () => {
  return {
    type: ON_HIDE_LOADER,
  };
};

