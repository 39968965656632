import {
    HIDE_MESSAGE,
    ON_HIDE_LOADER,
    ON_SHOW_LOADER,
    SHOW_MESSAGE,
    REPORT_SHOW_SUCCESS_MESSAGE,
    GET_STATUS_INITIAL,
    GET_ReportList_SUCCESS_DATA,
    REPORT_SHOW_SUCCESS_MESSAGE_WITH_LINK,
    CLEAR_PREVIEW_LINK
} from "constants/ActionTypes";

// import States from "../states/states";

const INIT_STATE = {
    loader: false,
    alertMessage: '',
    successMessage: '',
    showMessage: false,
    showSuccessMessage: false,
    initURL: '',
    status : 'Initial',
    get_reportList_res : '',
    link : '',
    
};

export default(state = INIT_STATE, action) => {

    // if(States ===undefined){     return new States(); }
    switch (action.type) {

        case SHOW_MESSAGE:
            {
                return {
                    ...state,
                    alertMessage: action.payload,  
                    showMessage: true,
                    loader: true,
                    status : 'Initial'
                }
            }
        case GET_STATUS_INITIAL: 
            {
                return{
                    ...state,
                    status : 'Initial',
                    loader: true,
                }
            }
        case REPORT_SHOW_SUCCESS_MESSAGE:
            {
                return {
                    ...state,
                    // authUser: null,                                                                                                              
                    successMessage: action.payload,
                    showSuccessMessage: true,
                    loader: true,
                    status : 'Initial'
                }
            } 

        case REPORT_SHOW_SUCCESS_MESSAGE_WITH_LINK :
            {
                return {
                    ...state,                                                                                                          
                    // successMessage: action.payload,
                    link: action.link,
                    showSuccessMessage: false,
                    showMessage: false,
                    // showSuccessMessage: true,
                    loader: true,
                    status : 'Initial'
                }
            }
        case CLEAR_PREVIEW_LINK :
            {
                return {
                    ...state,
                    link : ''
                }
            }

        case GET_ReportList_SUCCESS_DATA: 
        {
            // console.log("payload",action.payload)
            return {
                ...state,
                // authUser: null,
                showSuccessMessage: false,
                showMessage: false,
                get_reportList_res: action.payload,
                loader: false,
                status : 'Dataloaded'
            }
        }
        

        case HIDE_MESSAGE:
            {
                return {
                    ...state,
                    alertMessage: '',
                    successMessage: '',
                    showMessage: false,
                    showSuccessMessage: false,
                    loader: false
                }
            } 

        case ON_SHOW_LOADER:
            {
                return {
                    ...state,
                    loader: true
                }
            }
        case ON_HIDE_LOADER:
            {
                return {
                    ...state,
                    loader: false
                }
            }
        default:
            return state;
    }
}
// export default INIT_STATE;