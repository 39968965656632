import React, {useEffect}  from "react";
import {Button, Form, Input, message, Upload, Icon, notification} from "antd";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {reset_password, hideMessage} from "appRedux/actions/Auth";
// import Sider from "antd/lib/layout/Sider";
// import IntlMessages from "util/IntlMessages";

const FormItem = Form.Item;
var vcode = '';

const AddPassword = (props) => {

    const dispatch = useDispatch();
    const {successMessage, alertMessage, showMessage, showSuccessMessage} = useSelector(({auth}) => auth);
    const history = useHistory();

    useEffect(() => {
        if (showMessage) {
            setTimeout(() => {
                dispatch(hideMessage());
            }, 100);
        }
        if (showSuccessMessage) {
            setTimeout(() => {
                dispatch(hideMessage());
                history.push('/signin');
            }, 100);
        }
        vcode = window.location.pathname.split("/").pop();
        // document.getElementById("vcode").value = vcode;
        // if (authUser !== null) {   history.push('/'); }
    });

    const handleSubmit = (e) => {

        e.preventDefault();
        props
            .form
            .validateFields((err, values) => {
                if (!err) {
                    values.vcode = vcode;
                    values.pType = "ADD";
                   // console.log('Received values of form: ', values);
                    dispatch(reset_password(values));
                }
            });
    };
    
    var pattern = '^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]+$';
    const compareToFirstPassword = (rule, value, callback) => {
        const form = props.form;
        if (value && value !== form.getFieldValue('password')) {
            // callback('Two passwords that you enter is inconsistent!');
            callback('The password does not match');
        } 
        else {
            callback();
        }
    };

    const validateToNextPassword = (rule, value, callback) => {
        const form = props.form;
        //var specialchar = new RegExp("^(?=.*?[#?!@$%^&*-<>])");
        // var specialchar = new RegExp("^(?=.*[@$!%*?&])");
        var specialchar = new RegExp("^(?=.*[ -\/:-@\[-\`{-~]{1,})");//for all special char
        if (value && props.confirmDirty) {
            form.validateFields(['confirm'], {force: true});
        }
        else{
           
            if (!value.match(specialchar)) {
                callback("Your password must contain at least one special character.");
            }
            callback();
        }
        
    };

    // const normFile = (e) => {
    //     //console.log('Upload event:', e);
    //     if (Array.isArray(e)) {
    //       return e;
    //     }
    //     return e && e.fileList;
    //   };
    
   

    const {getFieldDecorator} = props.form;

    return (
        <div className="gx-login-container">
            <div className="gx-login-content">

                <div className="gx-login-header">
                    <img src={require("assets/images/KMS_LOGO_new.png")} alt="wieldy" title="wieldy"/>
                </div>
                <div className="gx-mb-4">
                    <h2>Set Password</h2>
                    {/* <p><IntlMessages id="appModule.enterPasswordReset"/></p> */}
                    <p>Set your password for your account</p>
                </div>

                <Form onSubmit={handleSubmit} className="gx-login-form gx-form-row0">
                    {/* <FormItem>
                        {getFieldDecorator('company_name', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please enter your company name!'
                                }
                            ]
                        })(<Input placeholder="Company Name"/>
                        )}
                    </FormItem> */}

                    

                    <FormItem>
                        {getFieldDecorator('password', {
                            rules: [
                                
                                { min: 6, message: 'Please enter minimum 6 characters.' },
                                { max: 32, message: 'Please enter maximum 32 characters.' },
                                 {
                                    validator: validateToNextPassword
                                },
                                {
                                    required: true,
                                    message: 'Please enter your Password!'
                                }
                            ]
                        })(<Input type="password" placeholder="New Password"/>
                        )}
                    </FormItem>

                    <FormItem>
                        {getFieldDecorator('confirm', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please enter Confirm Password'
                                }, {
                                    validator: compareToFirstPassword
                                }
                            ]
                        })(<Input placeholder="Confirm Password" type="password"/>)}
                    </FormItem>

                    {/* <FormItem
                        // {...formItemLayout}
                        style={{width:"300px"}}
                    >
                        {getFieldDecorator('upload', {
                        valuePropName: 'fileList',
                        getValueFromEvent: normFile,
                        })(
                        <Upload name="logo" action="/upload.do" listType="picture">
                            <Button>
                            <Icon type="upload"/> Click to upload
                            </Button>
                        </Upload>
                        )}
                    </FormItem> */}

                    
                    {/* <FormItem>
                    {getFieldDecorator('vcode', {
                        rules: [
                            {
                                required: true,
                            }
                        ]
                        })(<Input type="hidden" id="vcode" />
                        )}
                        
                    </FormItem> */}

                    <FormItem>
                        <Button type="primary" htmlType="submit">
                            SET PASSWORD
                        </Button>
                    </FormItem>
                    
                </Form>
                {/* {showSuccessMessage
                    ? message.success(successMessage.toString())
                    : null}

                {showMessage
                    ? message.error(alertMessage.toString())
                    : null} */}

                {showSuccessMessage
                    ?
                    notification['success']({
                        message: 'success',
                        description: successMessage.toString()
                      })
                    : null}

                {showMessage 
                    ?
                    notification['error']({
                        message: 'error',
                        description: alertMessage.toString()
                      }) 
                    : null}
            </div>
        </div>
    );
};

const WrappedAddPasswordForm = Form.create()(AddPassword);

export default WrappedAddPasswordForm;


