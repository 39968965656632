import React, {useEffect} from "react";
import {Button, Form, Input, message, notification} from "antd";
import IntlMessages from "util/IntlMessages";
import {Link, useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {forgot_password, hideMessage} from "appRedux/actions/Auth";

const FormItem = Form.Item;

const ForgotPassword = (props) => {

    const dispatch = useDispatch();
    const {successMessage, alertMessage, showMessage, showSuccessMessage} = useSelector(({auth}) => auth);
    const history = useHistory();

    useEffect(() => {
        if (showMessage) {
            setTimeout(() => {
                dispatch(hideMessage());
            }, 100);
        }
        if (showSuccessMessage) {
            setTimeout(() => {
                dispatch(hideMessage());
                history.push('/signin');
            }, 100);
        }
        // if (authUser !== null) {   history.push('/'); }
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        props
            .form
            .validateFields((err, values) => {
                if (!err) {
                   // console.log('Received values of form: ', values);
                    dispatch(forgot_password(values));
                }            
            });

    };

    const {getFieldDecorator} = props.form;

    return (
        <div className="gx-login-container">
            <div className="gx-login-content">

                <div className="gx-login-header">
                    <img alt="logo2" src={require("assets/images/KMS_LOGO_new.png")}/>
                </div>
                <div className="gx-mb-4">
                    <h2>Forgot Your Password?</h2>
                    <p><IntlMessages id="app.userAuth.forgot"/></p>
                </div>

                <Form
                    layout="vertical"
                    onSubmit={handleSubmit}
                    className="gx-login-form gx-form-row0">

                    <FormItem>
                        {getFieldDecorator('email', {
                            rules: [
                                {
                                    type: 'email',
                                    message: 'The E-mail is not valid!'
                                }, {
                                    required: true,
                                    message: 'Please enter your E-mail!'
                                }
                            ]
                        })(<Input type="email" placeholder="E-Mail Address"/>)}
                    </FormItem>

                    <FormItem>
                        <Button type="primary" htmlType="submit">
                            {/* <IntlMessages id="app.userAuth.send"/> */}
                            SEND
                        </Button>
                    </FormItem>
                    <FormItem>
                        <Link className="gx-login-form-forgot" to="signin">Back To SIGN IN</Link>
                    </FormItem>
                </Form>

                {/* {showSuccessMessage
                    ? message.success(successMessage.toString())
                    : null}

                {showMessage
                    ? message.error(alertMessage.toString())
                    : null} */}
                
                {showSuccessMessage
                    ?
                    notification['success']({
                        message: 'success',
                        description: successMessage.toString()
                      })
                    : null}

                {showMessage 
                    ?
                    notification['error']({
                        message: 'error',
                        description: alertMessage.toString()
                      }) 
                    : null}

            </div>
        </div>
    );
}

const WrappedForgotPasswordForm = Form.create()(ForgotPassword);

export default(WrappedForgotPasswordForm);
