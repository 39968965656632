// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

//Contact Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';

//Auth const
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS';
export const SIGNIN_TWITTER_USER = 'SIGNIN_TWITTER_USER';
export const SIGNIN_TWITTER_USER_SUCCESS = 'SIGNIN_TWITTER_USER_SUCCESS';
export const SIGNIN_GITHUB_USER = 'SIGNIN_GITHUB_USER';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_success';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL'; 
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const SEND_ACTIVATION_LINK = 'SEND_ACTIVATION_LINK';
export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const GET_SUCCESS_USER_DETAILS = 'GET_SUCCESS_USER_DETAILS';
export const GET_DASHBORAD_COUNT = 'GET_DASHBORAD_COUNT';
export const GET_DASHBORAD_COUNT_DATA = 'GET_DASHBORAD_COUNT_DATA';

//Sticky
export const GET_STICKY = 'GET_STICKY';
export const NOTES_UPDATE = 'NOTES_UPDATE';
export const FETCH_ALL_NOTES_SUCCESS = 'FETCH_ALL_NOTES_SUCCESS';
export const UPDATE_ALL_NOTES_SUCCESS = 'UPDATE_ALL_NOTES_SUCCESS';

//Contact
export const GET_All_CONTACT_SUCCESS = 'GET_All_CONTACT_SUCCESS';
export const ON_ADD_CONTACT_SUCCESS = 'ON_ADD_CONTACT_SUCCESS';
export const UPDATE_CONTACT_SUCCESS = 'UPDATE_CONTACT_SUCCESS';
export const DELETE_CONTACT_SUCCESS = 'DELETE_CONTACT_SUCCESS';

/* kp updated start */
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const SHOW_SUCCESS_MESSAGE = 'SHOW_SUCCESS_MESSAGE';
export const RESET_PASSWORD = 'RESET_PASSWORD';

//Profiler section
export const ADD_PROFILER = 'ADD_PROFILER';
export const GET_PROFILER = 'GET_PROFILER';
export const GET_PROFILER_SUCCESS_DATA = 'GET_PROFILER_SUCCESS_DATA';
export const UPDATE_PROFILER = 'UPDATE_PROFILER';
export const UPDATE_PROFILER_USER = 'UPDATE_PROFILER_USER';
export const GET_STATUS_INITIAL = 'GET_STATUS_INITIAL';
export const PROFILER_USER_STATUS_CHANGE = 'PROFILER_USER_STATUS_CHANGE';
export const PROFILER_DELETE_USER_ROW = 'PROFILER_DELETE_USER_ROW';
export const PROFILER_SHOW_SUCCESS_MESSAGE = 'PROFILER_SHOW_SUCCESS_MESSAGE';
export const PROFILER_SEND_ACTIVATION_LINK = 'PROFILER_SEND_ACTIVATION_LINK';

//Staff section
export const ADD_STAFF = 'ADD_STAFF';
export const GET_STAFF = 'GET_STAFF';
export const UPDATE_STAFF = 'UPDATE_STAFF';
export const GET_STAFF_SUCCESS_DATA = 'GET_STAFF_SUCCESS_DATA';
export const STAFF_USER_STATUS_CHANGE = 'STAFF_USER_STATUS_CHANGE';
export const STAFF_STATUS_SHOW_SUCCESS_MESSAGE = 'STAFF_STATUS_SHOW_SUCCESS_MESSAGE';
export const DELETE_STAFF_USER_ROW = 'DELETE_STAFF_USER_ROW';
export const STAFF_SEND_ACTIVATION_LINK = 'STAFF_SEND_ACTIVATION_LINK';
export const UPDATE_STAFF_USER = 'UPDATE_STAFF_USER';

export const CHANGE_STATUS = 'CHANGE_STATUS';
export const UPDATE_USER = 'UPDATE_USER';

export const DELETE_USER_ROW = 'DELETE_USER_ROW';
export const USER_STATUS_CHANGE = 'USER_STATUS_CHANGE';


//* Role & Permission section start *//
export const GET_ROLE = 'GET_ROLE';
export const ADD_ROLE = 'ADD_ROLE';
export const DELETE_ROLE = "DELETE_ROLE"
export const GET_ROLE_SUCCESS_DATA = 'GET_ROLE_SUCCESS_DATA';

export const ADD_PERMISSION = 'ADD_PERMISSION';
export const GET_PERMISSION_BY_ROLEID = 'GET_PERMISSION_BY_ROLEID';
export const GET_PERMISSION_SUCCESS_DATA = 'GET_PERMISSION_SUCCESS_DATA';
export const ROLE_SHOW_MESSAGE = 'ROLE_SHOW_MESSAGE';
export const ROLE_PERMISSION_SHOW_SUCCESS_MESSAGE ='ROLE_PERMISSION_SHOW_SUCCESS_MESSAGE';
//* Role & Permission section end *//

//Assessment 
export const GET_ASSESSMENT = 'GET_ASSESSMENT';
export const ADD_ASSIGNED_ASSESSMENT = 'ADD_ASSIGNED_ASSESSMENT';
export const GET_ASSESSMENT_SUCCESS_DATA = 'GET_ASSESSMENT_SUCCESS_DATA';
export const ADD_ASSESSMENT = 'ADD_ASSESSMENT';
export const UPDATE_ASSESSMENT = 'UPDATE_ASSESSMENT';
export const ASSESSMENT_ADD_SHOW_SUCCESS_MESSAGE = 'ASSESSMENT_ADD_SHOW_SUCCESS_MESSAGE';
export const ASSESSMENT_SHOW_MESSAGE = 'ASSESSMENT_SHOW_MESSAGE';
export const ASSESSMENT_STATUS_CHANGE = 'ASSESSMENT_STATUS_CHANGE';
export const DELETE_ASSESSMENT_ROW = 'DELETE_ASSESSMENT_ROW';
export const ASSESSMENT_COPY_DATA = 'ASSESSMENT_COPY_DATA';
export const GET_ASSESSMENT_BYPROFILER = "GET_ASSESSMENT_BYPROFILER";
export const GET_ASSESSMENT_BYPROFILER_SUCCESS_DATA = "GET_ASSESSMENT_BYPROFILER_SUCCESS_DATA";
export const SUBMIT_ASSESSMENT_ANSWER = "SUBMIT_ASSESSMENT_ANSWER"
export const ADD_COMPANY_FOR_ASSESSMENT = "ADD_COMPANY_FOR_ASSESSMENT"
export const GET_COMPANY_FOR_ASSESSMENT = "GET_COMPANY_FOR_ASSESSMENT"
export const GET_COMPANY_FOR_ASSESSMENT_SUCCESS_DATA = "GET_COMPANY_FOR_ASSESSMENT_SUCCESS_DATA"
export const UPDATE_COMPANY_FOR_ASSESSMENT = "UPDATE_COMPANY_FOR_ASSESSMENT"

export const CLEAR_COMPANY_FOR_ASSESSMENT = "CLEAR_COMPANY_FOR_ASSESSMENT"
export const CLEAR_ASSESSMENT_BYPROFILER = "CLEAR_ASSESSMENT_BYPROFILER"

export const ADD_REPORT = "ADD_REPORT"
export const GET_REPORTLIST = "GET_REPORTLIST"
export const GET_ReportList_SUCCESS_DATA = "GET_ReportList_SUCCESS_DATA"
export const REPORT_SHOW_SUCCESS_MESSAGE = "REPORT_SHOW_SUCCESS_MESSAGE"
export const CLEAR_PREVIEW_LINK = "CLEAR_PREVIEW_LINK"


export const GET_METRIC_TABLE = "GET_METRIC_TABLE"
export const GET_METRIC_TABLE_SUCCESS_DATA = "GET_METRIC_TABLE_SUCCESS_DATA" 

export const GET_METRIC_GRAPH = "GET_METRIC_GRAPH"
export const GET_METRIC_GRAPH_SUCCESS_DATA = "GET_METRIC_GRAPH_SUCCESS_DATA" 
export const REPORT_SHOW_SUCCESS_MESSAGE_WITH_LINK = "REPORT_SHOW_SUCCESS_MESSAGE_WITH_LINK"

export const GET_ASSESSMENT_With_SUBMITED = "GET_ASSESSMENT_With_SUBMITED"
export const GET_ASSESSMENT_With_SUBMITED_DATA = "GET_ASSESSMENT_With_SUBMITED_DATA"


export const GET_ASSESSMENT_BY_PROFILER_DASHBOARD = "GET_ASSESSMENT_BY_PROFILER_DASHBOARD"
export const GET_ASSESSMENT_BY_PROFILER_DASHBOARD_DATA = "GET_ASSESSMENT_BY_PROFILER_DASHBOARD_DATA"

export const GET_HISTORY_ASSIGNED_ASSESSMENT = "GET_HISTORY_ASSIGNED_ASSESSMENT"
export const GET_HISTORY_ASSIGNED_ASSESSMENT_DATA = "GET_HISTORY_ASSIGNED_ASSESSMENT_DATA"

export const CREATE_ASSESSMENT_EXCEL = "CREATE_ASSESSMENT_EXCEL"
export const CREATE_ASSESSMENT_EXCEL_SUCCESS = "CREATE_ASSESSMENT_EXCEL_SUCCESS"