import {
  HIDE_MESSAGE,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  // PROFILER_SHOW_SUCCESS_MESSAGE,
  // ADD_PROFILER,
  // UPDATE_PROFILER,
  // GET_PROFILER,
  // GET_PROFILER_SUCCESS_DATA,
  // PROFILER_DELETE_USER_ROW,
  // PROFILER_USER_STATUS_CHANGE,
  // PROFILER_SEND_ACTIVATION_LINK,
  // GET_STAFF_SUCCESS_DATA,
  // GET_STAFF,
  REPORT_SHOW_SUCCESS_MESSAGE,
  GET_STATUS_INITIAL,
  ADD_REPORT,
  GET_REPORTLIST,
  GET_ReportList_SUCCESS_DATA,
  REPORT_SHOW_SUCCESS_MESSAGE_WITH_LINK,
  CLEAR_PREVIEW_LINK

} from "constants/ActionTypes";


export const setstatustoinitial = () => {
  return {
    type: GET_STATUS_INITIAL,
  };
}


export const get_reportList = (user) => {
  return {
    type: GET_REPORTLIST,
    payload: user
  };
};

export const getReportListData = (data) => {
  return {
    type: GET_ReportList_SUCCESS_DATA,
    payload: data
  };
};

export const addReport = (data) => {
  return {
    type: ADD_REPORT,
    payload: data
  };
}



export const showErrorMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

export const showSuccessMessage = (message) => {
  return {
    type: REPORT_SHOW_SUCCESS_MESSAGE,
    payload: message
  };
}; 

export const showSuccessMessageWithLink = ( link) => {
  return {
    type: REPORT_SHOW_SUCCESS_MESSAGE_WITH_LINK,
    link : link
  };
};

export const clearLink = () => {
  return {
    type: CLEAR_PREVIEW_LINK,
  }
}


export const showReportLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};
export const hideAuthLoader = () => {
  return {
    type: ON_HIDE_LOADER,
  };
};

