import React, {Component} from "react";
// import {Col, Row, Card, Table, Button} from "antd"; import Auxiliary from
// "util/Auxiliary";
import {Link} from 'react-router-dom'
import {
    Button,
    Card,
    Col,
    Form,
    Input,
    Row,
    Select,
    message,
    notification
} from "antd";
import {connect} from "react-redux";
import {add_staff, hideMessage, update_staff,get_role} from "appRedux/actions/StaffActions";
const FormItem = Form.Item;

const { Option } = Select;
var roledata = [];
class Addstaff extends Component {

    constructor(props) {
        super(props);
        this.state = {
            confirmDirty: false,
            fname: '',
            lname: '',
            email: '',
            isUpdate: false,
            isAdd: true,
            userid: '',
            username: '',
            cardTitle: 'Create Staff',
            editdata: '',
            disabled: ''
        };
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this
            .props
            .form
            .validateFieldsAndScroll((err, values) => {
                if (!err) {
                    console.log('Received values of form: ', values);
                    if (this.state.isUpdate) {
                        values.userid = this.state.userid;
                        values.username = this.state.username;
                        values.update_profile = 0;
                        this.props.update_staff(values);

                        setTimeout(() => {
                            this.props.hideMessage();
                            // this.props.history.push("/staff");
                        }, 1000);

                    } else {
                        this.props.add_staff(values); 

                        setTimeout(() => {
                            this.props.hideMessage();
                            // this.props.history.push("/staff");
                        }, 1000);

                    }

                }
            });
    }
    getRole() {
        this
            .props
            .get_role(4);
    }

    componentWillMount(){
          localStorage.setItem("progress", 40);
        if(typeof this.props.authUser != 'undefined'){

            var authpermission = this.props.authUser.permission;
            console.log("authpermission",authpermission);
            if(authpermission.length > 0){
                if(!authpermission.includes('92')){
                    this
                    .props
                    .history
                    .push("/dashboard");
                }
            }
        }
    }
    componentDidUpdate(){
        if(this.props.showSuccessMessage){
            setTimeout(() => {
                this.props.hideMessage();
                this.props.history.push("/staff");
            }, 100);
        }
        if(this.props.showMessage)
        {
            setTimeout(() => {
                this.props.hideMessage();
            }, 100);
        }
    }
    componentDidMount() {
        setTimeout(() => {
          this.props.hideMessage();
        }, 1000);
        
        localStorage.setItem("progress", 100);

        setTimeout(() => {  
                    localStorage.setItem("progress", 0);
                  }, 2000);  
        this.getRole();
        let datarecordstaff = this.props.location.state;
        // eslint-disable-next-line
        if (datarecordstaff == "undefined" || datarecordstaff == "" || datarecordstaff == null) {
            // Object is empty (Would return true in this example)

        } else {
            // Object is NOT empty
            datarecordstaff = datarecordstaff.record
            console.log("datarecordstaff", datarecordstaff);
            const form = this.props.form;
            // eslint-disable-next-line
            form.setFieldsValue({['fname']: datarecordstaff.fname, ['lname']: datarecordstaff.lname, ['email']: datarecordstaff.email,['role']: datarecordstaff.role});

            this.setState({isUpdate: true, cardTitle: "Update Staff", userid: datarecordstaff.id, username: datarecordstaff.username, disabled: "disabled"});
        }
    }

    render() {
        const {getFieldDecorator} = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: {
                    span: 24
                },
                sm: {
                    span: 8
                }
            },
            wrapperCol: {
                xs: {
                    span: 24
                },
                sm: {
                    span: 12
                }
            }
        };
        const formItemLayout1 = {
            wrapperCol: {
                xs: {
                    span: 24
                },
                sm: {
                    span: 24
                }
            }
        };

        function isEmpty(obj) {
            for (var key in obj) {
                if (obj.hasOwnProperty(key)) 
                    return false;
                }
            return true;
        }
        var RoleData = this.props.getRoleData;
        
        if (isEmpty(RoleData)) {
            // Object is empty (Would return true in this example)
        } else {
            // Object is NOT empty
            roledata = RoleData.group
            console.log("Role", roledata);
        }
        

        return (
            <Card className="gx-card add_profile_cust" title={this.state.cardTitle}>
                <Link to="/staff" className="add_profile_back">
                    <Button type="ghost" icon="left">
                        <i className="icon icon-left"></i>
                        BACK
                    </Button>
                </Link>
                <Form onSubmit={this.handleSubmit}>
                    <FormItem {...formItemLayout} label="First Name">
                        {getFieldDecorator('fname', {
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: 'Please enter your First Name!'
                                }
                            ]
                        })(<Input id="fname"/>)}
                    </FormItem>
                    <FormItem {...formItemLayout} label="Last Name">
                        {getFieldDecorator('lname', {
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: 'Please enter your Last Name!'
                                }
                            ]
                        })(<Input id="lname"/>)}
                    </FormItem>
                    <FormItem {...formItemLayout} label="E-mail">
                        {getFieldDecorator('email', {
                            rules: [
                                {
                                    type: 'email',
                                    message: 'This is not a valid e-mail'
                                }, {
                                    required: true,
                                    whitespace: true,
                                    message: 'Please enter your E-mail!'
                                }
                            ]
                        })(<Input id="email1" disabled={this.state.disabled}/>)}
                    </FormItem>
                    <FormItem
                        {...formItemLayout}
                        label="Role"
                        >
                            {getFieldDecorator('role',{
                                rules: [
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: 'Please select Role'
                                    }
                                ]
                            
                        })(<Select
                        placeholder="Please Select Role"
                          >
                              <Option key="0" value="0" disabled selected>Please Select Role</Option>
                            {roledata.map(province => (
                              <Option key={province.id}>{province.name}</Option>
                            ))}
                          </Select>
                            )}
                        
                    </FormItem>
                    <FormItem {...formItemLayout1}>
                        <Row>
                            <Col span={24} sm={8}></Col>
                            <Col span={24} sm={12}>
                                {this.state.isUpdate
                                    ? <Button type="primary" htmlType="submit">UPDATE</Button>
                                    : <Button type="primary" htmlType="submit">ADD</Button>}
                            </Col>
                        </Row>
                    </FormItem>
                </Form> 
                {/* {this.props.showSuccessMessage
                    ? message.success(this.props.successMessage.toString())
                    : null}

                {this.props.showMessage 
                    ? message.error(this.props.alertMessage.toString())
                    : null} */}

                {this.props.showSuccessMessage
                    ?
                    notification['success']({
                        message: 'success',
                        description: this.props.successMessage.toString()
                      })
                    : null}

                {this.props.showMessage 
                    ?
                    notification['error']({
                        message: 'error',
                        description: this.props.alertMessage.toString()
                      }) 
                    : null} 
            </Card>
        );
    }

}

const RegistrationForm = Form.create()(Addstaff);
const mapStateToProps = state => {
    return {authUser: state.auth.authUser, showSuccessMessage: state.staffReducers.showSuccessMessage, showMessage: state.staffReducers.showMessage, successMessage: state.staffReducers.successMessage, alertMessage: state.staffReducers.alertMessage,getRoleData: state.rolePermissionReducers.get_role_res}
};
// Object of action creators
const mapDispatchToProps = {
    add_staff: add_staff,
    update_staff: update_staff,
    hideMessage: hideMessage,
    get_role: get_role
}
// export default RegistrationForm;
export default connect(mapStateToProps, mapDispatchToProps)(RegistrationForm);
