import React, {useEffect}  from "react";
import {Button, Form, Input, message, Upload, Icon, notification} from "antd";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {reset_password, hideMessage} from "appRedux/actions/Auth";
// import Sider from "antd/lib/layout/Sider";
// import IntlMessages from "util/IntlMessages";

const FormItem = Form.Item;
var assessmentID = '';

const AssessmentPdf = (props) => {

    const dispatch = useDispatch();
    const {successMessage, alertMessage, showMessage, showSuccessMessage} = useSelector(({auth}) => auth);
    const history = useHistory();

    useEffect(() => {
        // if (showMessage) {
        //     setTimeout(() => {
        //         dispatch(hideMessage());
        //     }, 100);
        // }
        // if (showSuccessMessage) {
        //     setTimeout(() => {
        //         dispatch(hideMessage());
        //         history.push('/signin');
        //     }, 100);
        // }
        assessmentID = window.location.pathname.split("/").pop();
        console.log('asplr',window.location.pathname.split("/").pop());
        window.print();
        // document.getElementById("vcode").value = vcode;
        // if (authUser !== null) {   history.push('/'); }
    });

    // const handleSubmit = (e) => {

    //     e.preventDefault();
    //     props
    //         .form
    //         .validateFields((err, values) => {
    //             if (!err) {
    //                 values.vcode = vcode;
    //                 values.pType = "ADD";
    //                // console.log('Received values of form: ', values);
    //                 dispatch(reset_password(values));
    //             }
    //         });
    // };
    
    var pattern = '^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]+$';
    const compareToFirstPassword = (rule, value, callback) => {
        const form = props.form;
        if (value && value !== form.getFieldValue('password')) {
            // callback('Two passwords that you enter is inconsistent!');
            callback('The password does not match');
        } 
        else {
            callback();
        }
    };

    const validateToNextPassword = (rule, value, callback) => {
        const form = props.form;
        //var specialchar = new RegExp("^(?=.*?[#?!@$%^&*-<>])");
        // var specialchar = new RegExp("^(?=.*[@$!%*?&])");
        var specialchar = new RegExp("^(?=.*[ -\/:-@\[-\`{-~]{1,})");//for all special char
        if (value && props.confirmDirty) {
            form.validateFields(['confirm'], {force: true});
        }
        else{
           
            if (!value.match(specialchar)) {
                callback("Your password must contain at least one special character.");
            }
            callback();
        }
        
    };

    // const normFile = (e) => {
    //     //console.log('Upload event:', e);
    //     if (Array.isArray(e)) {
    //       return e;
    //     }
    //     return e && e.fileList;
    //   };
    
   

    const {getFieldDecorator} = props.form;
    console.log('aspl',assessmentID);
    return (
        <div>
            Rohan {assessmentID}
        </div>
    );
};

const WrappedAddPasswordForm = Form.create()(AssessmentPdf);

export default WrappedAddPasswordForm;


