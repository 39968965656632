import {
  HIDE_MESSAGE,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  PROFILER_SHOW_SUCCESS_MESSAGE,
  ADD_PROFILER,
  UPDATE_PROFILER,
  GET_PROFILER,
  GET_PROFILER_SUCCESS_DATA,
  PROFILER_DELETE_USER_ROW,
  PROFILER_USER_STATUS_CHANGE,
  PROFILER_SEND_ACTIVATION_LINK,
  GET_STAFF_SUCCESS_DATA,
  GET_STAFF,
  GET_STATUS_INITIAL,
  ADD_ASSIGNED_ASSESSMENT,
  ADD_REPORT,
  // GET_REPORTLIST,
  // GET_ReportList_SUCCESS_DATA

} from "constants/ActionTypes";


/* Profiler Module start add, get, update, edit, delete */
export const SendUserActivationLink = (data) => {
  return {
    type: PROFILER_SEND_ACTIVATION_LINK,
    payload: data
  };
};
export const add_profiler = (user) => {
  return {
    type: ADD_PROFILER,
    payload: user
  };
};
export const update_profiler = (user) => {
  return {
    type: UPDATE_PROFILER,
    payload: user
  };
};

export const get_profiler = (user) => {
  return {
    type: GET_PROFILER,
    payload: user
  };
};




export const setstatustoinitial = () => {
  return {
    type: GET_STATUS_INITIAL,
  };
}
export const getProfilerSuccess = (data) => {
  return {
    type: GET_PROFILER_SUCCESS_DATA,
    payload: data
  };
};

// export const get_reportList = (user) => {
//   return {
//     type: GET_REPORTLIST,
//     payload: user
//   };
// };

// export const getReportListData = (data) => {
//   return {
//     type: GET_ReportList_SUCCESS_DATA,
//     payload: data
//   };
// };

export const getStaffSuccess = (data) => {
  return {
    type: GET_STAFF_SUCCESS_DATA,
    payload: data
  };
};

export const get_staff = (user) => {
  return {
    type: GET_STAFF,
    payload: user
  };
};

export const add_assign_assessment = (data) => {
  return {
    type: ADD_ASSIGNED_ASSESSMENT,
    payload: data
  };
};

export const addReport = (data) => {
  return {
    type: ADD_REPORT,
    payload: data
  };
}


export const deleteUserById = (data) => {
  // alert("delete from action")
  return {
    type: PROFILER_DELETE_USER_ROW,
    payload: data
  };
};


export const userStatusChange = (data) => {
  return {
    type: PROFILER_USER_STATUS_CHANGE,
    payload: data
  };
};




// export function setPermission(permission) {
//   return (dispatch) => {
//     dispatch({type: SIGNIN_USER_SUCCESS, permission}); 
//   }
// }

/* Profiler Module end add, get, update, edit, delete */

export const showErrorMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

export const showSuccessMessage = (message) => {
  return {
    type: PROFILER_SHOW_SUCCESS_MESSAGE,
    payload: message
  };
};


export const showProfilerLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};
export const hideAuthLoader = () => {
  return {
    type: ON_HIDE_LOADER,
  };
};

