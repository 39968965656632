import React, {Component} from "react";
import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";

import data from "./data";
var graphData = '';
// const TinyBarChart = () => (
class TinyBarChart extends Component {

    constructor(props) {
        super(props);
        this.state = {
            status_value: 'Active',
           
        }
    }

    componentWillMount() {


        
      let datarecord = this.props.data;
      // eslint-disable-next-line
      // var graphData = [];
      if (datarecord == "undefined" || datarecord == "" || datarecord == null)
      {
          // Object is empty (Would return true in this example)
      } 
      else 
      {
          // Object is NOT empty
          datarecord = datarecord.barChartData
          graphData = datarecord;

        console.log("Graphdatadatarecord", graphData)
        

      }
    }
    render(){
      return (
    
      <ResponsiveContainer width="100%" height={300} >
        <BarChart data={graphData} margin={{top: 10, right: 0, left: -15, bottom: 0}} >
          <XAxis dataKey="name"/>
          <YAxis/>
          <CartesianGrid strokeDasharray="3 3"/>
          <Tooltip/>
          <Legend/>
          <Bar dataKey="Best In Class" fill="#10069f"/>
          <Bar dataKey="Competitive Average" fill="#FE9E15"/>
        </BarChart>
      </ResponsiveContainer>
    );
  }
};

export default TinyBarChart;
