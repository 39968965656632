import React, {useEffect}  from "react";
import {Button, Form, Input, message, notification} from "antd";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {reset_password, hideMessage} from "appRedux/actions/Auth";
import IntlMessages from "util/IntlMessages";

const FormItem = Form.Item;
var vcode = '';
const ResetPassword = (props) => {

    const dispatch = useDispatch();
    const {successMessage, alertMessage, showMessage, showSuccessMessage} = useSelector(({auth}) => auth);
    const history = useHistory();

    useEffect(() => {
        if (showMessage) {
            setTimeout(() => {
                dispatch(hideMessage());
            }, 100);
        }
        if (showSuccessMessage) {
            setTimeout(() => {
                dispatch(hideMessage());
                history.push('/signin');
            }, 100);
        }
        vcode = window.location.pathname.split("/").pop();
        // document.getElementById("vcode").value = vcode;
        // if (authUser !== null) {   history.push('/'); }
    });

    const handleSubmit = (e) => {

        e.preventDefault();
        props
            .form
            .validateFields((err, values) => {
                if (!err) {
                    values.vcode = vcode;
                    values.pType = "RESET";
                    //console.log('Received values of form: ', values);
                    dispatch(reset_password(values));
                }
            });
    };

    const compareToFirstPassword = (rule, value, callback) => {
        const form = props.form;
        if (value && value !== form.getFieldValue('password')) {
            // callback('Two passwords that you enter is inconsistent!');
            // callback('The password is not Matched!');
            callback('The password does not match!');
        } 
        else 
        {
            callback();
        }
    };

    const validateToNextPassword = (rule, value, callback) => {
        const form = props.form;
        if (value && props.confirmDirty) {
            form.validateFields(['confirm'], {force: true});
        }
        callback();
    };

    const {getFieldDecorator} = props.form;

    return (
        <div className="gx-login-container">
            <div className="gx-login-content">

                <div className="gx-login-header">
                    <img src={require("assets/images/KMS_LOGO_new.png")} alt="wieldy" title="wieldy"/>
                </div>
                <div className="gx-mb-4">
                    <h2>Reset Password</h2>
                    <p><IntlMessages id="appModule.enterPasswordReset"/></p>
                </div>

                <Form onSubmit={handleSubmit} className="gx-login-form gx-form-row0">

                    <FormItem>
                        {getFieldDecorator('password', {
                            rules: [
                                { min: 6, message: 'Please enter minimum 6 characters.' },
                                { max: 12, message: 'Please enter maximum 13 characters.' },
                                {
                                    required: true,
                                    message: 'Please enter your Password!'
                                }, {
                                    validator: validateToNextPassword
                                }
                            ]
                        })(<Input type="password" placeholder="New Password"/>
                        )}
                    </FormItem>

                    <FormItem>
                        {getFieldDecorator('confirm', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please enter Confirm Password'
                                }, {
                                    validator: compareToFirstPassword
                                }
                            ]
                        })(<Input placeholder="Confirm Password" type="password"/>)}
                    </FormItem>
                    {/* <FormItem>
                    {getFieldDecorator('vcode', {
                        rules: [
                            {
                                required: true,
                            }
                        ]
                        })(<Input type="hidden" id="vcode" />
                        )}
                        
                    </FormItem> */}

                    <FormItem>
                        <Button type="primary" htmlType="submit">
                        RESET{/* <IntlMessages id="app.userAuth.reset"/>  */}
                        </Button>
                    </FormItem>
                    
                </Form>
                {/* {showSuccessMessage
                    ? message.success(successMessage.toString())
                    : null}

                {showMessage
                    ? message.error(alertMessage.toString())
                    : null} */}
                {showSuccessMessage
                    ?
                    notification['success']({
                        message: 'success',
                        description: successMessage.toString()
                      })
                    : null}

                {showMessage 
                    ?
                    notification['error']({
                        message: 'error',
                        description: alertMessage.toString()
                      }) 
                    : null}
            </div>
        </div>
    );
};

const WrappedResetPasswordForm = Form.create()(ResetPassword);

export default WrappedResetPasswordForm;
