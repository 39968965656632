import React, {Component} from "react";
import {
    Row,
    Button,
    Col,
    Card,
    Icon,
    message,
    notification
} from "antd";
import {Link} from 'react-router-dom'
import {connect} from "react-redux";
import {submitanswers,hideMessage,} from "appRedux/actions/AssessmentActions";
import { ReactFormGenerator } from 'react-form-builder2';
import $ from 'jquery'; 

var profiler_ids = '';
class AssessmentDetailsview extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.state = {
            status_value: 'Active',
            status_color: '#6BA539',
            Active_color : '#6BA539',
            Deactive_color: '#ff4d4f',
            data: [],
            authID : '',
            profiler_array : [],
            randomurlcode : '',
            datarecordassessment : {},
            testsubmitvar : false,
            answer_json : [],
            activekey : 0,
            Pbtntype : 'ghost',
            Nbtntype : 'ghost',
            company_id : '',
            Profilerdatarecord : ''
        };
    }

    componentWillMount(){
      localStorage.setItem("progress", 40);
        if(typeof this.props.authUser != 'undefined'){
            var authpermission = this.props.authUser.permission;
            this.setState({authID : this.props.authUser.id});
            console.log("authpermission",authpermission);
            if(authpermission.length > 0){
                if(!authpermission.includes('93')){
                    this.props.history.push("/dashboard");
                }
            }
            // this.props.get_assessmentbyprofiler({profiler_id : this.state.authID});
          
        }
    }


    componentDidMount(){

localStorage.setItem("progress", 100);

        setTimeout(() => {  
                    localStorage.setItem("progress", 0);
                  }, 2000);  
      let datarecordassessment = this.props.location.state;
      this.setState({authID : this.props.authUser.id});
      if (datarecordassessment == "undefined" || datarecordassessment == "" || datarecordassessment == null) {
        this.props.history.push("/profiler");
      }
      else
      {
        datarecordassessment = datarecordassessment.record
        console.log("datarecordassessment",datarecordassessment);
        

        // Profilerdatarecord = this.props.location.Profilerdatarecord;
        this.setState({Profilerdatarecord : this.props.location.state.Profilerdatarecord})
        // let company_id = this.props.location.comp_id;
        console.log("datarecord",profiler_ids,this.state.profiler_ids);

        if(datarecordassessment.answer.length > 0){
          this.setState({answer_json: JSON.parse(datarecordassessment.answer[0].assessment_answer_json_data)});
        }
        
          this.setState({datarecordassessment: datarecordassessment});
      }
    }
    
    handleclick = (status,assessment_id) => {
        var answers = $('form').serializeArray();
        this.props.submitanswers({answers : answers, company_id : this.state.company_id, status : status, profiler_id : this.state.authID, assessment_id : assessment_id })
        setTimeout(() => {
          this.props.hideMessage();
          this.props.history.push("/fill-assessment");
        }, 1000);
    }
    handleback = () => {
      var key_now = this.state.activekey;
      key_now = key_now - parseInt(1);
      this.setState({
        activekey : key_now,
        Pbtntype  : 'primary',
        Nbtntype  : 'ghost'
      })
      this.scrollToMyRef();
    }
    handlenext = () => {
        $(".gx-layout-content").animate({ scrollTop: 0 }, 1);
        var key_now = this.state.activekey;
        key_now = key_now+ parseInt(1);
        this.setState({
          activekey : key_now,
          Nbtntype  : 'primary',
          Pbtntype  : 'ghost'
        })
        this.scrollToMyRef();
    }
    render() {
        
        return (
          <Card>
            <Row>
                <Col xl={14} lg={14} md={14} sm={24} xs={24}>
                  <h1>{this.state.datarecordassessment.company_name}</h1> 
                </Col>
                <Col xl={10} lg={10} md={10} sm={24} xs={24}>
                <div className="cust-btns-right">
                    <Link to={{ pathname:"/assigned-assessment" , update : this.state.Profilerdatarecord
                    }} > 
                      <Button type="ghost" ><i className="icon icon-left"/><Icon type="left"/> BACK </Button>
                    </Link>
                  </div>
                </Col>
                {typeof this.state.datarecordassessment.assesment_sec != 'undefined' ? this.state.datarecordassessment.assesment_sec.map((iteration,key) => {
                 
                 return <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        
                      <div style={ key == this.state.activekey ? { 'display':'block'} : { 'display':'none'} }>
                        
                      <h5 style={{fontSize:"22px"}}  >{iteration.tab_name}</h5>
                      {iteration.assessment_json != "null" ? <ReactFormGenerator
                            download_path=""
                            back_action="/"
                            back_name="Back"
                            answer_data={this.state.answer_json}
                            action_name="Save"
                            form_action="/"
                            form_method="POST"
                            data={JSON.parse(iteration.assessment_json)}
                            hide_actions={true}
                          /> : null}
                      </div> 
                             
                      </Col> 
                          
                }) : null }
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <center>
                    {
                    this.state.activekey == 0 
                    ? 
                    null 
                    : 
                    <Button type={this.state.Pbtntype}  onClick={this.handleback} >
                    <Icon type="left"/>
                        Previous
                    </Button>
                    }
                    {
                    typeof this.state.datarecordassessment.assesment_sec != 'undefined' 
                    ?  
                      this.state.activekey == this.state.datarecordassessment.assesment_sec.length - parseInt(1) 
                      ? 
                      null 
                      :
                      <Button type={this.state.Nbtntype} onClick={this.handlenext} >
                      Next
                      <Icon type="right"/>
                        
                        
                      </Button> 
                    : 
                    null
                    }
                  </center>
                </Col>
            </Row>
            {/* {this.props.showSuccessMessage ?
              message.success(this.props.successMessage.toString()) : null}
                        {this.props.showMessage ?
              message.error(this.props.alertMessage.toString()) : null}  */}
              {/* {this.props.showSuccessMessage
                    ?
                    notification['success']({
                        message: 'success',
                        description: this.props.successMessage.toString()
                      })
                    : null}

                {this.props.showMessage 
                    ?
                    notification['error']({
                        message: 'error',
                        description: this.props.alertMessage.toString()
                      }) 
                    : null} */}
          </Card>
        );
    }
    scrollToMyRef = () => window.scrollTo(0, 0)
};

// Object of action creators
const mapDispatchToProps = {
    submitanswers,
    hideMessage,
}

const mapStateToProps =  state => {
return { 
        getAssessmentData: state.assessmentReducers.get_assessment_res ,
        loader : state.assessmentReducers.loader,
        showSuccessMessage : state.assessmentReducers.showSuccessMessage,
        successMessage : state.assessmentReducers.successMessage,
        showMessage : state.assessmentReducers.showMessage,
        alertMessage : state.assessmentReducers.alertMessage,
        authUser : state.auth.authUser,
        status : state.assessmentReducers.status,
    }; 
};
// export default Staff;
export default connect(mapStateToProps,mapDispatchToProps)(AssessmentDetailsview);
