import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_FACEBOOK_USER,
  SIGNIN_FACEBOOK_USER_SUCCESS,
  SIGNIN_GITHUB_USER,
  SIGNIN_GITHUB_USER_SUCCESS,
  SIGNIN_GOOGLE_USER,
  SIGNIN_GOOGLE_USER_SUCCESS,
  SIGNIN_TWITTER_USER,
  SIGNIN_TWITTER_USER_SUCCESS,
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS,
  FORGOT_PASSWORD,
  SHOW_SUCCESS_MESSAGE,
  RESET_PASSWORD,
  CHANGE_PASSWORD,
  // SEND_ACTIVATION_LINK,
  GET_USER_DETAILS,
  GET_SUCCESS_USER_DETAILS,
  CHANGE_STATUS,
  UPDATE_USER,
  UPDATE_STAFF_USER,
  GET_DASHBORAD_COUNT,
  GET_DASHBORAD_COUNT_DATA
  // UPDATE_PROFILER_USER1
  // ADD_PROFILER,
  // UPDATE_PROFILER,
  // GET_PROFILER,
  // GET_PROFILER_SUCCESS_DATA,
  // DELETE_USER_ROW,
  // ADD_STAFF,
  // GET_STAFF_SUCCESS_DATA,
  // GET_STAFF,
  // UPDATE_STAFF,
  // GET_ROLE,
  // ADD_ROLE,
  // DELETE_ROLE,
  // GET_ROLE_SUCCESS_DATA,
  // USER_STATUS_CHANGE,
  // ADD_PERMISSION,
  // GET_PERMISSION_BY_ROLEID,
  // GET_PERMISSION_SUCCESS_DATA

} from "constants/ActionTypes";


export const getDashboardCount = (user) => {
  return {
    type: GET_DASHBORAD_COUNT,
    payload: user
  };
};

export const getDashboardCount_Data = (user) => {
  return {
    type: GET_DASHBORAD_COUNT_DATA,
    payload: user
  };
};

export const userSignUp = (user) => {
  return {
    type: SIGNUP_USER,
    payload: user
  };
};

export const update_user = (user) => {
  return {
    type: UPDATE_USER,
    payload: user
  };
};

export const update_staff_user= (user) => {
  return {
    type: UPDATE_STAFF_USER,
    payload: user
  };
};

// export const update_profiler_user1= (user) => {
//   return {
//     type: UPDATE_PROFILER_USER1,
//     payload: user
//   };
// };

export const change_Password = (user) => {
  return {
    type: CHANGE_PASSWORD,
    payload: user
  };
};

// export const SendUserActivationLink = (data) => {
//   return {
//     type: SEND_ACTIVATION_LINK,
//     payload: data
//   };
// };

export const getUserDetails = (data) => {
  return {
    type: GET_USER_DETAILS,
    payload: data
  };
};

export const getSuccessUserDetails = (data) => {
  return {
    type: GET_SUCCESS_USER_DETAILS,
    payload: data
  };
};

export const userSignIn = (user) => {
  return {
    type: SIGNIN_USER,
    payload: user
  };
};
export const forgot_password = (user) => {
  return {
    type: FORGOT_PASSWORD,
    payload: user
  };
};
export const reset_password = (user) => {
  return {
    type: RESET_PASSWORD,
    payload: user
  };
};

export const userSignOut = () => {
  return {
    type: SIGNOUT_USER
  };
};
export const userSignUpSuccess = (authUser) => {
  return {
    type: SIGNUP_USER_SUCCESS,
    payload: authUser
  };
};

export const userSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_USER_SUCCESS,
    payload: authUser
  }
};

export const userSignOutSuccess = () => {
  return {
    type: SIGNOUT_USER_SUCCESS,
  }
};

export const showAuthMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

export const showSuccessMessage = (message) => {
  return {
    type: SHOW_SUCCESS_MESSAGE,
    payload: message
  };
};

export const showErrorMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

export const userGoogleSignIn = () => {
  return {
    type: SIGNIN_GOOGLE_USER
  };
};
export const userGoogleSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_GOOGLE_USER_SUCCESS,
    payload: authUser
  };
};
export const userFacebookSignIn = () => {
  return {
    type: SIGNIN_FACEBOOK_USER
  };
};
export const userFacebookSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_FACEBOOK_USER_SUCCESS,
    payload: authUser
  };
};
export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};
export const userTwitterSignIn = () => {
  return {
    type: SIGNIN_TWITTER_USER
  };
};
export const userTwitterSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_TWITTER_USER_SUCCESS,
    payload: authUser
  };
};
export const userGithubSignIn = () => {
  return {
    type: SIGNIN_GITHUB_USER
  };
};
export const userGithubSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_GITHUB_USER_SUCCESS,
    payload: authUser
  };
};
export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};
export const changestatus = (status) => {
  return {
    type: CHANGE_STATUS,
    payload: status
  };
};
export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};
export const hideAuthLoader = () => {
  return {
    type: ON_HIDE_LOADER,
  };
};

