import {
  HIDE_MESSAGE,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  ASSESSMENT_SHOW_MESSAGE,
  ASSESSMENT_ADD_SHOW_SUCCESS_MESSAGE,
  SHOW_MESSAGE,
  GET_STATUS_INITIAL,

  GET_ASSESSMENT,
  GET_ASSESSMENT_SUCCESS_DATA,
  ADD_ASSESSMENT,
  UPDATE_ASSESSMENT,
  ASSESSMENT_STATUS_CHANGE,
  DELETE_ASSESSMENT_ROW,
  ASSESSMENT_COPY_DATA,
  GET_ASSESSMENT_BYPROFILER,
  GET_ASSESSMENT_BYPROFILER_SUCCESS_DATA,
  SUBMIT_ASSESSMENT_ANSWER,
  ADD_COMPANY_FOR_ASSESSMENT,
  GET_COMPANY_FOR_ASSESSMENT,
  GET_COMPANY_FOR_ASSESSMENT_SUCCESS_DATA,
  UPDATE_PROFILER_USER,
  UPDATE_COMPANY_FOR_ASSESSMENT,
  CLEAR_COMPANY_FOR_ASSESSMENT,
  CLEAR_ASSESSMENT_BYPROFILER,
  GET_METRIC_TABLE,
  GET_METRIC_TABLE_SUCCESS_DATA,
  GET_ASSESSMENT_With_SUBMITED,
  GET_ASSESSMENT_With_SUBMITED_DATA,
  GET_METRIC_GRAPH,
  GET_METRIC_GRAPH_SUCCESS_DATA,
  GET_ASSESSMENT_BY_PROFILER_DASHBOARD,
  GET_ASSESSMENT_BY_PROFILER_DASHBOARD_DATA,
  GET_HISTORY_ASSIGNED_ASSESSMENT,
  GET_HISTORY_ASSIGNED_ASSESSMENT_DATA,
  CREATE_ASSESSMENT_EXCEL,
  CREATE_ASSESSMENT_EXCEL_SUCCESS
} from "constants/ActionTypes";

/* Assessment Module start add, get, update, edit, delete */
export const createAssessmentExcel = (data) => {
  return {
    type: CREATE_ASSESSMENT_EXCEL,
    payload: data
  };
};
export const createAssessmentExcelSuccess = (data) => {
  return {
    type: CREATE_ASSESSMENT_EXCEL_SUCCESS,
    payload: data
  };
};
export const update_profiler_user= (user) => {
  return {
    type: UPDATE_PROFILER_USER,
    payload: user
  };
};

export const history_AssignedAssessment = (data) => {
  return {
    type: GET_HISTORY_ASSIGNED_ASSESSMENT,
    payload: data
  };
}

export const history_AssignedAssessment_SuccessData = (data) => {
  return {
    type: GET_HISTORY_ASSIGNED_ASSESSMENT_DATA,
    payload: data
  };
}

export const getMetricGraph = (data) => {
  return {
    type: GET_METRIC_GRAPH,
    payload: data
  };
}

export const getMetricGraph_successData = (data) => {
  return {
    type: GET_METRIC_GRAPH_SUCCESS_DATA,
    payload: data
  };
}

export const getMetricTable = (data) => {
  return {
    type: GET_METRIC_TABLE,
    payload: data
  };
};

export const getMetricTable_successData = (data) => {
  return {
    type: GET_METRIC_TABLE_SUCCESS_DATA,
    payload: data
  };
};


export const get_assessment = (data) => {
  return {
    type: GET_ASSESSMENT,
    payload: data
  };
};


export const get_assessmentWithSubmited = (data) => {
  return {
    type: GET_ASSESSMENT_With_SUBMITED,
    payload: data
  };
};

export const get_assessmentWithSubmitedSuccess = (data) => {
  return {
    type: GET_ASSESSMENT_With_SUBMITED_DATA,
    payload: data
  };
};

export const getAssessmentSuccess = (data) => {
  return {
    type: GET_ASSESSMENT_SUCCESS_DATA,
    payload: data
  };
};



 
export const get_assessmentbyprofiler = (data) => {
  return {
    type: GET_ASSESSMENT_BYPROFILER,
    payload: data
  };
};

export const get_assessmentbyprofilerDashboard = (data) => {
  return {
    type: GET_ASSESSMENT_BY_PROFILER_DASHBOARD,
    payload: data
  };
}

export const getAssessmentbyprofilerDashboardSuccess = (data) => {
  return {
    type: GET_ASSESSMENT_BY_PROFILER_DASHBOARD_DATA,
    payload: data
  };
}

export const clear_assessmentbyprofiler = () => {
  return {
    type: CLEAR_ASSESSMENT_BYPROFILER
    };
};
export const getAssessmentbyprofilerSuccess = (data) => {
  return {
    type: GET_ASSESSMENT_BYPROFILER_SUCCESS_DATA,
    payload: data
  };
};
export const submitanswers = (data) => {
  return {
    type: SUBMIT_ASSESSMENT_ANSWER,
    payload: data
  };
};
export const add_assessment = (data) => {
  return {
    type: ADD_ASSESSMENT,
    payload: data
  };
};


export const update_assessment = (data) => {
  return {
    type: UPDATE_ASSESSMENT,
    payload: data
  };
};

export const deleteAssessmentById = (data) => {
  return {
    type: DELETE_ASSESSMENT_ROW,
    payload: data
  };
};
export const add_assessment_for_company = (data) => {
  return {
    type: ADD_COMPANY_FOR_ASSESSMENT,
    payload: data
  };
};

export const update_assessment_for_company = (data) => {
  return {
    type: UPDATE_COMPANY_FOR_ASSESSMENT,
    payload: data
  };
};

export const getAssessmentForCompany = (data) => {
  return {
    type: GET_COMPANY_FOR_ASSESSMENT,
    payload: data
  };
};

export const clearAssessmentForCompany = () => {
  return {
    type: CLEAR_COMPANY_FOR_ASSESSMENT,
  };
};

export const getAssessmentForCompanySuccessData = (data) => {
  return {
    type: GET_COMPANY_FOR_ASSESSMENT_SUCCESS_DATA,
    payload: data
  };
};
/* Assessment Module end add, get, update, edit, delete */
export const setstatustoinitial = () => {
  return {
    type: GET_STATUS_INITIAL,
  };
}
export const statusAssessmentchange = (data) => {
  return { 
    type: ASSESSMENT_STATUS_CHANGE,
    payload: data
  };
};

export const copydata = (data) => {
  return { 
    type: ASSESSMENT_COPY_DATA,
    payload: data
  };
}
export const showAuthMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

export const showSuccessMessage = (message) => {
  return {
    type: ASSESSMENT_ADD_SHOW_SUCCESS_MESSAGE,
    payload: message
  };
};

export const showErrorMessage = (message) => {
  return {
    type: ASSESSMENT_SHOW_MESSAGE,
    payload: message
  };
};
 
export const showAssessmentLoader = () => { 
  return {
    type: ON_SHOW_LOADER,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};
export const hideAuthLoader = () => {
  return {
    type: ON_HIDE_LOADER,
  };
};

