import React, {Component} from "react";
// import {Col, Row, Card, Table, Button} from "antd";
import {
    Row,
    Card,
    Table,
    Button,
    message,
    Popconfirm,
    Badge,
    notification,
    Input
} from "antd";
import {Link} from 'react-router-dom'
import Auxiliary from "util/Auxiliary";
import {connect} from "react-redux";
import {get_staff,deleteUserById,statusStaffchange,hideMessage,setstatustoinitial,showStaffLoader} from "appRedux/actions/StaffActions";
import { SendUserActivationLink } from "appRedux/actions/StaffActions";
import CircularProgress from "components/CircularProgress/index";
// const Dtext = 'Are you sure delete this task?';
import ReactExport from "react-export-excel";
import { SearchOutlined } from '@ant-design/icons';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const hurl = "#";
const Search = Input.Search;

class Staff extends Component {
    constructor(props) {
        super(props);
        this.state = {
            status_value: 'Active',
            status_color: '#6BA539',
            Active_color : '#6BA539',
            Deactive_color: '#ff4d4f',
            data: [],
            current : 1,
            filter : '',
            field : 'undefined',
            order : 'undefined',
            // columns : []
        };
        this.confirm = this
            .confirm
            .bind(this);
        this.deleteProfiler = this
            .deleteProfiler
            .bind(this);
        this.chageStatusProfiler = this
            .chageStatusProfiler
            .bind(this);
    }

    confirm() {
        message.info('Click on Yes.');
        this.setState({advalue: 'Deactive', status_color: '#ff4d4f'});
    }

    chageStatusProfiler(id, status) {
        // message.success('Status has been changed');
        this.props.showStaffLoader();
        var Statusdata = {
            "id" : id,
            "status" : status
        }
        // this.props.setstatustoinitial();
        this.props.statusStaffchange(Statusdata);
        // message.success('Status has been changed');
        // this.get_staffById();
    }
    
    SendLink(email) {
        // this.props.setstatustoinitial();
        var email = {
            "email" : email
        }
        this.props.SendUserActivationLink(email);
        // message.success('Link has been sent.');
        // this.get_staffById();
    }
    componentWillMount(){
    localStorage.setItem("progress", 40);
        if(typeof this.props.authUser != 'undefined'){

            var authpermission = this.props.authUser.permission;
            console.log("authpermission",authpermission);
            if(authpermission.length > 0){
                if(!authpermission.includes('92')){
                    this
                    .props
                    .history
                    .push("/dashboard");
                }
            }
        }
        
    }

    deleteProfiler = (key) => {
        this.props.showStaffLoader();
        this.props.deleteUserById(key);
        // this.props.setstatustoinitial();
        // message.success('Your record is deleted');
        // this.get_staffById()
        
    }

    get_staffById(){
        if(this.props.status == 'Initial'){
            var currents = 0;

            if(this.state.current >= 2){
                    currents = (this.state.current - 1) * 10
            }
            else{
                currents = 0;
            }
            var val = {
                group_id : 5,
                filter : this.state.filter,
                current : currents,
                field : this.state.field,
                order : this.state.order,
            }
            
            this.props.get_staff(val);
            
        }
    }

    componentDidMount(){
        localStorage.setItem("progress", 100);

        setTimeout(() => {  
                    localStorage.setItem("progress", 0);
                  }, 2000);  
                  this.setState(
                    {
                        field : 'undefined',
                        order : 'undefined',
                        
                    }
                )
        this.props.setstatustoinitial();
        this.get_staffById()
    }
    componentDidUpdate(){
        if(this.props.showSuccessMessage || this.props.showMessage)
        {
            // this.get_profilerById()
                setTimeout(() => {
                    this.props.hideMessage();
                }, 100);
        }
        this.get_staffById();
    }

    
    searchInputData = (vals) => {
        console.log("searchInputData",vals)

        this.setState(
            {
                filter : vals,
                current : 1,
                
            }
        )
        this.setState(
            {
                field : 'undefined',
                order : 'undefined',
                
            }
        )
        this.props.setstatustoinitial();

        // var val = {
        //     group_id : 5,
        //     filter : vals,
        //     current : 0
        // }
        // this.props.get_staff(val);
        // alert("search in put")

    }

    handleTableChange = (pagination, filters, sorter) => {
        console.log("pagination",pagination.current)
        var currents = 0;
        
        if(pagination.current >= 2){
                currents = (pagination.current - 1) * 10
        }
        else{
             currents = 0;
        }

        this.setState({
            current : pagination.current,
            field : sorter.field,
            order : sorter.order,
        })
        this.props.setstatustoinitial();
        // var values = {
        //     group_id : 5,
        //     current : currents,
        //     filter : this.state.filter
        // }
        // this.props.get_staff(values);
        // alert('handlechange')

    };

    render() {
        function isEmpty(obj) {
            for(var key in obj) {
                if(obj.hasOwnProperty(key))
                    return false;
            }
            return true;
        }
        var staffData = this.props.getStaffData;
        var sdata = ''; 
        var pagiTotal = 0;
        var excelData  = ''
        if(isEmpty(staffData)) {
            // Object is empty (Would return true in this example)
        } else {
            // Object is NOT empty
            sdata = staffData.user
            excelData = staffData.exceldata
            pagiTotal = staffData.total
            console.log("aspltestdata",this.props.getStaffData)
        }

        const columns = [
            {
                title: 'First Name',
                dataIndex: 'fname',
                width: 200,
                sorter: true,
            }, {
                title: 'Last Name',
                dataIndex: 'lname',
                width: 200,
                sorter: true,

            }, { 
                title: 'Email',
                dataIndex: 'email',
                width: 200,
                sorter: true,

            },{
                title: 'Created Date',
                dataIndex: 'date',
                width: 150,
                sorter: true,

            }, 
            {
                title: 'Status',
                dataIndex: 'status',
                width: 150,
                render : (text,record) =>
                (
                    <span>
                        {
                            record.banned
                            ?
                            <Popconfirm
                            placement="top"
                            title="Are you sure change this status?"
                            onConfirm={(e) => this.chageStatusProfiler(record.id,'inactive')}
                            okText="YES"
                            cancelText="No">
                            <a href={hurl}>
                            {/* {'\u00A0'}|{'\u00A0'} */}
                                <Badge
                                    className="site-badge-count-109"
                                    // abc={ this.setState({banned : record.banned}) }
                                    // count={this.state.status_value}
                                    count="Active"
                                    style={{
                                    backgroundColor: this.state.Active_color,
                                    width : "70px"
                                    }}
                                /></a>
                            </Popconfirm>
                            :
                            <Popconfirm
                            placement="top"
                            title="Are you sure change this status?"
                            onConfirm={(e) => this.chageStatusProfiler(record.id,'active')}
                            okText="YES"
                            cancelText="No">
                            <a href={hurl}>
                            {/* {'\u00A0'}|{'\u00A0'}  */}
                                <Badge
                                    className="site-badge-count-109"
                                    // abc={ this.setState({banned : record.banned}) }
                                    count="Deactive"
                                    style={{
                                    backgroundColor: this.state.Deactive_color,
                                    width : "70px"
                                    }}
                                /></a>
                            </Popconfirm>
                        }
                    </span>
                )

            },
            {
                title: 'Action',
                key: 'action',
                width: 300,
                
                render: (text, record) => (

                    <span>

                        <Link to={{
                                pathname:"/add-staff",
                                state : {record : record}
                            }} >
                                <i className="icon icon-edit"></i>
                        </Link>
                        
                        <Popconfirm
                            placement="top"
                            title="Are you sure want to delete?"
                            onConfirm={(e) => this.deleteProfiler(record.id)}
                            okText="YES"
                            cancelText="No">
                            <a href={hurl}>
                            {'\u00A0'}|{'\u00A0'} 
                                <i
                                    className="icon icon-trash"
                                    style={{
                                    color: "red"
                                }}></i>
                            </a>
                        </Popconfirm>
                        
                        
                        {
                            record.banned
                            ?
                            ''
                            :
                            <Popconfirm
                            placement="top"
                            title="Send Link, Are you sure?"
                            onConfirm={(e) => this.SendLink(record.email)}
                            okText="YES"
                            cancelText="No">
                            <a href={hurl}>
                            {'\u00A0'}|{'\u00A0'}
                                <Badge
                                    className="site-badge-count-109"
                                    // abc={ this.setState({banned : record.banned}) }
                                    // count={this.state.status_value}
                                    count="Activation Link"
                                    style={{
                                    backgroundColor: this.state.Active_color
                                    }}
                                /></a>
                            </Popconfirm>
                        }
                    </span>
                    
                )
            }
        ];

        const excelbtn = (
            <ExcelFile filename="Staff" element={<Button icon="download" type="primary"  >EXPORT</Button>} >
                <ExcelSheet data={excelData} name="Staff">
                    <ExcelColumn label="First Name" value="fname"/>
                    <ExcelColumn label="Last Name" value="lname"/>
                    <ExcelColumn label="Email" style="width:230px;" value="email"/>
                    <ExcelColumn label="Created Date" value="date"/>
                    {/* <ExcelColumn label="Status" value="sex"/> */}
                    <ExcelColumn label="Status"
                                 value={(record) => record.banned ? "Active" : "Deactive"}/>
                </ExcelSheet>
            </ExcelFile>
        );

        const searchBtn = (
            <Search
            className="searchBtn"
            placeholder="Search"
            onSearch={(value) => this.searchInputData(value)}
            style={{width: 300, marginLeft:10,marginRight:10}}
            allowClear 
          />
           
       );
        return (

                    <Card className="gx-card cust_asses" style={{width: "100%"}}
                        title="Staff Management"
                        extra={  <div className="cardextraelement">
                                {searchBtn}
                                <Link to="add-staff" className="ADDbtn">
                                    <Button type="primary" icon="add">
                                        <i className="icon icon-add"></i>
                                        ADD
                                    </Button>
                                </Link>
                                {excelbtn}
                                </div>
                            }>
                        {/* {this.props.loader ?
                        <div className="gx-loader-view">
                            <CircularProgress/>
                        </div> : null}      */}
                        {/* {this.props.showSuccessMessage ?
              message.success(this.props.successMessage.toString()) : null}
                        {this.props.showMessage ?
              message.error(this.props.alertMessage.toString()) : null}         */}
                       {/* {this.props.loader ? null :  */}
                        <Table 
                            className="gx-table-responsive"
                            columns={columns}
                            rowkey={record => record.id}
                            loading ={this.props.loader}
                            dataSource={sdata}
                            pagination={{
                                current : this.state.current,
                                total : pagiTotal,
                                defaultPageSize: 10, 
                                hideOnSinglePage: true
                            }}
                            onChange={this.handleTableChange}
                        /> 
                    {/* } */}

                {this.props.showSuccessMessage
                    ?
                    notification['success']({
                        message: 'success',
                        description: this.props.successMessage.toString()
                      })
                    : null}

                {this.props.showMessage 
                    ?
                    notification['error']({
                        message: 'error',
                        description: this.props.alertMessage.toString()
                      })
                    : null}
                    </Card>

        );
    }
};

// Object of action creators
const mapDispatchToProps = {
    get_staff,
    deleteUserById,
    statusStaffchange,
    SendUserActivationLink,
    hideMessage,
    setstatustoinitial,
    showStaffLoader
}

const mapStateToProps =  state => {
return { 
        getStaffData: state.staffReducers.get_staff_res ,
        loader : state.staffReducers.loader,
        showSuccessMessage : state.staffReducers.showSuccessMessage,
        successMessage : state.staffReducers.successMessage,
        authUser : state.auth.authUser,
        showMessage : state.staffReducers.showMessage,
        alertMessage : state.staffReducers.alertMessage,
        status : state.staffReducers.status,
    }; 
};
// export default Staff;
export default connect(mapStateToProps,mapDispatchToProps)(Staff);
