import {
    HIDE_MESSAGE,
    INIT_URL,
    ON_HIDE_LOADER,
    ON_SHOW_LOADER,
    SHOW_MESSAGE,
    SIGNIN_FACEBOOK_USER_SUCCESS,
    SIGNIN_GITHUB_USER_SUCCESS,
    SIGNIN_GOOGLE_USER_SUCCESS,
    SIGNIN_TWITTER_USER_SUCCESS,
    SIGNIN_USER_SUCCESS,
    SIGNOUT_USER_SUCCESS,
    SIGNUP_USER_SUCCESS,
    SHOW_SUCCESS_MESSAGE,
    GET_SUCCESS_USER_DETAILS,
    UPDATE_USER,
    GET_PROFILER_SUCCESS_DATA,
    GET_STAFF_SUCCESS_DATA,
    GET_ROLE_SUCCESS_DATA,
    GET_PERMISSION_SUCCESS_DATA,
    CHANGE_STATUS,
    GET_DASHBORAD_COUNT_DATA
} from "constants/ActionTypes";

// import States from "../states/states";

const INIT_STATE = {
    loader: false,
    alertMessage: '',
    successMessage:'',
    showMessage: false,
    showSuccessMessage:false,
    initURL: '',
    authUser: JSON.parse(localStorage.getItem('user_id')),
    token : '',
    permissionidByGroupId: '',
    userGroupId: '',
    UserDetails :'',
    status: '',
    getDashboard_count : ''
};

export default(state = INIT_STATE, action) => {

    // if(States ===undefined){
    //     return new States();
    // }
    switch (action.type) {
        case SIGNUP_USER_SUCCESS:
            {
                return {
                    ...state,
                    loader: false,
                    authUser: action.payload
                }
            }
        case UPDATE_USER:
            {
                return {
                    ...state,
                    loader: false,
                    authUser: action.payload
                }
            }
        case SIGNIN_USER_SUCCESS:
            { 
                // console.log("permissionidByGroupId",action.payload.data.permission);
                return {
                    ...state,
                    loader: false,
                    authUser: action.payload.data,
                    userGroupId: action.payload.data.group_id,
                    permissionidByGroupId: action.payload.data.permission,
                    token : action.payload.token
                }
            }
        case INIT_URL:
            {
                return {
                    ...state,
                    initURL: action.payload
                }
            }
        case SIGNOUT_USER_SUCCESS:
            {
                return {
                    ...state,
                    authUser: null,
                    token : null,
                    initURL: '/signin',
                    loader: false
                }
            }
        case GET_SUCCESS_USER_DETAILS : 
        {
            return {
                ...state,
                status : 'Updatedataloaded',
                UserDetails: action.payload.data.user,
            }
        }
        case CHANGE_STATUS : 
        {
            return {
                ...state,
                status : action.payload,
            }
        }
        case SHOW_MESSAGE:
            {
                return {
                    ...state,
                    alertMessage: action.payload,
                    showMessage: true,
                    loader: false
                }
            }

        case SHOW_SUCCESS_MESSAGE:
            {
                return {
                    ...state,
                    // authUser: null,
                    successMessage: action.payload,
                    showSuccessMessage: true,
                    loader: false
                }
            }

        case HIDE_MESSAGE:
            {
                return {
                    ...state,
                    alertMessage: '',
                    successMessage:'',
                    showMessage: false,
                    showSuccessMessage:false,
                    loader: false
                }
            }

        case GET_DASHBORAD_COUNT_DATA:
            {
                // console.log("payload",action.payload)
                return {
                    ...state,
                    getDashboard_count : action.payload,
                    alertMessage: '',
                    successMessage:'',
                    showMessage: false,
                    showSuccessMessage:false,
                    loader: false,
                    status : 'Dataloaded',
                }
            }

        case SIGNIN_GOOGLE_USER_SUCCESS:
            {
                return {
                    ...state,
                    loader: false,
                    authUser: action.payload
                }
            }
        case SIGNIN_FACEBOOK_USER_SUCCESS:
            {
                return {
                    ...state,
                    loader: false,
                    authUser: action.payload
                }
            }
        case SIGNIN_TWITTER_USER_SUCCESS:
            {
                return {
                    ...state,
                    loader: false,
                    authUser: action.payload
                }
            }
        case SIGNIN_GITHUB_USER_SUCCESS:
            {
                return {
                    ...state,
                    loader: false,
                    authUser: action.payload
                }
            }
        case ON_SHOW_LOADER:
            {
                return {
                    ...state,
                    loader: true
                }
            }
        case ON_HIDE_LOADER:
            {
                return {
                    ...state,
                    loader: false
                }
            }
        default:
            return state;
    }
}
// export default INIT_STATE;