import React, {Component} from "react";
import {Col, Row, Card, message,notification,Table ,Select,Button} from "antd";
import Auxiliary from "util/Auxiliary";
import IconWithTextCard from "components/Metrics/IconWithTextCard";

import TinyBarChart from "./Mycomponents/TinyBarChart";
import PieChartWithBrokenDownSlices from "./Mycomponents/PieChartWithBrokenDownSlices";
import {hideMessage,getDashboardCount} from "appRedux/actions/Auth";
import {get_assessment,getMetricTable,get_assessmentWithSubmited,getMetricGraph,showAssessmentLoader,setstatustoinitial,} from "appRedux/actions/AssessmentActions";
import {connect} from "react-redux";
import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import CircularProgress from "components/CircularProgress/index";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const {Option} = Select;
var group_id = 0;
var dataload = false;
var assessmentTbale_Id = ''
class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            status_value: 'Active',
            status_color: '#6BA539',
            Active_color : '#6BA539',
            Deactive_color: '#ff4d4f',
            data: [],
            banned : '',
            dataload : true,
            assessmentTableId : '',
            assessmentGraphId : ''
        }
    }
    componentWillMount(){

        if(typeof this.props.authUser != 'undefined'){

            var authpermission = this.props.authUser.permission;
            console.log("authpermission",authpermission);
            // if(authpermission.length > 0){
            //     if(!authpermission.includes('93')){
            //         this
            //         .props
            //         .history
            //         .push("/dashboard");
            //     }
            // }

            
            this.setState({authID :  this.props.authUser.id})


            this.get_assessmentById(this.props.authUser.id)
            group_id = this.props.authUser.group_id;

            if(this.props.authUser.group_id == 6){
                setTimeout(() => {	
                    this
                    .props
                    .history
                    .push("/fill-assessment");
                  }, 100);	
                // return false
            }
            localStorage.setItem("progress", 40);
        }

        if (this.props.showMessage) {
            setTimeout(() => {
                this.props.hideMessage();
            }, 100);
          }
          if(this.props.showSuccessMessage){
              setTimeout(() => {
                this.props.hideMessage();
                 }, 100);
          }
          this.props.setstatustoinitial();
        this.props.getDashboardCount();
        dataload =true;
        this.setState({dataload : true})

    }

    componentDidMount(){
        this.setState({dataload : true})
        console.log('getDashboard_count',this.props.getDashboard_count)
        // this.get_assessmentById()
        this.props.setstatustoinitial();
        dataload =true;
        setTimeout(() => {
            this.getassessmentWithSubmited_Data()
        }, 2000);
        localStorage.setItem("progress", 100);

        setTimeout(() => {  
                    localStorage.setItem("progress", 0);
                  }, 2000);  
    }

    getassessmentWithSubmited_Data = () =>{

    if(this.state.dataload){

        var getassessmentWithSubmiteddata =  this.props.getassessmentWithSubmited;
        var ppdata = ''; 
        if(this.isEmpty_11(getassessmentWithSubmiteddata)) {
            // Object is empty (Would return true in this example) 
        } else {

            // Object is NOT empty
            ppdata = getassessmentWithSubmiteddata.PublishedAssessment

            if(ppdata.length > 0){
                this.setState({assessmentTableId : ppdata[0].id})
                this.setState({assessmentGraphId : ppdata[0].id})
                assessmentTbale_Id = ppdata[0].id
                this.onChangeAssessment(ppdata[0].id)
                this.onChangeAssessment_forGraph(ppdata[0].id)
            }
            
        }
    }
    dataload = false;
    this.setState({dataload : false})
    }

    isEmpty_11 = (obj) => {
        for(var key in obj) {
            if(obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    get_assessmentById(authID){
            
        var val ={
            authID : authID,
            filter : '',
            current : ''
        }
            this.props.get_assessment(val);
            this.props.get_assessmentWithSubmited(authID);

    }


    onChangeAssessment = (value) => {
        this.props.showAssessmentLoader();
        var values = {
                "assessment_id" : value,
              }
        this.props.getMetricTable(values);
        this.setState({assessmentTableId : value})
    }
    onChangeAssessment_forGraph = (value) => {
        this.props.showAssessmentLoader();
        var values = {
                "assessment_id" : value,
              }

        this.props.getMetricGraph(values);
        this.setState({assessmentGraphId : value})
    }

   
    render() {

        function isEmpty(obj) {
            for(var key in obj) {
                if(obj.hasOwnProperty(key))
                    return false;
            }
            return true;
        }
        var columns = []
        var sectiondata = []
          
        var getMetricTableData = this.props.getMetricTableData;

        if(isEmpty(getMetricTableData)){
            // Object is empty (Would return true in this example)
        }
        else 
        {
            columns = [
                {
                    title: 'Manufacturer Name',
                    dataIndex: 'company_name',
                }
     
              ]
            // Object is NOT empty
            getMetricTableData = getMetricTableData

            console.log("getMetricTableData",getMetricTableData)
            var section = getMetricTableData.sectionName
            if(section.length > 0){
                section.map(element => {

                    columns.push({
                        title: element.tab_name,
                        dataIndex: 'yes_'+element.tab_name,
                        align : "center"
                    })
                })
                var section_data = getMetricTableData.metricCompany
                section_data.map(element => {
    

                    var col = {}
                    var cols = {}
                    col['company_name'] = element.company_name;
                    element.sectiondata.map(element_1 =>{

                            for (var property in element_1) {
                                section.map(elements => {   
                                    console.log("element_1[property]",element_1[property])
                                  
                                    var tavname = element_1[property].tab_name;
                                     if(elements.tab_name == tavname)
                                    {

                                        console.log('yes_',elements.tab_name, tavname)
                                        console.log('yes_'+element_1[property].tab_name,element_1[property].yes)
                                    col['yes_'+element_1[property].tab_name] = element_1[property].yes+'%'
                                
                                
                                    console.log("getMetricTableData",element_1[property])
                                    }
                                    else{
                                        console.log('yes_else',elements.tab_name, tavname)
                                        console.log('yes_else'+element_1[property].tab_name,element_1[property].yes)
                            
                                        cols['yes_'+elements.tab_name] = '0%'

                                    }
                                })
                            }

                            sectiondata.push(col)
                        //   console.log("col",col,cols)
                    })
                    
    
                })   
            }
            
            // console.log("sectiondata",sectiondata)
           
        }
  
        function isEmpty_1(obj) {
            for(var key in obj) {
                if(obj.hasOwnProperty(key))
                    return false;
            }
            return true;
        }
        var getDashboard_count = this.props.getDashboard_count;
        var pdata = ''; 
        if(isEmpty_1(getDashboard_count)) {
            // Object is empty (Would return true in this example) 
        } else {
            // Object is NOT empty
            pdata = getDashboard_count.data
            // console.log("pdata",pdata)
        }

        function isEmpty_2(obj) {
            for(var key in obj) {
                if(obj.hasOwnProperty(key))
                    return false;
            }
            return true;
        }
        var getAssessmentDataSubmited = this.props.getassessmentWithSubmited
        var sdata = ""
        if(isEmpty_2(getAssessmentDataSubmited)) {
            // Object is empty (Would return true in this example) 
        } else {
            // Object is NOT empty
            sdata = getAssessmentDataSubmited.PublishedAssessment

            // this.setState({assessmentId : sdata[0].id})
            // console.log("pdata",sdata)
        }

        function isEmpty_3(obj) {
            for(var key in obj) {
                if(obj.hasOwnProperty(key))
                    return false;
            }
            return true;
        }
        var getMetricGraphData = this.props.getMetricGraphData
        var Graphdata = ""
        if(isEmpty_3(getMetricGraphData)) {
            // Object is empty (Would return true in this example) 
        } else {
            // Object is NOT empty
            Graphdata = getMetricGraphData.barChartData

            // this.setState({assessmentId : sdata[0].id})
            console.log("Graphdata",Graphdata)
        }
       
        const excelbtn = (
            <ExcelFile filename="Metric_Performance" element={<Button type="primary" className="exportbtn_graphtable" icon="download" >EXPORT</Button>}>
                <ExcelSheet data={sectiondata} name="Metric_Performance">
                    {
                        columns.map(element => (
                            <ExcelColumn label={element.title} value={element.dataIndex} />
                        ))
                    }
                    
                    {/* <ExcelColumn label="Manufacturer Name" value="CompanyName"/>
                    <ExcelColumn label="POC" value="POCName"/> */}
                    {/* <ExcelColumn label="Status" value="sex"/> */}
                    {/* <ExcelColumn label="Status" value={(record) => record.status ? "Published" : "Drafted"}/> */}
                </ExcelSheet>
            </ExcelFile>
        );
        
        const group_ids = group_id;
    return (
        group_ids != 6 ?
        <Auxiliary>
            {/* {showSuccessMessage ?
              message.success(successMessage.toString()) : null}
                        {showMessage ?
              message.error(alertMessage.toString()) : null} */}
              {this.props.showSuccessMessage
                    ?
                    notification['success']({
                        message: 'success',
                        description: this.props.successMessage.toString()
                      })
                    : null}

                {this.props.showMessage 
                    ?
                    notification['error']({
                        message: 'error',
                        description: this.props.alertMessage.toString()
                      }) 
                    : null}
            <Row>
                <Col className="custom-dash-card card-pro" xl={6} lg={12} md={12} sm={12} xs={24}>
                    <IconWithTextCard                        
                        icon="profile"
                        iconColor="geekblue"
                        title={pdata ? pdata.total_user : 0}
                        subTitle="No. of Profiler"/> {/* <BuildingCard icon="icon icon-profile" title="No. of Profiler" number="19"  ></BuildingCard> */}
                </Col>

                <Col xl={6} lg={12} md={12} sm={12} xs={24}  className="custom-dash-card card-asses">
                    <IconWithTextCard                        
                        icon="editor"
                        iconColor="geekblue"
                        // title="20"
                        title={pdata ? pdata.total_Assessment : 0}
                        subTitle="No. of Assessment"/> {/* <BuildingCard icon="icon icon-editor" title="No. of Assesssments" number="20"  ></BuildingCard> */}
                </Col>

                <Col xl={6} lg={12} md={12} sm={12} xs={24} className="custom-dash-card card-complete-asses">
                    <IconWithTextCard                         
                        icon="select"
                        iconColor="geekblue"
                        // title="18"
                        title={pdata ? pdata.completed_assessment : 0}
                        subTitle="Completed Assessment"/> {/* <BuildingCard icon="icon icon-select" title="Completed Assesssments" number="18"  ></BuildingCard> */}
                </Col>

                <Col xl={6} lg={12} md={12} sm={12} xs={24} className="custom-dash-card card-reports">
                    <IconWithTextCard                        
                        icon="tasks"
                        iconColor="geekblue"
                        title={pdata ? pdata.completed_report : 0}
                        subTitle="Completed Reports"/> {/* <BuildingCard icon="icon icon-tasks" title="Completed Reports" number="16"  ></BuildingCard> */}
                </Col>

            </Row>

            <Row>
                <Col lg={24} md={24} sm={24} xs={24} className="cust-matrix-card">
                    <Card
                        className="gx-card metric-performance-class"
                        title="Metrics Performance"
                        extra={[
                            <>
                            <Select
                               
                                name="SelectAssessment"
                                value={this.state.assessmentGraphId}
                                defaultActiveFirstOption = "true"
                                placeholder="Select Assessment"
                                selectable="true"
                                style={{
                                width: 250,
                                verticalAlign: "middle"
                                // float: "right"
        
                                }}
                                
                                onChange={(e) => this.onChangeAssessment_forGraph(e)}
                            >
                                <Option value="disabled" disabled>Please Select Assessment</Option>
                            {
                                sdata
                                ?
                                
                                
                                sdata.map(province => (
                                    
                                    <Option key={province.id} value={province.id} >{province.name}</Option>
                                   
                                ))
                              
                                :
                                null
                                // <Option value="disabled" disabled>Please Select Assessment</Option>
                            }
        
                            </Select>

                            
                            </>
                            ]}  
                        >
                            <ResponsiveContainer width="100%" height={500} >
                                <BarChart data={Graphdata} margin={{top: 10, right: 0, left: -15, bottom: 0}} >
                                <XAxis dataKey="name" interval={0} angle={30} dx={20} />
                                <YAxis />
                                <CartesianGrid strokeDasharray="3 3"/>
                                <Tooltip/>
                                <Legend/>
                                <Bar dataKey="BestInClass" name="Best In Class" fill="#10069f"/>
                                <Bar dataKey="CompetitiveAverage" name="Competitive Average" fill="#DC4405"/>
                                </BarChart>
                            </ResponsiveContainer>
                        {/* <TinyBarChart data={this.props.getMetricGraphData} /> */}
                    </Card>
                </Col>
            </Row>
            <Row>
                    {/* 
                    <Col lg={12} md={12} sm={24} xs={24}>
                        <Card className="gx-card" title="Manufacturing Categories">
                            <PieChartWithBrokenDownSlices/>
                        </Card>
                    </Col> 
                    */}
            </Row> 
            <Row className="dash-cust-row">
                <Card
                style={{width: "100%"}}
                className="gx-card"
               
                title="Metrics Performance Table"
                extra={[
                    <>
                    <Select
               
                        // }
                        name="SelectAssessment"
                        value={this.state.assessmentTableId}
                        // defaultActiveFirstOption = "true"
                        placeholder="Select Assessment"
                        selectable="true"
                        style={{
                        width: 300,
                        verticalAlign: "middle"
                        // float: "right"
                        }}
                        onChange={(e) => this.onChangeAssessment(e)}
                    >
                        <Option value="disabled" disabled>Please Select Assessment</Option>
                    {
                        sdata
                        ?
                        sdata.map(province => (
                            <Option  value={province.id} >{province.name}</Option>
                        ))
                        :
                        null
                        // <Option value="disabled" disabled>Please Select Assessment</Option>
                    }

                    </Select>
                    {excelbtn}
                    </>
                    ]}  
                >
                     {/* {this.props.loader ?
                <div className="gx-loader-view">
                    <CircularProgress/>
                </div> : null}       */}
                {/* {this.props.loader ? null :  */}
                <Table 
                    style={{width: "100%"}}
                    className="gx-table-responsive"
                    columns={columns}
                   
                    dataSource={sectiondata}
                    rowkey="id"
                    loading ={this.props.loader}
                    pagination={{
                                    defaultPageSize: 10, 
                                    hideOnSinglePage: true
                                }}
                                // scroll={{ x: "max-content" }}
                />
                 {/* }  */}
                </Card>
            </Row>

        </Auxiliary> : null
    );
    }
};

// Object of action creators
const mapDispatchToProps = {
    hideMessage,
    getDashboardCount,
    get_assessment,
    get_assessmentWithSubmited,
    getMetricTable,
    getMetricGraph,
    showAssessmentLoader,
    setstatustoinitial,
}

const mapStateToProps =  state => {
return { 
        getDashboard_count : state.auth.getDashboard_count,
        // loader : state.auth.loader,
        showSuccessMessage : state.auth.showSuccessMessage,
        successMessage : state.auth.successMessage,
        authUser : state.auth.authUser,
        showMessage : state.auth.showMessage,
        alertMessage : state.auth.alertMessage,
        getAssessmentData: state.assessmentReducers.get_assessment_res,
        getassessmentWithSubmited: state.assessmentReducers.getassessmentWithSubmited,
        getMetricTableData: state.assessmentReducers.get_MetricTableData,
        getMetricGraphData: state.assessmentReducers.get_MetricGraphData,
        loader : state.assessmentReducers.loader,
        status : state.assessmentReducers.status,

    }; 
};
// export default Profiler;
export default connect(mapStateToProps,mapDispatchToProps)(Dashboard);