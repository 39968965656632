import React, {Component} from "react";
import {
    Row,
    Button,
    Col,
    Card,
    Icon,
    message,
    notification
} from "antd";
import {Link} from 'react-router-dom'
import {connect} from "react-redux"; 
import {submitanswers,hideMessage,} from "appRedux/actions/AssessmentActions";
import { ReactFormGenerator } from 'react-form-builder2';
import $ from 'jquery'; 
var new_answer_json = ''
// date_default_timezone_set("America/New_York");

class AssessmentDetails extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()  
        this.state = {
            status_value: 'Active' ,
            status_color: '#6BA539',
            Active_color : '#6BA539',
            Deactive_color: '#ff4d4f',
            data: [],
            authID : '',
            profiler_array : [],
            randomurlcode : '',
            datarecordassessment : {},
            testsubmitvar : false,
            answer_json : [],
            activekey : 0,
            Pbtntype : 'ghost',
            Nbtntype : ' ghost',
            company_id : '',
            btnview : false
        };
    }
 
componentWillMount(){
  localStorage.setItem("progress", 40);

}
    componentDidMount(){
      this.scrollToMyRef();
localStorage.setItem("progress", 100);

        setTimeout(() => {  
                    localStorage.setItem("progress", 0);
                  }, 2000);  
      let datarecordassessments = this.props.location.state

      
      this.setState({authID : this.props.authUser.id});
      let datarecordassessment = ''
      if (datarecordassessments == "undefined" || datarecordassessments == "" || datarecordassessments == null) {
        this.props.history.push("/fill-assessment");
      }
      else
      {
        console.log("datarecordassessment",datarecordassessments);
        //  this.setState({answer_json: datarecordassessment.assessment_answer[0].assessment_answer_json_data});

        let view = datarecordassessments.view;
        this.setState({btnview : view})
        var re_Com = datarecordassessments.comp_id
        let company_id = re_Com.id;
        console.log("company_id",company_id,re_Com);
        this.setState({company_id : company_id});

        datarecordassessment = datarecordassessments.update
              console.log("datarecordassessment_update",datarecordassessment);
              console.log("length",datarecordassessment.assessment_answer.length)
          if(datarecordassessment.assessment_answer.length > 0 ){
            
            for(var j = 0; j < datarecordassessment.assessment_answer.length; j++){
              
                if(datarecordassessment.assessment_answer[j].company_id == company_id){


                  console.log("company_ids",company_id, JSON.parse(datarecordassessment.assessment_answer[j].assessment_answer_json_data));

                  new_answer_json = JSON.parse(datarecordassessment.assessment_answer[j].assessment_answer_json_data)

                  this.setState({answer_json: JSON.parse(datarecordassessment.assessment_answer[j].assessment_answer_json_data)}); 
                  console.log("new_answer_json13",new_answer_json)
                  break;
                }
                else{
                  var localstore = JSON.parse(localStorage.getItem("user_id"));
                  console.log("localstore",localstore)

                    console.log("company_ids",company_id, JSON.parse(datarecordassessment.assessment_answer[j].assessment_answer_json_data));

                    new_answer_json = JSON.parse(datarecordassessment.assessment_answer[j].assessment_answer_json_data)


                  new_answer_json = [
                    {
                      name: "Profiler_Name", value: localstore.company_name
                    },
                    {
                      name : "Profiler_Email_Address", value : localstore.email
                    },
                    {
                      name : "Profiler_Phone_Number", value : localstore.phone_number
                    },
                    {
                      name: "Company_Contact_Name", value: re_Com.person_name
                    },
                    { 
                      name: "Company_Name", value: re_Com.company_name
                    }
                  ]
                    this.setState({answer_json: JSON.parse(datarecordassessment.assessment_answer[j].assessment_answer_json_data)}); 
                    console.log("new_answer_json13",new_answer_json)
                }

            }
            console.log("new_answer_json12",new_answer_json)
                  
          }
          else{
               var localstore = JSON.parse(localStorage.getItem("user_id"));
                  console.log("localstore",localstore)

                  this.setState({answer_json: [{name: "Company_Name", value: localstore.company_name}]})

                  // new_answer_json = JSON.parse(datarecordassessment.assessment_answer[j].assessment_answer_json_data)

                   new_answer_json = [
                    {
                      name: "Profiler_Name", value: localstore.company_name
                    },
                    {
                      name : "Profiler_Email_Address", value : localstore.email
                    },
                    {
                      name : "Profiler_Phone_Number", value : localstore.phone_number
                    },
                    {
                      name: "Company_Contact_Name", value: re_Com.person_name
                    },
                    { 
                      name: "Company_Name", value: re_Com.company_name
                    }
                  ]
                  
                  console.log("datarecordassessment.assessment[0]",datarecordassessment.assessment[0])
                  console.log("new_answer_json1",new_answer_json)
            }
            
          console.log("datarecordassessment.assessment[0]",datarecordassessment.assessment[0])
          this.setState({datarecordassessment: datarecordassessment.assessment[0]});
      }
    }
    
    handleclick = (status,assessment_id) => {
        var answers = $('form').serializeArray();
        // $('input:invalid').css('background-color','red');
        // console.log("beforefalse",$('input:valid'),$('input:invalid'))

        if(status === 1){
            if($('input:valid')){
              $('input:valid').css('border','1px solid #ccc');
              $('textarea:valid').css('border','1px solid #ccc');
                if($('input:invalid')){
                  // $('<span style="color:red" >Please fill all of things.</span>').insertAfter($('input:invalid'));
                  $('input:invalid').css('border','1px solid red');
                  $('textarea:invalid').css('border','1px solid red');
                  if($('input:valid')){
                    $('input:valid').css('border','1px solid #ccc');
                    $('textarea:valid').css('border','1px solid #ccc');
                }
                console.log("invalid",$('input:valid'),$('input:invalid'))
      
              }
            
            }
    
    
            if($('input:required').val() && $('textarea:required').val() && $('input[type=radio].cust_required').is(':checked')){
              console.log("your radio button selected")
    
                this.props.submitanswers({answers : answers, company_id : this.state.company_id, status : status, profiler_id : this.state.authID, assessment_id : assessment_id })
                setTimeout(() => {
                  this.props.hideMessage();
                  this.props.history.push("/fill-assessment");
                }, 1000);
              
            }
            else if($('input[type=radio]').is(':checked')){
              this.props.submitanswers({answers : answers, company_id : this.state.company_id, status : status, profiler_id : this.state.authID, assessment_id : assessment_id })
              setTimeout(() => {
                this.props.hideMessage();
                this.props.history.push("/fill-assessment");
              }, 1000);
            }
            else{
              // alert("Select all options.")
              notification['warning']({
                message: 'warning',
                description: 'Select all answers.'
              }) 
              console.log("your radio button not selected")
            }
        }
        else{
            this.props.submitanswers({answers : answers, company_id : this.state.company_id, status : status, profiler_id : this.state.authID, assessment_id : assessment_id })
            setTimeout(() => {
              this.props.hideMessage();
              this.props.history.push("/fill-assessment");
            }, 1000);
        }
        

  
    } 
    handleback = () => {
      // this.scrollToMyRef();
      $(".gx-layout-content").animate({ scrollTop: 0 }, 1);
      var key_now = this.state.activekey;
      key_now = key_now - parseInt(1);
      this.setState({
        activekey : key_now,
        Pbtntype  : 'primary',
        Nbtntype  : 'ghost'
      })
    }
    handlenext = () => {
      // this.scrollToMyRef();
      var i = 0;
      var j = 0;
      var k = 0;

      console.log("radio",$('input[type=radio]:visible'));

      var Inputlen = $('input[type=text]:visible').length
      var Textarealen = $('textarea:visible').length
      var radioLen = $('input[type=radio]:visible').length;
      var checkBoxLen = $('input[type=checkbox]:visible').length;

      console.log("radio",Inputlen,Textarealen,radioLen);
      
      var InputVisible = $('input[type=text]:visible');

      var TextareaVisible = $('Textarea:visible');
      var TextareaCheck = false;

      var radioVisible = $("input[type=radio]:visible");
      var radioCheck = false;

      var checkBoxVisible = $("input[type=checkbox]:visible");
      var checkBoxChecked = false;
      var nextButtunGo =false;
      if(Inputlen > 0){
        while(i < Inputlen)
        {

          if(InputVisible[i].value == '')
          {
            notification['warning']({
                  message: 'warning',
                  description: 'Fill all blanks.'
                })
            checkBoxLen = 0
            break;
            return false;
          }
          else{
            if(i == Inputlen-1)
            {

              if(Textarealen > 0){
                TextareaCheck = true;
              }
              else if(radioLen > 0){
                radioCheck = true;
              }
              else{
                nextButtunGo = true;
                 // $(".gx-layout-content").animate({ scrollTop: 0 }, 1);
                 //  var key_now = this.state.activekey;
                 //  key_now = key_now+ parseInt(1);
                 //  this.setState({
                 //    activekey : key_now,
                 //    Nbtntype  : 'primary',
                 //    Pbtntype  : 'ghost'
                 //  })
              }

            }
          }

          

          i++;
            
        }
      }else{
        // $(".gx-layout-content").animate({ scrollTop: 0 }, 1);
        //   var key_now = this.state.activekey;
        //   key_now = key_now+ parseInt(1);
        //   this.setState({
        //     activekey : key_now,
        //     Nbtntype  : 'primary',
        //     Pbtntype  : 'ghost'
        //   })
        if(Textarealen > 0){
          TextareaCheck = true;
        }
        else if(radioLen > 0){
          radioCheck = true;
        }
        else{
          nextButtunGo = true;
        }
        
      }
     
       if (TextareaCheck) {
          while(j < Textarealen)
          {
            console.log("in start While");
            if(TextareaVisible[j].value == '')
            {
              notification['warning']({
                    message: 'warning',
                    description: 'Fill all blanks.'
                  })
              break;
              return false;
            }
            console.log("after While");
            if(j == Textarealen-1)
            {
              
              if(radioLen > 0){
                radioCheck = true;
              }
              else{
                 // $(".gx-layout-content").animate({ scrollTop: 0 }, 1);
                 //  var key_now = this.state.activekey;
                 //  key_now = key_now+ parseInt(1);
                 //  this.setState({
                 //    activekey : key_now,
                 //    Nbtntype  : 'primary',
                 //    Pbtntype  : 'ghost'
                 //  })
                 nextButtunGo = true;
              }
            }
            j++;
          }
        }

        if(checkBoxLen > 0){
            var namearr = [];
            $("input[type=checkbox]:visible").each(function() {

                var name = $(this).attr("name");

                if(!namearr.includes(name)){

                    namearr.push(name);

                    if ($("input[name='"+name+"']:checked").length == 0){

                        notification['warning']({
                            message: 'warning',
                            description: 'Fill all blanks.'
                          })

                        nextButtunGo = false;
                        return false;
                    }
                    else{

                      if(radioLen > 0){
                        radioCheck = true;
                      }
                      else{
                         nextButtunGo = true;
                      }
                     
                    }
                }
            })
         }

        
        if(radioCheck){
            
            var namearrs = [];
            console.log("vishal")
            $("input[type=radio]:visible").each(function() { 
              console.log("vishal1")
                var names = $(this).attr("name");
                if(!namearrs.includes(names)){
                    namearrs.push(names);
                    console.log("visha2")
                    if ($("input[name='"+names+"']:checked").length == 0){
                        console.log("vishal3")
                        notification['warning']({
                            message: 'warning',
                            description: 'Fill all blanks.'
                          })
                        nextButtunGo = false;
                        return false;
                    }
                    else{

                      nextButtunGo = true;
                     
                    }
                }
            })
         }

         


         if(nextButtunGo){
           $(".gx-layout-content").animate({ scrollTop: 0 }, 1);
              var key_now = this.state.activekey;
              key_now = key_now+ parseInt(1);
              this.setState({
                activekey : key_now,
                Nbtntype  : 'primary',
                Pbtntype  : 'ghost'
              })
         }

    }
    render() {
        
        return (
          <Card>
            <Row>
            <Col xl={14} lg={14} md={14} sm={24} xs={24}>
                  <h1>{this.state.datarecordassessment.name}</h1> 
                </Col>
                <Col xl={10} lg={10} md={10} sm={24} xs={24}>
                  <div className="cust-btns-right">
                {console.log("this.state.answer_json",this.state.answer_json)}
                  {
                    this.state.btnview 
                    ?
                    null
                    :
                      typeof this.state.datarecordassessment.assessment_section != 'undefined' 
                      ?  
                        this.state.activekey == this.state.datarecordassessment.assessment_section.length - parseInt(1) 
                        ? 
                        <Button type="ghost" onClick={() => this.handleclick(1,this.state.datarecordassessment.id)} ><i className="icon icon-sent"/> {'\u00A0'}SUBMIT</Button>
                        :
                        null
                      : 
                      null
                    }
                    {/* <Button type="ghost" onClick={() => this.handleclick(1,this.state.datarecordassessment.id)} ><i className="icon icon-sent"/> {'\u00A0'}SUBMIT</Button> */}
                    {
                    this.state.btnview 
                    ?
                    null
                    :
                      <Button type="ghost" onClick={() => this.handleclick(0,this.state.datarecordassessment.id)} ><i className="icon icon-draft"/> {'\u00A0'} SAVE AS DRAFT</Button>
                    }

                    {
                    this.state.btnview 
                    ?
                    <Link to={{ pathname:"/fill-assessment"}} > 
                    <Button type="ghost" ><i className="icon icon-left"/><Icon type="left"/> BACK </Button>
                    </Link>
                    :
                    null
                    }
                    </div>
                  
                </Col>
                {typeof this.state.datarecordassessment.assessment_section != 'undefined' ? this.state.datarecordassessment.assessment_section.map((iteration,key) => {
                 
                 return <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        
                      <div className={ key == this.state.activekey ?  "divActiveValidation"  : "" } style={ key == this.state.activekey ? { 'display':'block'} : { 'display':'none'} }>
                      <h5 style={{fontSize:"22px"}} >{iteration.tab_name}</h5>
                      {iteration.assessment_json != "null" ? <ReactFormGenerator
                            download_path=""
                            back_action="/"
                            back_name="Back"
                            answer_data={new_answer_json}
                            action_name="Save"
                            form_action="/"
                            form_method="POST"
                            data={JSON.parse(iteration.assessment_json)}
                            hide_actions={true}
                          /> : null}
                      </div> 
                             {console.log("JSON.parse(iteration.assessment_json)",JSON.parse(iteration.assessment_json),iteration.assessment_json)}
                      </Col> 
                          
                }) : null }
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <center>
                    {
                    this.state.activekey == 0 
                    ? 
                    null 
                    : 
                    <Button type={this.state.Pbtntype}  onClick={this.handleback} >
                    <Icon type="left"/>
                        Previous
                    </Button>
                    }
                    {
                    typeof this.state.datarecordassessment.assessment_section != 'undefined' 
                    ?  
                      this.state.activekey == this.state.datarecordassessment.assessment_section.length - parseInt(1) 
                      ? 
                      null 
                      :
                      <Button type={this.state.Nbtntype} onClick={this.handlenext} >
                      Next
                      <Icon type="right"/>
                        
                        
                      </Button> 
                    : 
                    null
                    }
                  </center>
                </Col>
              {/* </form> */}
            </Row>
            {/* {this.props.showSuccessMessage ?
              message.success(this.props.successMessage.toString()) : null}
                        {this.props.showMessage ?
              message.error(this.props.alertMessage.toString()) : null}  */}
              {this.props.showSuccessMessage
                    ?
                    notification['success']({
                        message: 'success',
                        description: this.props.successMessage.toString()
                      })
                    : null}

                {this.props.showMessage 
                    ?
                    notification['error']({
                        message: 'error',
                        description: this.props.alertMessage.toString()
                      }) 
                    : null}
          </Card>
        );
    }
    scrollToMyRef = () => window.scrollTo(0, 0)
};

// Object of action creators
const mapDispatchToProps = {
    submitanswers,
    hideMessage,
}

const mapStateToProps =  state => {
return { 
        getAssessmentData: state.assessmentReducers.get_assessment_res ,
        loader : state.assessmentReducers.loader,
        showSuccessMessage : state.assessmentReducers.showSuccessMessage,
        successMessage : state.assessmentReducers.successMessage,
        showMessage : state.assessmentReducers.showMessage,
        alertMessage : state.assessmentReducers.alertMessage,
        authUser : state.auth.authUser,
        status : state.assessmentReducers.status,
    }; 
};
// export default Staff;
export default connect(mapStateToProps,mapDispatchToProps)(AssessmentDetails);
