import React, {Component} from "react";
// import {Col, Row, Card, Table, Button} from "antd"; import Auxiliary from
// "util/Auxiliary";
import {Link} from 'react-router-dom'
import {
    Button,
    Card,
    Col,
    Form,
    Input,
    Row,
    Select,
    Modal,
    message,
    notification,
    Divider
} from "antd";

import {connect} from "react-redux";
import { hideMessage,addReport} from "appRedux/actions/ReportActions";
// import CKEditorcomponent from "react-ckeditor-component";
import { contentdata } from './profilerData'
// import CKEditor from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import {PDFtoIMG} from 'react-pdf-to-image';
// import PDF2Pic from 'pdf2pic';
// import pdfFile from './assessment_426_81.pdf';
// const PDF2Pic = require("pdf2pic");
// import logger from 'logging-library';
// import FileViewer from 'react-file-viewer';
// import { CustomErrorComponent } from 'custom-error';

const FormItem = Form.Item;
const Option = Select.Option;
const {TextArea} = Input;
var datajson = []
var link_show = "";
var contentdatas = contentdata;
var previewVisible =false;

// const pdf2pic = new PDF2Pic({
//     density: 100,           // output pixels per inch
//     savename: "untitled",   // output file name
//     savedir: "./Mycomponents",    // output file location
//     format: "png",          // output file format
//     size: "600x600"         // output size in pixels
//   });
class previewDocx extends Component {

    constructor(props) {
        super(props);
        this.myRef = React.createRef()  
        this.state = {
            confirmDirty: false,
            company_name: '',
            email: 'demo@example.com',
            poc_name: '',
            no_of_assessment: '',
            phone_number: '',
            address1: '',
            address2: '',
            city: '',
            pincode: '',
            note: '',
            isUpdate: false,
            isAdd: true,
            userid: '',
            username: '',
            cardTitle: 'Create Profiler',
            editdata: '',
            disabled: '',
            staffData: '',
            reportcontant : '',
            status : 0,
            data : '',
            AssessmentName : '',
            CompanyName : '',
            POCName : '',
            assessment_id : '',
            company_id : '',
            profiler_id : '',
            header : '',
            footer : '',
            previewVisible : false,
            link_show : "",
           
        };
    }

    previewhandleCancel = () => {
        // this.setState({previewVisible: false});
        previewVisible = false;
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this
            .props
            .form
            .validateFieldsAndScroll((err, values) => {

                if (!err) {
                 
                    values.AssessmentName = this.state.AssessmentName;
                    values.CompanyName = this.state.CompanyName;
                    values.POCName = this.state.POCName;
                    values.profiler_id = this.state.profiler_id;
                    values.assessment_id = this.state.assessment_id;
                    values.company_id = this.state.company_id;
                    values.status = this.state.status;
                    values.data = this.state.data;
                    values.header = this.state.header;
                    values.footer = this.state.footer;

                    console.log("values", values)
                    this
                        .props
                        .addReport(values);

                    setTimeout(() => {
                        if(this.state.status != 2){
                        this
                            .props
                            .hideMessage();
                        this
                            .props
                            .history
                            .push("/report");
                        }
                        
                    }, 1000); 
                }
            });

    }

    validateToNextPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirm'], {force: true});
        }
        callback();
    }
    
    componentDidUpdate() {
     
    }

    componentWillMount(){
    localStorage.setItem("progress", 40);
        if(typeof this.props.authUser != 'undefined'){

            var url = localStorage.getItem("previewdocURL");
            this.setState({link_show : url});
            var authpermission = this.props.authUser.permission;
            console.log("authpermission",authpermission);
            if(authpermission.length > 0){
                if(!authpermission.includes('96')){
                    this
                    .props
                    .history
                    .push("/dashboard");
                }
            }
        }
        // var authpermission = this.props.permission;
        // console.log("authpermission",authpermission);
        // if(!authpermission.includes('93')){
        //     this
        //     .props
        //     .history
        //     .push("/dashboard");
        // }
        // else{
           
        // }

    }
    // onError(e) {
    //     logger.logError(e, 'error in file-viewer');
    //   }

    componentDidMount() {
        localStorage.setItem("progress", 100);

        setTimeout(() => {  
                    localStorage.setItem("progress", 0);
                  }, 2000);  

                //   pdf2pic.convertBulk(pdfFile,[1]).then((resolve) => {
                //     console.log("image converter successfully!");
                   
                //     return resolve;
                //   });
        
    }
    reportcontant = (evt) => {
        const newContent = evt.editor.getData();
        this.setState({reportcontant : newContent});
      }
      modeChange = (val) => {

        this.setState({status : val})

      }
      setHeaderVal = (data) => {
        this.setState({header : data})
      }

      setDataVal = (data) =>{
          this.setState({data : data})
      }

      setFooterVal = (data) => {
        this.setState({footer : data})
      }

    render() {
        const {getFieldDecorator} = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: {
                    span: 24
                },
                sm: {
                    span: 8
                }
            },
            wrapperCol: {
                xs: {
                    span: 24
                },
                sm: {
                    span: 12
                }
            }
        };
        const formItemLayout1 = {
            wrapperCol: {
                xs: {
                    span: 24
                },
                sm: {
                    span: 24
                }
            }
        };


       return(
        <Card className="gx-card cust_asses" title='Report'> 


            <iframe class="doc" src={"https://docs.google.com/gview?url="+this.state.link_show+"&embedded=true"} style={{width:"95%",height:"500px"}}></iframe>
         
           
  
        
        </Card>
       );
    }
    scrollToMyRef = () => window.scrollTo(0, 0)

}
const mapStateToProps = state => {
    return {
        authUser: state.auth.authUser, 
        showSuccessMessage: state.reportReducers.showSuccessMessage,
        showMessage: state.reportReducers.showMessage, 
        successMessage: state.reportReducers.successMessage, 
        alertMessage: state.reportReducers.alertMessage,
        link: state.reportReducers.link,
    }
};
// const {successMessage, alertMessage, showMessage, showSuccessMessage} =
// useSelector(({auth}) => auth); Object of action creators
const mapDispatchToProps = {
    hideMessage: hideMessage,
    addReport : addReport
} 
const previewDocxForm = Form.create()(previewDocx);
// export default RegistrationForm;
export default connect(mapStateToProps, mapDispatchToProps)(previewDocxForm);
