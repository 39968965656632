import React from "react";
import {Tag} from "antd";

var status = '';
var name = '';
var created_datetime = '';
var description = '';

const ProfileAssessmentTabItem = ({data}) => {
  // const {name,description,status,created_datetime} = data;
//console.log("assessmentByProfiler123",data.assessment)
var abc = data.assessment
  abc.map((data1, index1) => {
    //console.log("Assessmentdata1",data1.name)
    status = data1.status;
    name = data1.name;
    created_datetime = data1.created_datetime;
    description = data1.description;
  })

  return (
    <div className="gx-media gx-featured-item">

      {
      /* <div className="gx-featured-thumb">
        <img className="gx-rounded-lg" src={image} alt="..."/>
      </div> */
      }
      <div className="gx-media-body gx-featured-content assest-box">
        <div className="gx-featured-content-left assest-left">
    <Tag className="gx-rounded-xs" color={status == 1 ? '#6BA539' : '#ff4d4f' }>{status == 1 ? 'Active' : 'Deactive' }</Tag>
          <h3 className="gx-mb-2">{name}</h3>
          <div className="ant-row-flex">
            <div className="gx-media gx-text-grey gx-mb-1">
              {/* <i className={`icon icon-location gx-fs-lg gx-mr-2 gx-d-inline-flex gx-vertical-align-middle`}/> */}
              {/* <span className="gx-media-body gx-ml-1">Texas</span> */}
            </div>
          </div>
        </div>
        <div className="gx-featured-content-right gx-profile-content-right assest-right">
          <h2 className="gx-text-primary gx-mb-1">
            {/* <i className={`icon icon-calendar gx-fs-lg gx-mr-2 gx-d-inline-flex gx-vertical-align-middle`}/>  */}
            
            <span className="gx-d-inline-flex gx-vertical-align-middle">Last Accessed :  {created_datetime} 
            {/* <span className="cust-time">12 : 11 pm</span> */}
            </span>
          </h2>
          <p className="gx-text-primary gx-text-truncate gx-mt-sm-auto gx-mb-0 gx-pointer">{description}
          </p>
        </div>

      </div>
    </div>
  );
}
status = '';
name = '';
created_datetime = '';
description = '';
export default ProfileAssessmentTabItem;
