import React, {Component} from "react";
import {
    Card,
    Table,
    Button,
    message,
    Popconfirm,
    Badge,
    notification,
    Input
} from "antd";
import {Link} from 'react-router-dom'
import {connect} from "react-redux";
import CircularProgress from "components/CircularProgress/index";
import { get, post } from './requests';
import ReadMoreAndLess from 'react-read-more-less';
import {get_assessment,showAssessmentLoader,setstatustoinitial,hideMessage,deleteAssessmentById,statusAssessmentchange,copydata,createAssessmentExcel} from "appRedux/actions/AssessmentActions";
import ReactExport from "react-export-excel";
import { SearchOutlined } from '@ant-design/icons';
import { ReactFormBuilder, ElementStore , ReactFormGenerator } from 'react-form-builder2';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const Search = Input.Search;
const hurl = "#";
const getUrl = (cid) => `https://safe-springs-35306.herokuapp.com/api/formdata?cid=${cid}`;
class AssessmentList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            status_value: 'Active',
            status_color: '#6BA539',
            Active_color : '#6BA539',
            Deactive_color: '#ff4d4f',
            data: [],
            authID : '',
            current : 1,
            pagination: {
                current: 0,
                pageSize: 10,
                // defaultPageSize: 10, 
            },
            filter : '',
            assessmentArray : [],
        };
        this.confirm = this
            .confirm
            .bind(this);
        this.deleteAssessment = this
            .deleteAssessment
            .bind(this);
        this.chageStatusAssessment = this
            .chageStatusAssessment
            .bind(this);
        this.CopyData = this.CopyData.bind(this);
    }

    confirm() {
        message.info('Click on Yes.');
        this.setState({advalue: 'Deactive', status_color: '#ff4d4f'});
    }

    chageStatusAssessment = (id, status) => {
        var Statusdata = {
            "id" : id,
            "status" : status
        }
        this.props.showAssessmentLoader();
        this.props.statusAssessmentchange(Statusdata);
        // message.success('Status has been changed');
        // this.get_staffById();
    }
    
    CopyData = (assessment_id,user) => {
         
        var values = {
            "id" : user,
            "assessment_id" : assessment_id
        }
        this.props.showAssessmentLoader();
        this.props.copydata(values);
        // message.success('Link has been sent.');
        // this.get_staffById();
    }
    componentWillMount(){
          localStorage.setItem("progress", 40);
        if(typeof this.props.authUser != 'undefined'){

            var authpermission = this.props.authUser.permission;
            this.setState({authID : this.props.authUser.id});
            //console.log("authpermission",authpermission);
            if(authpermission.length > 0){
                if(!authpermission.includes('97')){
                    this
                    .props
                    .history
                    .push("/dashboard");
                }
            }
        }
        
    }

    deleteAssessment = (key) => {
        this.props.showAssessmentLoader();
        this.props.deleteAssessmentById(key);
        // message.success('Your record is deleted');
        // this.get_staffById()
    }
    get_assessmentById(){
        if(this.props.status == 'Initial'){
            var val = {
                authID : this.state.authID,
                filter : '',
                current : 0,
                field : '',
                order : '',
            }
            this.props.get_assessment(val);
        }
    }
    createExcel = (assessmentId) => {
        this.props.showAssessmentLoader();
        this.props.createAssessmentExcel(assessmentId);
        console.log(assessmentId);
    }
    componentDidMount(){

localStorage.setItem("progress", 100);

        setTimeout(() => {  
                    localStorage.setItem("progress", 0);
                  }, 2000);  
        // for(var j = 0 ; j < 15; j++){
        //     const saveUrl = getUrl('newTabyyy'+j);
        //     //console.log("saveUrl",saveUrl)
        //     post(saveUrl, {task_data : []});
        // }
        //   const saveUrl = getUrl('');
        //   post(saveUrl, {task_data : []});
        this.props.setstatustoinitial();
        this.get_assessmentById()
    }
    componentDidUpdate(){
        if(this.props.showSuccessMessage || this.props.showMessage)
        {
            // this.get_profilerById()
                setTimeout(() => {
                    this.props.hideMessage();
                },100);
        }
        this.get_assessmentById();
    }

    searchInputData = (vals) => {
        ////console.log("searchInputData",vals)

        this.setState({filter : vals})
        var val = {
            authID : this.state.authID,
            filter : vals,
            current : 0,
            field : '',
            order : '',
        }
        this.props.get_assessment(val);
        // alert("search in put")

    }

    handleTableChange = (pagination, filters, sorter) => {
        // this.props.setstatustoinitial();
        //console.log("pagination",pagination)
        var currents = 0;
        this.setState({
            current : pagination.current
        })
        if(pagination.current >= 2){
                currents = (pagination.current - 1) * 10
        }
        else{
             currents = 0;
        }

        var values = {
            current : currents,
            // pageSize : 10,
            authID : this.state.authID,
            filter : this.state.filter,
            field : sorter.field,
            order : sorter.order,
        }
        this.props.get_assessment(values);
        // alert('handlechange')

    };
    createPdf(record)
    {
        // this.setState({assessmentArray:JSON.parse(record.assessment_section[0].assessment_json)},() => this.test())
        this.setState({assessmentArray:record.assessment_section},() => this.createPdfPage(record.name))
        console.log('aspl',record.assessment_section[0].assessment_json);
    }
    createPdfPage(title)
    {
        const input = document.getElementById('assessmentCode');
        var inputhtml = input.outerHTML;
        inputhtml = inputhtml.replace('style="display: none;"','');
        // console.log(inputhtml.replace('style="display: none;"',''));
        var printWindow = window.open();
        printWindow.document.write('<html><head><title>' + title +'</title>');
        // printWindow.document.write('<style>@page{size: A4 landscape;}</style>');
        
        printWindow.document.write("<style> .react-form-builder-form .rfb-item label { font-weight: 400; margin-right: 15px; vertical-align: top; } .react-form-builder-form .rfb-item .form-group .checkbox-label, .react-form-builder-form .rfb-item .form-group .radio-label { font-weight: normal; display: block; } .react-form-builder-form .rfb-item { padding: 5px 0; position: relative; } .form-group { margin-bottom: 7px; } .form-control { display: block; width: 100%; height: 34px; padding: 6px 12px; font-size: 14px; line-height: 1.42857143; color: #555; background-color: #fff; background-image: none; border: 1px solid #ccc; border-radius: 4px; box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%); transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s; @media print {.react-form-builder-form .rfb-item label { font-weight: 400; margin-right: 15px; vertical-align: top; } .react-form-builder-form .rfb-item .form-group .checkbox-label, .react-form-builder-form .rfb-item .form-group .radio-label { font-weight: normal; display: block; } .react-form-builder-form .rfb-item { padding: 10px 0; position: relative; } .form-group { margin-bottom: 15px; } .form-control { display: block; width: 100%; height: 34px; padding: 6px 12px; font-size: 14px; line-height: 1.42857143; color: #555; background-color: #fff; background-image: none; border: 1px solid #ccc; border-radius: 4px; box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%); transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s; }}</style>");
        // printWindow.document.write('<script>window.onafterprint = function () { window.close(); }</script>');
        
        printWindow.document.write('</head><body>');
        printWindow.document.write(inputhtml);
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.print();
    }
    render() {
        function isEmpty(obj) {
            for(var key in obj) {
                if(obj.hasOwnProperty(key))
                    return false;
            }
            return true;
        }
        var AssessmenData = this.props.getAssessmentData;
        var sdata = '';
        var paginationToatal = 0;
        var allAssessment = ''
        if(isEmpty(AssessmenData)){
            // Object is empty (Would return true in this example)
        }
        else 
        {
            // Object is NOT empty
            sdata = AssessmenData.assessment

            paginationToatal = AssessmenData.total
            allAssessment = AssessmenData.allAssessment
            //console.log("AssessmenData",sdata)
        }

        const columns = [
            {
                title: 'Title',
                dataIndex: 'name',
                width: 180,
                sorter: true,
            },{
                title: 'Description',
                dataIndex: 'description',
                width: 380,
                sorter: true,
                render: (text, record) => (
                    <ReadMoreAndLess
                        ref={this.ReadMore}
                        className="read-more-content"
                        charLimit={50}
                        readMoreText="Read more"
                        readLessText="Read less"
                    >
                        {record.description}
                    </ReadMoreAndLess>
                   
                )

            },{
                title: 'Created Date',
                dataIndex: 'created_date',
                className : 'cust_date_td',
                sorter: true,
                width: 155,
                align : "center"

            },{
                title: 'Status',
                // dataIndex: 'description',
                width: 60,
                render: (text, record) => (
                    <span>
                    {
                        record.publish == 1
                        ?
                        <Badge
                            className="site-badge-count-109 cust-badge-width"
                            // abc={ this.setState({banned : record.banned}) }
                            // count={this.state.status_value}
                            count="Published"
                            style={{
                            backgroundColor: '#6BA539'
                            }}
                        />
                        :
                        <Badge
                            className="site-badge-count-109 cust-badge-width"
                            // abc={ this.setState({banned : record.banned}) }
                            // count={this.state.status_value}
                            count="Draft"
                            style={{
                            backgroundColor: '#ff4d4f'
                            }}
                        />
                        
                    }
                    </span>
                   
                )

            },{
                title: 'Action',
                key: 'action',
                width: 275,
                render: (text, record) => (

                    <span>
                        {/* <Link to={{
                                pathname:"/assessmentPdf/1"
                            }} 
                            target="_blank" >
                                <i className="icon icon-wysiwyg"></i>
                            {'\u00A0'}{'\u00A0'}|{'\u00A0'}{'\u00A0'}
                        </Link> */}
                        <span style={{'cursor':'pointer'}} title="Create Pdf" onClick={() => this.createPdf(record)}>
                            <i className="icon icon-wysiwyg"></i>
                            {'\u00A0'}{'\u00A0'}|{'\u00A0'}{'\u00A0'}
                        </span> 
                        {
                            record.publish == 1
                            ?
                            <Link to={{
                                pathname:"/view-assessment",
                                state : {record : record}
                            }} >
                                <i className="icon icon-view"></i>
                                {'\u00A0'}{'\u00A0'}|{'\u00A0'}{'\u00A0'}
                            </Link>
                            :
                            <Link to={{
                                    pathname:"/add-assessment",
                                    state : {record : record}
                                }} >
                                    <i className="icon icon-edit"></i>
                                    {'\u00A0'}{'\u00A0'}|{'\u00A0'}{'\u00A0'}
                            </Link>
                        }
                        {
                            record.publish == 1
                            ?
                                <span style={{'cursor':'pointer'}} title="Export Excel" onClick={() => this.createExcel(record.id)}>
                                    {/* <Button icon="download"> */}
                                        <i className="icon icon-table-general"></i>
                                    {/* </Button> */}
                                    {'\u00A0'}{'\u00A0'}|{'\u00A0'}{'\u00A0'}
                                </span> 
                            :
                            null
                        }
                        {
                            record.publish == 1
                            ?
                            ''
                            :
                            <Popconfirm
                            placement="top"
                            title="Are you sure want to delete?"
                            onConfirm={(e) => this.deleteAssessment(record.id)}
                            okText="YES"
                            cancelText="No">
                            <a href={hurl}>
                         
                                <i
                                    className="icon icon-trash"
                                    style={{
                                    color: "red"
                                }}></i>
                            </a>
                            {'\u00A0'}{'\u00A0'}|{'\u00A0'}{'\u00A0'}
                        </Popconfirm>

                        }
                        
                        {
                            record.status == 1
                            ?

                            <Popconfirm
                            placement="top"
                            title="Are you sure change this status?"
                            onConfirm={(e) => this.chageStatusAssessment(record.id,0)}
                            okText="YES"
                            cancelText="No">
                            <a href={hurl}>
                            
                                <Badge
                                    className="site-badge-count-109"
                                    // abc={ this.setState({banned : record.banned}) }
                                    // count={this.state.status_value}
                                    count="Active"
                                    style={{ 
                                    backgroundColor: this.state.Active_color
                                    }}
                                /></a>
                            </Popconfirm>
                            :
                            <Popconfirm
                            placement="top"
                            title="Are you sure change this status?"
                            onConfirm={(e) => this.chageStatusAssessment(record.id,1)}
                            okText="YES"
                            cancelText="No">
                            <a href={hurl}>
                            
                                <Badge
                                    className="site-badge-count-109"
                                    // abc={ this.setState({banned : record.banned}) }
                                    count="Deactive"
                                    style={{
                                    backgroundColor: this.state.Deactive_color,
                                    width: "70px"
                                    }}
                                /></a>
                            </Popconfirm>
                        }
                        
                        {
                            /* {
                            record.banned
                            ?
                            ''
                            : */}
                            <Popconfirm
                            placement="top"
                            title="Are you sure want to copy the assessment?"
                            onConfirm={(e) => this.CopyData(record.id,record.created_by)}
                            okText="YES"
                            cancelText="No">
                            <a href={hurl}>
                            {'\u00A0'}{'\u00A0'}|{'\u00A0'}{'\u00A0'}
                                <Badge
                                    className="site-badge-count-109"
                                    // abc={ this.setState({banned : record.banned}) }
                                    // count={this.state.status_value}
                                    count="Copy"
                                    style={{
                                    backgroundColor: this.state.Active_color
                                    }}
                                />
                                </a>
                            </Popconfirm>
                        {/* } */}

                    </span>
                    
                )
            }
        ];

        const excelbtn = (
            <ExcelFile filename="Assessment-List" element={<Button icon="download" type="primary"  >EXPORT</Button>} >
                <ExcelSheet data={allAssessment} name="Assessment">
                    <ExcelColumn label="Title" value="name"/>
                    <ExcelColumn label="Description" value="description"/>
                    <ExcelColumn label="Created Date" value="created_datetime"/>
                    <ExcelColumn label="Status"
                                 value={(record) => record.publish == "1" ? "Published" : "Draft"}/>
                </ExcelSheet>
            </ExcelFile>
        );

        const searchBtn = (
             <Search
             className="searchBtn"
             placeholder="Search"
             onSearch={(value) => this.searchInputData(value)}
             style={{width: 300, marginLeft:10,marginRight:10}}
             allowClear 
           />
            
        );

        return (
                <Card className="gx-card cust_asses" style={ {width: "100%"}}
                title="Assessment List"
                extra={ 
                    <div className="cardextraelement">
                        {searchBtn}
                        <Link to="add-assessment"  className="ADDbtn">
                            <Button type="primary" icon="add">
                                <i className="icon icon-add"></i>
                                ADD
                            </Button>
                        </Link>
                        {excelbtn}
                    </div>
                    }>
                {/* {this.props.loader ?
                <div className="gx-loader-view">
                    <CircularProgress/>
                </div> : null}       */}

                {/* {this.props.showSuccessMessage ?
                message.success(this.props.successMessage.toString()) : null}
                {this.props.showMessage ?
                message.error(this.props.alertMessage.toString()) : null}  */}

                    {/* {this.props.loader ? null :  */}
                    <Table 
                    className="gx-table-responsive"
                    columns={columns}
                    rowkey={record => record.id}
                    loading ={this.props.loader}
                    dataSource={sdata}
                    pagination={{
                        current : this.state.current,
                        total : paginationToatal,
                        defaultPageSize: 10, 
                        hideOnSinglePage: true
                    }}
                    onChange={this.handleTableChange}

                    />  
                    <div id="assessmentCode" style={{'display':'none'}}>
                        {this.state.assessmentArray.map((item,index) =>
                        {
                            return(
                                <span>
                                    <h2>{item.tab_name}</h2>
                                    <br/>
                                    <ReactFormGenerator
                                        download_path=""
                                        back_action="/"
                                        back_name="Back"
                                        answer_data={{}}
                                        action_name="Save"
                                        form_action="/"
                                        form_method="POST"
                                        data={JSON.parse(item.assessment_json)}
                                        hide_actions={true}
                                    />
                                </span>
                            )
                        })}
                         
                    </div>
                    {/* } */}
                
                {
                    this.props.showSuccessMessage
                    ?
                    notification['success']({
                        message: 'success',
                        description: this.props.successMessage.toString()
                    })
                    : null
                }

                {
                    this.props.showMessage 
                    ?
                    notification['error']({
                        message: 'error',
                        description: this.props.alertMessage.toString()
                      }) 
                    : null
                }
                </Card>

        );
    }
};

// Object of action creators
const mapDispatchToProps = {
    get_assessment,
    deleteAssessmentById,
    statusAssessmentchange,
    copydata,
    hideMessage,
    setstatustoinitial,
    showAssessmentLoader,
    createAssessmentExcel
}

const mapStateToProps =  state => {
return { 
        getAssessmentData: state.assessmentReducers.get_assessment_res ,
        loader : state.assessmentReducers.loader,
        showSuccessMessage : state.assessmentReducers.showSuccessMessage,
        successMessage : state.assessmentReducers.successMessage,
        showMessage : state.assessmentReducers.showMessage,
        alertMessage : state.assessmentReducers.alertMessage,
        authUser : state.auth.authUser,
        status : state.assessmentReducers.status,
    }; 
};
// export default Staff;
export default connect(mapStateToProps,mapDispatchToProps)(AssessmentList);
