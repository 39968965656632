module.exports = {
  footerText: 'Copyright KMS © 2020',
  // baseURL : 'http://localhost/kms/',
  // baseURL : 'https://initzero.tech/kms/',
  // baseURL : 'http://localhost/kms_backend/',
  baseURL : 'https://link.wearekms.com/backend/',

  apikey : '566127F9949F7BFFF2F0AE327D47E741',
}
// "homepage": "http://66.150.26.166/kms"
//  "homepage": "http://66.150.26.166/kms"