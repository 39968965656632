import React, {Component} from "react";
import {
    Row,
    Card,
    Button,
    Col,
} from "antd";
import {Link} from 'react-router-dom'
import {connect} from "react-redux";
import {get_assessmentbyprofiler,showAssessmentLoader,hideMessage,setstatustoinitial,deleteAssessmentById,statusAssessmentchange,copydata} from "appRedux/actions/AssessmentActions";
import CircularProgress from "components/CircularProgress/index";
class AssessmentFill extends Component {
    constructor(props) {
        super(props);
        this.state = {
            status_value: 'Active',
            status_color: '#6BA539',
            Active_color : '#6BA539',
            Deactive_color: '#ff4d4f',
            data: [],
            authID : ''
        };
    }

    
   
    componentWillMount(){
          localStorage.setItem("progress", 40);
        if(typeof this.props.authUser != 'undefined'){
            var authpermission = this.props.authUser.permission;
            this.setState({authID : this.props.authUser.id});
            console.log("authpermission",authpermission);
            if(authpermission.length > 0){
                if(!authpermission.includes('97')){
                    this.props.history.push("/dashboard");
                }
            }
        }
    }
    componentDidMount(){
        localStorage.setItem("progress", 100);

        setTimeout(() => {  
                    localStorage.setItem("progress", 0);
                  }, 2000);  
        this.props.setstatustoinitial();
        if(this.props.status == 'Initial'){
            this.props.get_assessmentbyprofiler({profiler_id : this.state.authID,type : 'publish'});
        }
    }
    componentDidUpdate(){
        console.log("vishal",this.props.get_assessment_profiler);
        if(this.props.showSuccessMessage || this.props.showMessage)
        {
            setTimeout(() => {
                this.props.hideMessage();
            }, 100);
        }
        if(this.props.status == 'Initial'){
            this.props.get_assessmentbyprofiler({profiler_id : this.state.authID,type : 'publish'});
        }
        // this.get_assessmentById();
    }
    
    render() {
        console.log("vishal",this.props.get_assessment_profiler);
        return (
            <Row>
            {
            this.props.loader ?
            <div className="gx-loader-view">
                <CircularProgress/>
            </div> : null
            }
            {this.props.loader ? null : this.props.get_assessment_profiler ? 
            this.props.get_assessment_profiler.assessmentByProfiler.map((iteration)=>{
            return iteration.assessment.length > 0 ? <Col xl={8} lg={12} md={12} sm={24} xs={24}>
            <Card
            actions={[
            <Link>Saved {iteration.assessment[0].created_datetime}</Link>,
            <Link to={{ pathname:"/assessment-detail", update : iteration }} > 
            { 
            typeof iteration.assessment_answer != 'undefined' ? iteration.assessment_answer.length > 0 ? iteration.assessment_answer[iteration.assessment_answer.length - parseInt(1)].status == "1" ? null :  <Button type="ghost" > { typeof iteration.assessment_answer != 'undefined' ? iteration.assessment_answer.length > 0 ? "RESUME" : "START" :"START" } </Button> : <Button type="ghost" > { typeof iteration.assessment_answer != 'undefined' ? iteration.assessment_answer.length > 0 ? "RESUME" : "START" :"START" } </Button> : <Button type="ghost" > { typeof iteration.assessment_answer != 'undefined' ? iteration.assessment_answer.length > 0 ? "RESUME" : "START" :"START" } </Button> 
            }
            </Link>
            ]}
            >   
            <h3>{iteration.assessment[0].name}</h3>
             <p>{iteration.assessment[0].description}</p>
             {/* <p>{iteration.assessment[0].cresated_datetime}</p> */}
            </Card>
            </Col> : null;
            }) : null}
          
          </Row>
        );
    }
};

// Object of action creators
const mapDispatchToProps = {
    get_assessmentbyprofiler,
    deleteAssessmentById,
    statusAssessmentchange,
    copydata,
    hideMessage,
    setstatustoinitial,
    showAssessmentLoader
}

const mapStateToProps =  state => {
return { 
        get_assessment_profiler: state.assessmentReducers.get_assessment_profiler ,
        loader : state.assessmentReducers.loader,
        showSuccessMessage : state.assessmentReducers.showSuccessMessage,
        successMessage : state.assessmentReducers.successMessage,
        showMessage : state.assessmentReducers.showMessage,
        alertMessage : state.assessmentReducers.alertMessage,
        authUser : state.auth.authUser,
        status : state.assessmentReducers.status,
    }; 
};
// export default Staff;
export default connect(mapStateToProps,mapDispatchToProps)(AssessmentFill);
