    import {all, call, fork, put, takeEvery} from "redux-saga/effects";
    import axios from 'axios';
    import {baseURL, apikey} from 'util/config'
    import {
        ADD_PERMISSION,
        GET_PERMISSION_BY_ROLEID,
        GET_PERMISSION_SUCCESS_DATA,

        GET_ROLE,
        ADD_ROLE,
        DELETE_ROLE,
        GET_ROLE_SUCCESS_DATA
    } from "constants/ActionTypes";
    import {showErrorMessage, showSuccessMessage,getRoleSuccess,getpermissionSuccess,} from "../actions/RolePermissionActions";

    export const token = (state) => state.token;

    const headers = {
        'Content-Type': 'application/json',
        'X-API-KEY': apikey
    }

    const headersWithToken = {
        'Content-Type': 'application/json',
        'X-API-KEY': apikey,
        'X-Token' : token

    }
    
    /*Role and Permissino Module End */
    const getRoleByGroup = async(roleid) => await axios.get(baseURL + 'api/group/allRole?roleid='+roleid, {headers: headers})
    .then(getStaffRes => getStaffRes.data)
    .catch(error => error);

    
    const getPermissionByRoleid = async(groupid) => await axios.get(baseURL + 'api/user/permissionlist?groupid='+groupid, {headers: headers})
    .then(getPerRes => getPerRes.data)
    .catch(error => error);

    const addRoleByGroup = async(role) => await axios.post(baseURL + 'api/group/add', {
        "name": role,
        "definition": role
    }, {headers: headers})
        .then(addRoleRes => addRoleRes.data)
        .catch(error => error);

        
    const addPermissionByGroupId = async(group_id,id) => await axios.post(baseURL + 'api/user/permission_access', {
        "group_id": group_id,
        "id": id
    }, {headers: headers})
        .then(PerRes => PerRes.data)
        .catch(error => error);

    const deleteRoleById = async(roleid) => await axios.post(baseURL + 'api/group/delete', {
        "id": roleid,
    }, {headers: headers})
        .then(deleteRes => deleteRes.data)
        .catch(error => error);
    /*Role and Permissino Module End */


    /* Role Function call start */
    function * getRoleById({payload}) {
        // const {group_id} = payload;
        try {
            const getRoleRes = yield call(getRoleByGroup,payload);
            // console.log("getProfilerRes",getProfilerRes);
            if (getRoleRes.status) {

                yield put(getRoleSuccess(getRoleRes.data));
                // yield put(showSuccessMessage(getProfilerRes.message));
            } else {
                yield put(showErrorMessage(getRoleRes.message));
            }
        } catch (error) {
            yield put(showErrorMessage(error));
        }
    }

    
    function * getPermissionByRole({payload}) {
        // const {group_id} = payload;
        try {
            const getperRes = yield call(getPermissionByRoleid,payload);
            // console.log("getProfilerRes",getProfilerRes);
            if (getperRes.status) {

                yield put(getpermissionSuccess(getperRes.data));
                // yield put(showSuccessMessage(getProfilerRes.message));
            } else {
                yield put(showErrorMessage(getperRes.message));
            }
        } catch (error) {
            yield put(showErrorMessage(error));
        }
    }

    function * addRoleByUser({payload}) {
        const {role} = payload;
        try {
            const getRoleRes = yield call(addRoleByGroup,role);
            // console.log("getProfilerRes",getProfilerRes);
            if (getRoleRes.status) {

                // yield put(getRoleSuccess(getRoleRes.data));
                yield put(showSuccessMessage(getRoleRes.message));
            } else {
                yield put(showErrorMessage(getRoleRes.message));
            }
        } catch (error) {
            yield put(showErrorMessage(error));
        }
    }

    function * addPermissionByGroup({payload}) {
        const {group_id,id} = payload;
        try {
            const getPerRes = yield call(addPermissionByGroupId,group_id,id);
            console.log("addPermissionByGroup",getPerRes);
            if (getPerRes.status) {

                // yield put(getRoleSuccess(getRoleRes.data));
                yield put(showSuccessMessage(getPerRes.message));
            } else {
                yield put(showErrorMessage(getPerRes.message));
            }
        } catch (error) {
            yield put(showErrorMessage(error));
        }
    }

    function * deleteUserRoleById({payload}) {
        // const {group_id} = payload;
        try {
            const deleteRoleRes = yield call(deleteRoleById,payload);
            // console.log("getProfilerRes",getProfilerRes);
            if (deleteRoleRes.status) {

                // yield put(getProfilerSuccess(getProfilerRes.data));
                yield put(showSuccessMessage(deleteRoleRes.message));
            } else {
                yield put(showErrorMessage(deleteRoleRes.message));
            }
        } catch (error) {
            yield put(showErrorMessage(error));
        }
    }
    /* Role function call end */


    /* Role take every function start */
    export function * getrole() {
        yield takeEvery(GET_ROLE, getRoleById);
    }
    export function * addrole() {
        yield takeEvery(ADD_ROLE, addRoleByUser);
    }
    export function * deleterole() {
        yield takeEvery(DELETE_ROLE, deleteUserRoleById);
    }
    export function * addpermission() {
        yield takeEvery(ADD_PERMISSION, addPermissionByGroup);
    }
    export function * getpermission() {
        yield takeEvery(GET_PERMISSION_BY_ROLEID, getPermissionByRole);
    }
     /* Role take every function end */

    export default function * rootSaga() {
        yield all([
            fork(getrole),
            fork(addrole),
            fork(addpermission),
            fork(getpermission),
            fork(deleterole),
        ]);
    }
