import React from "react";
import {Route, Switch} from "react-router-dom";

import Components from "./components/index";
import CustomViews from "./customViews/index";
import Extensions from "./extensions/index";
import ExtraComponents from "./extraComponents/index";
import InBuiltApps from "./inBuiltApps/index";
import SocialApps from "./socialApps/index";
import Main from "./main/index";
import Documents from "./documents/index";
import asyncComponent from "util/asyncComponent";
import Dashboard from "./dashboard/Dashboard";
import Profiler from "./dashboard/profiler";
import Addprofiler from "./dashboard/Addprofiler";
import Updateprofiler from "./dashboard/Updateprofiler";
import Assessment from "./dashboard/Assessment";
import AssessmentList from "./dashboard/AssessmentList";
import Staff from './dashboard/Staff';
import Addstaff from './dashboard/Addstaff';
import Updatestaff from './dashboard/Updatestaff';
// import Role_Permission from './dashboard/settings/Role_Permission';
import Role_Permission from './dashboard/Role_Permission';
import updatePassword from './dashboard/updatePassword';
import ScrollToTop from "components/ScrollToTop"
import AssessmentDetails from "./dashboard/Assessment_detail"
import AssessmentFill from "./dashboard/Assessment_fill"
import FillAssessment from "./dashboard/Fill-Assessment"
import AssignAssessment from "./dashboard/AssignAssessment"
import ViewAssessment from "./dashboard/ViewAssessment"
import AssessmentDetailsview from "./dashboard/Assessment_detail_adminview"
import Assessmentdetailprofilerview from "./dashboard/Assessment_detail_profilerview"
import Report from './dashboard/Report'
import EditReport from './dashboard/Edit-Report'
import previewDocxForm from "./dashboard/previewDocx"

const App = ({match}) => (
  <div className="gx-main-content-wrapper">
    <ScrollToTop />
      <Switch>
        <Route path={`${match.url}dashboard`} component={Dashboard}/>
        <Route path={`${match.url}profiler`} component={Profiler}/>
        <Route path={`${match.url}add-profiler`} component={Addprofiler}/>
        <Route path={`${match.url}update-profiler`} component={Updateprofiler}/>
        <Route path={`${match.url}update-profile`} component={Updateprofiler}/>
        <Route path={`${match.url}add-assessment`} component={Assessment}/>
        <Route path={`${match.url}view-assessment`} component={ViewAssessment}/>
        <Route path={`${match.url}assessment`} component={AssessmentList}/>
        <Route path={`${match.url}staff`} component={Staff}/>
        <Route path={`${match.url}add-staff`} component={Addstaff}/>
        <Route path={`${match.url}update-staff`} component={Updatestaff}/>
        <Route path={`${match.url}updateProfile`} component={Updatestaff}/>
        <Route path={`${match.url}role-permission`} component={Role_Permission}/>
        <Route path={`${match.url}change-password`} component={updatePassword}/>
        <Route path={`${match.url}previewDocx`} component={previewDocxForm}/>
        {/* <Route path={`${match.url}settings/role-permission`} component={Role_Permission}/> */}
        
        <Route path={`${match.url}assessment-fill`} component={AssessmentFill}/>
        <Route path={`${match.url}fill-assessment`} component={FillAssessment}/>
        <Route path={`${match.url}assessment-detail`} component={AssessmentDetails}/>
        <Route path={`${match.url}profiler-assessment-view`} component={AssessmentDetailsview}/>
        <Route path={`${match.url}assessment-profiler-view`} component={Assessmentdetailprofilerview}/>
        <Route path={`${match.url}assigned-assessment`} component={AssignAssessment}/>
        <Route path={`${match.url}report`} component={Report}/>

        <Route path={`${match.url}edit-report`} component={EditReport}/>


        <Route path={`${match.url}main`} component={Main}/>
        <Route path={`${match.url}components`} component={Components}/>
        <Route path={`${match.url}custom-views`} component={CustomViews}/>
        <Route path={`${match.url}extensions`} component={Extensions}/>
        <Route path={`${match.url}extra-components`} component={ExtraComponents}/>
        <Route path={`${match.url}in-built-apps`} component={InBuiltApps}/>
        <Route path={`${match.url}social-apps`} component={SocialApps}/>
        <Route path={`${match.url}documents`} component={Documents}/>
        <Route path={`${match.url}/forgot-password`} component={asyncComponent(() => import('../containers/forgot-password'))}/>
      </Switch>
  </div>
);

export default App;
