    import {all, call, fork, put, takeEvery} from "redux-saga/effects";
    import axios from 'axios';
    import {baseURL, apikey} from 'util/config'
    import {

        ADD_ASSIGNED_ASSESSMENT,
        ADD_REPORT,
        GET_REPORTLIST
    } from "constants/ActionTypes";
    import {showErrorMessage, showSuccessMessage,getReportListData, showSuccessMessageWithLink, get_profiler} from "../actions/ReportActions";

    export const token = (state) => state.token;

    const headers = {
        'Content-Type': 'application/json',
        'X-API-KEY': apikey
    } 

    const headersWithToken = {
        'X-API-KEY': apikey,

    }
    
    /*Report Module Start Add, Get*/

    const getReportListyById = async(current,pageSize,id,filter,field,order) => await axios.get(baseURL + 'api/assessment/reportList?created_by='+id+'&start='+current+'&limit='+pageSize+'&filter='+filter+'&field='+field+'&order='+order, {headers: headersWithToken})
    .then(getReportRes => getReportRes.data)
    .catch(error => error);


    const AddReportForPublish = async(AssessmentName,CompanyName,POCName,profiler_id, assessment_id, company_id, status, data,header,footer) => await axios.post(baseURL + 'api/assessment/publishAssessmentReport', {
        "AssessmentName": AssessmentName,
        "CompanyName": CompanyName,
        "POCName": POCName,
        "profiler_id": profiler_id,
        "assessment_id": assessment_id,
        "company_id": company_id,
        "status": status,
        "data": data,
        "header": header,
        "footer": footer,
    }, {headers: headers})
        .then(assessment => assessment.data)
        .catch(error => error);


    /*Profiler Module END Add, Get*/


    //Report Module start ADD, GET
    
    function * getReportById({payload}) {
        const {current,pageSize,authUser,filter,field,order} = payload;
        try {
            const getReportById = yield call(getReportListyById,current,pageSize,authUser,filter,field,order);
            // console.log("getProfilerRes",getProfilerRes);
            if (getReportById.status) {

                yield put(getReportListData(getReportById.data));
                // yield put(showSuccessMessage(getProfilerRes.message));
            } else {
                yield put(showErrorMessage(getReportById.message));
            }
        } catch (error) {
            yield put(showErrorMessage(error));
        }
    }

    function * AddReport({payload}) {
        const {AssessmentName,CompanyName,POCName,profiler_id, assessment_id, company_id, status, data,header,footer} = payload;
        try {
            const AddReport = yield call(AddReportForPublish, AssessmentName,CompanyName,POCName,profiler_id, assessment_id, company_id, status, data,header,footer);
            // if (AddReport.mode != 2) {
                if (AddReport.status) {
                    if(AddReport.mode != 2){
                        yield put(showSuccessMessage(AddReport.message));
                    }
                    else{
                        yield put(showSuccessMessageWithLink(AddReport.pdflink));
                    }
                } else {
                    if(AddReport.mode != 2){
                    yield put(showErrorMessage(AddReport.message));
                    }
                }
            // }

        } catch (error) {
            yield put(showErrorMessage(error));
        }
    }




    //Profiler Module end ADD, GET


//take Every function call

    //Profiler Module start ADD, Get
    
    export function * getReport() {
        yield takeEvery(GET_REPORTLIST, getReportById);
    }
    
    export function * add_report(){
        yield takeEvery(ADD_REPORT, AddReport);
    }


    export default function * rootSaga() {
        yield all([
            
            fork(add_report),
            fork(getReport),
        ]);
    }
