import {
  HIDE_MESSAGE,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SHOW_SUCCESS_MESSAGE,
  STAFF_STATUS_SHOW_SUCCESS_MESSAGE,
  STAFF_SEND_ACTIVATION_LINK,

  ADD_STAFF,
  UPDATE_STAFF,
  GET_STAFF,
  GET_STAFF_SUCCESS_DATA,
  GET_ROLE,
  STAFF_USER_STATUS_CHANGE,
  DELETE_STAFF_USER_ROW,
  GET_STATUS_INITIAL

} from "constants/ActionTypes";


/* Staff Module start add, get, update, edit, delete */
export const SendUserActivationLink = (data) => {
  return {
    type: STAFF_SEND_ACTIVATION_LINK,
    payload: data
  };
};

export const add_staff = (user) => {
  return {
    type: ADD_STAFF,
    payload: user
  };
};
export const update_staff= (user) => {
  return {
    type: UPDATE_STAFF,
    payload: user
  };
};


export const getStaffSuccess = (data) => {
  return {
    type: GET_STAFF_SUCCESS_DATA,
    payload: data
  };
};

export const get_staff = (user) => {
  return {
    type: GET_STAFF,
    payload: user
  };
};

export const get_role = (user) => {
    return {
      type: GET_ROLE,
      payload: user
    };
  };

export const deleteUserById = (data) => {
  return {
    type: DELETE_STAFF_USER_ROW,
    payload: data
  };
};
export const setstatustoinitial = () => {
  return {
    type: GET_STATUS_INITIAL,
  };
}
export const showStaffLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};
export const statusStaffchange = (data) => {
    return { 
      type: STAFF_USER_STATUS_CHANGE,
      payload: data
    };
  };

// export const userStatusChange = (data) => {
//   return {
//     type: USER_STATUS_CHANGE,
//     payload: data
//   };
// };





/* Staff Module end add, get, update, edit, delete */

export const showErrorMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

export const showSuccessMessage = (message) => {
  return {
    type: STAFF_STATUS_SHOW_SUCCESS_MESSAGE,
    payload: message
  };
};


export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};
export const hideAuthLoader = () => {
  return {
    type: ON_HIDE_LOADER,
  };
};

