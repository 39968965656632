import {
    HIDE_MESSAGE,
    ON_HIDE_LOADER,
    ON_SHOW_LOADER,
    ASSESSMENT_SHOW_MESSAGE,
    ASSESSMENT_ADD_SHOW_SUCCESS_MESSAGE,
    GET_ASSESSMENT_SUCCESS_DATA,
    GET_ASSESSMENT_BYPROFILER_SUCCESS_DATA,
    GET_COMPANY_FOR_ASSESSMENT_SUCCESS_DATA,
    GET_STATUS_INITIAL,
    CLEAR_COMPANY_FOR_ASSESSMENT,
    CLEAR_ASSESSMENT_BYPROFILER,
    GET_METRIC_TABLE_SUCCESS_DATA,
    GET_ASSESSMENT_With_SUBMITED_DATA,
    GET_METRIC_GRAPH_SUCCESS_DATA,
    GET_ASSESSMENT_BY_PROFILER_DASHBOARD_DATA,
    GET_HISTORY_ASSIGNED_ASSESSMENT_DATA,
    CREATE_ASSESSMENT_EXCEL_SUCCESS
} from "constants/ActionTypes";

// import States from "../states/states";

const INIT_STATE = {
    loader: false,
    alertMessage: '',
    successMessage: '',
    showMessage: false,
    showSuccessMessage: false, 
    initURL: '',
    get_assessment_res : '',
    status : 'Initial',
    get_company_for_assessment : '',
    get_MetricTableData : '',
    getassessmentWithSubmited : '',
    get_MetricGraphData : '',
    get_history_assigned_assessment : '',
    get_assessment_profiler_dashboard : ''
};

export default(state = INIT_STATE, action) => {

    // if(States ===undefined){     return new States(); }
    switch (action.type) {

        case GET_ASSESSMENT_SUCCESS_DATA:
            {
                // console.log("payload",action.payload)
                return {
                    ...state,
                    // authUser: null,
                    get_assessment_res: action.payload,
                    loader: false,
                    status : 'Dataloaded',
                }
            }

        case ASSESSMENT_SHOW_MESSAGE: 
            {
                return {
                    ...state,
                    alertMessage: action.payload,
                    showMessage: true,
                    loader: true,
                    status : 'Initial',
                }
            }
        case GET_ASSESSMENT_BYPROFILER_SUCCESS_DATA:
        {
            // console.log("payload",action.payload)
            return {
                ...state,
                get_assessment_profiler : action.payload,
                loader: false,
                status : 'Dataloaded',
            }
        }
        case GET_ASSESSMENT_BY_PROFILER_DASHBOARD_DATA:
        {
            // console.log("payload",action.payload)
            return {
                ...state,
                get_assessment_profiler_dashboard : action.payload,
                loader: false,
                status : 'Dataloaded',
            }
        }
        case GET_ASSESSMENT_With_SUBMITED_DATA:
        {
            // console.log("payload",action.payload)
            return {
                ...state,
                getassessmentWithSubmited : action.payload,
                loader: false,
                status : 'Dataloaded',
            }
        }
        case GET_COMPANY_FOR_ASSESSMENT_SUCCESS_DATA:
        {
            // console.log("payload",action.payload)
            return {
                ...state,
                get_company_for_assessment : action.payload,
                loader: false,
                status : 'Dataloaded',
            }
        }

        case GET_HISTORY_ASSIGNED_ASSESSMENT_DATA:
        {
            // console.log("payload",action.payload)
            return {
                ...state,
                get_history_assigned_assessment : action.payload,
                loader: false,
                status : 'Dataloaded',
            }
        }

        case GET_METRIC_TABLE_SUCCESS_DATA:
        {
            // console.log("payload",action.payload)
            return {
                ...state,
                get_MetricTableData : action.payload,
                loader: false,
                status : 'Dataloaded',
            }
        }

        case GET_METRIC_GRAPH_SUCCESS_DATA:
        {
            // console.log("payload",action.payload)
            return {
                ...state,
                get_MetricGraphData : action.payload,
                loader: false,
                status : 'Dataloaded',
            }
        }

        case CLEAR_COMPANY_FOR_ASSESSMENT: 
            {
                // console.log("payload",action.payload)
                return {
                    ...state,
                    get_company_for_assessment : '',
                    // loader: false,
                    // status : 'Initial',
                }
            }
        case CLEAR_ASSESSMENT_BYPROFILER:
            {
                // console.log("payload",action.payload)
                return {
                    ...state,
                    get_assessment_profiler : '',
                    // loader: false,
                    // status : 'Initial',
                }
            }
            
        case GET_STATUS_INITIAL:
            {
                return{
                    ...state,
                    status : 'Initial',
                    loader: true,
                }
            }  
        case ASSESSMENT_ADD_SHOW_SUCCESS_MESSAGE:
            {
                return {
                    ...state,
                    // authUser: null,
                    successMessage: action.payload,
                    showSuccessMessage: true,
                    loader: true,
                    status : 'Initial',
                }
            }

        case HIDE_MESSAGE:
            {
                return {
                    ...state,
                    alertMessage: '',
                    successMessage: '',
                    showMessage: false,
                    showSuccessMessage: false,
                    loader: false
                }
            }

        case ON_SHOW_LOADER:
            {
                return {
                    ...state,
                    loader: true
                }
            }
        case ON_HIDE_LOADER:
            { 
                return {
                    ...state,
                    loader: false
                }
            }
        default:
            return state;
    }
}
// export default INIT_STATE;