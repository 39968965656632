import React, {useState,useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Avatar, Popover} from "antd";
import {userSignOut,update_user} from "appRedux/actions/Auth";	
import {Link, useHistory} from 'react-router-dom'
import {baseURL} from 'util/config'
import { getUserDetails } from "appRedux/actions/Auth";
// import {connect} from "react-redux";

// var user = localStorage.getItem('user_id');
// var users = JSON.parse(user);
import {  Popconfirm } from "antd";
const UserProfile = (props) => {
  
  const [isCustomizerOpened, setIsCustomizerOpened] = useState(false);
  // const dispatch = useDispatch();
  const dispatch = useDispatch();
  var authUser = useSelector(({auth}) => auth.authUser);
  var UserDetails = useSelector(({auth}) => auth.UserDetails);
  const themeType = useSelector(({settings}) => settings.themeType);
  const {auth} = props;
  const history = useHistory();	
  useEffect(() => {	
    //console.log("kunvis",authUser);	
    var localstore = localStorage.getItem("user_id");	
    localstore = JSON.parse(localstore);	
    if(localstore){	
      //console.log("kunvis",localstore);	
      if(localstore.id != authUser.id){	
        dispatch(update_user(localstore));	
        setTimeout(() => {	
          history.push('/dashboard');	
        }, 1000);	
      }	
    }	
    else{	
      dispatch(userSignOut())	
    }	
  });
  // alert("vishal");
  // useEffect(() => {
  //   // dispatch(getUserDetails(authUser.id))

  // },[]);
  // useEffect(() => {
  //   dispatch(getUserDetails(authUser.id))
  // }, [dispatch]);
  // useEffect(() => {
  //   window.addEventListener('resize', () => {
  //     dispatch(updateWindowWidth(window.innerWidth));
  //   })
  // }, [dispatch]);
  const userMenuOptions = ( 
    <ul className="gx-user-popover">
      {
      authUser.fname != '' &&  authUser.lname != '' && authUser.fname != '' 
      && authUser.poc_name != null
      ?
        <li  >
        <Link to={{
                pathname:"/update-profile",
                state :{authUser : authUser}
            }} 
        >
          My Account
        </Link>
      </li>  
    : 
    <li  >
        <Link to={{
                pathname:"/updateProfile",
                state :{authUser : authUser}
            }} 
        >
          My Account
        </Link>
      </li>
    }
      

      {/* <li>Connections</li> */}
      <li  >
        <Link to={{
                pathname:"change-password",
                // state :{authUser : authUser}
            }} 
        >
         Change Password
        </Link>
      </li>

      <li onClick={() => dispatch(userSignOut())}>Sign Out</li>
      {/* <li>
      <Popconfirm
        placement="bottomRight"
        title="Are you sure want to Logout?"
        onConfirm={() => dispatch(userSignOut())}
        okText="Yes"
        cancelText="No">
              Logout
        </Popconfirm>
      </li> */}
    </ul>
  );

  
  var companyName =  authUser.company_name  ? authUser.company_name : ''

  var fullName = authUser.full_name ? authUser.full_name : ''
  //console.log("authUser",authUser);
  if(authUser.companyName){
    //console.log("authUser2",authUser);
  }
  else{
    //console.log("authUser3",authUser);
  }
  if(authUser.full_name){
    //console.log("authUser4",fullName);
  }
  else{
    //console.log("authUser5",fullName);
  }
  return ( 

    <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
      <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
      <Avatar src={authUser.avatar ? authUser.avatar : baseURL+'/uploads/user/default2.png' }
                className="gx-size-40 gx-pointer gx-mr-3" alt=""/>
      {/* <Avatar src={authUser.avatar ? baseURL+'/uploads/user/'+authUser.avatar : baseURL+'/uploads/user/default2.png' }
                className="gx-size-40 gx-pointer gx-mr-3" alt=""/> */}
      {/* <Avatar src="https://wieldy.g-axon.work/static/media/alisha.10d664cb.jpeg"
                className="gx-size-40 gx-pointer gx-mr-3" alt=""/> */}
        {/* <Avatar src={require("assets/images/frandis.png")}
                className="gx-size-40 gx-pointer gx-mr-3" alt=""/> */}
        <span className="gx-avatar-name">{authUser.company_name ? companyName : fullName   }<i
          className="icon icon-chevron-down gx-fs-xxs gx-ml-2"/></span>
      </Popover>
    </div>

  )
};

const mapStateToProps =  state => {
  return { 
    authUser: state.auth.authUser 
      }; 
  };
export default UserProfile;
// export default connect(mapStateToProps)(UserProfile);
