import React, {Component} from "react";
import {
    Row,
    Col,
    Card,
    Typography,
    Popover,
    Form,
    Input,
    Table,
    Button,
    message,
    Popconfirm,
    Tabs,
    Select,
    notification
} from "antd";
// import { Link } from 'react-router-dom'
import Checkbox from './Mycomponents/check'
import {connect} from "react-redux";
import {showRoleLoader,get_role, add_role, hideMessage, deleteRoleById, addPermission,getPermissionByRoleID,setstatustoinitial} from "appRedux/actions/RolePermissionActions";
import CircularProgress from "components/CircularProgress/index";

const Dtext = 'Are you sure want to delete this Role?';
const hurl = "#";
const TabPane = Tabs.TabPane;
const {Title} = Typography;
const FormItem = Form.Item;
const {Option} = Select;

  var gval = [];
  var roledata = [];
class Role_Permission extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // advalue: 'Active',
            status_color: '#6BA539',
            data: [],
            pdata: [],
            id : [],
            checkAll: false,
            // columns : []
            popvisible: false,
            checkNick: false,
            handheld: true ,
            checkedItems: new Map(),
            all_per : [],
            group_id: '',
            stateset : true,
            role : '',
            
        };
        this.confirm = this
            .confirm
            .bind(this);
        this.deleteRole = this
            .deleteRole
            .bind(this);
            this.handleChange = this.handleChange.bind(this);
            this.handleRoleChange = this.handleRoleChange.bind(this);

    }
   
    callback = (key) => {
        console.log(key);
    };

    confirm() {
        message.info('Click on Yes.');
        // this.setState({advalue: 'Deactive', status_color: '#ff4d4f'});
    }

    deleteRole(key) {
        this.props.showRoleLoader();
        this.props.deleteRoleById(key);
        // setTimeout(() => {
        //     this.props.hideMessage();
        // }, 100);
        // message.success('Your record is deleted');
        // this.getRole()
    }

    handleSubmit = (e) => {  

        e.preventDefault();
        this
            .props
            .form
            .validateFieldsAndScroll((err, values, callback) => {
                if (!err) {
                    console.log('Received values of form: ', values);
                    const { role } = values;
                    // role.trim()

                    console.log(role.length)
                    if (role.trim() != ''){

                        var roledata = {role: role.trim()}
                        console.log(roledata)
                            this
                            .props
                            .add_role(roledata);
                            this.setState({popvisible: false});
                            
                            // message.success("Role is Added.")
                        setTimeout(() => {
                            this
                                .props
                                .hideMessage();
                                this.getRole()

                        }, 1000);
                       
                    }
                    else{

                        this 
                    .props
                    .form.setFieldsValue({['role']: ''});
                        // callback('Role is not add');
                    }

                    
                }
            });

    }

    // Permission assign submit
    handleRoleSubmit = (e) => {
        e.preventDefault();  
        

        var perdata = this.state.checkedItems;
        console.log("perdata", perdata)
        var permissiontruedata = [];
        perdata.forEach(function(key,value) {
            console.log("key",key,"perdata[key]",value)
            if(key){
                permissiontruedata.push(value)
            }
            
        })
        // Object.entries(perdata).map((t,k) => console.log("key",t,"perdata[key]",k)) 

      
        // console.log("permissiontruedata",permissiontruedata)
        // console.log("all_per",this.state.all_per)
        var data ={
            group_id : this.state.group_id,
            id : permissiontruedata
        }        
        this.props.addPermission(data);
        this.setState({all_per:''});
        gval =[];
        // message.success("Permission is Added.")
        //console.log("RoleSubmit",data);
    }

    handleVisibleChange = (popvisible) => {
        // console.log("popupchange");
        this.setState({popvisible: popvisible});
    }

    hide = () => {
        this.setState({popvisible: false});
        const form = this.props.form;
        // eslint-disable-next-line
        form.setFieldsValue({['role']: ''});
    }

    onChangepermission(e) {
        console.log("check", e.target.value);
    }

    getRole() {
        // alert(this.props.loader);
        if(this.props.status == 'Initial'){
            this.props.get_role(4);    
        }
        
    }

    //Permission push in array
    handleChange(e) {
        const item = e.target.name;
        const isChecked = e.target.checked;
        
        console.log("check value",isChecked)
        if(isChecked == true){
            gval.push(e.target.value);
            // console.log("Gval Push",gval)
        }
        else
        {
            const index = gval.indexOf(e.target.value);
            if (index > -1) {
                gval.splice(index, 1);
                // console.log("Gval Pop")
            }
            
        }
        // console.log("Gval Push",gval)
        this.setState({all_per:gval});
        //console.log("Gval id in state",this.state.all_per)
        this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(item, isChecked) }));
        
    }


    handleRoleChange(value) {
        // console.log("changerole",`selected ${value}`);
       // console.log("changerole",value);
        // this.props.showRoleLoader();
        this.setState({group_id : value,checkedItems:new Map(),stateset : true});
        this.props.getPermissionByRoleID(value);
        this.module_permission();

        // var permission1 = this.props.getPermissionData;
        // if(permission1.length > 0) {
        //     gval = [];
        //     this.setState({all_per : ''})
        //     permission1.map((key,value)=>{
        // gval.push(key.perm_id)
        // this.setState({all_per : gval})
        //     })
        //     console.log("all_per",this.state.all_per)
        // }

    }
    
    module_permission(){

        // this.setState({all_per : gval})
        // if(this.props.status == 'Initial'){
            if(this.state.stateset){
                var permission = this.props.getPermissionData;
                //console.log("changerolePermission",permission);
                // var permission_array = [];
                // gval = [];
                if(permission.length > 0) {
                        permission.map((key,value)=>{
                        
                            if(key.group_id == this.state.group_id){
                                if(key.perm_id == "93"){
                                    this.setState(prevState => ({ checkedItems: prevState.checkedItems.set("93", true) }));
                                    this.setState({stateset : false});
                                    // gval.push(93)
                                    // this.setState({all_per:gval});
                                    // gval = [];
                                }
                                else if(key.perm_id == "92"){
                                    this.setState(prevState => ({ checkedItems: prevState.checkedItems.set("92", true) }));
                                    this.setState({stateset : false});
                                    // gval.push(92)
                                    // this.setState({all_per:gval});
                                    // gval = [];
                                }
                                else if(key.perm_id == "97"){
                                    this.setState(prevState => ({ checkedItems: prevState.checkedItems.set("97", true) }));
                                    this.setState({stateset : false});
                                    // gval.push(97)
                                    // this.setState({all_per:gval});
                                    // gval = [];
                                }
                                else if(key.perm_id == "96"){
                                    this.setState(prevState => ({ checkedItems: prevState.checkedItems.set("96", true) }));
                                    this.setState({stateset : false});
                                    // gval.push(96)
                                    // this.setState({all_per:gval});
                                    // gval = [];
                                }
                                else if(key.perm_id == "98"){
                                    this.setState(prevState => ({ checkedItems: prevState.checkedItems.set("98", true) }));
                                    this.setState({stateset : false});
                                    // gval.push(98)
                                    // this.setState({all_per:gval});
                                    // gval = [];
                                } 
                                else if(key.perm_id == "112"){
                                    this.setState(prevState => ({ checkedItems: prevState.checkedItems.set("112", true) }));
                                    this.setState({stateset : false});
                                    // gval.push(98)
                                    // this.setState({all_per:gval});
                                    // gval = [];
                                } 
                            }
                            else if(key.group_id == this.state.group_id){
                            
                                if(key.perm_id == "93"){
                                    this.setState(prevState => ({ checkedItems: prevState.checkedItems.set("93", true) }));
                                    this.setState({stateset : false});
                                    // gval.push(93)
                                    // this.setState({all_per:gval});
                                    // gval = [];
                                }
                                else if(key.perm_id == "92"){
                                    this.setState(prevState => ({ checkedItems: prevState.checkedItems.set("92", true) }));
                                    this.setState({stateset : false});
                                    // gval.push(92)
                                    // this.setState({all_per:gval});
                                    // gval = [];
                                }
                                else if(key.perm_id == "97"){
                                    this.setState(prevState => ({ checkedItems: prevState.checkedItems.set("97", true) }));
                                    this.setState({stateset : false});
                                    // gval.push(97)
                                    // this.setState({all_per:gval});
                                    // gval = [];
                                }
                                else if(key.perm_id == "96"){
                                    this.setState(prevState => ({ checkedItems: prevState.checkedItems.set("96", true) }));
                                    this.setState({stateset : false});
                                    // gval.push(96)
                                    // this.setState({all_per:gval});
                                    // gval = [];
                                }
                                else if(key.perm_id == "98"){
                                    this.setState(prevState => ({ checkedItems: prevState.checkedItems.set("98", true) }));
                                    this.setState({stateset : false});
                                    // gval.push(98)
                                    // this.setState({all_per:gval});
                                    // gval = [];
                                } 
                                else if(key.perm_id == "112"){
                                    this.setState(prevState => ({ checkedItems: prevState.checkedItems.set("112", true) }));
                                    this.setState({stateset : false});
                                    // gval.push(98)
                                    // this.setState({all_per:gval});
                                    // gval = [];
                                } 
                            }
                            else{
        
                            }

                        })
                    }
                }
        // }
    }
    componentDidMount(){
        localStorage.setItem("progress", 100);

        setTimeout(() => {  
                    localStorage.setItem("progress", 0);
                  }, 2000);  
        this.props.setstatustoinitial();
    }
    componentDidUpdate(){
        if(this.props.showSuccessMessage || this.props.showMessage)
        {
            // this.get_profilerById()
                setTimeout(() => {
                    this.props.hideMessage();
                }, 100);
        }
        this.getRole();
        this.module_permission();
    }
    componentWillMount() {
            localStorage.setItem("progress", 40);
        if(typeof this.props.authUser != 'undefined'){

            var authpermission = this.props.authUser.permission;
            console.log("authpermission",authpermission);
            if(authpermission.length > 0){
                if(!authpermission.includes('98')){
                    this
                    .props
                    .history
                    .push("/dashboard");
                }
            }
        }
        // var authpermission = this.props.permission;
        // //console.log("authpermission",authpermission);
        // if(!authpermission.includes('98')){
        //     this
        //     .props
        //     .history
        //     .push("/dashboard");
        // }
        this.getRole();
    }

    validateRole = (e) => {
        // const form = props.form;
        console.log("e",e.keyCode);
        if (e.keyCode == 32) {
            return false;
        }
        else{
            
        }
        
    };

    render() {
        console.log("getPermissionData",this.props.getPermissionData);

        const {getFieldDecorator} = this.props.form;
        
        function isEmpty(obj) {
            for (var key in obj) {
                if (obj.hasOwnProperty(key)) 
                    return false;
                }
            return true;
        }
        var RoleData = this.props.getRoleData;
        var rdata = '';
        if (isEmpty(RoleData)) {
            // Object is empty (Would return true in this example)
        } else {
            // Object is NOT empty
            rdata = RoleData.group
            console.log("Role", rdata);
        }

        const text = <span>CREATE ROLE</span>;
        const layout = {
            labelCol: {
                span: 0
            },
            wrapperCol: {
                span: 24
            }
        };
        const tailLayout = {
            wrapperCol: {
                span: 24
            }
        };

       

        const content = (

            <Form {...layout} onSubmit={this.handleSubmit} name="basic">
                <FormItem >
                    {getFieldDecorator('role', {
                        rules: [
                            
                            {
                                required: true,
                                whitespace: true,
                                message: 'Please Enter Role!'
                                
                            }
                            
                        ],
                        // onChange: () => this.validateRole
                        // onChange:this.validateRole
                    })(<Input placeholder="Please Enter Role" id="role"/>)}
                </FormItem>
                <FormItem {...tailLayout}>
                    <Button className="custbtn" type="primary" htmlType="submit">ADD</Button>
                    {/* <Button className="custbtn" type="primary" onClick={this.hide}>CANCEL</Button> */}
                </FormItem>
            </Form>

        );

        const columns = [
            {
                title: 'Role',
                dataIndex: 'name',
                // width: 100,
            }, {
                title: 'Action',
                key: 'action',
                render: (text, record) => (
                    <span>
                        <Popconfirm
                            placement="top"
                            title={Dtext}
                            onConfirm={(e) => this.deleteRole(record.id)}
                            okText="YES"
                            cancelText="NO">
                            <a href={hurl}>
                                <i
                                    class="icon icon-trash"
                                    style={{
                                    color: "red"
                                }}></i>
                            </a>
                        </Popconfirm>
                    </span>
                )
            }
        ];

        const pcolumns = [
            {
                title: 'Module',
                dataIndex: 'module',
                // width: 100,
            }, {
                title: 'Permissions',
                key: 'permision',
                render: (text, record) => (
                    <span>
                        <FormItem key="handheld">
                            {getFieldDecorator('handheld', {
                                valuePropName: 'checked',
                            })(
                                <Checkbox value={this.state.handheld} onChange={(e) => this.onChangepermission(e,'handheld')}>
                                </Checkbox>
                            )}
                        </FormItem>
                    {/* <Checkbox
                           
                           onChange={(e) => this.onChangepermission(e,record.id)}
                            checked={this.state.checkAll}
                            /> */}


                    </span>
                )
            }

        ];
        const perdata = [
            {
                "key": 1,
                "module": 'Profiler',
                "gid": "93"

            }, {
                "key": 2,
                "module": 'Staff',
                "gid": "92"
            }, {
                "key": 3,
                "module": 'Assessment',
                "gid": "97"
            }, {
                "key": 4,
                "module": 'Reports',
                "gid": "96"
            },{
                "key": 5,
                "module": 'Report Approval',
                "gid": "112"
            }, {
                "key": 6,
                "module": 'Setting',
                "gid" : "98"
            }
        ];

        function isEmpty(obj) {
            for (var key in obj) {
                if (obj.hasOwnProperty(key)) 
                    return false;
                }
            return true;
        }
        var RoleData = this.props.getRoleData;
        
        if (isEmpty(RoleData)) {
            // Object is empty (Would return true in this example)
        } else {
            // Object is NOT empty
            roledata = RoleData.group
            console.log("Role", roledata);
        }

        return (

            <Card className="gx-card" title="Role & Permissions">
                
                {/* {this.props.showSuccessMessage ?
              message.success(this.props.successMessage.toString()) : null}
                        {this.props.showMessage ?
              message.error(this.props.alertMessage.toString()) : null} */}
              {/* {this.props.loader ?
                        <div className="gx-loader-view">
                            <CircularProgress/>
                        </div> : null}   
                        {this.props.loader ? null :  */}
                <Tabs defaultActiveKey="1" onChange={this.callback}>
                    <TabPane tab="Role" key="1">
                        <Row>
                            <Col span={16}>
                                <Title level={4}>Role Management</Title>
                            </Col>
                            <Col span={8}>
                                <Popover
                                    placement="left"
                                    title={text}
                                    visible={this.state.popvisible}
                                    onVisibleChange={this.handleVisibleChange}
                                    content={content}
                                    trigger="click">
                                    <Button
                                        style={{
                                        float: "right"
                                    }}
                                    type="primary" className="Addpermissionrole" icon="add"
                                        onClick={this.handleVisibleChange}><i className="icon icon-add"></i>
                                        ADD</Button >
                                </Popover>
                            </Col>
                        </Row>
                        <Table
                            className="gx-table-responsive"
                            loading ={this.props.loader}
                            columns={columns}
                            dataSource={rdata}
                            pagination={{
                            pageSize: 10,
                            hideOnSinglePage: true
                            }}
                        />
                    </TabPane>
                    <TabPane tab="Permissions" key="2">
                        <Form onSubmit={this.handleRoleSubmit}>
                            <Row>
                                <Col span={18}>
                                    <Title level={4}>Manage Permissions</Title>
                                </Col>
                                <Col span={4}>
                                
                                   <Select
                                        defaultValue="disabled"
                                        style={{
                                        width: 180,
                                        float: "right"
                                    }}
                                        onChange={this.handleRoleChange}
                                    >
                                        <Option key="0" value="disabled" disabled>Please Select Role</Option>
                                        {roledata.map(province => (
                                        <Option key={province.id}>{province.name}</Option>
                                        ))}
                                        {/* <Option value="disabled" disabled>Please Select Role</Option> */}
                                        {/* <Option value="5">Staff</Option>
                                        <Option value="6">Profiler</Option> */}
                                    </Select>

                                </Col>
                                <Col span={2}>
                                    <Form.Item>
                                        <Button disabled={!this.state.group_id} type="primary" htmlType="submit">SAVE</Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                            {/* <Table
                                className="gx-table-responsive"
                                columns={pcolumns}
                                dataSource={perdata}
                                pagination={{
                                pageSize: 10
                            }}/> */}
                            <div className="ant-table-content" span={24}>
                                <table  loading ={this.props.loader} className="gx-table-responsive" style={{width: "100%"}}  >
                                    <thead>
                                        <tr>
                                            <th className="ant-table-cell role_table">Module</th>
                                            <th className="ant-table-cell role_table">Permissions</th>
                                        </tr>
                                    </thead>
                                    <tbody className="ant-table-tbody">
                                    {
                                        perdata.map(item => (
                                           
                                            <tr key={item.key} data-row-key="0" className="ant-table-row ant-table-row-level-0">
                                                <td className="ant-table-cell role_table">{item.module}</td>
                                                <td className="ant-table-cell role_table">
                                                
                                                <Checkbox name={item.gid} value={item.gid} checked={this.state.checkedItems.get(item.gid)} onChange={this.handleChange} />

                                                </td>
                                            </tr> 
                                        ))
                                    }
                                       
                                    </tbody>
                                </table>
                            </div>
                            

                        </Form>
                    </TabPane>
                </Tabs> 
                {/* } */}
                {/* {this.props.showSuccessMessage
                    ? message.success(this.props.successMessage.toString())
                    : null}

                {this.props.showMessage
                    ? message.error(this.props.alertMessage.toString())
                    : null} */}

                {this.props.showSuccessMessage
                    ?
                    notification['success']({
                        message: 'success',
                        description: this.props.successMessage.toString()
                      })
                    : null}

                {this.props.showMessage 
                    ?
                    notification['error']({
                        message: 'error',
                        description: this.props.alertMessage.toString()
                      }) 
                    : null}
            </Card>
        );
    }
};

// export default Role_Permission;
const RegistrationForm = Form.create()(Role_Permission);
const mapDispatchToProps = {
    get_role: get_role,
    add_role: add_role,
    hideMessage: hideMessage,
    deleteRoleById: deleteRoleById,
    addPermission : addPermission,
    getPermissionByRoleID : getPermissionByRoleID,
    setstatustoinitial : setstatustoinitial,
    showRoleLoader : showRoleLoader
    // deleteUserById : deleteUserById
}

const mapStateToProps = state => {
    return {
        authUser: state.auth.authUser, 
        showSuccessMessage: state.rolePermissionReducers.showSuccessMessage, 
        showMessage: state.rolePermissionReducers.showMessage, 
        successMessage: state.rolePermissionReducers.successMessage, 
        alertMessage: state.rolePermissionReducers.alertMessage, 
        getRoleData: state.rolePermissionReducers.get_role_res,
        getPermissionData:state.rolePermissionReducers.get_per_res,
        getRoleData: state.rolePermissionReducers.get_role_res,
        status : state.rolePermissionReducers.status,
        loader : state.rolePermissionReducers.loader,
    }


};
export default connect(mapStateToProps, mapDispatchToProps)(RegistrationForm);