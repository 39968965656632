    import {all, call, fork, put, takeEvery} from "redux-saga/effects";
    import axios from 'axios';
    import {baseURL, apikey} from 'util/config'
    import {
        ADD_PROFILER,
        UPDATE_PROFILER,
        GET_PROFILER,
        PROFILER_DELETE_USER_ROW,
        PROFILER_USER_STATUS_CHANGE,
        PROFILER_SEND_ACTIVATION_LINK,
        GET_STAFF,
        ADD_ASSIGNED_ASSESSMENT,
        // ADD_REPORT
    } from "constants/ActionTypes";
    import {showErrorMessage, showSuccessMessage, getProfilerSuccess,getStaffSuccess, get_profiler} from "../actions/ProfilerActions";

    export const token = (state) => state.token;

    const headers = {
        'Content-Type': 'application/json',
        'X-API-KEY': apikey
    }

    const headersWithToken = {
        'Content-Type': 'application/json',
        'X-API-KEY': apikey,
        'X-Token' : token

    }
    
    /*Profiler Module Start Add, Get*/

    //add Profiler
    const addProfilerWithGroup = async(f_name,company_name, email, poc_name,  phone_number, address1,address2,city,pincode, note, no_of_assessment) => await axios.post(baseURL + 'api/user/adduserByProfiler', {
        "f_name" : f_name,
        "company_name": company_name,
        "email": email,
        "poc_name": poc_name,
        "no_of_assessment": no_of_assessment,
        "phone_number": phone_number,
        "address1": address1,
        "address2": address2,
        "city": city,
        "pincode": pincode,
        "note": note,
        "group": 6

    }, {headers: headers})
        .then(profilerRes => profilerRes.data)
        .catch(error => error);
    
    //updaqte profiler
    const addUpdateWithGroup = async(f_name, company_name, email, poc_name, phone_number, address1,address2,city,pincode, note, username, user_id, update_profile,no_of_assessment) => await axios.post(baseURL + 'api/user/updateuserByProfiler', {
        "f_name" : f_name,
        "company_name": company_name,
        "email": email,
        "poc_name": poc_name,
        "no_of_assessment": no_of_assessment,
        "phone_number": phone_number,
        "address1": address1,
        "address2": address2,
        "city": city,
        "pincode": pincode,
        "note": note,
        "username": username, 
        "id": user_id,
        "update_profile" : update_profile,
        // "password" : password
    }, {headers: headers})
        .then(updateProfilerRes => updateProfilerRes.data)
        .catch(error => error);

    //get Profiler
    const getProfilerByGroupId = async(group_id,filter,current,field,order) => await axios.get(baseURL + 'api/user/userbyrole?role='+group_id+'&filter='+filter+'&start='+current+"&sort_by="+field+"&sort_order="+order, {headers: headersWithToken})
        .then(getProfilerRes => getProfilerRes.data)
        .catch(error => error);

     //get Staff
     const getStaffByGroupId = async(group_id,filter,current) => await axios.get(baseURL + 'api/user/userbyrole?role='+group_id+'&filter='+filter+'&start='+current, {headers: headers})
     .then(getStaffRes => getStaffRes.data)
     .catch(error => error);
    
        
    // const getReportListyById = async(id) => await axios.get(baseURL + 'api/assessment/reportList?created_by='+id, {headers: headers})
    // .then(getReportRes => getReportRes.data)
    // .catch(error => error);

    const changeUserStatusById = async(id,status) => await axios.post(baseURL + 'api/user/set_status', {
        "id": id,
        "status": status
    }, {headers: headers})
        .then(statusRes => statusRes.data)
        .catch(error => error);

    const SendUSerActivationLink = async(email) => await axios.post(baseURL + 'api/user/Send_activation_link', {
        "email": email
    }, {headers: headers})
        .then(linksend => linksend.data)
        .catch(error => error);
        

    const AssignAssessmentToProfiler = async(profiler_id, assessment_id, max_assign_assessment) => await axios.post(baseURL + 'api/assessment/AssignAssessmentToProfiler', {
        "profiler_id": profiler_id,
        "assessment_id": assessment_id,
        "max_assign_assessment": max_assign_assessment
    }, {headers: headers})
        .then(assessment => assessment.data)
        .catch(error => error);

        
    // const AddReportForPublish = async(profiler_id, assessment_id, company_id, status, data) => await axios.post(baseURL + 'api/assessment/publishAssessmentReport', {
    //     "profiler_id": profiler_id,
    //     "assessment_id": assessment_id,
    //     "company_id": company_id,
    //     "status": status,
    //     "data": data,
    // }, {headers: headers})
    //     .then(assessment => assessment.data)
    //     .catch(error => error);

    const deleteUserByUserId = async(userid) => await axios.post(baseURL + 'api/user/delete', {
        "id": userid,
    }, {headers: headers})
        .then(userRes => userRes.data)
        .catch(error => error);
        
    /*Profiler Module END Add, Get*/


    //Profiler Module start ADD, GET
    function * addProfilerWithEmail({payload}) {
        const {
            f_name,
            company_name,
            email,
            poc_name,
            no_of_assessment,
            phone_number,
            address1,
            address2,
            city,
            pincode,
            note
        } = payload;
        try {
            const addProfilerRes = yield call(addProfilerWithGroup, f_name,company_name, email, poc_name, phone_number, address1,address2,city,pincode, note, no_of_assessment);
            if (addProfilerRes.status) {
                yield put(showSuccessMessage(addProfilerRes.message));
            } else {
                yield put(showErrorMessage(addProfilerRes.message));
            }
        } catch (error) {
            yield put(showErrorMessage(error));
        }
    }

    //updaqte profiler
    function * addupdateWithEmail({payload}) {
        const {
            f_name,
            company_name,
            email,
            poc_name,
            no_of_assessment,
            phone_number,
            address1,
            address2,
            city,
            pincode,
            note,
            username,
            userid,
            update_profile,
            // password
        } = payload;
        try {
            const addUpdateRes = yield call(addUpdateWithGroup, f_name, company_name, email, poc_name, phone_number, address1,address2,city,pincode, note, username, userid, update_profile,no_of_assessment);
            if (addUpdateRes.status) {
                yield put(showSuccessMessage(addUpdateRes.message));
            } else {
                yield put(showErrorMessage(addUpdateRes.message));
            }
        } catch (error) {
            yield put(showErrorMessage(error));
        }
    }

    function * getProfilerById({payload}) {
        const {group_id,filter,current,field,order} = payload;
        try {
            const getProfilerRes = yield call(getProfilerByGroupId,group_id,filter,current,field,order);
            // console.log("getProfilerRes",getProfilerRes);
            console.log('token',token);
            if (getProfilerRes.status) {

                yield put(getProfilerSuccess(getProfilerRes.data));
                // yield put(showSuccessMessage(getProfilerRes.message));
            } else {
                yield put(showErrorMessage(getProfilerRes.message));
            }
        } catch (error) {
            yield put(showErrorMessage(error));
        }
    }

    function * getStaffById({payload}) {
        const {group_id,filter,current} = payload;
        try {
            const getStaffRes = yield call(getStaffByGroupId,group_id,filter,current);
            // console.log("getProfilerRes",getProfilerRes);
            if (getStaffRes.status) {

                yield put(getStaffSuccess(getStaffRes.data));
                // yield put(showSuccessMessage(getProfilerRes.message));
            } else {
                yield put(showErrorMessage(getStaffRes.message));
            } 
        } catch (error) {
            yield put(showErrorMessage(error));
        }
    }

    
    // function * getReportById({payload}) {
    //     // const {group_id} = payload;
    //     try {
    //         const getReportById = yield call(getReportListyById,payload);
    //         // console.log("getProfilerRes",getProfilerRes);
    //         if (getReportById.status) {

    //             yield put(getReportListData(getReportById.data));
    //             // yield put(showSuccessMessage(getProfilerRes.message));
    //         } else {
    //             yield put(showErrorMessage(getReportById.message));
    //         }
    //     } catch (error) {
    //         yield put(showErrorMessage(error));
    //     }
    // }

    function * SendActivationLink({payload}) {
        const {email} = payload;
        try {
            const activationlink = yield call(SendUSerActivationLink, email);
            if (activationlink.status) {
                yield put(showSuccessMessage(activationlink.message));
            } else {
                yield put(showErrorMessage(activationlink.message));
            }
        } catch (error) {
            yield put(showErrorMessage(error));
        }
    }

    
    function * AddAssignAssessment({payload}) {
        const {profiler_id, assessment_id, max_assign_assessment} = payload;
        try {
            const assignAssessment = yield call(AssignAssessmentToProfiler, profiler_id, assessment_id, max_assign_assessment);
            if (assignAssessment.status) {
                yield put(showSuccessMessage(assignAssessment.message));
            } else {
                yield put(showErrorMessage(assignAssessment.message));
            }
        } catch (error) {
            yield put(showErrorMessage(error));
        }
    }
    
    // function * AddReport({payload}) {
    //     const {profiler_id, assessment_id, company_id, status, data} = payload;
    //     try {
    //         const AddReport = yield call(AddReportForPublish, profiler_id, assessment_id, company_id, status, data);
    //         if (AddReport.status) {
    //             yield put(showSuccessMessage(AddReport.message));
    //         } else {
    //             yield put(showErrorMessage(AddReport.message));
    //         }
    //     } catch (error) {
    //         yield put(showErrorMessage(error));
    //     }
    // }
    function * changeStatusById({payload}) {
        const {id, status} = payload;
        try {
            const getStatusRes = yield call(changeUserStatusById,id,status);
            // console.log("getProfilerRes",getProfilerRes);
            if (getStatusRes.status) {
                
                yield put(showSuccessMessage(getStatusRes.message));
                // yield put(get_profiler(6));
            } else {
                yield put(showErrorMessage(getStatusRes.message));
            }
        } catch (error) {
            yield put(showErrorMessage(error));
        }
    }

    function * deleteUserRowById({payload}) {
        // const {group_id} = payload;
        try {
            const getProfilerRes = yield call(deleteUserByUserId,payload);
            // console.log("getProfilerRes",getProfilerRes);
            if (getProfilerRes.status) {

                // yield put(getProfilerSuccess(getProfilerRes.data));
                yield put(showSuccessMessage(getProfilerRes.message));
            } else {
                yield put(showErrorMessage(getProfilerRes.message));
            }
        } catch (error) {
            yield put(showErrorMessage(error));
        }
    }
 
    //Profiler Module end ADD, GET


//take Every function call

    //Profiler Module start ADD, Get
    export function * addprofiler() {
        yield takeEvery(ADD_PROFILER, addProfilerWithEmail);
    }
    export function * updateprofiler() {
        yield takeEvery(UPDATE_PROFILER, addupdateWithEmail);
    }
    export function * getprofiler() {
        yield takeEvery(GET_PROFILER, getProfilerById);
    }
    export function * getstaff() {
        yield takeEvery(GET_STAFF, getStaffById);
    }
    
    // export function * getReport() {
    //     yield takeEvery(GET_REPORTLIST, getReportById);
    // }
    export function * deleteuser() {
        yield takeEvery(PROFILER_DELETE_USER_ROW, deleteUserRowById);
    }

    export function * statuschange() {
        yield takeEvery(PROFILER_USER_STATUS_CHANGE, changeStatusById);
    }
    
    export function * sendLink() {
        yield takeEvery(PROFILER_SEND_ACTIVATION_LINK, SendActivationLink);
    }

    export function * add_assignassessment(){
        yield takeEvery(ADD_ASSIGNED_ASSESSMENT, AddAssignAssessment);
    }
    
    // export function * add_report(){
    //     yield takeEvery(ADD_REPORT, AddReport);
    // }


    export default function * rootSaga() {
        yield all([
            
            fork(addprofiler),
            fork(updateprofiler),
            fork(getprofiler),
            fork(getstaff),
            fork(statuschange),
            fork(deleteuser),
            fork(sendLink),
            fork(add_assignassessment),
            // fork(add_report),
            // fork(getReport),
        ]);
    }
