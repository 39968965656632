import React, {Component} from "react";
import {Col, Row, Tabs, Pagination} from "antd";
import Widget from "components/Widget";
// import {aboutList} from '../../../routes/socialApps/Profile/data'
// import ProfileAssessmentTab from "../../../routes/dashboard/Mycomponents/ProfileAssessmentTab";
import {connect} from "react-redux";
import {Link} from 'react-router-dom'

// import {get_assessmentbyprofiler,showAssessmentLoader,hideMessage,setstatustoinitial,deleteAssessmentById,statusAssessmentchange,copydata} from "appRedux/actions/AssessmentActions";
// import CircularProgress from "components/CircularProgress/index";
import ProfileAssessmentTabItem from "../../../routes/dashboard/Mycomponents/ProfileAssessmentTabItem";
import {eventList} from "../../../routes/socialApps/Profile/data"

const TabPane = Tabs.TabPane;
var assessment = '';
var assessmentByProfiler = '';
// const ProfilerAssessment = () => {
class ProfilerAssessment extends Component {

        constructor(props) {
            super(props);
            this.myRef = React.createRef()  
            this.state = {
                status_value: 'Active',
                status_color: '#6BA539',
                Active_color : '#6BA539',
                Deactive_color: '#ff4d4f',
                data: [],
                authID : ''
            };
        }
        
        componentWillMount(){
            if(typeof this.props.authUser != 'undefined'){
                var authpermission = this.props.authUser.permission;
                this.setState({authID : this.props.authUser.id});
                //console.log("authpermission",authpermission);
                if(authpermission.length > 0){
                    if(!authpermission.includes('97')){
                        this.props.history.push("/dashboard");
                    }
                }
               
            }
            // var Assessmentdata = this.props.data;
            // //console.log("Assessmentdata",this.props.data)
        }
        componentDidMount(){
            // var Assessmentdata = this.props.data;
            // Assessmentdata.map((data, index) => {
            //     //console.log("Assessmentdata",data,index)
            // })
            // //console.log("Assessmentdata",Assessmentdata)
        }
    render() {

        function isEmpty(obj) {
            for (var key in obj) {
                if (obj.hasOwnProperty(key)) 
                    return false;
                }
            return true;
        }
        var RoleData = this.props.data;
        //console.log("RoleData", RoleData);
        if (isEmpty(RoleData)) {
            // Object is empty (Would return true in this example)
        } else {
            // Object is NOT empty
            assessmentByProfiler = RoleData.assessmentByProfiler
            //console.log("assessmentByProfiler1",assessmentByProfiler)

        }

        return (
        <Widget title="Assessment" styleName="gx-card-tabs gx-card-tabs-right gx-card-profile">
            <Tabs defaultActiveKey="1">
            <TabPane tab="Assessment" key="1">
                <div className="gx-pt-md-3">
                    {
                        assessmentByProfiler !=""
                    ?
                        assessmentByProfiler.map((data, index) =>
                        <ProfileAssessmentTabItem key={index} data={data}/>
                        )
                    :
                    'No Assessment Available'
                    }
                </div>
                {/* <Pagination defaultCurrent={6} total={500}/> */}
            </TabPane>
            <TabPane tab="Report" key="2">
                {/* <div className="gx-pt-md-3">
                    {eventList.map((data, index) =>
                    <ProfileAssessmentTabItem key={index} data={data}/>
                    )}
                </div>  */}
            </TabPane>
            </Tabs>
        </Widget>
        );
    }
}

// Object of action creators
const mapDispatchToProps = {
    // get_assessmentbyprofiler,
    // deleteAssessmentById,
    // statusAssessmentchange,
    // copydata,
    // hideMessage,
    // setstatustoinitial,
    // showAssessmentLoader
}

const mapStateToProps =  state => {
return { 
        // get_assessment_profiler: state.assessmentReducers.get_assessment_profiler ,
        // loader : state.assessmentReducers.loader,
        // showSuccessMessage : state.assessmentReducers.showSuccessMessage,
        // successMessage : state.assessmentReducers.successMessage,
        // showMessage : state.assessmentReducers.showMessage,
        // alertMessage : state.assessmentReducers.alertMessage,
        // authUser : state.auth.authUser,
        // status : state.assessmentReducers.status,
    }; 
};
assessmentByProfiler = ''
export default connect(mapStateToProps, mapDispatchToProps)(ProfilerAssessment);
// export default ProfilerAssessment;
