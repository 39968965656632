    import {all, call, fork, put, takeEvery} from "redux-saga/effects";
    import axios from 'axios';
    import {baseURL, apikey} from 'util/config'
    import {
        ADD_STAFF,
        UPDATE_STAFF,
        DELETE_STAFF_USER_ROW,
        STAFF_USER_STATUS_CHANGE,
        GET_STAFF,
        STAFF_SEND_ACTIVATION_LINK
    } from "constants/ActionTypes";
    import {showErrorMessage, showSuccessMessage,getStaffSuccess, get_staff} from "../actions/StaffActions";

    export const token = (state) => state.token;
 
    const headers = {
        'Content-Type': 'application/json',
        'X-API-KEY': apikey
    }

    const headersWithToken = {
        'Content-Type': 'application/json',
        'X-API-KEY': apikey,
        'X-Token' : token

    }
    
    /*Staff Module Start Add, Get*/

    const addStaffWithGroup = async(fname, lname, email, role) => await axios.post(baseURL + 'api/user/adduserByStaff', {
        "fname": fname,
        "lname": lname,
        "email": email,
        "group": role,
        "role": role
    }, {headers: headers})
        .then(profilerRes => profilerRes.data)
        .catch(error => error);

    const addUpdateStaffWithGroup = async(fname, lname, email, userid, username, role,update_profile,avatar) => await axios.post(baseURL + 'api/user/updateuserBystaff', {
        "fname": fname,
        "lname": lname,
        "email": email,
        "username": username,
        "group": role,
        "role": role,
        "id": userid,
        "update_profile" : update_profile,
        "avatar" : avatar
        // "password" : password
    }, {headers: headers})
        .then(updateStaffRes => updateStaffRes.data)
        .catch(error => error);

    //get Staff
    const getStaffByGroupId = async(group_id,filter,current,field,order) => await axios.get(baseURL + 'api/user/userbyrole?role='+group_id+'&filter='+filter+'&start='+current+"&sort_by="+field+"&sort_order="+order, {headers: headers})
        .then(getStaffRes => getStaffRes.data)
        .catch(error => error);

    const changeUserStatusById = async(id,status) => await axios.post(baseURL + 'api/user/set_status', {
        "id": id,
        "status": status
    }, {headers: headers})
        .then(statusRes => statusRes.data)
        .catch(error => error);

    const deleteUserByUserId = async(userid) => await axios.post(baseURL + 'api/user/delete', {
        "id": userid,
    }, {headers: headers})
        .then(userRes => userRes.data)
        .catch(error => error);

    const SendUSerActivationLink = async(email) => await axios.post(baseURL + 'api/user/Send_activation_link', {
        "email": email
    }, {headers: headers})
        .then(linksend => linksend.data)
        .catch(error => error);
        
    /*Staff Module END Add, Get*/


    //Staff Module start ADD, GET
    function * addStaffWithEmail({payload}) {
        const {
            fname,
            lname,
            email,
            role
        } = payload;
        try {
            const addProfilerRes = yield call(addStaffWithGroup, fname, lname, email, role);
            if (addProfilerRes.status) {
                yield put(showSuccessMessage(addProfilerRes.message));
            } else {
                yield put(showErrorMessage(addProfilerRes.message));
            }
        } catch (error) {
            yield put(showErrorMessage(error));
        }
    }
    
    function * addupdateStaffWithEmail({payload}) {
        const {
            fname,
            lname,
            email,
            username,
            role,
            userid,
            update_profile,
            avatar
            // password
        } = payload;
        try {
            const updateStaffRes = yield call(addUpdateStaffWithGroup, fname, lname, email, userid, username,role,update_profile,avatar);
            if (updateStaffRes.status) {
                yield put(showSuccessMessage(updateStaffRes.message));
            } else {
                yield put(showErrorMessage(updateStaffRes.message));
            }
        } catch (error) {
            yield put(showErrorMessage(error));
        }
    }

    function * getStaffById({payload}) {
        const {group_id,filter,current,field,order} = payload;
        try {
            const getStaffRes = yield call(getStaffByGroupId,group_id,filter,current,field,order);
            // console.log("getProfilerRes",getProfilerRes);
            if (getStaffRes.status) {

                yield put(getStaffSuccess(getStaffRes.data));
                // yield put(showSuccessMessage(getProfilerRes.message));
            } else {
                yield put(showErrorMessage(getStaffRes.message));
            } 
        } catch (error) {
            yield put(showErrorMessage(error));
        }
    }

    function * changeStatusById({payload}) {
        const {id, status} = payload;
        try {
            const getStatusRes = yield call(changeUserStatusById,id,status);
            // console.log("getProfilerRes",getProfilerRes);
            if (getStatusRes.status) {
                
                // yield put(getRoleSuccess(getRoleRes.data));
                yield put(showSuccessMessage(getStatusRes.message));
                // yield put(get_staff(6));
            } else {
                yield put(showErrorMessage(getStatusRes.message));
            }
        } catch (error) {
            yield put(showErrorMessage(error));
        }
    }

    function * deleteUserRowById({payload}) {
        // const {group_id} = payload;
        try {
            const getStaffRes = yield call(deleteUserByUserId,payload);
            // console.log("getProfilerRes",getProfilerRes);
            if (getStaffRes.status) {

                // yield put(getProfilerSuccess(getProfilerRes.data));
                yield put(showSuccessMessage(getStaffRes.message));
            } else {
                yield put(showErrorMessage(getStaffRes.message));
            }
        } catch (error) {
            yield put(showErrorMessage(error));
        }
    }
 
    function * SendActivationLink({payload}) {
        const {email} = payload;
        try {
            const activationlink = yield call(SendUSerActivationLink, email);
            if (activationlink.status) {
                yield put(showSuccessMessage(activationlink.message));
            } else {
                yield put(showErrorMessage(activationlink.message));
            }
        } catch (error) {
            yield put(showErrorMessage(error));
        }
    }
    //Staff Module end ADD, GET


//take Every function call

    //Staff Module start ADD, Get
    export function * addstaff() {
        yield takeEvery(ADD_STAFF, addStaffWithEmail);
    }
    export function * updatestaff() {
        yield takeEvery(UPDATE_STAFF, addupdateStaffWithEmail);
    }
    export function * getstaff() {
        yield takeEvery(GET_STAFF, getStaffById);
    }
    export function * deleteuser() {
        yield takeEvery(DELETE_STAFF_USER_ROW, deleteUserRowById);
    }
    export function * sendLink() {
        yield takeEvery(STAFF_SEND_ACTIVATION_LINK, SendActivationLink);
    }

    export function * statuschange() {
        yield takeEvery(STAFF_USER_STATUS_CHANGE, changeStatusById);
    }


    export default function * rootSaga() {
        yield all([
            
            fork(addstaff),
            fork(updatestaff),
            fork(getstaff),
            fork(statuschange),
            fork(deleteuser),
            fork(sendLink)
        ]);
    }
