import React, {Component} from "react";
import {
    Row,
    Card,
    Button,
    Col,
    Collapse,
    Input,
    Modal,
    Form,
    message,
    Avatar,
    Badge,
    notification, 
    Spin,
    Divider,
    Upload,
    Tooltip,
    Pagination
} from "antd";
import {Link} from 'react-router-dom'
import {connect} from "react-redux";
import {get_assessmentbyprofiler,get_assessmentbyprofilerDashboard,showAssessmentLoader,hideMessage,setstatustoinitial,deleteAssessmentById,statusAssessmentchange,copydata,add_assessment_for_company,update_assessment_for_company,getAssessmentForCompany} from "appRedux/actions/AssessmentActions";
import CircularProgress from "components/CircularProgress/index";
// import { SettingOutlined, PlusCircleFilled } from '@ant-design/icons';
import { SearchOutlined } from '@ant-design/icons';
const FormItem = Form.Item;
const Search = Input.Search;
// const Option = Select.Option;
const Panel = Collapse.Panel;
const text = <span>Change Logo!</span>;
function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}
 
function beforeUpload(file) {
  console.log("fileimage",file.type)
  console.log("fileimage",file)
  var isJPG = file.type === 'image/jpeg' || 'image/png' || 'image/jpg';
  var blank = file.type === '' || null;
  
  // console.log("fileimage",isJPG,blank)
  if(file.type === '' || null){
    message.error('You can only upload image file!');
    // return true;
  }
  else{
    if ('image/jpeg' == file.type || 'image/png' == file.type || 'image/jpg' == file.type) {
      // return true;
    }
    else{
      message.error('You can only upload image file!');
    }
    // return true;
    
  }
  
  // const isLt2M = file.size / 1024 / 1024 < 2;
  // if (!isLt2M) {
  //   message.error('Image must smaller than 2MB!');
  // }
  // return isJPG && isLt2M;

  return true;
}
var imagedata = ''
class FillAssessment extends Component {
    constructor(props) { 
        super(props);
        this.state = {
            status_value: 'Active',
            status_color: '#6BA539',
            Active_color : '#6BA539',
            Deactive_color: '#ff4d4f',
            data: [],
            authID : '',
            visible: false,
            confirmLoading: false,
            assessment_id : '',
            accordionshow : false,
            activekey : 0,
            fileList: [],
            previewImage : '',
            imagedata : '',
            current : 0,
            currentVal : 1
        };
        this.callback = this
            .callback
            .bind(this);
    }

    
   
    componentWillMount(){
          localStorage.setItem("progress", 40);
        if(typeof this.props.authUser != 'undefined'){
            var authpermission = this.props.authUser.permission;
            this.setState({authID : this.props.authUser.id});
            console.log("authpermission",authpermission);
            if(authpermission.length > 0){
                if(!authpermission.includes('97')){
                    this.props.history.push("/dashboard");
                }
            }
        }
    }
    componentDidMount(){
      localStorage.setItem("progress", 100);

        setTimeout(() => {  
                    localStorage.setItem("progress", 0);
                  }, 2000);  
        this.props.setstatustoinitial();
        if(this.props.status == 'Initial'){
            // this.props.get_assessmentbyprofiler({profiler_id : this.state.authID,type : 'publish', filter : '', current : this.state.current});

            this.props.get_assessmentbyprofilerDashboard({profiler_id : this.state.authID,type : 'publish', filter : '',current : this.state.current})
        }
    }
    componentDidUpdate(){
        // console.log("vishal",this.props.get_assessment_profiler_dashboard);
        
        if(this.props.status == 'Initial'){
            // this.props.get_assessmentbyprofiler({profiler_id : this.state.authID,type : 'publish', filter : '',current : this.state.current});

            this.props.get_assessmentbyprofilerDashboard({profiler_id : this.state.authID,type : 'publish', filter : '',current : this.state.current})
            // this.props.hideMessage();
        }
        if(this.props.showSuccessMessage || this.props.showMessage)
        {
            setTimeout(() => {
                this.props.hideMessage();
            }, 100);
        }
        // this.get_assessmentById();
    }
    callback(assessment_id,profiler_id,i){

      this.setState({
        activekey : i,
        "accordionshow" : true
      })
      var values = {
        "assessment_id" : assessment_id,
        "profiler_id" : profiler_id,
        
      }
        this.props.getAssessmentForCompany(values);
        console.log("callback",assessment_id,profiler_id)

        
      };
      
      showModal = (ids) => {
        this.setState({
          visible: true,
          assessment_id : ids
        });
        const form = this.props.form;
            form.setFieldsValue({['company_name']: '',['person_name'] : ''
            });
      };
      handleCancel = () => {
        console.log('Clicked cancel button');
        this.setState({
          visible: false,
        });
        const form = this.props.form;
            form.setFieldsValue({['company_name']: '',['person_name'] : ''
            });
      }

      handleOk = (e) => {

        e.preventDefault();

        this.props.form.validateFieldsAndScroll(['company_name','person_name'],(err, values) => {
          if (!err) {
            values.profiler_id = this.state.authID;
            values.assessment_id = this.state.assessment_id;
            console.log('AssessmentData',values)
            this.props.add_assessment_for_company(values); 
              setTimeout(() => {
                this.props.hideMessage();
                this.props.history.push("/fill-assessment");
              }, 2000);
            this.setState({
              ModalText: 'The modal will be closed after two seconds',
              confirmLoading: true,
            });
            setTimeout(() => {
              this.setState({
                visible: false,
                confirmLoading: false,
              });
            }, 1000);
            const form = this.props.form;
            form.setFieldsValue({['company_name']: '',['person_name'] : ''
            });
          }
        })
        
      };
      genExtra = (id) => (
            
        // this.setState({
        //     assessment_id : ids
        //   })

        <Button className="AddAssignAssessmentbtn" 
        onClick={ (id) =>{
            this.setState({
                visible: true,
                assessment_id : id
              });
            }
        }
        //   onClick={this.showModal}
        size="small"  type="ghost" >
        START NEW ASSESSMENT
        </Button>
        
      );

      // handleChange2 = info => {
      //   let fileList = [...info.fileList];
    
      //   // 1. Limit the number of uploaded files
      //   // Only to show two recent uploaded files, and old ones will be replaced by the new
      //   fileList = fileList.slice(-2);
    
      //   // 2. Read from response and show file link
      //   fileList = fileList.map(file => {
      //     if (file.response) {
      //       // Component will show file.url as link
      //       file.url = file.response.url;
      //     }
      //     return file;
      //   });
    
      //   this.setState({ fileList });
      //   console.log("fileList1",fileList)
      // };

      // handlePreview = file => {
      //   this.setState({
      //     previewImage: file.thumbUrl,
      //     previewVisible : true
      //   });
      // };
    handleChange1 = (info) => {
        console.log("imageUrl",info)
        if (info.file.status === 'uploading') {
          this.setState({loading: true});
          return;
        }
        if (info.file.status === 'done') {
            // this.setState({imageUrl : info,loading: false})
        //   Get this url from response in real world.
          getBase64(info.file.originFileObj, imageUrl => this.setState({
            imageUrl,
            loading: false,
          }));

        //   console.log("imageUrl",this.state.imageUrl)
        }
      };
      handleChange = ({ fileList },id) => {
        //---------------^^^^^----------------
        // this is equivalent to your "const img = event.target.files[0]"
        // here, antd is giving you an array of files, just like event.target.files
        // but the structure is a bit different that the original file
        // the original file is located at the `originFileObj` key of each of this files
        // so `event.target.files[0]` is actually fileList[0].originFileObj
        console.log('fileList', fileList);
        console.log('fileList', id);
        
        
        // you store them in state, so that you can make a http req with them later
        this.setState({ fileList });
        //  this.setState({'imagedata' : ''})
        imagedata = ''
        if(fileList.length > 0){

          var len = fileList.length - 1;
        
          if(typeof fileList[len].thumbUrl != 'undefined' || fileList[len].thumbUrl != '' || fileList[len].thumbUrl != null){

            // alert('out')

            if(fileList[len].type === '' || null){
              
            }
            else{
              if ('image/jpeg' == fileList[len].type || 'image/png' == fileList[len].type || 'image/jpg' == fileList[len].type) {
                this.setState({
                  // 'imagedata' : fileList[0].thumbUrl,
                  'comp_id' : id
                })
                imagedata = fileList[len].thumbUrl
                setTimeout(() => {
                this.imageuploadforcomp();
                },1000);
                
              }
              else{
                // message.error('You can only upload image file!');
              }
              
            }
      

            // alert('succsess')
            // this.setState({
            //   // 'imagedata' : fileList[0].thumbUrl,
            //   'comp_id' : id
            // })
            // imagedata = fileList[len].thumbUrl
            // setTimeout(() => {
            // this.imageuploadforcomp();
            // },1000);
            
          
        
          
          // for(var i = 0; i <= fileList.length; i++){

          //   if(i == fileList.length){

          //       if(typeof fileList[i].thumbUrl != 'undefined' || fileList[i].thumbUrl != '' || fileList[i].thumbUrl != null){

          //         this.setState({
          //           // 'imagedata' : fileList[0].thumbUrl,
          //           'comp_id' : id
          //         })
          //         imagedata = fileList[i].thumbUrl
          //         setTimeout(() => {
          //           this.imageuploadforcomp();
          //         },1000);
                  
          //       }
          //     }
          //   }
            
          
        }
        

        // message.success('upload image');
            
      };
    }

      imageuploadforcomp(){
        // var imagedata = fileList[0].thumbUrl\
        console.log("imagedata",imagedata)
        if(imagedata != ''){
          var values = {
            comp_id : this.state.comp_id,
            avatar : imagedata
          }
          console.log("imagedata",values)

          if(values.avatar != '' || values.avatar != null ){
            // alert('hii')
            this.props.update_assessment_for_company(values); 
            
            // this.props.history.push("/fill-assessment");
            
            // this.props.get_assessmentbyprofiler({profiler_id : this.state.authID,type : 'publish'});
            this.props.hideMessage();
            // setTimeout(() => {
            //   this.props.hideMessage();
            //   this.props.history.push("/fill-assessment");
            //   this.props.hideMessage();
            // },100);
            imagedata = ''
          }
          imagedata = ''
        }
        imagedata = ''
          
      }

      searchInputData = (val) => {
        console.log("searchInputData",val)

        this.setState({filter : val})
      

        // this.props.get_assessmentbyprofiler({profiler_id : this.state.authID,type : 'publish',filter : val,current : this.state.current});
        // this.props.get_reportList(values);
        // alert("search in put")
        this.props.get_assessmentbyprofilerDashboard({profiler_id : this.state.authID,type : 'publish', filter : val,current : this.state.current})

    }
    handleTableChange = (pagination, filters, sorter) => {
      console.log("pagination",pagination)


      var currents = 0;
      if(pagination>= 2){
              currents = (pagination - 1) * 10
              // currents = (pagination - 1) * 5
      }
      else{
           currents = 0;
      }

      this.setState({current : currents,currentVal : pagination})


      // this.props.get_assessmentbyprofiler({profiler_id : this.state.authID,type : 'publish', filter : '',current : currents});
      // this.props.get_reportList(values);
      // alert('handlechange')
      this.props.get_assessmentbyprofilerDashboard({profiler_id : this.state.authID,type : 'publish', filter : '',current : currents})

  };
    render() {
        
        console.log("vishal",this.props.get_assessment_profiler_dashboard);
        function isEmpty(obj) {
            for(var key in obj) {
                if(obj.hasOwnProperty(key))
                    return false;
            }
            return true;
        }
        var AssessmentForCompany = this.props.get_company_for_assessment;
        var CompanyData = '';
        var pagination = 0;
        if(isEmpty(AssessmentForCompany)){
            // Object is empty (Would return true in this example)
        }
        else  
        {
            // Object is NOT empty
            CompanyData = AssessmentForCompany
            console.log("AssessmentForCompany",CompanyData)
        }
        
        
        var pagination = 0;
        if(isEmpty(this.props.get_assessment_profiler_dashboard)){
            // Object is empty (Would return true in this example)
        }
        else  
        {
            // Object is NOT empty
            pagination = this.props.get_assessment_profiler_dashboard.paginationCount
            console.log("pagination",pagination)
        }
          const {visible, confirmLoading} = this.state;
        const {getFieldDecorator} = this.props.form;
    
        // const {autoCompleteResult} = this.state;
    
    
        const formItemLayout1 = {
            labelCol: {
              xs: {span: 24},
              sm: {span: 6},
            },
            wrapperCol: {
              xs: {span: 24},
              sm: {span: 16},
            },
          };
          var i = 1;

          // const props = {
          //   action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
          //   onChange: this.handleChange,
          //   multiple: false,
          // };

          const searchBtn = (
              <Search
              className="searchBtn"
              placeholder="Search"
              onSearch={(value) => this.searchInputData(value)}
              style={{width: 400, marginLeft:10,marginRight:10}}
              allowClear
            />
          );
          const {fileList,previewImage } = this.state;
        return (
          
            <>
             
                <Card className="gx-card  fillAssessmentcard" 
                loading ={this.props.loader}
                extra = {
                  <>
                    {searchBtn}
                  </>
                }
                title="Assessment"
                >
                    
                {
                this.props.loader ?
                <div className="gx-loader-view">
                    <CircularProgress/>
                </div> : null
                }
                {this.props.loader 
                ? 
                null 
                : 
                    this.props.get_assessment_profiler_dashboard 
                    ? 
                    
                    this.props.get_assessment_profiler_dashboard.assessmentByProfiler.length > 0
                    ?
                        this.props.get_assessment_profiler_dashboard.assessmentByProfiler.map((iteration)=>{
                        return iteration.assessment.length > 0 
                        ? 
                        <Collapse 
                         className="startAssessmentCollapse" 
                        //  accordion={this.state.accordionshow}
                        defaultActiveKey={[i]}
                        // activeKey = {this.state.activekey}
                        expandIconPosition="right" 
                        onChange={(e) => this.callback(iteration.assessment_id,iteration.profiler_id,i)}>
                          
                            <Panel header={iteration.assessment[0].name+' (No. Of Assessments - '+iteration.max_assign_assessment+')'} 

                            key={i++} 
                            extra={
                            
                              iteration.no_company > 0
                              ?
                                iteration.max_assign_assessment > iteration.no_company
                                ?

                                <Button className="AddAssignAssessmentbtn" 
                                    onClick={ () =>{
                                        this.setState({
                                            visible: true,
                                            assessment_id : iteration.assessment_id
                                        });
                                        }
                                    }
                                  //   onClick={this.showModal}
                                  size="small"  type="ghost" >
                                  START NEW ASSESSMENT
                                  </Button>
                                :
                                null
                              :
                              null
                              }
                              >
                                {/* {
                                  iteration.assessment[0].assessment_id == iteration.assessment_id
                                  ?
                                  this.callback(iteration.assessment_id,iteration.profiler_id)
                                  :
                                  ''
                                } */}
                                {
                                  // this.props.get_company_for_assessment
                                  iteration.no_company > 0
                                  ?
                                  iteration.company.map( iterations =>
                          
                                // <Card className="startAssessmentCard" >
                                  <>
                                    <Row className="startAssessmentCardRow cust-assest-row" >
                                      
                                        <Col span={2} className="antduploadlist">
                                        {/* <img src="https://wieldy.g-axon.work/static/media/a5.3ae38faf.png" width="40" height="40" /> */}
                                        {/* <Avatar src="https://wieldy.g-axon.work/static/media/alisha.10d664cb.jpeg"
                                        className="gx-size-40 gx-pointer gx-mr-3" alt=""/> */}

                                        {/* <Upload 
                                        // {...props}
                                        name="avatar"
                                        fileList={this.state.fileList}
                                        onPreview={this.handlePreview}
                                        onChange={this.handleChange}
                                        beforeUpload={() => false}
                                        
                                        >
                                          <Avatar src="https://wieldy.g-axon.work/static/media/alisha.10d664cb.jpeg"
                                          className="gx-size-40 gx-pointer gx-mr-3" alt=""/>
                                        </Upload> */}
                                        <Upload
                                        // name="avatar"
                                        listType="picture"
                                        // className="avatar-uploader"
                                        // showUploadList={false}
                                        fileList={fileList}
                                        showUploadList="false"
                                        // action="http://initzero.tech/kms/uploads/user/"
                                        // onPreview={this.handlePreview}
                                        onChange={(e)  => this.handleChange(e,iterations.id)}
                                        beforeUpload={beforeUpload}
                                        >
                                          <Tooltip placement="top" title={text}>
                                          <Avatar src={iterations.avatar ? iterations.avatar : 'https://via.placeholder.com/500X500/afafaf/000000?text=Click+Here!' }
                                          className="gx-size-50 gx-pointer gx-mr-3" alt=""/>
                                          </Tooltip>
                                        </Upload>
                                        </Col>
                                        <Col span={8}>
                                            <span>{iterations.company_name}</span>
                                        </Col>

                                        <Col span={5}>
                                          <span> {iterations.person_name}</span>
                                        </Col>
                                        <Col span={5}>
                                           {
                                            
                                              iterations.answer.length > 0 
                                              ?
                                                iterations.answer[0].status == 1
                                                ?
                                                `Submitted ${iterations.answer[0].created_datetime}`
                                                :
                                                `Last Saved ${iterations.answer[0].created_datetime}`
                                              :
                                              `Day Created ${iterations.created_datetime}`
                                          }
                                             {/* Assigned By {iterations.created_datetime} */}
                                        </Col>
                                        <Col span={4} className="cust-data-center">

                                        { 

                                          iterations.answer.length > 0 
                                          ?
                                            iterations.answer[0].status == 1
                                            ?
                                            <>
                                             <Link to={{ 
                                              pathname:"/assessment-profiler-view", 
                                              state : { 
                                                update : iteration,
                                                comp_id : iterations.id, 
                                                view : true
                                              }
                                             }} > 
                                              <Tooltip placement="top" title='View Submitted Assessment'>
                                                <i className="icon icon-view"></i>
                                              </Tooltip>
                                            {/* <Badge
                                                className="site-badge-count-109"
                                                count="PREVIEW"
                                                style={{
                                                backgroundColor: "#DC4405",
                                                width : "80px"
                                                }} 
                                            />*/}
                                            </Link>
                                             {'\u00A0'}|{'\u00A0'}
                                             <Badge
                                                className="site-badge-count-109"
                                                // abc={ this.setState({banned : record.banned}) }
                                                // count={this.state.status_value}
                                                count="SUBMITTED"
                                                style={{
                                                backgroundColor: this.state.Active_color,
                                                // width : "80px"
                                                }}
                                              />
                                           
                                            </>
                                            :
                                            <Link to={{ 
                                              pathname:"/assessment-detail", 
                                              state : {
                                                update : iteration,
                                              comp_id : iterations
                                              }
                                              }} > 
                                            <Button type="primary" className="cust-width-btn">RESUME</Button> 
                                            </Link>
                                          :
                                          <Link to={{
                                             pathname:"/assessment-detail", 
                                             state : {
                                              update : iteration,
                                              comp_id : iterations
                                             }}} > 
                                          <Button type="primary" className="cust-width-btn" >START</Button> 
                                          </Link>
                                       
                                        }
                                        
                                        </Col>
                                        
                                    </Row>
                                    {/* <Divider /> */}
                                  </>
                                // </Card>
                                )
                                :
                                <span>
                                <Button className="AddAssignAssessmentbtn btncenterAssessment" 
                                  onClick={ () =>{
                                      this.setState({
                                          visible: true,
                                          assessment_id : iteration.assessment_id
                                      });
                                      }
                                  }
                                //   onClick={this.showModal}
                                size="small"  type="ghost" >
                                START NEW ASSESSMENT
                                </Button>
                                </span>
                                }
                            </Panel>
                         
                        </Collapse>
                        : 
                        
                         null
                        
                    }) 
                    :
                    <span className="All_cust_noAssessment" ><span className="cust_noAssessment" >
                      <span className="cust_noAssessmentAvailable" >No Assessment Available</span> 
                    <span className="cust_noAssessmentkms" >Please contact Kansas Manufacturing Solutions to obtain access to an assessment.</span></span></span>
                : 
                null
              
                }
                {/* {alert(this.props.get_assessment_profiler_dashboard ? this.props.get_assessment_profiler_dashboard.paginationCount : 'no')} */}

                {
                 pagination > 10
                 ?
                    <Pagination 
                    current={this.state.currentVal} 
                    pageSize = '10'
                    
                    hideOnSinglePage = 'true'  
                    total={pagination}
                    // pagination={{
                    //   // current: 0,
                    //   // pageSize: 10,
                    //   // total : 50,
                    //   hideOnSinglePage: true
                    // }}
                    onChange={this.handleTableChange}
                      style={{
                        float : "right"
                      }}
                    />
                  :
                  null
                }
                </Card>

                <Modal title="Assessment"
                  visible={visible}
                  onOk={this.handleOk}
                  confirmLoading={confirmLoading}
                  onCancel={this.handleCancel}
                  width="900px"
                  footer={[
                    <Button key="back" onClick={this.handleCancel}>CANCEL</Button>,
                    <Button 
                      // disabled={!this.state.tabname || !this.state.tvalue ||
                      //   this.state.questionarysection ? !this.state.bestinclass || !this.state.positiverecommendations : null
                      // }  
                    type="primary" htmlType="submit" loading={this.state.loading} onClick={this.handleOk }> 
                      SUBMIT
                    </Button>
                  ]}
                  >
                    <Form onSubmit={this.handleOk}  >
                      {/* <Input.Group > */}
                        <FormItem
                          {...formItemLayout1}
                          label="Manufacturer Name"
                        >
                          {getFieldDecorator('company_name', {
                            rules: [{
                              required: true, 
                              message: 'Please enter the Manufacturer Name.',
                              whitespace: true
                            }],
                            // onChange : (e) => this.tabname(e.target.value)
                          })(
                            <Input placeholder="Please enter the Manufacturer Name." />
                            // <Input placeholder="Please enter Section Name" />
                          )}
                        </FormItem>

                        <FormItem
                        {...formItemLayout1}
                        label="Point of Contact"
                      >
                        {getFieldDecorator('person_name', {
                          rules: [{
                            required: true, 
                            message: 'Please enter the Point of Contact.',
                            whitespace: true
                          }],
                          // onChange : (e) => this.tabname(e.target.value)
                        })(
                          <Input placeholder="Please enter the Point of Contact." />
                          // <Input placeholder="Please enter Section Name" />
                        )}
                      </FormItem>
                      </Form>
                  </Modal>
        {/* {this.props.showSuccessMessage ?
              message.success(this.props.successMessage.toString()) : null}
        {this.props.showMessage ?
              message.error(this.props.alertMessage.toString()) : null}  */}
              {this.props.showSuccessMessage
                    ?
                    notification['success']({
                        message: 'success',
                        description: this.props.successMessage.toString()
                      })
                    : null}

                {this.props.showMessage 
                    ?
                    notification['error']({
                        message: 'error',
                        description: this.props.alertMessage.toString()
                      }) 
                    : null}
          </>
        );
    }
};

const FillAssessmentForm = Form.create()(FillAssessment);
// Object of action creators
const mapDispatchToProps = {
    get_assessmentbyprofiler,
    get_assessmentbyprofilerDashboard,
    deleteAssessmentById,
    statusAssessmentchange,
    copydata,
    hideMessage,
    setstatustoinitial,
    showAssessmentLoader,
    add_assessment_for_company,
    update_assessment_for_company,
    getAssessmentForCompany
}

const mapStateToProps =  state => {
return { 
        get_assessment_profiler: state.assessmentReducers.get_assessment_profiler ,
        loader : state.assessmentReducers.loader,
        showSuccessMessage : state.assessmentReducers.showSuccessMessage,
        successMessage : state.assessmentReducers.successMessage,
        showMessage : state.assessmentReducers.showMessage,
        alertMessage : state.assessmentReducers.alertMessage,
        authUser : state.auth.authUser,
        status : state.assessmentReducers.status,
        get_company_for_assessment : state.assessmentReducers.get_company_for_assessment,
        get_assessment_profiler_dashboard : state.assessmentReducers.get_assessment_profiler_dashboard
    }; 
};
// export default Staff;
export default connect(mapStateToProps,mapDispatchToProps)(FillAssessmentForm);
