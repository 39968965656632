import React, {Component} from "react";
// import {Col, Row, Card, Table, Button} from "antd"; import Auxiliary from
// "util/Auxiliary";
import {Link} from 'react-router-dom'
import {
    Button,
    Card,
    Col,
    Form,
    Input,
    Row,
    Select,
    message,
    notification
} from "antd";

import {connect} from "react-redux";
import {change_Password, hideMessage} from "appRedux/actions/Auth";

const FormItem = Form.Item;
const Option = Select.Option;
const {TextArea} = Input;

class updatePassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            confirmDirty: false,
            isUpdate: true,
            isAdd: true,
            userid: '',
            username: '',
            cardTitle: 'Change Password',
            editdata: '',
            disabled: '',
            staffData: ''
        };
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props
            .form
            .validateFieldsAndScroll(['password','confirm'],(err, values) => {

                if (!err) {
                    console.log('Received values of form: ', values);
                    // dispatch(add_profiler(values));
                    // if (this.state.isUpdate) {
                        values.userid = this.state.userid;
                        // values.username = this.state.username;
                        // values.update_profile = 1;
                        // console.log("values", values)
                        
                        this
                            .props
                            .change_Password(values);

                        setTimeout(() => {
                            this
                                .props
                                .hideMessage();
                                
                                var localstore = localStorage.getItem("user_id");	
                                localstore = JSON.parse(localstore);	
                                if(localstore){	
                                    if(localstore.group_id == 6){
                                        
                                        this
                                            .props
                                            .history
                                            .push("/fill-assessment");
                                    }
                                    else{
                                        this
                                            .props
                                            .history
                                            .push("/dashboard");
                                    }
                                }

                        }, 3000);

                    // } 

                }
            });

    }

    // validateToNextPassword = (rule, value, callback) => {
    //     const form = this.props.form;
    //     if (value && this.state.confirmDirty) {
    //         form.validateFields(['confirm'], {force: true});
    //     }
    //     callback();
    // }
    // get_staffById(){
    //     this.props.get_staff(5);
    // }

    componentWillMount(){
            localStorage.setItem("progress", 40);
        if(typeof this.props.authUser != 'undefined'){

            var authid = this.props.authUser.id;
            console.log("authpermission",authid);
            this.setState({userid: authid});
        }


    }


    componentDidMount() {
localStorage.setItem("progress", 100);

        setTimeout(() => {  
                    localStorage.setItem("progress", 0);
                  }, 2000);  

        // this.get_staffById();
        // let datarecord = this.props.location.state.authUser;
        // // eslint-disable-next-line
        // if (datarecord == "undefined" || datarecord == "" || datarecord == null) {
        //     // Object is empty (Would return true in this example)
        // } else {
        //     // Object is NOT empty
        //     console.log("datarecord", datarecord);
        //     const form = this.props.form;
        //     // eslint-disable-next-line
        //     form.setFieldsValue({['company_name']: datarecord.company_name,['email']: datarecord.email,['poc_name']: datarecord.poc_name,['phone_number']: datarecord.phone_number,['no_of_assessment']: datarecord.no_of_assessment,['address1']: datarecord.address1,['address2']: datarecord.address2,['city']: datarecord.city,['pincode']: datarecord.pincode,['note']: datarecord.note
        //     });
        //     this.setState({isUpdate: true, cardTitle: "Update Profiler", userid: datarecord.id, username: datarecord.username, disabled: "disabled"});
        // }
        
        

    }

    render() {

        const {getFieldDecorator} = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: {
                    span: 24
                },
                sm: {
                    span: 8
                }
            },
            wrapperCol: {
                xs: {
                    span: 24
                },
                sm: {
                    span: 12
                }
            }
        };
        const formItemLayout1 = {
            wrapperCol: {
                xs: {
                    span: 24
                },
                sm: {
                    span: 24
                }
            }
        };


        const compareToFirstPassword = (rule, value, callback) => {
            const form = this.props.form;
            if (value && value !== form.getFieldValue('password')) {
                // callback('Two passwords that you enter is inconsistent!');
                callback('The password is not matched!');
            } 
            else {
                callback();
            }
        };
    
        const validateToNextPassword = (rule, value, callback) => {
            const form = this.props.form;
            //var specialchar = new RegExp("^(?=.*?[#?!@$%^&*-<>])");
            // var specialchar = new RegExp("^(?=.*[@$!%*?&])");
            var specialchar = new RegExp("^(?=.*[ -\/:-@\[-\`{-~]{1,})");//for all special char
            if (value && this.props.confirmDirty) {
                
                form.validateFields(['confirm'], {force: true});

                
            }
            else{
                
                
                if (!value.match(specialchar)) {
                    console.log("value",value)
                    callback("Your password must contain at least one special character.");
                }
                callback();
            }
            
        };

        return (
            <Card className="gx-card add_profile_cust" title={this.state.cardTitle}>

                <Form onSubmit={this.handleSubmit}>

                    <FormItem
                    {...formItemLayout}
                    label="Password"
                    >
                        {getFieldDecorator('password', {
                            rules: [
                                
                                { min: 6, message: 'Please enter minimum 6 characters.' },
                                { max: 32, message: 'Please enter maximum 32 characters.' },
                                 {
                                    validator: validateToNextPassword
                                } ,
                                {
                                    required: true,
                                    message: 'Please enter your Password!.'
                                }
                                
                            ]
                        })(<Input type="password" placeholder="New Password"/>
                        )}
                    </FormItem>

                    <FormItem
                    {...formItemLayout}
                    label="Confirm Password"
                    >
                        {getFieldDecorator('confirm', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please enter Confirm Password'
                                }, {
                                    validator: compareToFirstPassword
                                }
                            ]
                        })(<Input placeholder="Confirm Password" type="password"/>)}
                    </FormItem>

                    <FormItem {...formItemLayout1}>
                        <Row>
                            <Col span={24} sm={8}></Col>
                            <Col span={24} sm={12}>
                                <Button type="primary" htmlType="submit">CHANGE PASSWORD</Button>
                                   
                            </Col>
                        </Row>
                    </FormItem>
                </Form>
                {/* {this.props.showSuccessMessage
                    ? message.success(this.props.successMessage.toString())
                    : null}

                {this.props.showMessage
                    ? message.error(this.props.alertMessage.toString())
                    : null} */}

                {this.props.showSuccessMessage
                    ?
                    notification['success']({
                        message: 'success',
                        description: this.props.successMessage.toString()
                      })
                    : null}

                {this.props.showMessage 
                    ?
                    notification['error']({
                        message: 'error',
                        description: this.props.alertMessage.toString()
                      }) 
                    : null}
            </Card>
        );
    }

}
const mapStateToProps = state => {
    return {
        authUser: state.auth.authUser, 
        showSuccessMessage: state.auth.showSuccessMessage,
        showMessage: state.auth.showMessage, 
        successMessage: state.auth.successMessage, 
        alertMessage: state.auth.alertMessage
    }
};

// const {successMessage, alertMessage, showMessage, showSuccessMessage} =
// useSelector(({auth}) => auth); Object of action creators
const mapDispatchToProps = {
    change_Password: change_Password,
    hideMessage: hideMessage,
}
const RegistrationForm = Form.create()(updatePassword);
// export default RegistrationForm;
export default connect(mapStateToProps, mapDispatchToProps)(RegistrationForm);
