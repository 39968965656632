import React, {Component} from "react";
// import {Col, Row, Card, Table, Button} from "antd"; import Auxiliary from
// "util/Auxiliary";
import {Link} from 'react-router-dom'
import {
    Button,
    Card,
    Col,
    Form,
    Input,
    Row,
    Select,
    message,
    Upload,
    Icon,
    notification,
    Modal
} from "antd";

import {connect} from "react-redux";
import {add_profiler, get_staff} from "appRedux/actions/ProfilerActions";
import {hideMessage,update_profiler_user} from "appRedux/actions/AssessmentActions";
import { getUserDetails,changestatus,update_user,auth } from "appRedux/actions/Auth";

const FormItem = Form.Item;
const Option = Select.Option;
const {TextArea} = Input;
function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }
   
//   function beforeUpload(file) {
//     const isJPG = file.type === 'image/jpeg';
//     if (!isJPG) {
//       message.error('You can only upload JPG file!');
//     }
//     const isLt2M = file.size / 1024 / 1024 < 2;
//     if (!isLt2M) {
//       message.error('Image must smaller than 2MB!');
//     }
//     return isJPG && isLt2M;
//   }
function beforeUpload(file) {
    // const isJPG = file.type === 'image/jpeg';
    // if (!isJPG) {
    //   message.error('You can only upload JPG file!');
    // }
    
    // return isJPG && isLt2M;

    if(file.type === '' || null){
        message.error('You can only upload image file!');
    // return true;
    }
    else{
        if ('image/jpeg' == file.type || 'image/png' == file.type || 'image/jpg' == file.type) {
            
        }
        else{
            message.error('You can only upload image file!');
        }
    }
  }
class Updateprofiler extends Component {

    constructor(props) {
        super(props);
        this.state = {
            confirmDirty: false,
            company_name: '',
            email: 'demo@example.com',
            poc_name: '',
            no_of_assessment: '',
            phone_number: '',
            address1: '',
            address2: '',
            city: '',
            pincode: '',
            note: '',
            isUpdate: true,
            isAdd: true,
            userid: '',
            username: '',
            cardTitle: 'Update Profile',
            editdata: '',
            disabled: '',
            staffData: '',
            loading: false,
            previewVisible: false,
            previewImage: "",
            fileList: []
        };
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this
            .props
            .form
            .validateFieldsAndScroll((err, values) => {

                if (!err) {
                    if(this.state.fileList.length > 0){
                        values.avatar = this.state.fileList[0].thumbUrl;
                    }
                    else
                    {
                        values.avatar = null;//baseURL+'/uploads/user/default2.png';
                    }
                    console.log('Received values of form: ', values);
                    // dispatch(add_profiler(values));
                    if (this.state.isUpdate) {
                        
                        var localstore = localStorage.getItem("user_id");
                        localstore = JSON.parse(localstore);
                        if(this.state.fileList.length > 0){
                            localstore.avatar = values.avatar
                            }
                        localstore.fname = values.fname
                        localstore.lname = values.lname
                        localstore.company_name = values.company_name
                        localStorage.setItem("user_id",JSON.stringify(localstore));
                        console.log(JSON.stringify(localstore));
                        this.props.update_user(localstore);

 
                        values.no_of_assessment = this.state.no_of_assessment;
                        values.userid = this.state.userid;
                        values.username = this.state.username;
                        values.update_profile = 1;
                        // values.full_name = values.full_name;
                        console.log("values", values)
                        // console.log("localuset",localStorage.getItem('user_id'));
 
                        // var abc = {email:"kpsahani9@yopmail.com"}
                        // localStorage.setItem('user_id', JSON.stringify(abc));
                
                        // console.log("localuget",JSON.parse(localStorage.getItem('user_id')))
                        this
                            .props
                            .update_profiler_user(values);

                        setTimeout(() => {
                            this
                                .props
                                .hideMessage();

                    var localstore = localStorage.getItem("user_id");	
                    localstore = JSON.parse(localstore);	
                    if(localstore){	
                        if(localstore.group_id == 6){
                            
                            this
                                .props
                                .history
                                .push("/fill-assessment");
                        }
                        else{
                            this
                                .props
                                .history
                                .push("/dashboard");
                        }
                    }
                            
                            

                        }, 3000);

                    } 

                }
            });

    }

    validateToNextPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirm'], {force: true});
        }
        callback();
    }
    get_staffById(){
        var val = {
            group_id : 5,
            filter : '',
            current : 0
        }
        this.props.get_staff(val);
    }
  
    componentWillMount(){
            localStorage.setItem("progress", 40);
        // var authpermission = this.props.permission;
        // console.log("authpermission",authpermission);
        // if(!authpermission.includes('93')){
        //     this
        //     .props
        //     .history
        //     .push("/dashboard");
        // }
        console.log("authUser",this.props.location.state.authUser);
        // this.getallData();
    }

    componentDidUpdate(){
        // alert(this.props.status);
        if(this.props.status == 'Updatedataloaded' || this.props.status == ''){
            this.props.changestatus('updatedata');
            this.getallData();
        }
    }

    getallData(){

        
        var datarecord = this.props.AuthDetails;
        let datarecords = this.props.location.state.authUser;
        
        // eslint-disable-next-line
        if (datarecords == "undefined" || datarecords == "" || datarecords == null) {
            // Object is empty (Would return true in this example)
        } else {
            // Object is NOT empty
            console.log("datarecord", datarecord);
            
            if(this.props.status == ''){
                this.props.getUserDetails(datarecords.id);
            }
            if(typeof this.props.AuthDetails != 'undefined' || datarecord == "undefined" || datarecord == "" || datarecord == null){
                
                console.log("AuthDetailss",datarecord);
                const form = this.props.form;
                // eslint-disable-next-line
                form.setFieldsValue({['full_name']: datarecord.full_name,['company_name']: datarecord.company_name,['email']: datarecord.email,['poc_name']: datarecord.poc_name,['phone_number']: datarecord.phone_number,['no_of_assessment']: datarecord.no_of_assessment,['address1']: datarecord.address1,['address2']: datarecord.address2,['city']: datarecord.city,['pincode']: datarecord.pincode,['note']: datarecord.note
                });
                this.setState({isUpdate: true, cardTitle: "Update Profile", userid: datarecord.id, username: datarecord.username, disabled: "disabled",no_of_assessment : datarecord.no_of_assessment });

            }
            
        }
    }

    componentDidMount() {
        localStorage.setItem("progress", 100);

        setTimeout(() => {  
                    localStorage.setItem("progress", 0);
                  }, 2000);  

        this.getallData();
        this.get_staffById();
        this.props.hideMessage();
        this.props.changestatus('');
    }
    handlePreview = file => {
        this.setState({
          previewImage: file.thumbUrl,
          previewVisible : true
        });
      };
    handleChange1 = (info) => {
        console.log("imageUrl",info)
        if (info.file.status === 'uploading') {
          this.setState({loading: true});
          return;
        }
        if (info.file.status === 'done') {
            // this.setState({imageUrl : info,loading: false})
        //   Get this url from response in real world.
          getBase64(info.file.originFileObj, imageUrl => this.setState({
            imageUrl,
            loading: false,
          }));

        //   console.log("imageUrl",this.state.imageUrl)
        }
      };
      handleChange = ({ fileList }) => {
        //---------------^^^^^----------------
        // this is equivalent to your "const img = event.target.files[0]"
        // here, antd is giving you an array of files, just like event.target.files
        // but the structure is a bit different that the original file
        // the original file is located at the `originFileObj` key of each of this files
        // so `event.target.files[0]` is actually fileList[0].originFileObj
        // const isJPG = fileList.type === 'image/jpeg';
        // const isLt2M = fileList.size / 1024 / 1024 < 2;
        // if (!isJPG) {
        // message.error('You can only upload JPG file!');
        // }
        // else if (!isLt2M) {
        // message.error('Image must smaller than 2MB!');
        // }
        // console.log('fileList1', fileList);
        if(fileList.type === '' || null){
            // return true;
        }
        else{
            // console.log('fileList2', fileList);
            if (fileList.type == 'image/jpeg' || 'image/png' || 'image/jpg') {
              // return true;
                if(fileList.length > 0){
                    fileList[0].status = 'success'
                }
              
              this.setState({ fileList });
              // return isJPG && isLt2M;
              console.log('fileList', fileList);
            }
           
        }
        
            // this.setState({ fileList });
            // // return isJPG && isLt2M;
            // console.log('fileList', fileList);
        // you store them in state, so that you can make a http req with them later
       
      };

      
    handleCancel = () => this.setState({ previewVisible: false })
    render() {
        var staff = this.props.getStaffData.user

        const {getFieldDecorator} = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: {
                    span: 24
                },
                sm: {
                    span: 8
                }
            },
            wrapperCol: {
                xs: {
                    span: 24
                },
                sm: {
                    span: 12
                }
            }
        };
        const formItemLayout1 = {
            wrapperCol: {
                xs: {
                    span: 24
                },
                sm: {
                    span: 24
                }
            }
        };

        // var staff = this.props.getStaffData.user
        // const prefixSelectorStaff = getFieldDecorator('prefix', {initialValue: ''})(
        //     <Select>
        //         {/* {
        //             // console.log("getStaffData",this.props.getStaffData.user)
                    
        //             // if(typeof staff != 'undefined'){
            
        //             //     console.log("getStaff",staff)
        //                 // this.setState({staffData : staff});
                    
        //         } */}
        //         {typeof staff != 'undefined'  ? staff.map((key,val)=>{
        //                    return ( <Option value={key.id}>{key.full_name}</Option> )
        //                 }) : '' }
                    
                        
                
                
        //         {/* <Option value="1">+1</Option> */}
        //     </Select>
        // );

        const prefixSelector = getFieldDecorator('prefix', {initialValue: '1'})(
            <Select style={{
                width: 70
            }}>
                <Option value="1">+1</Option>
            </Select>
        );

        // const compareToFirstPassword = (rule, value, callback) => {
        //     const form = this.props.form;
        //     if (value && value !== form.getFieldValue('password')) {
        //         // callback('Two passwords that you enter is inconsistent!');
        //         callback('The password is not matched!');
        //     } 
        //     else {
        //         callback();
        //     }
        // };
    
        // const validateToNextPassword = (rule, value, callback) => {
        //     const form = this.props.form;
        //     if (value && this.props.confirmDirty) {
        //         form.validateFields(['confirm'], {force: true});
        //     }
        //     else{
        //         callback();
        //     }
            
        // };
        const uploadButton = (
            <div>
              <Icon type={this.state.loading ? 'loading' : 'plus'}/>
              <div className="ant-upload-text">Upload</div>
            </div>
          );
        const imageUrl = this.state.imageUrl;
        const {previewImage, fileList,previewVisible } = this.state;
        return (
            <Card className="gx-card add_profile_cust" title={this.state.cardTitle}>
                {/* <Link to="/profiler" className="add_profile_back">
                    <Button type="ghost" icon="left">
                        <i className="icon icon-left"></i>
                        BACK
                    </Button>
                </Link> */}
                <Form onSubmit={this.handleSubmit}>
                <FormItem {...formItemLayout} label="Company Logo">
                        <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            // showUploadList={false}
                            fileList={fileList}
                            // action="http://initzero.tech/kms/uploads/user/"
                            onPreview={this.handlePreview}
                            onChange={this.handleChange}
                            // beforeUpload={() => false}
                            beforeUpload={beforeUpload}
                            >
                            {fileList.length == 1 ? null : uploadButton}
                            </Upload>
                            <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                            <img alt="example" style={{ width: '100%' }} src={previewImage} />
                            </Modal>
                            
                    </FormItem>
                    <FormItem {...formItemLayout} label="Name">
                        {getFieldDecorator('company_name', {
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: 'Please enter your Name!'
                                }
                            ]
                        })(<Input id="email1"/>)}
                    </FormItem>
                    <FormItem {...formItemLayout} label="Company name">
                        {getFieldDecorator('full_name', {
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: 'Please enter your Company Name!'
                                }
                            ]
                        })(<Input id="email1"/>)}
                    </FormItem>
                    <FormItem {...formItemLayout} label="E-mail">
                        {getFieldDecorator('email', {
                            rules: [
                                {
                                    type: 'email',
                                    message: 'The enter is not valid E-mail!'
                                }, {
                                    required: true,
                                    whitespace: true,
                                    message: 'Please enter your E-mail!'
                                }
                            ]
                        })(<Input id="email1" disabled={this.state.disabled}/>)}
                    </FormItem>
                    {/* {prefixSelector} */}
                    {/* <FormItem {...formItemLayout} label="POC Name">
                        {getFieldDecorator('poc_name', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please enter your POC Name!',
                                    whitespace: true
                                }
                            ]
                        })({prefixSelectorStaff})}
                    </FormItem> */}

                    <FormItem
                        {...formItemLayout} label="KMS POC"
                    >
                        {getFieldDecorator('poc_name',{
                        // initialValue: 60,
                        rules: [
                            {
                                required: true,
                                message: 'Please enter your POC Name!',
                                whitespace: true
                            }
                        ],
                        })(
                        <Select
                            // placeholder="Select a POC"
                            disabled={this.state.disabled}
                            // onChange={this.handleSelectChange}
                        >
                            {/* <Option key="0" value="0" disabled >Please Select Role</Option> */}
                            { 
                            typeof staff != 'undefined'  
                            ? 
                            
                            staff.map((key,val)=>{
                            
                                if(key.banned == true){
                                    return ( <Option value={key.id} selected>{key.full_name}</Option> )
                                }
                            }) 
                            : 
                            '' 
                            } 

                        </Select>
                        )}
                    </FormItem>

                    {/* <FormItem {...formItemLayout} label="No. Of Assessment">
                        {getFieldDecorator('no_of_assessment', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please enter No. Of Assessment!',
                                    whitespace: true
                                }    
                            ]
                        })(<Input/>)}
                    </FormItem> */}

                    <FormItem {...formItemLayout} label="Phone Number">
                        {getFieldDecorator('phone_number', {
                            rules: [
                                {   
                                    required: true,
                                    pattern: new RegExp("^[0-9]*$"),
                                    message: 'Please enter only digits',
                                },
                                {   min: 10,
                                    message: 'Please enter minimum 10 digits.' 
                                },
                                {   max: 10, 
                                    message: 'Please enter maximum 10 digits.' 
                                },

                            ]
                        })(<Input
                            addonBefore={prefixSelector}
                            style={{
                            width: '100%'
                        }}/>)}
                    </FormItem>

                    <FormItem {...formItemLayout} label="Address 1">
                        {getFieldDecorator('address1', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please enter your Address 1!',
                                    whitespace: true
                                }
                            ]
                        })(<Input/>)}
                    </FormItem>

                    {/* <FormItem {...formItemLayout} label="Address 2">
                        {getFieldDecorator('address2', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please enter your Address 2!',
                                    whitespace: true
                                }
                            ]
                        })(<TextArea rows={4}/>)}
                    </FormItem> */}
                    <FormItem {...formItemLayout} label="Address 2">
                        {getFieldDecorator('address2', {
                            rules: [
                                {
                                    required: false,
                                    // message: 'Please enter your Address 2!',
                                    whitespace: true
                                }
                            ]
                        })(<Input/>)}
                    </FormItem>
                    
                    <FormItem {...formItemLayout} label="City">
                        {getFieldDecorator('city', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please enter City',
                                    whitespace: true
                                }
                            ]
                        })(<Input/>)}
                    </FormItem>

                    <FormItem {...formItemLayout} label="Zip Code">
                        {getFieldDecorator('pincode', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please enter Zip Code',
                                    whitespace: true
                                }
                            ]
                        })(<Input/>)}
                    </FormItem>

                    <FormItem {...formItemLayout} label="Note">
                        {getFieldDecorator('note', {
                            // rules: [
                            //     {
                            //         required: true,
                            //         message: 'Please enter your Note!',
                            //         whitespace: true
                            //     }
                            // ]
                        })(<TextArea rows={4}/>)}
                    </FormItem>

                    {/* <FormItem
                    {...formItemLayout}
                    label="Password"
                    >
                        {getFieldDecorator('password', {
                            rules: [
                                
                                { min: 6, message: 'Please enter minimum 6 characters.' },
                                { max: 12, message: 'Please enter maximum 13 characters.' },
                                {
                                    required: false,
                                    message: 'Please enter your Password!'
                                }, {
                                    validator: validateToNextPassword
                                }
                                
                            ]
                        })(<Input type="password" placeholder="New Password"/>
                        )}
                    </FormItem>

                    <FormItem
                    {...formItemLayout}
                    label="Confirm Password"
                    >
                        {getFieldDecorator('confirm', {
                            rules: [
                                {
                                    required: false,
                                    message: 'Please enter Confirm Password'
                                }, {
                                    validator: compareToFirstPassword
                                }
                            ]
                        })(<Input placeholder="Confirm Password" type="password"/>)}
                    </FormItem> */}

                    <FormItem {...formItemLayout1}>
                        <Row>
                            <Col span={24} sm={8}></Col>
                            <Col span={24} sm={12}>
                                {this.state.isUpdate
                                    ? <Button type="primary" htmlType="submit">UPDATE</Button>
                                    : <Button type="primary" htmlType="submit">ADD</Button>}
                            </Col>
                        </Row>
                    </FormItem>
                </Form>
                {/* {this.props.showSuccessMessage
                    ? message.success(this.props.successMessage.toString())
                    : null}

                {this.props.showMessage
                    ? message.error(this.props.alertMessage.toString())
                    : null} */}
               {this.props.showSuccessMessage
                    ?
                    notification['success']({
                        message: 'success',
                        description: this.props.successMessage.toString()
                      })
                    : null}

                {this.props.showMessage 
                    ?
                    notification['error']({
                        message: 'error',
                        description: this.props.alertMessage.toString()
                      }) 
                    : null}
            </Card>
        );
    }

}
const mapStateToProps = state => {
    return {status : state.auth.status, 
        AuthDetails : state.auth.UserDetails,
        showSuccessMessage: state.assessmentReducers.showSuccessMessage, 
        showMessage: state.assessmentReducers.showMessage, 
        successMessage: state.assessmentReducers.successMessage, 
        alertMessage: state.assessmentReducers.alertMessage,
        getStaffData: state.staffReducers.get_staff_res }
};
// const {successMessage, alertMessage, showMessage, showSuccessMessage} =
// useSelector(({auth}) => auth); Object of action creators
const mapDispatchToProps = {
    add_profiler: add_profiler,
    update_profiler_user: update_profiler_user,
    hideMessage: hideMessage,
    get_staff : get_staff,
    getUserDetails : getUserDetails,
    changestatus : changestatus,
    update_user : update_user,
}
const RegistrationForm = Form.create()(Updateprofiler);
// export default RegistrationForm;
export default connect(mapStateToProps, mapDispatchToProps)(RegistrationForm);
