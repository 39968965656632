    import {all, call, fork, put, takeEvery} from "redux-saga/effects";
    import {auth, facebookAuthProvider, githubAuthProvider, googleAuthProvider, twitterAuthProvider} from "../../firebase/firebase";
    import axios from 'axios';
    import {baseURL, apikey} from 'util/config'
    import {
        SIGNIN_FACEBOOK_USER,
        SIGNIN_GITHUB_USER,
        SIGNIN_GOOGLE_USER,
        SIGNIN_TWITTER_USER,
        SIGNIN_USER,
        SIGNOUT_USER,
        SIGNUP_USER,
        FORGOT_PASSWORD,
        RESET_PASSWORD,
        CHANGE_PASSWORD,
        // SEND_ACTIVATION_LINK,
        GET_SUCCESS_USER_DETAILS,
        GET_USER_DETAILS,
        UPDATE_STAFF_USER,
        UPDATE_PROFILER_USER,
        GET_DASHBORAD_COUNT,
        GET_DASHBORAD_COUNT_DATA
        // ADD_PROFILER,
        // UPDATE_PROFILER,
        // GET_PROFILER,
        // ADD_STAFF,
        // UPDATE_STAFF,
        // GET_STAFF,
        // DELETE_USER_ROW,
        // GET_ROLE,
        // ADD_ROLE,
        // DELETE_ROLE,
        // USER_STATUS_CHANGE,
        // ADD_PERMISSION,
        // GET_PERMISSION_BY_ROLEID
    } from "constants/ActionTypes";
    import {showAuthMessage, showSuccessMessage,showErrorMessage, userSignInSuccess, userSignOutSuccess, userSignUpSuccess,getSuccessUserDetails,getDashboardCount_Data} from "../../appRedux/actions/Auth";
    import {userFacebookSignInSuccess, userGithubSignInSuccess, userGoogleSignInSuccess, userTwitterSignInSuccess} from "../actions/Auth";
    export const token = (state) => state.token;

    const headers = {
        'Content-Type': 'application/json',
        'X-API-KEY': apikey
    }

    const headersWithToken = {
        'Content-Type': 'application/json',
        'X-API-KEY': apikey,
        'X-Token' : token

    }
    const createUserWithEmailPasswordRequest = async(email, password) => await auth
        .createUserWithEmailAndPassword(email, password)
        .then(authUser => authUser)
        .catch(error => error);

    const signInUserWithEmailPasswordRequest = async(email, password) => await axios.post(baseURL + 'api/user/login', {
        "username": email,
        "password": password
    }, {headers: headers})
        .then(authUser => authUser.data)
        .catch(error => error);

    const forgotPasswordWithEmailRequest = async(email) => await axios.post(baseURL + 'api/user/forgot_password', {
        "email": email,
        "baseurl": baseURL + 'reset-password/' //check api side for base url port
    }, {headers: headers})
        .then(forgotRes => forgotRes.data)
        .catch(error => error);

    const changePasswordById = async(userid, password) => await axios.post(baseURL + 'api/user/change_password', {
        "userid": userid,
        "password" : password
    }, {headers: headers})
        .then(changepassres => changepassres.data)
        .catch(error => error);

    
    // const SendUSerActivationLink = async(email) => await axios.post(baseURL + 'api/user/Send_activation_link', {
    //     "email": email
    // }, {headers: headers})
    //     .then(linksend => linksend.data)
    //     .catch(error => error);

        

    const resetPasswordWithVcodeRequest = async(company_name, password, vcode, pType) => await axios.post(baseURL + 'api/user/reset_password', {
        "company_name": company_name,
        "password": password,
        "varification_code": vcode,
        "pType" : pType
    }, {headers: headers})
        .then(resetRes => resetRes.data)
        .catch(error => error);

    const addUpdateStaffuserWithGroup = async(fname, lname, email, userid, username, role,update_profile,avatar) => await axios.post(baseURL + 'api/user/updateuserBystaff', {
        "fname": fname,
        "lname": lname,
        "email": email,
        "username": username,
        "group": role,
        "role": role,
        "id": userid,
        "update_profile" : update_profile,
        "avatar" : avatar
        // "password" : password
    }, {headers: headers})
        .then(updateStaffRes => updateStaffRes.data)
        .catch(error => error);

    //updaqte profiler
    const addUpdateprofileruserWithGroup = async(company_name, email, poc_name, phone_number, address1,address2,city,pincode, note, username, user_id, update_profile,no_of_assessment,avatar) => await axios.post(baseURL + 'api/user/updateuserByProfiler', {
        "company_name": company_name,
        "email": email,
        "poc_name": poc_name,
        "no_of_assessment": no_of_assessment,
        "phone_number": phone_number,
        "address1": address1,
        "address2": address2,
        "city": city,
        "pincode": pincode,
        "note": note,
        "username": username, 
        "id": user_id,
        "update_profile" : update_profile,
        "avatar" : avatar
        // "password" : password
    }, {headers: headers})
        .then(updateProfilerRes => updateProfilerRes.data)
        .catch(error => error);
        
    const GetUSerDetailsData = async(id) => await axios.get(baseURL + 'api/user/detail?id='+id, {headers: headers})
    .then(getData => getData.data)
    .catch(error => error);
    const signOutRequest = async() => await auth
        .signOut()
        .then(authUser => authUser)
        .catch(error => error);
        
    const getDashboardCountData = async(id) => await axios.get(baseURL + 'api/user/dashboardCount', {headers: headers})
    .then(getData => getData.data)
    .catch(error => error);

    // const signOutRequest = async() => await auth
    //     .signOut()
    //     .then(authUser => authUser)
    //     .catch(error => error);

    const signInUserWithGoogleRequest = async() => await auth
        .signInWithPopup(googleAuthProvider)
        .then(authUser => authUser)
        .catch(error => error);

    const signInUserWithFacebookRequest = async() => await auth
        .signInWithPopup(facebookAuthProvider)
        .then(authUser => authUser)
        .catch(error => error);

    const signInUserWithGithubRequest = async() => await auth
        .signInWithPopup(githubAuthProvider)
        .then(authUser => authUser)
        .catch(error => error);

    const signInUserWithTwitterRequest = async() => await auth
        .signInWithPopup(twitterAuthProvider)
        .then(authUser => authUser)
        .catch(error => error);

    function * createUserWithEmailPassword({payload}) {
        const {email, password} = payload;
        try {
            const signUpUser = yield call(createUserWithEmailPasswordRequest, email, password);
            // console.log("signUpUser",signUpUser)
            if (!signUpUser.status) {
                yield put(showAuthMessage(signUpUser.message));
            } else {
                localStorage.setItem('user_id', signUpUser.user.uid);
                yield put(userSignUpSuccess(signUpUser.user.uid));
            }
        } catch (error) {
            // console.log("signUpUser1",error)
            yield put(showAuthMessage(error));
        }
    }

    function * signInUserWithGoogle() {
        try {
            const signUpUser = yield call(signInUserWithGoogleRequest);
            if (signUpUser.message) {
                yield put(showAuthMessage(signUpUser.message));
            } else {
                localStorage.setItem('user_id', signUpUser.user.uid);
                yield put(userGoogleSignInSuccess(signUpUser.user.uid));
            }
        } catch (error) {
            yield put(showAuthMessage(error));
        }
    }

    function * signInUserWithFacebook() {
        try {
            const signUpUser = yield call(signInUserWithFacebookRequest);
            if (signUpUser.message) {
                yield put(showAuthMessage(signUpUser.message));
            } else {
                localStorage.setItem('user_id', signUpUser.user.uid);
                yield put(userFacebookSignInSuccess(signUpUser.user.uid));
            }
        } catch (error) {
            yield put(showAuthMessage(error));
        }
    }

    function * signInUserWithGithub() {
        try {
            const signUpUser = yield call(signInUserWithGithubRequest);
            if (signUpUser.message) {
                yield put(showAuthMessage(signUpUser.message));
            } else {
                localStorage.setItem('user_id', signUpUser.user.uid);
                yield put(userGithubSignInSuccess(signUpUser.user.uid));
            }
        } catch (error) {
            yield put(showAuthMessage(error));
        }
    }

    function * signInUserWithTwitter() {
        try {
            const signUpUser = yield call(signInUserWithTwitterRequest);
            if (signUpUser.message) {
                if (signUpUser.message.length > 100) {
                    yield put(showAuthMessage('Your request has been canceled.'));
                } else {
                    yield put(showAuthMessage(signUpUser.message));
                }
            } else {
                localStorage.setItem('user_id', signUpUser.user.uid);
                yield put(userTwitterSignInSuccess(signUpUser.user.uid));
            }
        } catch (error) {
            yield put(showAuthMessage(error));
        }
    }

    function * signInUserWithEmailPassword({payload}) {
        const {email, password} = payload;
        try {
            const signInUser = yield call(signInUserWithEmailPasswordRequest, email, password);
            //  console.log("signInUser",signInUser.data.id);
            if (signInUser.status) {
                localStorage.setItem('user_id', JSON.stringify(signInUser.data));
                yield put(userSignInSuccess(signInUser));
            } else {
                yield put(showAuthMessage(signInUser.message));
            }
        } catch (error) {
            // console.log("signInUser",signInUser);
            yield put(showAuthMessage(error));
        }
    }

    function * forgotpasswordwithemail({payload}) {
        const {email} = payload;
        try {
            const forgotRes = yield call(forgotPasswordWithEmailRequest, email);
            if (forgotRes.status) {
                yield put(showSuccessMessage(forgotRes.message));
            } else {
                yield put(showAuthMessage(forgotRes.message));
            }
        } catch (error) {
            yield put(showAuthMessage(error));
        }
    }

    
    function * changePasswordByUserID({payload}) {
        const {userid,password} = payload;
        try {
            const changePasswordRes = yield call(changePasswordById, userid,password);
            if (changePasswordRes.status) {
                yield put(showSuccessMessage(changePasswordRes.message));
            } else {
                yield put(showAuthMessage(changePasswordRes.message));
            }
        } catch (error) {
            yield put(showAuthMessage(error));
        }
    }

    
    // function * SendActivationLink({payload}) {
    //     const {email} = payload;
    //     try {
    //         const activationlink = yield call(SendUSerActivationLink, email);
    //         if (activationlink.status) {
    //             yield put(showSuccessMessage(activationlink.message));
    //         } else {
    //             yield put(showAuthMessage(activationlink.message));
    //         }
    //     } catch (error) {
    //         yield put(showAuthMessage(error));
    //     }
    // }
    function * addupdateStaffuserWithEmail({payload}) {
        const {
            fname,
            lname,
            email,
            username,
            role,
            userid,
            update_profile,
            avatar
            // password
        } = payload;
        try {
            const updateStaffRes = yield call(addUpdateStaffuserWithGroup, fname, lname, email, userid, username,role,update_profile,avatar);
            if (updateStaffRes.status) {
                yield put(showSuccessMessage(updateStaffRes.message)); 
            } else {
                yield put(showErrorMessage(updateStaffRes.message));
            }
        } catch (error) {
            yield put(showErrorMessage(error));
        }
    }

    //updaqte profiler
    function * addupdateprofileruserWithEmail({payload}) {
        const {
            company_name,
            email,
            poc_name,
            no_of_assessment,
            phone_number,
            address1,
            address2,
            city,
            pincode,
            note,
            username,
            userid,
            update_profile,
            avatar
            // password
        } = payload;
        try {
            const addUpdateRes = yield call(addUpdateprofileruserWithGroup, company_name, email, poc_name, phone_number, address1,address2,city,pincode, note, username, userid, update_profile,no_of_assessment,avatar);
            if (addUpdateRes.status) {
                yield put(showSuccessMessage(addUpdateRes.message));
            } else {
                yield put(showErrorMessage(addUpdateRes.message));
            }
        } catch (error) {
            yield put(showErrorMessage(error));
        }
    }

    function * GetUSerDetails({payload}) {
        // const {email} = payload;
        try {
            const getData = yield call(GetUSerDetailsData, payload);
            console.log("getData",getData)
            if (getData.status) {
                yield put(getSuccessUserDetails(getData));
            } else {
                yield put(getSuccessUserDetails(getData.message));
            }
        } catch (error) {
            yield put(showAuthMessage(error));
        }
    }

    
    function * getDashboardData({payload}) {
        // const {email} = payload;
        try {
            const getData = yield call(getDashboardCountData, payload);
            // console.log("getData",getData)
            if (getData.status) {
                yield put(getDashboardCount_Data(getData));
            } else {
                yield put(getDashboardCount_Data(getData.message));
            }
        } catch (error) {
            yield put(showAuthMessage(error));
        }
    }

    function * resetPasswordWithVcode({payload}) {
        const {company_name,password, vcode, pType} = payload;
        try {
            const ResetRes = yield call(resetPasswordWithVcodeRequest, company_name, password, vcode, pType);
            if (ResetRes.status) {
                yield put(showSuccessMessage(ResetRes.message));
            } else {
                yield put(showAuthMessage(ResetRes.message));
            }
        } catch (error) {
            yield put(showAuthMessage(error));
        }
    }

    function * signOut() {
        try {
            const signOutUser = yield call(signOutRequest);
            if (signOutUser === undefined) {
                localStorage.removeItem('user_id');
                
                yield put(userSignOutSuccess(signOutUser));
                this
                    .props
                    .history
                    .push("/signin");
            } else {
                yield put(showAuthMessage(signOutUser.message));
            }
        } catch (error) {
            // yield put(showAuthMessage(error));
        }
    }

    export function * createUserAccount() {
        yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
    }

    export function * signInWithGoogle() {
        yield takeEvery(SIGNIN_GOOGLE_USER, signInUserWithGoogle);
    }

    export function * signInWithFacebook() {
        yield takeEvery(SIGNIN_FACEBOOK_USER, signInUserWithFacebook);
    }

    export function * signInWithTwitter() {
        yield takeEvery(SIGNIN_TWITTER_USER, signInUserWithTwitter);
    }

    export function * signInWithGithub() {
        yield takeEvery(SIGNIN_GITHUB_USER, signInUserWithGithub);
    }

    export function * signInUser() {
        yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
    }

    export function * signOutUser() {
        yield takeEvery(SIGNOUT_USER, signOut);
    }

    export function * forgotpassword() {
        yield takeEvery(FORGOT_PASSWORD, forgotpasswordwithemail);
    }
    export function * changePassword() {
        yield takeEvery(CHANGE_PASSWORD, changePasswordByUserID);
    }
    // export function * sendLink() {
    //     yield takeEvery(SEND_ACTIVATION_LINK, SendActivationLink);
    // }
    export function * getUserData() {
        yield takeEvery(GET_USER_DETAILS, GetUSerDetails);
    }
    export function * resetpassword() {
        yield takeEvery(RESET_PASSWORD, resetPasswordWithVcode);
    }

    export function * updatestaffuser() {
        yield takeEvery(UPDATE_STAFF_USER, addupdateStaffuserWithEmail);
    }
    
    export function * updateprofileruser() {
        yield takeEvery(UPDATE_PROFILER_USER, addupdateprofileruserWithEmail);
    }
    
    export function * getDashboard() {
        yield takeEvery(GET_DASHBORAD_COUNT, getDashboardData);
    }

    export default function * rootSaga() {
        yield all([
            fork(signInUser),
            fork(createUserAccount),
            // fork(signInWithGoogle),
            // fork(signInWithFacebook),
            // fork(signInWithTwitter),
            // fork(signInWithGithub),
            fork(changePassword),
            // fork(sendLink),
            fork(getUserData),
            fork(signOutUser),
            fork(forgotpassword),
            fork(resetpassword),
            fork(updatestaffuser),
            fork(updateprofileruser),
            fork(getDashboard),
        ]);
    }
