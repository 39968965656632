import React, { Component } from "react";
import ReactDOM from 'react-dom';
// import {Col, Row, Card, Table, Button} from "antd";
// import Auxiliary from "util/Auxiliary";
import { Link } from 'react-router-dom'
import { Button, Card, Col, Form, Input,InputNumber, Row, Switch, Select, Tabs, Modal, message,notification,Tooltip} from "antd";
import {connect} from "react-redux";
// import ReactDOM from "react-dom";
// import jquery from 'jquery'; 
import { ReactFormBuilder, ElementStore , ReactFormGenerator } from 'react-form-builder2';
import { get, post } from './requests';
// import '../../styles/addassessment.css'
import {add_assessment, hideMessage,update_assessment} from "appRedux/actions/AssessmentActions";
import {get_profiler} from "appRedux/actions/ProfilerActions";
import { getUserDetails} from "appRedux/actions/Auth";
import { DndProvider, DragSource, DropTarget } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import CKEditor from "react-ckeditor-component";

function randomcode() {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < 5; i++ ) {
     result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

class TabNode extends React.Component {
  render() {
    const { connectDragSource, connectDropTarget, children } = this.props;

    return connectDragSource(connectDropTarget(children));
  }
}

const cardTarget = {
  drop(props, monitor) {
    const dragKey = monitor.getItem().index;
    const hoverKey = props.index;

    if (dragKey === hoverKey) {
      return;
    }

    props.moveTabNode(dragKey, hoverKey);
    monitor.getItem().index = hoverKey;
  },
};

const cardSource = {
  beginDrag(props) {
    return {
      id: props.id,
      index: props.index,
    };
  },
};

const WrapTabNode = DropTarget('DND_NODE', cardTarget, connect => ({
  connectDropTarget: connect.dropTarget(),
}))(
  DragSource('DND_NODE', cardSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  }))(TabNode),
);

const getUrl = (cid) => `https://safe-springs-35306.herokuapp.com/api/formdata?cid=${cid}`;
// const getUrl = (cid) =>{}
var TabOrder = [];
const FormItem = Form.Item;
const Option = Select.Option;
const TabPane = Tabs.TabPane;
var items = [{
    key: 'Header',
    name: 'Header Text',
    icon: 'fa fa-header',
    static: true,
    content: 'Placeholder Text...'
  },
  {
    key: 'Paragraph',
    name: 'Paragraph',
    static: true,
    icon: 'fa fa-paragraph',
    content: 'Placeholder Text...'
  }];
  

  class DraggableTabs extends React.Component {
    state = {
      order: [],
    };
  
    moveTabNode = (dragKey, hoverKey) => {

      const newOrder = this.state.order.slice();
      const { children } = this.props;
  
      React.Children.forEach(children, c => {
        if (newOrder.indexOf(c.key) === -1) {
          newOrder.push(c.key);
        }
      });
  
      const dragIndex = newOrder.indexOf(dragKey);
      const hoverIndex = newOrder.indexOf(hoverKey);
  
      newOrder.splice(dragIndex, 1);
      newOrder.splice(hoverIndex, 0, dragKey);
      console.log("vishal",newOrder);

      TabOrder = newOrder;
      console.log("TabOrder",TabOrder,TabOrder.length);
      this.setState({
        order: newOrder,
      });
      return true;
    };
  
    renderTabBar = (props, DefaultTabBar) => (
      <DefaultTabBar {...props}>
        {node => (
          <WrapTabNode key={node.key} index={node.key} moveTabNode={this.moveTabNode}>
            {node}
          </WrapTabNode>
        )}
      </DefaultTabBar>
    );
  
    render() {
      const { order } = this.state;
      const { children } = this.props;
  
      const tabs = [];
      React.Children.forEach(children, c => {
        tabs.push(c);
      });
  
      const orderTabs = tabs.slice().sort((a, b) => {
        const orderA = order.indexOf(a.key);
        const orderB = order.indexOf(b.key);
  
        if (orderA !== -1 && orderB !== -1) {
          return orderA - orderB;
        }
        if (orderA !== -1) {
          return -1;
        }
        if (orderB !== -1) {
          return 1;
        }
  
        const ia = tabs.indexOf(a);
        const ib = tabs.indexOf(b);
  
        return ia - ib;
      });
  
      return (
        <DndProvider backend={HTML5Backend}>
          <Tabs renderTabBar={this.renderTabBar} {...this.props}>
            {orderTabs}
          </Tabs>
        </DndProvider>
      );
    }
  }
// const AutoCompleteOption = AutoComplete.Option;
const {TextArea} = Input;

class Assessment extends Component {

  constructor(props) {
    super(props);
    this.state = {
      confirmDirty: false,
      autoCompleteResult: [],
      formSection : 1,
      formSection1 :1,
      formSection2 :false,
      loading: false,  
      visible: false,
      pOption : '',
      authID : '',
      tvalue : '',
      tname : '',
      tc_name : 'Terms & Conditions',
      tc_value : 0,
      cp_name : 'Company Profile',
      cp_value : 0,
      q_name : 'Questionaries',
      q_value : 0,
      cm_name : 'Comments',
      cm_value : 0,
      previewVisible: false,
      formId: '',
      tc_stateval : '',
      cp_stateval : '',
      q_stateval : '',
      cm_stateval : '',
      tabname : '',
      bestinclass : '',
      positiverecommendations : '',
      profiler_array : '',
      panes : [],
      panespreview : [],
      activeKey : '0',
      newTabIndex : 0,
      questionarysection : false,
      alljson : [],
      showModelEdit : false,
      previewdata : [],
      cardTitle : 'Create Assessment',
      isUpdate : false,
      assessmentID : '',
      randomurlcode : '',
      tabtypetextname : '',
      tabheader : '',
      competitive_average: ''
    };
    this.formId = this.state.formId;
    const update = this._onChange.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    ElementStore.subscribe(state => update(state.data));
}

handleChange = (value) => {


  this.setState({ profiler_array: value });
  console.log("profiler_array",value)
}

handleSubmit = (e) => {

    e.preventDefault();
    console.log("this.TabOrder",TabOrder)
    console.log("tabname",this.state.tabname)
    console.log("formdata2 => ",this.state.tc_stateval)
    this.props.form.validateFieldsAndScroll(['title', 'description', 'publish','tab'],(err, values) => {
      if(!err) {
        values.profiler_ids = this.state.profiler_array;
        if(this.state.isUpdate){

          values.userid = this.state.authID;
          values.assessmentID = this.state.assessmentID;
          values.profiler_ids = this.state.profiler_array; //["10","15","25"];
          console.log("this.state.tc_stateval",this.state.tc_stateval)
          console.log("this.state.cp_stateval",this.state.cp_stateval)
          console.log("this.state.q_stateval",this.state.q_stateval)
          console.log("this.state.cm_stateval",this.state.cm_stateval)
                values.all_section = [];
                
                if(TabOrder.length > 0){
                  
                    TabOrder.map( key => {
                      this.state.panes.map(pane => {

                        if(key == pane.key){
                          console.log("this.state.alljson[pane.key]-if",this.state.alljson,pane.key)
                          var new_array = [];
                            if(typeof this.state.alljson[pane.key] != 'undefined'){
                                new_array.push(this.state.alljson[pane.key].task_data);
                            }
                            else{
                                new_array.push([]);
                            }
                            new_array.push(pane.tabtype);
                            new_array.push(pane.bestinclass);
                            new_array.push(pane.competitive_average)
                            new_array.push(pane.positiverec);
                            values.all_section.push({
                                [pane.title]:new_array 
                            });
                            var new_saveUrl = getUrl(pane.key);
                            post(new_saveUrl, {task_data : []});
                        }
  

                      })
                    })
                }
                else
                {
                  this.state.panes.map(pane => {

                      console.log("this.state.alljson[pane.key]-else",this.state.alljson,pane.key)
                     var new_array = [];
                      if(typeof this.state.alljson[pane.key] != 'undefined'){
                          new_array.push(this.state.alljson[pane.key].task_data);
                      }
                      else{
                          new_array.push([]);
                      }
                      new_array.push(pane.tabtype);
                      new_array.push(pane.bestinclass);
                      new_array.push(pane.competitive_average)
                      new_array.push(pane.positiverec);
                      values.all_section.push({
                          [pane.title]:new_array 
                      });
                      var new_saveUrl = getUrl(pane.key);
                      post(new_saveUrl, {task_data : []});
                    
                  })

                }
                values.profiler_ids = this.state.profiler_array;
                console.log('Received values of form: ', values);
                this.props.update_assessment(values); 
                setTimeout(() => {
                  this.props.hideMessage();
                  this.props.history.push("/assessment");
                }, 1000);
  
                this.setState({
               
                profiler_ids : '',
                section_1 : '',
                section_2 : '',
                section_3 : '',
                section_4 : '',
                tc_value : '',
                cp_value : '',
                q_value : '',
                cm_value : '',
                formId : '',
                tc_stateval : '',
                cp_stateval : '',
                q_stateval : '',
                cm_stateval : '',
                alljson : [],
                panes : [],
                questionarysection : false,
                tvalue : 0,
                tabheader : ''
              });
              this.formId = '';
            const form = this.props.form;
            // eslint-disable-next-line
            form.setFieldsValue({['title']: '', ['description']: '', ['status']: false,});
            
        }
        else
        {
            values.userid = this.state.authID;
            values.profiler_ids = this.state.profiler_array; //["10","15","25"];
            console.log("this.state.alljson",this.state.alljson)
              values.all_section = [];
                if(TabOrder.length > 0){

                    TabOrder.map( key => {
                      this.state.panes.map(pane => {
                        
                        if(key ==pane.key){

                          var new_array = [];
                          if(typeof this.state.alljson[pane.key] != 'undefined'){
                              new_array.push(this.state.alljson[pane.key].task_data);
                          }
                          else{
                              new_array.push([]);
                          }
                          // var new_array = [];
                          // new_array.push(this.state.alljson[pane.key].task_data);
                          new_array.push(pane.tabtype);
                          new_array.push(pane.bestinclass);
                          new_array.push(pane.competitive_average);
                          new_array.push(pane.positiverec);
                          values.all_section.push({
                              [pane.title]:new_array 
                          });
                          var new_saveUrl = getUrl(pane.key);
                          post(new_saveUrl, {task_data : []});
                        }
                        else{
                          console.log(key ,'==>',pane.key)
                        }
                        
                        
                      })
                    })
                 
                }
                else
                {
                  this.state.panes.map(pane => {
                    // var new_array = [];
                    // new_array.push(this.state.alljson[pane.key].task_data);
                    var new_array = [];
                    if(typeof this.state.alljson[pane.key] != 'undefined'){
                        new_array.push(this.state.alljson[pane.key].task_data);
                    }
                    else{
                        new_array.push([]);
                    }
                    new_array.push(pane.tabtype);
                    new_array.push(pane.bestinclass);
                    new_array.push(pane.competitive_average);
                    new_array.push(pane.positiverec);
                    values.all_section.push({
                        [pane.title]:new_array 
                    });
                    var new_saveUrl = getUrl(pane.key);
                    post(new_saveUrl, {task_data : []});
                    
                  })

              }
              // console.log('Received values of form: ', values);
              this.props.add_assessment(values); 
              setTimeout(() => {
                this.props.hideMessage();
                this.props.history.push("/assessment");
              }, 1000);
              this.setState({
             
              profiler_ids : '',
              section_1 : '',
              section_2 : '',
              section_3 : '',
              section_4 : '',
              tc_value : '',
              cp_value : '',
              q_value : '',
              cm_value : '',
              formId : '',
              tc_stateval : '',
              cp_stateval : '',
              q_stateval : '',
              cm_stateval : '',
              alljson : [],
              panes : [],
              questionarysection : false,
              tvalue : 0,
              tabheader : ''
            });
            this.formId = '';

          const form = this.props.form;
          form.setFieldsValue({['title']: '', ['description']: '', ['publish']: false,});

        }
    }
      
  });
      TabOrder = []
}
  showPreview = () => {
    // this.tabchange(this.state.randomurlcode+'0');
    this.setState({
      previewVisible: true
    });
  }

  showModal = () => {
    this.setState({
      visible: true,
      tabname : '',
      positiverecommendations : '',
      bestinclass : 0,
      competitive_average : 0,
      questionarysection : false
    });
    setTimeout(() => {
        const form = this.props.form;
        form.setFieldsValue({['tabname']: '', ['bestinclass']: 0, ['competitive_average'] : 0});
      },500);
  };

  showEditModal = (activeKey,tabvalue) => {
    this.setState({
      visible: true
    });

    const panes = this.state.panes;
    console.log('editmodalpanes',panes, activeKey,tabvalue);
    panes.map((pane)=>{
        if(pane.key == activeKey){
            console.log('panes',pane.key , activeKey);

            if(tabvalue == 4){
                this.setState({questionarysection : true})
            }
            else
            {
                this.setState({questionarysection : false})
            }
            this.setState({
                tabname: pane.title,
                bestinclass : pane.bestinclass,
                competitive_average : pane.competitive_average,
                positiverecommendations : pane.positiverec,
                showModelEdit : true,
                editActiveKey : activeKey,
                optionsState : tabvalue,
                tvalue  : tabvalue
            });
            setTimeout(() => {
              const form = this.props.form;
              form.setFieldsValue({['sectiontabname'] : tabvalue,['tabname']:  pane.title, ['bestinclass']: pane.bestinclass ,['competitive_average'] : pane.competitive_average });
                }, 500);
                this.tabvalue(tabvalue)

        }
        
    })
  };

  handleOk = (e) => {
    e.preventDefault();

      this.props.form.validateFieldsAndScroll(['tabname','bestinclass','sectiontabname','competitive_average'],(err, values) => {
        if (!err) {

          console.log('editValue',values)
          var panes = this.state.panes;
          var panespreview = this.state.panespreview;
          var tabtypetext = "";
          if(this.state.tvalue == 1 ){
            tabtypetext = "Introduction Page"; 
          }
          else if(this.state.tvalue == 2 ){
            tabtypetext = "Terms & Conditions"; 
          }
          else if(this.state.tvalue == 3 ){
            tabtypetext = "Company Profile"; 
          }
          else if(this.state.tvalue == 4 ){
            tabtypetext = "Questionnaires"; 
          }
          else if(this.state.tvalue == 5 ){
            tabtypetext = "Comments"; 
          }
          // const tvalue = this.state.tvalue
          const activeKey = this.state.randomurlcode+`${this.state.newTabIndex++}`;
          this.formId = activeKey;
          const formbuilder_id = `formbuilder${this.state.newTabIndex}`;
          const formgenerator_id = `formgenerator${this.state.newTabIndex}`;
          panes.push({title: this.state.tabname, content: <ReactFormBuilder id={formbuilder_id} onLoad={()=>this.onLoad(activeKey)} onPost={(data) => this.onPost(data,activeKey) } />, key: activeKey, positiverec : this.state.positiverecommendations , bestinclass : this.state.bestinclass,competitive_average:this.state.competitive_average,tabtype : this.state.tvalue, tabtypetext : tabtypetext });
          
          panespreview.push({title: this.state.tabname, content: <ReactFormGenerator
            download_path=""
            back_action="/"
            back_name="Back"
            answer_data={{}}
            action_name="Save"
            form_action="/"
            form_method="POST"
            id={formgenerator_id}
            data={this.state.previewdata}
           />, key: activeKey, positiverec : this.state.positiverecommendations , bestinclass : this.state.bestinclass,competitive_average:this.state.competitive_average,tabtype : this.state.tvalue, tabtypetext : tabtypetext });
          this.setState({panes, activeKey, panespreview,tabheader : this.state.tabname });
          this.setState({loading: true})
          setTimeout(() => { 
            this.setState({loading: false, visible: false,tabname : '', positiverecommendations : '', bestinclass : 0, competitive_average : 0});

            const form = this.props.form;
             form.setFieldsValue({['tabname']: '', ['bestinclass']: '', ['competitive_average'] : 0 });
          }, 500);
        }
      })
      TabOrder = []

  };

  tabedithandleOk = (e) => {

    e.preventDefault();

      this.props.form.validateFieldsAndScroll(['tabname','bestinclass','sectiontabname', 'competitive_average'],(err, values) => {
        if (!err) {
      
          const panes = this.state.panes;
          const panespreview = this.state.panespreview;
          var editactiveKey = this.state.editActiveKey;
          panes.map((pane)=>{
              console.log('console1',pane.key, editactiveKey);
              if(pane.key == editactiveKey){
                  // this.setState({
                  //     tabname: this.state.tabname,
                  //     // showModelEdit : true
                  //   });

                  pane.tabOption = this.state.tvalue; 
                  pane.tabtype =  this.state.tvalue;
                  pane.tabtypetext = this.state.tabtypetextname;
                  pane.title = this.state.tabname;
                  pane.bestinclass = this.state.bestinclass;
                  pane.competitive_average = this.state.competitive_average
                  pane.positiverec = this.state.positiverecommendations;
                  // this.setState({pane, activeKey});
                  console.log('console2',pane.tabtypetextname , this.state.tvalue,pane);
              }
                
          })
          panespreview.map((panepreview)=>{
            if(panepreview.key == editactiveKey){
              panepreview.tabOption = this.state.tvalue
              panepreview.tabtype =  this.state.tvalue
              panepreview.tabtypetext = this.state.tabtypetextname
              panepreview.title = this.state.tabname
              panepreview.bestinclass = this.state.bestinclass
              panepreview.competitive_average = this.state.competitive_average
              panepreview.positiverec = this.state.positiverecommendations
            }
        })
          this.setState({loading: true,tabheader : this.state.tabname})
          setTimeout(() => {
            this.setState({loading: false, visible: false,tabname : '', positiverecommendations : '', bestinclass : 0,competitive_average : 0,showModelEdit : false, editActiveKey:'',questionarysection:false, tvalue : 0})
            const form = this.props.form
             form.setFieldsValue({['tabname']: '', ['bestinclass']: '', ['competitive_average']:''})
          }, 500);

        }
      })

      TabOrder = []
    
  }
  handleCancel = () => {
    this.setState({visible: false});
  }

  previewhandleCancel = () => {
    this.setState({previewVisible: false});
  }
  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({confirmDirty: this.state.confirmDirty || !!value});
  }


  showSection = (v) => {
    // this.setState({formSection1 : v});
    const form = this.props.form;
    var values = form.getFieldValue('password');
    console.log("formSection1",values);
  }

  bestinclass = (value) => {
    this.setState({bestinclass : value});
  }

  competitive_average = (value) => {
    this.setState({competitive_average : value});
  }

  positiverecommendations = (evt) => {
    const newContent = evt.editor.getData();
    this.setState({positiverecommendations : newContent});
    console.log("positiverecommendations",newContent)
  }

  tabname = (value) => {

    console.log("formData",value);
    this.setState({tname : value});
    this.setState({tabname : value});
    console.log("formData123",this.state.tname, this.state.tabname);

  };

  tabvalue = (value) => {
    // const form = this.props.form;
    console.log("formData",value);
    if(value == 4){
      this.setState({questionarysection : true})  
    }
    else{
      this.setState({questionarysection : false})
    }
    this.setState({tvalue : value})

    if(value == 1 ){
      this.setState({tabtypetextname : "Introduction Page"})
      // tabtypetext = "Introduction Page"; 
    }
    else if(value == 2 ){
      this.setState({tabtypetextname : "Terms & Conditions"})
      // tabtypetext = "Terms & Conditions"; 
    }
    else if(value == 3 ){
      this.setState({tabtypetextname : "Company Profile"})
      // tabtypetext = "Company Profile"; 
    }
    else if(value == 4 ){
      this.setState({tabtypetextname : "Questionnaires"})
      // tabtypetext = "Questionaries"; 
    }
    else if(value == 5 ){
      this.setState({tabtypetextname : "Comments"})
      // tabtypetext = "Comments"; 
    }

};

  onLoad = (key) => {
    const url = getUrl(this.formId);
    console.log('onLoad', url);
    console.log("get(url)",get(url));
    var a = get(url);
    console.log('abc',a)
    return a;
  };

  onPost = (data,key) => {
    const saveUrl = getUrl(this.state.activeKey);
    var alljson = this.state.alljson;
    alljson[this.state.activeKey] = data;
    this.setState({alljson : alljson});
    console.log(alljson)
    post(saveUrl, data);
  };

  get_profilerById(){
    var val = {
      group_id : 6,
      filter : '',
      current : ''
  }
  this.props.get_profiler(val);
    // this.props.get_profiler(6);
  }
  onEdit = (targetKey, action) => {
    this[action](targetKey);
  }
  remove = targetKey => {
    let { activeKey } = this.state;
    let lastIndex;
    this.state.panes.forEach((pane, i) => {
      if (pane.key === targetKey) {
        lastIndex = i - 1;
      }
    });

  const panes = this.state.panes.filter(pane => pane.key !== targetKey);
  if (panes.length && activeKey === targetKey) {
    if (lastIndex >= 0) {
      activeKey = panes[lastIndex].key;
    } else {
      activeKey = panes[0].key;
    }
  }

  setTimeout(() => {
    this.tabchange(activeKey);
  }, 100);
  this.setState({ panes, activeKey});
};
  componentWillMount(){
      localStorage.setItem("progress", 40);
        var randomurlcode = randomcode();
        this.setState({randomurlcode});
        if(typeof this.props.authUser != 'undefined'){

          var authpermission = this.props.authUser.permission;
          this.setState({authID : this.props.authUser.id});
          console.log("authpermission",authpermission);
          console.log("authID",this.state.authID);
          if(authpermission.length > 0){
              if(!authpermission.includes('97')){
                  this
                  .props
                  .history
                  .push("/dashboard");
              }
          }
          this.get_profilerById()

         
      }

  }
    gotonext = () => {
        var nexttab = this.state.activeKey.substr(this.state.activeKey.length - 1);
        var next = this.state.activeKey.substr(0,this.state.activeKey.length - 1);
        var newtabid =  next + (parseInt(nexttab) + 1); 
        if(parseInt(nexttab) != parseInt(this.state.newTabIndex) - 1){
          this.tabchange(newtabid);
        } 
    }
    gotoprevious = () => {
      var nexttab = this.state.activeKey.substr(this.state.activeKey.length - 1);
      var next = this.state.activeKey.substr(0,this.state.activeKey.length - 1);
      var newtabid =  next + (parseInt(nexttab) - 1); 
      if(parseInt(nexttab) != 0){
        this.tabchange(newtabid);
      }
  }
    // onLoad = () => {
    tabchange = (val) => {
      // alert(val);
      this.state.panes.map((pane)=>{
        if(pane.key == val){
          this.setState({tabheader :  pane.title});
          return; 
        }
      });
      this.formId = val;
      this.setState({activeKey :  val});
      const url = getUrl(val);
      ElementStore.dispatch('load', { loadUrl: url });
      // const update = this._onChange.bind(this);
      // ElementStore.subscribe(state => update(state.previewdata));
      // this.onLoad();
    }
    _onChange(previewdata) {
      console.log("previewdata",previewdata);
      this.setState({
        previewdata,
      });
    }
    getallData(){

      let datarecords = this.props.authUser;
      var datarecord = this.props.AuthDetails;
      
      
      // eslint-disable-next-line
      if (datarecords == "undefined" || datarecords == "" || datarecords == null) {
          // Object is empty (Would return true in this example)
      } else {
          // Object is NOT empty
          this.props.getUserDetails(datarecords.id);
          console.log("DauthDetails",datarecord)
      }

      
    } 
    /**
   * Set the wrapper ref
   */
    setWrapperRef(node){
      this.wrapperRef = node;
      // alert("hiii")
    }

  /**
   * Alert if clicked on outside of element
   */
    handleClickOutside = (event) => {

      if(this.wrapperRef) {
        const domNode = ReactDOM.findDOMNode(this.wrapperRef)
        this.setState({
          visible: true});
          // alert(this.state.visible);
        // if (this.wrapperRef.contains(event.target)) {
        
        // }
    }
    
    
    
  }
    componentWillUnmount(){
        document.removeEventListener('click', this.handleClickOutside, true);
    }
    componentDidMount(){
      localStorage.setItem("progress", 100);

        setTimeout(() => {  
                    localStorage.setItem("progress", 0);
                  }, 2000);  
      document.addEventListener('click', this.handleClickOutside, true);

      let datarecordstaff = this.props.location.state;
      
      if (datarecordstaff == "undefined" || datarecordstaff == "" || datarecordstaff == null) {
      
      }
      else
      {
        datarecordstaff = datarecordstaff.record;
          const form = this.props.form;
          form.setFieldsValue({['title']: datarecordstaff.name, ['description']: datarecordstaff.description,['tab']: datarecordstaff.profiler, ['publish']: datarecordstaff.publish == 1 ? true : false });
          this.setState({profiler_array :datarecordstaff.profiler })
          
          var tabData= datarecordstaff.assessment_section;
         
          tabData.map((val, index)=>{
            
            var key =val.section_id;
            var tabtypetext = "";
            if(key == 1 ){
              tabtypetext = "Introduction Page"; 
            }
            else if(key == 2 ){
              tabtypetext = "Terms & Conditions"; 
            }
            else if(key == 3 ){
              tabtypetext = "Company Profile"; 
            }
            else if(key == 4 ){
              tabtypetext = "Questionnaires"; 
            }
            else if(key == 5 ){
              tabtypetext = "Comments"; 
            } 
            var tabvalue = key;
            // alert(this.state.randomurlcode);
            var activeKey = this.state.randomurlcode+`${this.state.newTabIndex++}`;
            var data = val.assessment_json;
            var json_array = this.state.alljson;
            json_array[activeKey]= {task_data : JSON.parse(data) };
            var tittle = val.tab_name;
            var panes = this.state.panes;
            const formbuilder_id = `formbuilder${this.state.newTabIndex}`;        
            const saveUrl = getUrl(activeKey);
            post(saveUrl, {task_data : data});
            panes.push({title: val.tab_name, content: <ReactFormBuilder onLoad={()=>this.onLoad(activeKey)} id={formbuilder_id} onPost={(postval) => this.onPost(postval,activeKey) } />, key: activeKey, tabOption : tabvalue, positiverec : val.possitive_recomendation , bestinclass : val.best_in_class,competitive_average : val.competitive_average, tabtype : tabvalue, tabtypetext : tabtypetext });
            this.setState({panes,activeKey,alljson : json_array });
            
            
        }) 
        this.setState({isUpdate: true, cardTitle: "Update Assessment", assessmentID: datarecordstaff.id});

        setTimeout(() => {
          this.tabchange(this.state.randomurlcode+'0');
        }, 300);
      }
  }
  render() {

    const children = [];
    function isEmpty(obj) {
      for(var key in obj) {
          if(obj.hasOwnProperty(key))
              return false;
      }
      return true;
    }
    var profilerData = this.props.getProfilerData;
    var pdata = ''; 
    if(isEmpty(profilerData)) {
        // Object is empty (Would return true in this example) 
    } else {
        // Object is NOT empty
        pdata = profilerData.user;
        
        for (let i = 0 ; pdata.length > i ; i++) {
          if(children.includes(<Option key={pdata[i].id}>{pdata[i].company_name}</Option>)){

          }
          else{
            children.push(<Option key={pdata[i].id}>{pdata[i].company_name}</Option>);
          }
          
        }
        
    }


    const {getFieldDecorator} = this.props.form;
    
    // const {autoCompleteResult} = this.state;

    const formItemLayout = {
      labelCol: {
        xs: {span: 24},
        sm: {span: 10},
      },
      wrapperCol: {
        xs: {span: 24},
        sm: {span: 14},
      },
    };

    
    const formItemLayout_Description = {
      labelCol: {
        xs: {span: 24},
        sm: {span: 5},
      },
      wrapperCol: {
        xs: {span: 24},
        sm: {span: 14},
      },
    };

    const formItemLayout1 = {
        labelCol: {
          xs: {span: 24},
          sm: {span: 6},
        },
        wrapperCol: {
          xs: {span: 24},
          sm: {span: 16},
        },
      };

    return (
      <>

      {
      
      this.state.formSection == 1 ?
      <Card className="gx-card cust_asses" title={this.state.cardTitle}> 
        {/* <Link to="/main/dashboard/profiler">
            <Button type="ghost" icon="left" >
              <i className="icon icon-left"></i> Back
            </Button>
          </Link> */}
        <Form onSubmit={this.handleSubmit}>

            <div className="assesment_btns" >
              <Row >
              
              <Col span={12} sm={12}>
                <Link to="/assessment" >
                      <Button type="ghost" icon="left">
                          <i className="icon icon-left"></i>
                          BACK
                      </Button>
                  </Link>
                </Col>
                <Col span={12} sm={12}>
                    <Button disabled={this.state.panes.length == 0} type="ghost" onClick={this.showPreview}>
                      PREVIEW
                    </Button> 
                </Col>
                <Col span={12} sm={12}>
                  <FormItem  >
                    {
                      this.state.isUpdate
                      ?
                      <Button type="primary" htmlType="submit">UPDATE</Button>
                      :
                      <Button type="primary" htmlType="submit">SAVE</Button>
                    }
                    
                  </FormItem>
                </Col>
              </Row>
              </div>
          
          
          <Row>
            <Col span={12} sm={12}>
              <FormItem
                    {...formItemLayout}
                    label="Title"
                  >
                    {getFieldDecorator('title', {
                      rules: [{
                        required: true, 
                        message: 'Please enter your Title!',
                        whitespace: true
                      }],
                    })(
                      <Input />
                    )}
                  </FormItem>                  
            </Col>
            <Col span={12} sm={12} className="assess_right">
                  <FormItem
                    {...formItemLayout}
                    label="Publish"
                  >
                    {getFieldDecorator('publish', 
                      {
                        valuePropName: 'checked',
                    })(
                        <Switch/>
                      )}
                  </FormItem>
                 
            </Col>
          </Row>
          <Row>
            <Col span={24} sm={24}>
              
                  <FormItem
                  {...formItemLayout_Description}
                  label="Description"
                    
                >
                  {getFieldDecorator('description', {
                    rules: [{required: true, 
                      message: 'Please enter your Description!', 
                      whitespace: true}],
                  })(
                    <TextArea rows={4}/>
                  )}
                </FormItem>
            </Col>
          </Row>
        </Form>
        
      </Card>
    : 
    '' 
    }

    <Card className="gx-card cust_asses" title="Form Builder"> 
        <Row>
            <Col span={12} sm={12}>
                <Button type="primary" onClick={this.showModal} >ADD</Button>
            </Col>
        </Row> 
        <Modal
          visible={this.state.previewVisible}
          title="Preview"
          onCancel={this.previewhandleCancel}
          width	= "800px"
          footer={[
             <Button key="back" onClick={this.gotoprevious} disabled={this.state.activeKey == this.state.randomurlcode+'0'}>Previous</Button>,
            <Button  type="primary" onClick={this.gotonext} disabled={this.state.activeKey == this.state.randomurlcode+(parseInt(this.state.newTabIndex) - 1) }> Next </Button>
          ]
          }
         >

          <div>
          <h1>{this.state.tabheader}</h1>
          <ReactFormGenerator
            download_path=""
            back_action="/"
            back_name="Back"
            answer_data={{}}
            action_name="Save"
            form_action="/"
            form_method="POST"
            data={this.state.previewdata}
            hide_actions={true}
          /> 
          </div>
     {/* </TabPane> */}
     {/* )} */}
          {/* </Tabs> */}
        </Modal>
        <Modal
          maskClosable="true"
        // ref={this.setWrapperRef}
          width="900px"
          className="addForm"
          visible={this.state.visible}
          title="Select Form Type"
          onOk={this.state.showModelEdit ? this.tabedithandleOk  :  this.handleOk }
          // onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>CANCEL</Button>,
            <Button 
   
            type="primary" htmlType="submit" loading={this.state.loading} onClick={this.state.showModelEdit ? this.tabedithandleOk  :  this.handleOk }> 
              SUBMIT
            </Button>
          ]}
        >
          
            <Form id="tabform" onSubmit={this.state.showModelEdit ? this.tabedithandleOk  :  this.handleOk}>
            <Input.Group >
                  <FormItem
                    {...formItemLayout1}
                    label="Section"
                  >
                    {getFieldDecorator('sectiontabname', {
                      // initialValue : this.state.tvalue,
                      rules: [{
                        required: true, 
                        message: 'Please select section',
                      }],
                      
                    })(
                      <Select
                      
                      defaultValue={this.state.tvalue}
                        placeholder="Please select section"
                        style={{width: '100%'}}
                        onChange ={ (e) => this.tabvalue(e)}
                      >
                          <Option key="0" value="0" disabled selected>Please Select section</Option>
                          <Option key="1" value="1" >Introduction Page</Option>
                          <Option key="2" value="2" >Term & Conditions</Option>
                          <Option key="3" value="3">Company Profile</Option>
                          <Option key="4" value="4">Questionnaires</Option>
                          <Option key="5" value="5">Comments</Option>
                        </Select>
                    )}
                  </FormItem>
                  
                    <FormItem
                    {...formItemLayout1}
                    label="Section Name"
                  >
                    {getFieldDecorator('tabname', {
                      rules: [{
                        required: true, 
                        message: 'Please enter Section Name',
                        whitespace: true
                      }],
                      onChange : (e) => this.tabname(e.target.value)
                    })(
                      <Input placeholder="Please enter Section Name" />
                    )}
                  </FormItem>

                  {this.state.questionarysection ?
                        <FormItem
                        {...formItemLayout1}
                        label="Best In Class"
                      >
                        {getFieldDecorator('bestinclass', {
                          
                          rules: [
                            {
                            required: true, 
                            message: 'Please enter Best In Class',
                            whitespace: true
                          }
                          ],
                          // initialValue : 0,
                          onChange : (e) => this.bestinclass(e.target.value)
                        })(
                          // <InputNumber className="gx-mb-12" min={0} max={100}  />
                          <Input  type="number" max={100} />
                        )}
                      </FormItem> : null }
              

                  {this.state.questionarysection ?
                        <FormItem
                        {...formItemLayout1}
                        label="Competitive Average"
                      >
                        {getFieldDecorator('competitive_average', {
                          
                          rules: [
                            {
                            required: true, 
                            message: 'Please enter Competitive Average',
                            whitespace: true
                          }
                          ],
                          // initialValue : 0,
                          onChange : (e) => this.competitive_average(e.target.value)
                        })(
                          // <InputNumber className="gx-mb-12" min={0} max={100}  />
                          <Input  type="number" max={100} />
                        )}
                      </FormItem> : null }
              

  
                    {this.state.questionarysection ?
                    <FormItem {...formItemLayout1} label="Positive Recommendations">
                        
                          <CKEditor
                          activeClass="p10"
                          content={this.state.positiverecommendations}
                          events={{
                            'change': this.positiverecommendations.bind(this)
                          }}
                        /> 
                    </FormItem> : null }
                </Input.Group>
              </Form> 



        </Modal>
        {
          this.state.panes.length > 0 
          ?  
          <DraggableTabs
          tabPosition="left"
          onChange={this.tabchange}
          activeKey={this.state.activeKey}
          type="editable-card"
          onEdit={this.onEdit}
          hideAdd
          >
            {
            this.state.panes.map(pane => <TabPane className="tab-design" tab={<div className="cust-tabtitle"><span><span className="custom-tab-title">{
            <Tooltip placement="top" title={pane.title}>{pane.title}</Tooltip>}</span> <i onClick={()=>this.showEditModal(pane.key,pane.tabtype)} class='fa fa-edit'></i></span> <span className="custmtabinner"><span style={{'size':'10px'}}> {pane.tabtypetext} </span> </span></div>} key={pane.key}>{pane.content}</TabPane>)
            } 
          </DraggableTabs>
          :
          ''
        }
        {/* {
        this.props.showSuccessMessage
            ? message.success(this.props.successMessage.toString())
            : null
        }

        {
        this.props.showMessage
            ? message.error(this.props.alertMessage.toString())
            : null
        } */}
        {this.props.showSuccessMessage
                    ?
                    notification['success']({
                        message: 'success',
                        description: this.props.successMessage.toString()
                      })
                    : null}

                {this.props.showMessage 
                    ?
                    notification['error']({
                        message: 'error',
                        description: this.props.alertMessage.toString()
                      }) 
                    : null}
    </Card>


      </>
    );
  }

}



const AssetRegistrationForm = Form.create()(Assessment);
const mapStateToProps =  state => {
  return { 
    authUser: state.auth.authUser,
    getProfilerData: state.profilerReducers.get_profiler_res,
    showSuccessMessage: state.assessmentReducers.showSuccessMessage, 
    showMessage: state.assessmentReducers.showMessage, 
    successMessage: state.assessmentReducers.successMessage, 
    alertMessage: state.assessmentReducers.alertMessage,
    AuthDetails : state.auth.UserDetails, 
      }; 
  };

  const mapDispatchToProps = {
    add_assessment: add_assessment,
    get_profiler : get_profiler,
    hideMessage: hideMessage,
    getUserDetails : getUserDetails,
    update_assessment : update_assessment
    
}
// export default AssetRegistrationForm;
export default connect(mapStateToProps,mapDispatchToProps)(AssetRegistrationForm);
