import React, {Component} from "react";
// import {Col, Row, Card, Table, Button} from "antd"; import Auxiliary from
// "util/Auxiliary";
import {Link} from 'react-router-dom'
import {
    Button,
    Card,
    Col,
    Form,
    Input,
    Row,
    Select,
    message,
    notification
} from "antd";

import {connect} from "react-redux";
import {add_profiler, update_profiler, hideMessage, get_staff} from "appRedux/actions/ProfilerActions";

const FormItem = Form.Item;
const Option = Select.Option;
const {TextArea} = Input;

class Addprofiler extends Component {

    constructor(props) {
        super(props);
        this.myRef = React.createRef()  
        this.state = {
            confirmDirty: false,
            company_name: '',
            f_name : '',
            email: 'demo@example.com',
            poc_name: '',
            no_of_assessment: '',
            phone_number: '',
            address1: '',
            address2: '',
            city: '',
            pincode: '',
            note: '',
            isUpdate: false,
            isAdd: true,
            userid: '',
            username: '',
            cardTitle: 'Create Profiler',
            editdata: '',
            disabled: '',
            staffData: ''
        };
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this
            .props
            .form
            .validateFieldsAndScroll((err, values) => {

                if (!err) {
                    console.log('Received values of form: ', values);
                    // dispatch(add_profiler(values));
                    values.no_of_assessment = 0;
                    if (this.state.isUpdate) {
                        values.userid = this.state.userid;
                        values.username = this.state.username;
                        
                        values.update_profile = 0;
                        console.log("values", values)
                        this
                            .props
                            .update_profiler(values);

                        setTimeout(() => {
                            this
                                .props
                                .hideMessage();
                            // this.props.history.push("/profiler");
                            

                        }, 1000); 

                    } else {
                        this
                            .props
                            .add_profiler(values);

                        setTimeout(() => {
                            this
                                .props
                                .hideMessage();
                            // this.props.history.push("/profiler");
                            

                        }, 1000);
                    }

                }
            });

    }

    validateToNextPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirm'], {force: true});
        }
        callback();
    }
    get_staffById(){
        var val = {
            group_id : 5,
            filter : '',
            current : ''
        }
        this.props.get_staff(val);
    }

    componentDidUpdate(prevProps) {
        // if (this.props.location !== prevProps.location) {
        // alert("hi");  
        // window.scrollTo(0, 0)/
        // this.anyRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        // }
        if(this.props.showSuccessMessage || this.props.showMessage)
        {
            if(this.props.showSuccessMessage){
                setTimeout(() => {
                    this.props.hideMessage();
                    this.props.history.push("/profiler");
                }, 100);
            }
            if(this.props.showMessage)
            {
                setTimeout(() => {
                    this.props.hideMessage();
                }, 100);
            }
        }
        this.scrollToMyRef();
      }

    componentWillMount(){
          localStorage.setItem("progress", 40);
        if(typeof this.props.authUser != 'undefined'){

            var authpermission = this.props.authUser.permission;
            console.log("authpermission",authpermission);
            if(authpermission.length > 0){
                if(!authpermission.includes('93')){
                    this
                    .props
                    .history
                    .push("/dashboard");
                }
            }
        }
        // var authpermission = this.props.permission;
        // console.log("authpermission",authpermission);
        // if(!authpermission.includes('93')){
        //     this
        //     .props
        //     .history
        //     .push("/dashboard");
        // }
        // else{
           
        // }
        this.get_staffById();

    }


    componentDidMount() {

        localStorage.setItem("progress", 100);

        setTimeout(() => {  
                    localStorage.setItem("progress", 0);
                  }, 2000);  
        
        let datarecord = this.props.location.state;
        // eslint-disable-next-line
        if (datarecord == "undefined" || datarecord == "" || datarecord == null) {
            // Object is empty (Would return true in this example)
        } 
        else 
        { 
            // Object is NOT empty
            datarecord = datarecord.record
            console.log("datarecord", datarecord);
            const form = this.props.form;
            // eslint-disable-next-line
            form.setFieldsValue({['f_name']: datarecord.full_name,['company_name']: datarecord.company_name,['email']: datarecord.email,['poc_name']: datarecord.pocid,['phone_number']: datarecord.phone_number,['address1']: datarecord.address1,['address2']: datarecord.address2,['city']: datarecord.city,['pincode']: datarecord.pincode,['note']: datarecord.note
            });
            this.setState({isUpdate: true, cardTitle: "Update Profiler", userid: datarecord.id, username: datarecord.username, disabled: "disabled"});
        }
        
        

    }

    render() {
        var staff = this.props.getStaffData.user

        const {getFieldDecorator} = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: {
                    span: 24
                },
                sm: {
                    span: 8
                }
            },
            wrapperCol: {
                xs: {
                    span: 24
                },
                sm: {
                    span: 12
                }
            }
        };
        const formItemLayout1 = {
            wrapperCol: {
                xs: {
                    span: 24
                },
                sm: {
                    span: 24
                }
            }
        };

        // var staff = this.props.getStaffData.user
        // const prefixSelectorStaff = getFieldDecorator('prefix', {initialValue: ''})(
        //     <Select>
        //         {/* {
        //             // console.log("getStaffData",this.props.getStaffData.user)
                    
        //             // if(typeof staff != 'undefined'){
            
        //             //     console.log("getStaff",staff)
        //                 // this.setState({staffData : staff});
                    
        //         } */}
        //         {typeof staff != 'undefined'  ? staff.map((key,val)=>{
        //                    return ( <Option value={key.id}>{key.full_name}</Option> )
        //                 }) : '' }
                    
                        
                
                
        //         {/* <Option value="1">+1</Option> */}
        //     </Select>
        // );

        const prefixSelector = getFieldDecorator('prefix', {initialValue: '1'})(
            <Select style={{
                width: 70
            }}>
                <Option value="1">+1</Option>
            </Select>
        );

        return (
            <Card className="gx-card add_profile_cust" title={this.state.cardTitle}>
                <Link to="/profiler" className="add_profile_back">
                    <Button type="ghost" icon="left">
                        <i className="icon icon-left"></i>
                        BACK
                    </Button>
                </Link>
                <Form onSubmit={this.handleSubmit}>
                    
                    <FormItem {...formItemLayout} label="Name">
                        {getFieldDecorator('company_name', {
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: 'Please enter your Name!'
                                }
                            ]
                        })(<Input id="email1"/>)}
                    </FormItem>
                    <FormItem {...formItemLayout} label="Company Name">
                        {getFieldDecorator('f_name', {
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: 'Please enter your Company Name!'
                                }
                            ]
                        })(<Input id="f_name"/>)}
                    </FormItem>
                    <FormItem {...formItemLayout} label="E-mail">
                        {getFieldDecorator('email', {
                            rules: [
                                {
                                    type: 'email',
                                    message: 'This is not a valid e-mail'
                                }, {
                                    required: true,
                                    whitespace: true,
                                    message: 'Please enter your E-mail!'
                                }
                            ]
                        })(<Input id="email1" disabled={this.state.disabled}/>)}
                    </FormItem>
                    {/* {prefixSelector} */}
                    {/* <FormItem {...formItemLayout} label="POC Name">
                        {getFieldDecorator('poc_name', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please enter your POC Name!',
                                    whitespace: true
                                }
                            ]
                        })({prefixSelectorStaff})}
                    </FormItem> */}

                    <FormItem
                        {...formItemLayout} label="KMS POC"
                    >
                        {getFieldDecorator('poc_name',{
                        // initialValue: 60,
                        rules: [
                            {
                                required: true,
                                message: 'Please enter your POC Name!',
                                whitespace: true
                            }
                        ],
                        })(
                        <Select
                            placeholder="Select a POC"
                            // onChange={this.handleSelectChange}
                        >
                            { 
                            typeof staff != 'undefined'  
                            ? 
                            staff.map((key,val)=>{
                            
                                if(key.banned == true){
                                    return ( <Option value={key.id}>{key.full_name}</Option> )
                                }
                            }) 
                            : 
                            '' 
                            } 

                        </Select>
                        )}
                    </FormItem>

                    {/* <FormItem {...formItemLayout} label="No. Of Assessment">
                        {getFieldDecorator('no_of_assessment', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please enter No. Of Assessment!',
                                    whitespace: true
                                }    
                            ]
                        })(<Input/>)}
                    </FormItem> */}

                    <FormItem {...formItemLayout} label="Phone Number">
                        {getFieldDecorator('phone_number', {
                            rules: [
                                {   
                                    // required: true,
                                    whitespace: true,
                                    pattern: new RegExp("^[0-9]*$"),
                                    message: 'Please enter only digits',
                                },
                               {   min: 10,
                                    message: 'Please enter minimum 10 digits.' 
                                },
                                {   max: 10, 
                                    message: 'Please enter maximum 10 digits.' 
                                },

                            ]
                        })(<Input
                            addonBefore={prefixSelector}
                            style={{
                            width: '100%'
                        }}/>)}
                    </FormItem>

                    <FormItem {...formItemLayout} label="Address 1">
                        {getFieldDecorator('address1', {
                            rules: [
                                {
                                    // required: true,
                                    message: 'Please enter your Address 1!',
                                    whitespace: true
                                }
                            ]
                        })(<Input/>)}
                    </FormItem>

                    {/* <FormItem {...formItemLayout} label="Address 2">
                        {getFieldDecorator('address2', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please enter your Address 2!',
                                    whitespace: true
                                }
                            ]
                        })(<TextArea rows={4}/>)}
                    </FormItem> */}
                    <FormItem {...formItemLayout} label="Address 2">
                        {getFieldDecorator('address2', {
                            rules: [
                                {
                                    // required: true,
                                    message: 'Please enter your Address 2!',
                                    whitespace: true
                                }
                            ]
                        })(<Input/>)}
                    </FormItem>
                    
                    <FormItem {...formItemLayout} label="City">
                        {getFieldDecorator('city', {
                            rules: [
                                {
                                    // required: true,
                                    message: 'Please enter City',
                                    whitespace: true
                                }
                            ]
                        })(<Input/>)}
                    </FormItem>

                    <FormItem {...formItemLayout} label="Zip Code">
                        {getFieldDecorator('pincode', {
                            rules: [
                                {
                                    // required: true,
                                    message: 'Please enter Zip Code',
                                    whitespace: true
                                }
                            ]
                        })(<Input/>)}
                    </FormItem>

                    <FormItem {...formItemLayout} label="Note">
                        {getFieldDecorator('note', {
                            rules: [
                                {
                                    // required: true,
                                    message: 'Please enter your Note!',
                                    whitespace: true
                                }
                            ]
                        })(<TextArea rows={4}/>)}
                    </FormItem>

                    <FormItem {...formItemLayout1}>
                        <Row>
                            <Col span={24} sm={8}></Col>
                            <Col span={24} sm={12}>
                                {this.state.isUpdate
                                    ? <Button type="primary" htmlType="submit">UPDATE</Button>
                                    : <Button type="primary" htmlType="submit">ADD</Button>}
                            </Col>
                        </Row>
                    </FormItem>
                </Form>
                {/* {this.props.showSuccessMessage
                    ? message.success(this.props.successMessage.toString())
                    : null}

                {this.props.showMessage 
                    ? message.error(this.props.alertMessage.toString())
                    : null} */}
                {this.props.showSuccessMessage
                    ?
                    notification['success']({
                        message: 'success',
                        description: this.props.successMessage.toString()
                      })
                    : null}

                {this.props.showMessage 
                    ?
                    notification['error']({
                        message: 'error',
                        description: this.props.alertMessage.toString()
                      }) 
                    : null}
            </Card>
        );
    }
    scrollToMyRef = () => window.scrollTo(0, 0)

}
const mapStateToProps = state => {
    return {authUser: state.auth.authUser, showSuccessMessage: state.profilerReducers.showSuccessMessage, showMessage: state.profilerReducers.showMessage, successMessage: state.profilerReducers.successMessage, alertMessage: state.profilerReducers.alertMessage,getStaffData: state.staffReducers.get_staff_res }
};
// const {successMessage, alertMessage, showMessage, showSuccessMessage} =
// useSelector(({auth}) => auth); Object of action creators
const mapDispatchToProps = {
    add_profiler: add_profiler,
    update_profiler: update_profiler,
    hideMessage: hideMessage,
    get_staff : get_staff,
} 
const RegistrationForm = Form.create()(Addprofiler);
// export default RegistrationForm;
export default connect(mapStateToProps, mapDispatchToProps)(RegistrationForm);
