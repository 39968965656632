import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import $ from 'jquery';;

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    // console.log($('.gx-layout-content').scrollTop(0));
    $('.gx-layout-content').scrollTop(0);
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}