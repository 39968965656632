import React, {Component} from "react";
import {
    Row,
    Card,
    Table,
    Button,
    message,
    Popconfirm,
    Badge,
    notification,
    Input,
    Form,
    Select,
    Tooltip
} from "antd";
//  "antd": "^3.26.6",
import {Link} from 'react-router-dom'
import {connect} from "react-redux";
import {get_reportList,showReportLoader,hideMessage,setstatustoinitial} from "appRedux/actions/ReportActions";
import CircularProgress from "components/CircularProgress/index";
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var profilerData1 = ""
var reportTotal = ''
const Search = Input.Search;

const FormItem = Form.Item;
const Option = Select.Option;

// const Dtext = 'Are you sure delete this task?';
const hurl = "#";
var loader = true;
class Report extends Component {
    constructor(props) {
        super(props);
        this.state = {
            status_value: 'Active',
            status_color: '#6BA539',
            Active_color : '#6BA539',
            Deactive_color: '#ff4d4f',
            data: [],
            banned : '',
            authUser : '',
            searchText: '',
            searchedColumn: '',
            current : 0,
            pagination: {
                current: 0,
                pageSize: 10,
                // defaultPageSize: 10, 
            },
            filter : '',
            authpermission :'',
            loader : false
        };
        // this.confirm = this
        //     .confirm
        //     .bind(this);

    }

    handleTableChange = (pagination, filters, sorter) => {
        console.log("pagination",pagination,sorter)
        this.props.setstatustoinitial()
        var currents = 0;
        if(pagination.current >= 2){
                currents = (pagination.current - 1) * 10
        }
        else{
             currents = 0;
        }

        var values = {
            current : currents,
            pageSize : 10,
            authUser : this.state.authUser,
            filter : this.state.filter,
            field : sorter.field,
            order : sorter.order,
        }
        console.log("values",values) 
        this.props.get_reportList(values);
        // alert('handlechange')

    };

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                this.searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <>
              <Button
                type="primary"
                onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                // icon={}
                size="small"
                style={{ width: 90 }}
              >
                  <SearchOutlined style={{ color: 'white' }} />
                Search
              </Button>
              <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Reset
              </Button>
            </>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
          record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => this.searchInput.select());
          }
        },
        render: text =>
          this.state.searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[this.state.searchText]}
              autoEscape
              textToHighlight={text.toString()}
            />
          ) : (
            text
          ),
      });
    
      handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
      };
    
      handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
      };
    // confirm(key) {
    //     console.log("mydata",key);
    //     message.info('Are you sure want to delete?');
    //     this.setState({sget_profilerByIdatus_value: 'Deactive', status_color: '#ff4d4f'});

    // }

    get_reportListById = () =>{
        // alert(this.props.status);
        // if(this.props.status == 'Initial'){
            // this.props.showReportLoader();
            // alert('getreport list')
            var values = {
                current : this.state.current,
                pageSize : 10,
                authUser : this.state.authUser,
                filter : '',
                field : '',
                order : '',
            }
            console.log("values",values)
            this.props.get_reportList(values);
            // console.log("vishalmelwani")

        // }
        
    }
    componentDidUpdate(){

        if(this.props.showSuccessMessage || this.props.showMessage)
        {
            this.get_reportListById()
            setTimeout(() => {
                this.props.hideMessage();
            }, 100);
        }

        // 
    }
    componentWillMount(){
            localStorage.setItem("progress", 40);
        if(typeof this.props.authUser != 'undefined'){

            var authpermission = this.props.authUser.permission;
            console.log("authpermission",authpermission);
            if(authpermission.length > 0){
                if(!authpermission.includes('96')){
                    this
                    .props
                    .history
                    .push("/dashboard");
                }
            }
            this.setState({authUser : this.props.authUser.id, authpermission : authpermission})
        }
        this.props.setstatustoinitial()
        // this.get_reportListById();
        this.props.showReportLoader(); 
        
    }

    componentDidMount(){
        localStorage.setItem("progress", 100);

        setTimeout(() => {  
                    localStorage.setItem("progress", 0);
                  }, 2000);  
        this.props.setstatustoinitial()
        this.get_reportListById();
        //publishAssessmentReport_post

      
        profilerData1 = this.props.getReportListData;
        
        if(this.isEmpty1(profilerData1)) {
            // Object is empty (Would return true in this example) 
        } else {
            // Object is NOT empty
            this.setState({pagination : {total : profilerData1.total}})
            reportTotal = profilerData1.total
            console.log("profilerData1.total",profilerData1.total)
        }
    }

    isEmpty1 = (obj) => {
        for(var key in obj) {
            if(obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    redirectlink_forDocX = (link) =>{
  
            // previewVisible = true;
            localStorage.setItem("previewdocURL",link);
            setTimeout(() => {
                window.open('/previewDocx');
                // link_show = "";
            }, 1000);
            
        
    }
    searchInputData = (val) => {
        console.log("searchInputData",val)
        this.props.setstatustoinitial()
        this.setState({filter : val})
        var values = {
            current : 0,
            pageSize : 10,
            authUser : this.state.authUser,
            filter : val,
            field : '',
            order : '',
        }
        console.log("values",values)
        this.props.get_reportList(values);
        // alert("search in put")

    }
    searchInputDataClear = (e) => {
        console.log("searchInputDataClear",e)
    }

    clearFiltersData = () => {
        this.props.setstatustoinitial()
        this.setState({filter : null})
        var values = {
            current : 0,
            pageSize : 10,
            authUser : this.state.authUser,
            filter : '',
            field : '',
            order : '',
        }
        console.log("values",values)
        this.props.get_reportList(values);
        // alert('clear filter')
    }
    // componentDidUpdate() {
    //     window.scrollTo(0,0);
    //   }
    render(){
        function isEmpty(obj) {
            for(var key in obj) {
                if(obj.hasOwnProperty(key))
                    return false;
            }
            return true;
        }
        var profilerData = this.props.getReportListData;
        var pdata = ''; 
        var excelData = '';
        if(isEmpty(profilerData)) {
            // Object is empty (Would return true in this example) 
        } else {
            // Object is NOT empty
            pdata = profilerData.reportlist
            reportTotal = profilerData.total
            excelData = profilerData.excelData
            console.log("profilerData",profilerData.reportlist)
            loader = false;
        }


        const columns = [
            {
                title: 'Profiler Name',
                dataIndex: 'profiler_name',
                sorter: true,
            },
            {
                title: 'Assessment Name',
                dataIndex: 'AssessmentName',
                sorter: true,
                // ...this.getColumnSearchProps('AssessmentName'),
                // width: 120,
                // key: "company_name",
                // fixed: 'left',
                // defaultSortOrder: "descend",
                // sorter: (a, b) => (a.company_name ? a.company_name.localeCompare(b.company_name) : 1),

            }, {
                title: 'Manufacturer Name',
                dataIndex: 'CompanyName',
                sorter: true,
                // ...this.getColumnSearchProps('CompanyName'),
                // width: 110,
                // sorter: (a, b) => (a.CompanyName ? a.CompanyName.localeCompare(b.CompanyName) : 1),

            },{
                title: 'POC',
                dataIndex: 'POCName',
                sorter: true,
                // ...this.getColumnSearchProps('POCName'),
                // width: 120,
                // sorter: (a, b) => (a.POCName ? a.POCName.localeCompare(b.POCName) : 1),
                
            },
            
            {
                title: 'Status',
                // dataIndex: 'date',
                // width: 100,
                render : (text,record) =>(
                    <span>
                        {
                            record.status
                            ?
                     
                                <Badge
                                    className="site-badge-count-109"
                                    // abc={ this.setState({banned : record.banned}) }
                                    // count={this.state.status_value}
                                    count="Published"
                                    style={{
                                    backgroundColor: this.state.Active_color,
                                    width: "110px"
                                    }}
                                />
                            :
                                record.report_link == null
                                ?
                                    <Badge
                                        className="site-badge-count-109"
                                        // abc={ this.setState({banned : record.banned}) }
                                        // count={this.state.status_value}
                                        count="Ready to Review"
                                        style={{
                                        backgroundColor: '#DC4405',
                                        width: "110px"
                                        }}
                                    />
                                :
                                    <Badge
                                        className="site-badge-count-109"
                                        // abc={ this.setState({banned : record.banned}) }
                                        // count={this.state.status_value}
                                        count="Drafted View"
                                        style={{
                                        backgroundColor: this.state.Deactive_color,
                                        width: "110px"
                                        }}
                                    />

                        }
                    </span>
                )
       
            },
            
            {
                title: 'Action',
                key: 'action',
                // width: 200,
                render: (text, record) => (

                    <span>
                        {
                            record.status
                            ?
                                <>
                                    <span className="hoverCursor">
                                        <a href={"https://docs.google.com/viewerng/viewer?url="+record.report_link} target="_blank" >
                                        <Tooltip placement="top" title='View Submitted Assessment'>
                                        <i className="icon icon-view"></i>
                                        </Tooltip>
                                        </a>
                                    </span>
                                    
                                    {'\u00A0'}|{'\u00A0'} 
                                    
                                    <span className="hoverCursor">
                                        <a href={record.report_link} target="_blank" >
                                        {/* <i className="icon icon-chevron-down" ></i> */}
                                        Download
                                        </a>
                                    </span>
                                        
                                    
                                    
                                     {/* <span className="hoverCursor" onClick={()=> this.redirectlink_forDocX(record.report_link)} >
                                        <Badge
                                            className="site-badge-count-109"
                                            // abc={ this.setState({banned : record.banned}) }
                                            // count={this.state.status_value}
                                            count="Preview"
                                            style={{
                                            backgroundColor:"#DC4405",
                                            width: "70px"
                                            }}
                                        />
                                    </span>
                                    {'\u00A0'}|{'\u00A0'} 
                                    <Link to={{
                                        pathname:"",
                                        // update : record
                                    }} >
                                        (Send Email)
                                    </Link> */}
                                </>

                            :
                                <Link to={{
                                    pathname:"/edit-report",
                                    state : {record : record}
                                }} >
                                        <Badge
                                        className="site-badge-count-109"
                                        // abc={ this.setState({banned : record.banned}) }
                                        // count={this.state.status_value}
                                        count="EDIT"
                                        style={{
                                        backgroundColor:"#10069f",
                                        width: "70px"
                                        }}
                                    />
                                </Link>
                        }

                    </span> 
                )
            }
        ];

        const excelbtn = (
            <ExcelFile filename="ReportList" element={<Button type="primary" className="exportbtn" icon="download" >EXPORT</Button>}>
                <ExcelSheet data={excelData} name="ReportList">
                    <ExcelColumn label="Profiler Name" value="profiler_name"/>
                    <ExcelColumn label="Assessment Name" value="AssessmentName"/>
                    <ExcelColumn label="Manufacturer Name" value="CompanyName"/>
                    <ExcelColumn label="POC" value="POCName"/>
                    {/* <ExcelColumn label="Status" value="sex"/> */}
                    <ExcelColumn label="Status"
                                 value={(record) => record.status == "1"  ? "Published" : "Drafted"}/>
                </ExcelSheet>
            </ExcelFile>
        );

        const {getFieldDecorator} = this.props.form;
    
        // const {autoCompleteResult} = this.state;

        const formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 10},
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 14},
        },
        };
        const searchBtn = (
            // <Form>
            //     <FormItem
            //       >
            //         {getFieldDecorator('tabname', {
            //           rules: [{
            //             required: true, 
            //             message: 'Please enter',
            //             whitespace: true
            //           }],
            //         //   onChange : (e) => this.searchInputData(e.target.value)
            //         })(
            //           <Input placeholder="Please enter Section Name" />
            //         )}
            //       </FormItem>
            //       <Button type="primary" onClick={(e) => this.searchInputData(e.target.value)} shape="circle" icon={<SearchOutlined />} />
            //       <Button type="primary" onClick={() => this.clearFiltersData()} shape="circle" icon="close"  />
            // </Form>
             <Search
             className="searchBtn"
             placeholder="Search"
             onSearch={(value) => this.searchInputData(value)}
             style={{width: 300, marginLeft:10,marginRight:10}}
             allowClear = {(e)=>this.searchInputDataClear(e)}
           />
            
        );

        const cancelbtn = (<Button type="primary" onClick={() => this.clearFiltersData()} shape="circle" icon="close"  />);
        const { data, pagination, loading } = this.state;

        return (
         
                    <Card 
                        className="gx-card  cust_asses"
                        style={{width: "100%"}}
                        title="Report" 
                        extra={ 
                        <div className="cardextraelement">
                            {searchBtn}    
                            {/* {cancelbtn}                        */}
                            {excelbtn}
                        </div>
                            }>
                        {/* this.state.loader */}
                        {/* {this.state.loader ?
                        <div className="gx-loader-view">
                            <CircularProgress/>
                        </div> : null}      */}
                        {/* {this.props.showSuccessMessage ?
              message.success(this.props.successMessage.toString()) : null}
                        {this.props.showMessage ?
              message.error(this.props.alertMessage.toString()) : null} */}
                        {/* {this.state.loader ? null :  */}
                        <Table style={{width: "100%"}}
                            className="gx-table-responsive"
                            loading ={this.props.loader}
                            columns={columns}
                            dataSource={pdata}
                            rowkey={record => record.id}
                            pagination={pagination,{total : reportTotal,hideOnSinglePage: true}}
                            onChange={this.handleTableChange}
                            // scroll={{ x: "max-content" }}
                        /> 
                        {/* } */}

                {this.props.showSuccessMessage
                    ?
                    notification['success']({
                        message: 'success',
                        description: this.props.successMessage.toString(),
                      })
                    : null}

                {this.props.showMessage 
                    ?
                    notification['error']({
                        message: 'error',
                        description: this.props.alertMessage.toString(),
                      }) 
                    : null}
                    </Card>
            
        );
    }
    
};
const ReportForm = Form.create()(Report);
// Object of action creators
const mapDispatchToProps = {
    get_reportList,
    showReportLoader,
    hideMessage,
    setstatustoinitial
}

const mapStateToProps =  state => {
return { 
        getReportListData: state.reportReducers.get_reportList_res,
        loader : state.reportReducers.loader,
        showSuccessMessage : state.reportReducers.showSuccessMessage,
        successMessage : state.reportReducers.successMessage,
        authUser : state.auth.authUser,
        showMessage : state.reportReducers.showMessage,
        alertMessage : state.reportReducers.alertMessage,
        status : state.reportReducers.status,
    }; 
};

// export default Profiler;
export default connect(mapStateToProps,mapDispatchToProps)(ReportForm);
