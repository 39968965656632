import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import axios from 'axios';
import {baseURL, apikey} from 'util/config'
import {
    ADD_STAFF,
    UPDATE_STAFF,
    DELETE_USER_ROW,
    USER_STATUS_CHANGE,
    GET_STAFF,
    ADD_ASSESSMENT,
    UPDATE_ASSESSMENT,
    GET_ASSESSMENT,
    DELETE_ASSESSMENT_ROW,
    ASSESSMENT_STATUS_CHANGE,
    ASSESSMENT_COPY_DATA,
    SUBMIT_ASSESSMENT_ANSWER,
    GET_ASSESSMENT_BYPROFILER,
    ADD_COMPANY_FOR_ASSESSMENT,
    UPDATE_COMPANY_FOR_ASSESSMENT,
    GET_COMPANY_FOR_ASSESSMENT,
    UPDATE_PROFILER_USER,
    GET_METRIC_TABLE,
    GET_ASSESSMENT_With_SUBMITED,
    GET_METRIC_GRAPH,
    GET_ASSESSMENT_BY_PROFILER_DASHBOARD,
    GET_HISTORY_ASSIGNED_ASSESSMENT,
    CREATE_ASSESSMENT_EXCEL
} from "constants/ActionTypes";
import {showAuthMessage, showErrorMessage, showSuccessMessage, getAssessmentSuccess, getAssessmentbyprofilerSuccess,getAssessmentbyprofilerDashboardSuccess,history_AssignedAssessment_SuccessData,getAssessmentForCompanySuccessData, getAssessmentForCompany,getMetricTable_successData,get_assessmentWithSubmitedSuccess,getMetricGraph_successData,createAssessmentExcelSuccess} from "../actions/AssessmentActions";

export const token = (state) => state.token;

const headers = {
    'Content-Type': 'application/json',
    'X-API-KEY': apikey
}

const headersWithToken = {
    'Content-Type': 'application/json',
    'X-API-KEY': apikey,
    'X-Token' : token

}

/*Assessment Module Start Add, Get*/

const getAssessmentResByUserId = async(authID,filter,current, field, order) => await axios.get(baseURL + 'api/assessment/assessment?userId='+authID+"&filter="+filter+"&start="+current+"&sort_by="+field+"&sort_order="+order, {headers: headers})
    .then(getStaffRes => getStaffRes.data)
    .catch(error => error);

    
const getMetricTableData = async(assessment_id) => await axios.get(baseURL + 'api/assessment/metricPerformanceByTableAssessment?assessment_id='+assessment_id, {headers: headers})
    .then(getMetricTableDataRes => getMetricTableDataRes.data)
    .catch(error => error);

    
const getMetricGraphData = async(assessment_id) => await axios.get(baseURL + 'api/assessment/metricPerformanceByGraphAssessment?assessment_id='+assessment_id, {headers: headers})
.then(getMetricGraphDataRes => getMetricGraphDataRes.data)
.catch(error => error);
// const addAssessmentByProfiler = async(title,description, publish, userid, profiler_ids, section_1, section_2, section_3, section_4) => await axios.post(baseURL + 'api/assessment/add', {
//     "name": title,
//     "publish": publish,
//     "status": 0,//inactive
//     "description": description,
//     "userid" : userid,
//     "profiler_id" : profiler_ids,
//     "assessment_section_1" : section_1,
//     "assessment_section_2" : section_2,
//     "assessment_section_3" : section_3,
//     "assessment_section_4" : section_4
// }, {headers: headers})
//     .then(resetRes => resetRes.data)
//     .catch(error => error);
const getAssessmentForCompanyData = async(profiler_id,assessment_id,filter,current,field,order) => await axios.post(baseURL + 'api/assessment/getAssessmentForCompany?filter='+filter+'&current='+current+'&field='+field+'&order='+order, {
    "profiler_id": profiler_id,
    "assessment_id": assessment_id
}, {headers: headers})
    .then(getRescomp => getRescomp.data)
    .catch(error => error);

const addAssessmentByProfiler = async(title,description, publish, userid, profiler_ids, all_section) => await axios.post(baseURL + 'api/assessment/addassessment', {
    "name": title,
    "publish": publish,
    "status": 0,//inactive
    "description": description,
    "userid" : userid,
    "profiler_id" : profiler_ids,
    "all_section" : all_section
}, {headers: headers})
    .then(resetRes => resetRes.data)
    .catch(error => error);

const addAssessmentForCompany = async(profiler_id,assessment_id,company_name,person_name) => await axios.post(baseURL + 'api/assessment/addAssessmentForCompany', {
    "profiler_id": profiler_id,
    "assessment_id": assessment_id,
    "company_name": company_name,
    "person_name" : person_name
}, {headers: headers})
    .then(resetResforcom => resetResforcom.data)
    .catch(error => error);    
    

const updateAssessmentForCompany = async(comp_id, avatar) => await axios.post(baseURL + 'api/assessment/updateAssessmentForCompany', {
    "id": comp_id,
    "avatar": avatar
}, {headers: headers})
    .then(resetResforcom => resetResforcom.data)
    .catch(error => error);

const getAssessmentResByProfilerId = async(profiler_id,type) => await axios.get(baseURL + 'api/assessment/assessmentByProfiler?profile_id='+profiler_id+'&type='+type, {headers: headers})
    .then(getAssessmentRes => getAssessmentRes.data) 
    .catch(error => error);
    

const getAssessmentResByProfilerId_Dashboard = async(profiler_id,type,filter,current) => await axios.get(baseURL + 'api/assessment/assessmentByProfilerDashboard?profile_id='+profiler_id+'&type='+type+'&filter='+filter+'&start='+current, {headers: headers})
.then(getAssessmentRes => getAssessmentRes.data) 
.catch(error => error);


const getAssignedAssessmentProfiler_max = async(profiler_id,filter,current) => await axios.get(baseURL + 'api/assessment/history_AssignedAssessment?profilerId='+profiler_id+'&filter='+filter+'&start='+current, {headers: headers})
.then(getAssessmentRes => getAssessmentRes.data) 
.catch(error => error);

const getAssessment_With_Submited = async(userId) => await axios.get(baseURL + 'api/assessment/AssessmentForSubmitedReport?userId='+userId, {headers: headers})
    .then(getAssessmentRes => getAssessmentRes.data)
    .catch(error => error);

const  submitanswerapicall = async(answers,company_id,status, profiler_id, assessment_id) => await axios.post(baseURL + 'api/assessment/addassessmentAnswer', {
    "profiler_id": profiler_id,
    "company_id" : company_id,
    "assessment_id": assessment_id,
    "status": status,//inactive
    "all_section": answers
}, {headers: headers})
    .then(resetRes => resetRes.data)
    .catch(error => error);

const updateAssessmentByProfiler = async(title,description, publish, userid, profiler_ids, all_section,assessmentID) => await axios.post(baseURL + 'api/assessment/updateAssessment', {
    "id" : assessmentID,
    "name": title,
    "publish": publish,
    // "status": 0,//inactive
    "description": description,
    "userid" : userid,
    "profiler_id" : profiler_ids,
    "all_section" : all_section
}, {headers: headers})
    .then(resetRes => resetRes.data)
    .catch(error => error);
    

const changeAssessmentStatusById = async(id,status) => await axios.post(baseURL + 'api/assessment/changeAssessmentStatus', {
    "id": id,
    "status": status
}, {headers: headers})
    .then(resetRes => resetRes.data)
    .catch(error => error);

const CopyDataById = async(id,assessment_id) => await axios.post(baseURL + 'api/assessment/CopyData', {
    "id": id,
    "assessment_id" : assessment_id
}, {headers: headers})
    .then(resetRes => resetRes.data)
    .catch(error => error);
    
const deleteAssessmentDataById = async(assessment_id) => await axios.post(baseURL + 'api/assessment/delete', {
    "id": assessment_id
}, {headers: headers})
    .then(delres => delres.data)
    .catch(error => error);

//updaqte profiler
const addUpdateprofileruserWithGroup = async(full_name, company_name, email, poc_name, phone_number, address1,address2,city,pincode, note, username, user_id, update_profile,no_of_assessment,avatar) => await axios.post(baseURL + 'api/user/updateuserByProfiler', {
    "company_name": company_name,
    "email": email,
    "poc_name": poc_name,
    "no_of_assessment": no_of_assessment,
    "phone_number": phone_number,
    "address1": address1,
    "address2": address2,
    "city": city,
    "pincode": pincode,
    "note": note,
    "username": username, 
    "id": user_id,
    "update_profile" : update_profile,
    "avatar" : avatar,
    "full_name":full_name
    // "password" : password
}, {headers: headers})
    .then(updateProfilerRes => updateProfilerRes.data)
    .catch(error => error);

const createAssessmentExcelAPIRequest = async(assessment_id) => await axios.post(baseURL + 'api/assessment/createAssessmentExcel', {
        "assessment_id" : assessment_id
    }, {headers: headers})
        .then(resetRes => resetRes.data)
        .catch(error => error);
/*Assessment Module END Add, Get*/

//+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++   
//Staff Module start ADD, GET

function * getMetricTable({payload}) {
    const {assessment_id} = payload;
    try {
        const getMetricTableRes = yield call(getMetricTableData,assessment_id);
        // console.log("getProfilerRes",getProfilerRes);
        if (getMetricTableRes.status) {

            yield put(getMetricTable_successData(getMetricTableRes.data));
            // yield put(showSuccessMessage(getProfilerRes.message));
        } else {
            yield put(showErrorMessage(getMetricTableRes.message));
        }
    } catch (error) {
        yield put(showErrorMessage(error));
    }
}


function * getMetricGraph({payload}) {
    const {assessment_id} = payload;
    try {
        const getMetricGraphRes = yield call(getMetricGraphData,assessment_id);
        // console.log("getProfilerRes",getProfilerRes);
        if (getMetricGraphRes.status) {

            yield put(getMetricGraph_successData(getMetricGraphRes.data));
            // yield put(showSuccessMessage(getProfilerRes.message));
        } else {
            yield put(showErrorMessage(getMetricGraphRes.message));
        }
    } catch (error) {
        yield put(showErrorMessage(error));
    }
}

function * getAssessmentById({payload}) {
    const {authID,filter,current, field, order} = payload;
    try {
        const getAssessmentRes = yield call(getAssessmentResByUserId,authID,filter,current, field, order);
        // console.log("getProfilerRes",getProfilerRes);
        if (getAssessmentRes.status) {

            yield put(getAssessmentSuccess(getAssessmentRes.data));
            // yield put(showSuccessMessage(getProfilerRes.message));
        } else {
            yield put(showErrorMessage(getAssessmentRes.message));
        }
    } catch (error) {
        yield put(showErrorMessage(error));
    }
}


function * getAssessmentForComp({payload}) {
    const {profiler_id,assessment_id,filter,current,field,order} = payload;
    try {
        const getAssessmentForcom = yield call(getAssessmentForCompanyData,profiler_id,assessment_id,filter,current,field,order);
        // console.log("getProfilerRes",getProfilerRes);
        if (getAssessmentForcom.status) {

            yield put(getAssessmentForCompanySuccessData(getAssessmentForcom.data));
            // yield put(showSuccessMessage(getProfilerRes.message));
        } else {
            yield put(showErrorMessage(getAssessmentForcom.message));
        }
    } catch (error) {
        yield put(showErrorMessage(error));
    }
}

function * addAssessmentWithForm({payload}) {
    const {title,description, publish, userid, profiler_ids, all_section} = payload;
    try {
        const addassessment = yield call(addAssessmentByProfiler, title,description, publish, userid, profiler_ids, all_section);
        if (addassessment.status) {
            yield put(showSuccessMessage(addassessment.message)); 
        } else {
            yield put(showErrorMessage(addassessment.message));
        }
    } catch (error) {
        yield put(showErrorMessage(error));
    }
}


function * addAssessmentToCompany({payload}) {
    const {profiler_id,assessment_id,company_name,person_name} = payload;
    try {
        const addassessmentforcomp = yield call(addAssessmentForCompany, profiler_id,assessment_id,company_name,person_name);
        if (addassessmentforcomp.status) {
            yield put(showSuccessMessage(addassessmentforcomp.message)); 
        } else {
            yield put(showErrorMessage(addassessmentforcomp.message));
        }
    } catch (error) {
        yield put(showErrorMessage(error));
    }
}

function * updateAssessmentToCompany({payload}) {
    const {comp_id, avatar} = payload;
    try {
        const addassessmentforcomp = yield call(updateAssessmentForCompany, comp_id, avatar);
        if (addassessmentforcomp.status) {
            yield put(showSuccessMessage(addassessmentforcomp.message)); 
        } else {
            yield put(showErrorMessage(addassessmentforcomp.message));
        }
    } catch (error) {
        yield put(showErrorMessage(error));
    }
}

function * getAssessmentByProfilerId({payload}) {
    const {profiler_id,type,filter,current} = payload;
    try {
        const getAssessmentRes = yield call(getAssessmentResByProfilerId,profiler_id,type,filter,current);
        // console.log("getProfilerRes",getProfilerRes);
        if (getAssessmentRes.status) {

            yield put(getAssessmentbyprofilerSuccess(getAssessmentRes.data));
            // yield put(showSuccessMessage(getProfilerRes.message));
        } else {
            yield put(showErrorMessage(getAssessmentRes.message));
        }
    } catch (error) {
        yield put(showErrorMessage(error));
    }
}


function * getAssessmentByProfilerId_DAshboard({payload}) {
    const {profiler_id,type,filter,current,} = payload;
    try {
        const getAssessmentRes = yield call(getAssessmentResByProfilerId_Dashboard,profiler_id,type,filter,current);
        // console.log("getProfilerRes",getProfilerRes);
        if (getAssessmentRes.status) {

            yield put(getAssessmentbyprofilerDashboardSuccess(getAssessmentRes.data));
            // yield put(showSuccessMessage(getProfilerRes.message));
        } else {
            yield put(showErrorMessage(getAssessmentRes.message));
        }
    } catch (error) {
        yield put(showErrorMessage(error));
    }
}


function * getAssignedAssessmentProfiler({payload}) {
    const {profiler_id,filter,current} = payload;
    try {
        const getAssessmentRes = yield call(getAssignedAssessmentProfiler_max,profiler_id,filter,current);
        // console.log("getProfilerRes",getProfilerRes);
        if (getAssessmentRes.status) {

            yield put(history_AssignedAssessment_SuccessData(getAssessmentRes.data));
            // yield put(showSuccessMessage(getProfilerRes.message));
        } else {
            yield put(showErrorMessage(getAssessmentRes.message));
        }
    } catch (error) {
        yield put(showErrorMessage(error));
    }
}

function * getAssessment_WithSubmited({payload}) {
    // const {} = payload;
    try {
        const getAssessment_WithSubmited = yield call(getAssessment_With_Submited,payload);
        // console.log("getProfilerRes",getProfilerRes);
        if (getAssessment_WithSubmited.status) {

            yield put(get_assessmentWithSubmitedSuccess(getAssessment_WithSubmited.data));
            // yield put(showSuccessMessage(getProfilerRes.message));
        } else {
            yield put(showErrorMessage(getAssessment_WithSubmited.message));
        }
    } catch (error) {
        yield put(showErrorMessage(error));
    }
}
function * submitanswerrequest({payload}) {
    const {answers,company_id,status, profiler_id, assessment_id} = payload;
    try {
        const submitansassessment = yield call(submitanswerapicall, answers,company_id,status, profiler_id, assessment_id);
        console.log("vishalmelwani",submitansassessment);
        if (submitansassessment.status) {
            yield put(showSuccessMessage(submitansassessment.message)); 
        } else {
            yield put(showErrorMessage(submitansassessment.message));
        }
    } catch (error) {
        yield put(showErrorMessage(error));
    }
}

function * updateAssessmentWithForm({payload}) {
    const {title,description, publish, userid, profiler_ids, all_section,assessmentID} = payload;
    try {
        const updateassessment = yield call(updateAssessmentByProfiler, title,description, publish, userid, profiler_ids,all_section ,assessmentID);
        if (updateassessment.status) {
            yield put(showSuccessMessage(updateassessment.message)); 
        } else {
            yield put(showErrorMessage(updateassessment.message));
        }
    } catch (error) {
        yield put(showErrorMessage(error));
    }
}

function * deleteAssessmentById({payload}) {
    
    try {
        const delassessment = yield call(deleteAssessmentDataById, payload);
        if (delassessment.status) {
            yield put(showSuccessMessage(delassessment.message)); 
        } else {
            yield put(showErrorMessage(delassessment.message));
        }
    } catch (error) {
        yield put(showErrorMessage(error));
    }
}


function * changeAssessmentStatus({payload}) {
    const {id,status} = payload;
    try {
        const changeAssessment = yield call(changeAssessmentStatusById, id,status);
        if (changeAssessment.status) {
            yield put(showSuccessMessage(changeAssessment.message)); 
        } else {
            yield put(showErrorMessage(changeAssessment.message));
        }
    } catch (error) {
        yield put(showErrorMessage(error));
    }
}


function * CopyData({payload}) {
    const {id, assessment_id} = payload;
    try {
        const copydata = yield call(CopyDataById, id, assessment_id);
        if (copydata.status) {
            yield put(showSuccessMessage(copydata.message)); 
        } else {
            yield put(showErrorMessage(copydata.message));
        }
    } catch (error) {
        yield put(showErrorMessage(error));
    }
}

//updaqte profiler
function * addupdateprofileruserWithEmail({payload}) {
    const {
        company_name,
        email,
        poc_name,
        no_of_assessment,
        phone_number,
        address1,
        address2,
        city,
        pincode,
        note,
        username,
        userid,
        update_profile,
        avatar,
        full_name
        // password
    } = payload;
    try {
        const addUpdateRes = yield call(addUpdateprofileruserWithGroup, full_name, company_name, email, poc_name, phone_number, address1,address2,city,pincode, note, username, userid, update_profile,no_of_assessment,avatar);
        if (addUpdateRes.status) {
            yield put(showSuccessMessage(addUpdateRes.message));
        } else {
            yield put(showErrorMessage(addUpdateRes.message));
        }
    } catch (error) {
        yield put(showErrorMessage(error));
    }
}
function * createAssessmentExcelAPI({payload}) {
    try {
        const copydata = yield call(createAssessmentExcelAPIRequest, payload);
        if (copydata.status) {
            window.open(baseURL+copydata.url, '_blank');
            yield put(showSuccessMessage(copydata.message)); 
        } else {
            yield put(showErrorMessage(copydata.message));
        }
    } catch (error) {
        yield put(showErrorMessage(error));
    }
}
//Staff Module start ADD, Get

//+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
export function * getassessment() {
    yield takeEvery(GET_ASSESSMENT, getAssessmentById);
}

export function * getmetric() {
    yield takeEvery(GET_METRIC_TABLE, getMetricTable);
}


export function * getmatricgraph() {
    yield takeEvery(GET_METRIC_GRAPH, getMetricGraph);
}

export function * getassessmentforcomp() {
    yield takeEvery(GET_COMPANY_FOR_ASSESSMENT, getAssessmentForComp);
}
export function * addassessment() {
    yield takeEvery(ADD_ASSESSMENT, addAssessmentWithForm);
}

export function * updateassessment() {
    yield takeEvery(UPDATE_ASSESSMENT, updateAssessmentWithForm);
}


export function * deleteassessment() {
    yield takeEvery(DELETE_ASSESSMENT_ROW,deleteAssessmentById);
}

export function * changeassessment() {
    yield takeEvery(ASSESSMENT_STATUS_CHANGE,changeAssessmentStatus);
}

export function * data(){
    yield takeEvery(ASSESSMENT_COPY_DATA,CopyData);
}

export function * submitanswer(){
    yield takeEvery(SUBMIT_ASSESSMENT_ANSWER,submitanswerrequest);
}
export function * getassessmentbyprofiler() {
    yield takeEvery(GET_ASSESSMENT_BYPROFILER, getAssessmentByProfilerId);
}

export function * getassessmentbyprofilerDashboard() {
    yield takeEvery(GET_ASSESSMENT_BY_PROFILER_DASHBOARD, getAssessmentByProfilerId_DAshboard);
}

export function * getAssignedAssessment() {
    yield takeEvery(GET_HISTORY_ASSIGNED_ASSESSMENT, getAssignedAssessmentProfiler);
}
export function * getassessmentWithSubmited() {
    yield takeEvery(GET_ASSESSMENT_With_SUBMITED, getAssessment_WithSubmited);
}
export function * assessmenttocompany() {
    yield takeEvery(ADD_COMPANY_FOR_ASSESSMENT, addAssessmentToCompany);
}

export function * updateassessmenttocompany() {
    yield takeEvery(UPDATE_COMPANY_FOR_ASSESSMENT, updateAssessmentToCompany);
}
export function * updateprofileruser() {
    yield takeEvery(UPDATE_PROFILER_USER, addupdateprofileruserWithEmail);
}
export function * createAssessmentExcel() {
    yield takeEvery(CREATE_ASSESSMENT_EXCEL, createAssessmentExcelAPI);
}


export default function * rootSaga() {
    yield all([
        fork(getassessment),
        fork(addassessment),
        fork(updateassessment),
        fork(deleteassessment),
        fork(changeassessment),
        fork(data),
        fork(submitanswer),
        fork(getassessmentbyprofiler),
        fork(assessmenttocompany),
        fork(updateassessmenttocompany),
        fork(getassessmentforcomp),
        fork(updateprofileruser),
        fork(getmetric),
        fork(getassessmentWithSubmited),
        fork(getmatricgraph),
        fork(getassessmentbyprofilerDashboard),
        fork(getAssignedAssessment),
        fork(createAssessmentExcel),
    ]);
}
